import React from "react";
import { TextField, Tooltip, InputAdornment, IconButton, MenuItem, Grid, Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { errorFormat } from 'utils';

const TextFieldWithInfo = (props) => {

  const { name, sx, label, value, onChange, error, required, helperTextDown, helperText, select, selectItems, selectKeyField
    , selectValueField, inputRef, validatorProps, onFocus, startAdormentObject, placeholder, fullWidth, multiline, autoFocus,
    onClickInfo, hrefInfo, targetHref, type, disabled, onKeyPress, pasteAddornment } = props

  const helperAddormentJSX = <InputAdornment position="end">
    <Tooltip title={helperText ? helperText : ""} >
      <IconButton
        aria-label={"info" + name}
        edge="end"
        sx={{ marginRight: "0.5em" }}
        onClick={onClickInfo ? onClickInfo : null}
        href={hrefInfo}
        target={targetHref}>
        {<InfoIcon />}
      </IconButton>
    </Tooltip>
  </InputAdornment >;

  const handlePasteClick = async () => {
    try {
      const text = await navigator.clipboard.readText();
      onChange({ target: { value: text } });
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  const pasteAddornmentJsx = <InputAdornment position="end">
    <Button onClick={handlePasteClick}>Pegar</Button>
  </InputAdornment>

  const getEndAddorment = () => {
    if (helperText) return helperAddormentJSX;
    if (select) return "";
    if (pasteAddornment) return pasteAddornmentJsx;
  }

  return (
    <>
      <TextField
        name={name}
        onKeyPress={onKeyPress ? onKeyPress : () => null}
        fullWidth={fullWidth}
        inputRef={inputRef}
        sx={sx}
        autoFocus={autoFocus}
        id={name}
        type={type ? type : ""}
        required={required}
        helperText={helperTextDown ? helperTextDown : ""}
        margin="normal"
        disabled={disabled}
        onFocus={onFocus}
        label={label}
        error={error}
        placeholder={placeholder ? placeholder : ""}
        value={value}
        onChange={onChange}
        select={select ? true : false}
        InputProps={{
          endAdornment: getEndAddorment(),
          startAdornment: startAdormentObject ? startAdormentObject : "",
        }}
        multiline={multiline}
      >
        {select ? selectItems.map((item) => (
          <MenuItem key={selectKeyField ? name + item[`${selectKeyField}`] : name + item} value={selectValueField ? item[`${selectValueField}`] : item}>
            {selectValueField ? item[`${selectValueField}`] : item}
          </MenuItem>
        )) : ""}
      </TextField >
      <Grid sx={validatorProps && validatorProps.sx ? validatorProps.sx : {}}>
        {validatorProps ? validatorProps.validator.current.message(name, value, validatorProps.restrictions, {
          className: 'text-danger',
          messages: { default: validatorProps.message },
          element: (message) => errorFormat(message)
        }) : ""}
      </Grid>

    </>
  )
}

export default TextFieldWithInfo;
