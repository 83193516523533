import { paymentDefault } from 'factory/payments.factory';
import * as ReducerTypes from 'redux/actions/Types';
import { getLastCompletedPaymentIfHas, getLastPendingPaymentIfHas } from 'utils/payments.utils';

const filterPaymentsWithSameIdThanNewOne = (oldPayments, addedPayments) => {
  if (!Array.isArray(addedPayments) || addedPayments.length === 0) return oldPayments;
  return oldPayments.filter(payment => !addedPayments.map(addedPayment => addedPayment.id).includes(payment.id));
}

const actualPaymentInitialState = paymentDefault({});

const initialState = {
  payments: [],
  actualPayment: actualPaymentInitialState
}

const PaymentsReducer = (state = initialState, action) => {
  const data = action.payload;
  switch (action.type) {

    case ReducerTypes.PAYMENTS_ADD_AND_GET_ACTUAL:
      const lastPaymentActive = getLastPendingPaymentIfHas(data);
      const lastCompletedPayment = getLastCompletedPaymentIfHas(data);
      let actualPayment = lastPaymentActive.status ? lastPaymentActive
        : lastCompletedPayment.status ? lastCompletedPayment : actualPaymentInitialState;
      return { payments: data, actualPayment }

    case ReducerTypes.PAYMENTS_ADD:
      const oldAndUniquesPayments = filterPaymentsWithSameIdThanNewOne(state.payments, action.payload);
      return { ...state, payments: [...action.payload, ...oldAndUniquesPayments] }

    case ReducerTypes.PAYMENTS_PAY_UPDATED:
      return { ...state, actualPayment: { ...state.actualPayment, ...data } };

    case ReducerTypes.PAYMENTS_PAY_DELETE:
      return {
        payments: state.payments.filter(payment => payment.id !== data),
        actualPayment: initialState.actualPayment
      };

    case ReducerTypes.PAYMENTS_RESET_PAYMENT_INFO:
      return { ...state, actualPayment: { ...initialState.actualPayment, preference_data: state.preference_data } };

    case ReducerTypes.PAYMENTS_SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};

export default PaymentsReducer;