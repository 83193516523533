import React, { useEffect, useState } from 'react';
import {
  CssBaseline, Backdrop, CircularProgress, Typography, Box, Grid, Paper
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import Copyright from 'components/Copyright/Copyright.js';
import { createTheme } from '@mui/material/styles';
import { Image } from 'mui-image'
import useQuery from 'customHooks/useQuery';
import { errorVerificationMailSubtitle, errorVerificationMailTitle } from 'utils/textToShow.utils';
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { toWithOutError } from 'utils';
import { userUpdateStatePostMailVerifcation } from 'redux/actions/UsersActions';

const MailVerification = () => {

  const params = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [emailVerificated, setEmailVerificated] = useState(false);

  const defaultInfoDialog = { title: "", subtitle: [""], open: false };
  const [infoDialogText, setInfoDialogText] = useState(defaultInfoDialog);

  useEffect(() => {
    const verificateUser = async code => {
      setLoading(true);
      const resetLinkIfExists = await toWithOutError(dispatch(userUpdateStatePostMailVerifcation(code)));
      if (resetLinkIfExists === "ERROR") { setInfoDialogText({ title: errorVerificationMailTitle, subtitle: errorVerificationMailSubtitle, open: true }); return; }
      setEmailVerificated(true);
      setLoading(false);
    }

    if (params.code) verificateUser(params.code);
  }, [params])

  const handleGoToLogin = () => params.code ? navigate('/login') : setInfoDialogText(defaultInfoDialog);

  return (
    <Grid container component="main" sx={rootStyle}>
      <CssBaseline />

      <InfoActionDialog key={"info-dialog"} id='info-dialog' isOpen={infoDialogText.open} handleOk={handleGoToLogin}
        handleClose={() => setInfoDialogText(defaultInfoDialog)} title={infoDialogText.title} contentTexts={infoDialogText.subtitle} />

      <Grid item xs={false} sm={4} md={7} >
        <Image src="/images/login-full.jpg" alt="image" duration={30} />
      </Grid>

      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

        <div style={paperStyle}>

          <Backdrop open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <Box sx={avatarStyle}>
            <Image src="/images/login-avatar.png" alt="logo" duration={30} />
          </Box>

          <Typography component="h1" variant="h5">
            Verificación de E-mail
          </Typography>

          <Typography padding={2} sx={{ fontSize: "25px", textAlign: 'center' }}>
            {params.code
              ? !emailVerificated ? "Estamos verificando tu e-mail." : "E-mail verificado."
              : "Hubo un error en el link de verificación del e-mail."}
          </Typography>

          {emailVerificated ? <CheckCircleIcon sx={{ fontSize: '64px', color: 'green', }} /> : null}
          {emailVerificated ? <Typography padding={2} sx={{ fontSize: "16px", color: "#929292", textAlign: 'center' }}>
            Puedes cerrar esta ventana
          </Typography> : null}

        </div>

        <Box mt={5}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}

const theme = createTheme({
  palette: {
    primary: { light: '#757ce8', main: '#3f50b5', dark: '#002884', contrastText: '#fff' },
    secondary: { light: '#ff7961', main: '#f44336', dark: '#ba000d', contrastText: '#000' },
    mode: 'light'
  }
});

const rootStyle = { height: '100vh' }
const paperStyle = { margin: theme.spacing(8, 4), display: 'flex', flexDirection: 'column', alignItems: 'center' }
const avatarStyle = { backgroundColor: theme.palette.common.white, width: 100, height: 100 }

export default MailVerification;