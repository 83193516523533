import React, { useState } from "react"
import { Typography, Tooltip, IconButton } from '@mui/material';
import { Info } from '@mui/icons-material';
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';

const TypographyWithInfo = ({ infoTooltip, infoDialog, title, dialogTitle, sx, containerSize, infoSx }) => {

  const [openInfoActionDialog, setOpenInfoActionDialog] = useState(false);

  return (
    <>
      <span>
        <Typography variant="h5" sx={sx} component="span">
          {title}
        </Typography>
        <Tooltip title={infoTooltip}>
          <IconButton size="small" sx={{ mb: '6px' }} onClick={() => setOpenInfoActionDialog(true)}>
            <Info />
          </IconButton>
        </Tooltip>
      </span>
      {infoDialog &&
        <InfoActionDialog
          id={'typography' + title}
          isOpen={openInfoActionDialog}
          handleClose={() => setOpenInfoActionDialog(false)}
          title={dialogTitle ? dialogTitle : title}
          contentTexts={infoDialog} />}
    </>
  )
}

export default TypographyWithInfo;