import React from 'react';
import { Button } from '@mui/material';

const ButtonWithInputFile = ({ textButton, onClickHandler, fileType, color }) => {
  const buttonAdd = { backgroundColor: color, '&:hover': { backgroundColor: color } }

  return (
    <Button
      key={'button' + textButton}
      sx={buttonAdd}
      variant="contained"
      component="label">
      {textButton}
      <input
        hidden
        id={'input id ' + textButton}
        name="upload-track"
        type="file"
        accept={fileType}
        onChange={onClickHandler}
      />
    </Button>
  );
}

export default ButtonWithInputFile;
