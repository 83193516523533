import React from "react"
import { Grid, Typography, Tooltip, IconButton } from '@mui/material';

const TypographyWithEndIcon = ({ infoTooltip, title, sx, containerSize, infoSx, handleOnClick, endIcon }) => {

  return (
    <>
      <Grid container item xs={containerSize ? containerSize : 3} sx={{ alignItems: 'center' }} justifyContent="center">

        <Grid item xs={10} >
          <Typography sx={sx}>{title}</Typography>
        </Grid>

        <Grid item xs={2} sx={infoSx}>
          <IconButton
            aria-label={"endIcon"}
            sx={{ color: "white" }}
            edge="start"
            onClick={handleOnClick}
          >
            <Tooltip title={infoTooltip} >{endIcon}</Tooltip>
          </IconButton>
        </Grid>

      </Grid>
    </>
  )
}

export default TypographyWithEndIcon;