import React from 'react';
import { Grid } from '@mui/material';

const TextWithBullets = ({ textLinesList, linesPerRow }) => {
  // Agrupa las líneas en pares (linesPerRow líneas por fila)
  if (!textLinesList || !Array.isArray(textLinesList)) return null;
  
  const groupedLines = [];
  for (let i = 0; i < textLinesList.length; i += linesPerRow) {
    groupedLines.push(textLinesList.slice(i, i + linesPerRow));
  }
  
  return (
    <Grid container spacing={1}>
      {groupedLines.map((row, index) => (
        <Grid item xs={12} key={index} sx={{ display: 'flex' }}>
          {Array.isArray(row) ? row.map((line, lineIndex) => (
            <Grid item xs={12 / (linesPerRow || 2)} textAlign='left' key={lineIndex} sx={{ marginRight: '20px' }}>
              <span style={{ fontSize: "16px", fontWeight: 400 }}>&bull; {line}</span>
            </Grid>
          )) : null}
        </Grid>
      ))}
    </Grid>
  );
};

export default TextWithBullets;
