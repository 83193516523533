import React from 'react';
import {Button, Tooltip} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  '& .loader-container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  '& .loader': {
    color: "primary",
  },
}));

const LoadingButton = ({ textButton, loading, disabled, buttonState, onClickHandler, noneIcon, color,
  buttonFullWidth, buttonSx, onKeyPress, tooltipText }) => {

  return (
    <Tooltip title={tooltipText}>
      <StyledButton
        fullWidth={buttonFullWidth}
        variant="contained"
        sx={buttonSx}
        color={color}
        onClick={onClickHandler}
        disabled={disabled ? disabled : loading}
        onKeyPress={onKeyPress}
      >
        {loading && (
          <div className="loader-container">
            <CircularProgress size={24} className="loader" />
          </div>
        )}
        {textButton}
      </StyledButton>
    </Tooltip>
  );
};

export default LoadingButton;