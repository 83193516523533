import React, { useEffect, useState, useRef } from 'react';
import {
  CssBaseline, TextField, Typography, Link, Box, Grid, Paper
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

import * as actions from 'redux/actions/AuthActions.js';
import Copyright from 'components/Copyright/Copyright.js';
import Danger from 'components/Typography/Danger.js';

import { createTheme } from '@mui/material/styles';
import TextFieldPassword from 'components/TextField/TextFieldPassword';
import { to, useForceUpdate } from 'utils';
import LoadingButton from 'components/CustomButtons/LoadingButton';
import { Image } from 'mui-image'

const SignUp = () => {

  const simpleValidator = useRef(new SimpleReactValidator());
  const forceUpdate = useForceUpdate();

  let errorFormat = (message) => (
    <Danger color="error" variant="h6">{message}</Danger>
  )

  const email = useSelector(store => store.auth.email);
  const password = useSelector(store => store.auth.password);
  const errorSignInStore = useSelector(store => store.auth.errorNoExisteUser);
  const signUpInfo = useSelector(store => store.signUpInfo);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [loading, setLoading] = useState(false);
  // const [errorSignUpFirebase, setErrorSignUpFirebase] = useState(false);
  const [userData, setUserData] = useState(false);

  const allFieldsValidSignUp = async () => {
    if (simpleValidator.current.allValid()) {
      setLoading(true);
      await signUp();
      setLoading(false);
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  }

  const signUp = async () => {
    let [errorSignUp] = await to(dispatch(actions.signUp({ email, password, nombre, apellido })));
    if (errorSignUp) {
      // setErrorSignUpFirebase(true);
      return;
    }
    setUserData({ email, password, nombre, apellido });
  };

  useEffect(() => {
    if (signUpInfo.userCreds && userData) {
      navigate('/admin/subscription');
    }
    else return;
  }, [signUpInfo, userData]);

  const changeEmail = text => dispatch(actions.emailChanged(text.toLowerCase()));
  const changePassword = text => dispatch(actions.passwordChanged(text));
  const changeNombre = text => setNombre(text);
  const changeApellido = text => setApellido(text);
  const handleKeyPress = event => { if (event.key === 'Enter') allFieldsValidSignUp(); }

  return (
    <Grid container component="main" sx={rootStyle}>
      <CssBaseline />

      <Grid item xs={false} sm={4} md={7} >
        <Image src="/images/login-full.jpg" alt="image" duration={30} />
      </Grid>

      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

        <div style={paperStyle}>

          <Box sx={avatarStyle}>
            <Image src="/images/login-avatar.png" alt="logo" duration={30} />
          </Box>

          <Typography component="h1" variant="h5">
            Registro
          </Typography>

          <form style={formStyle} noValidate>

            {errorSignInStore &&
              <Alert severity="error">
                Combinación Usuario/Constraseña incorrecta, o no estas registrado
              </Alert>}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="nombre"
                  variant="outlined"
                  required
                  fullWidth
                  id="nombre"
                  label="Nombre"
                  autoFocus
                  value={nombre}
                  onChange={(evento) => changeNombre(evento.target.value)}
                />
                {simpleValidator.current.message('nombre', nombre, 'required', {
                  className: 'text-danger',
                  messages: { default: "Debes ingresar un nombre." },
                  element: (message) => errorFormat(message)
                })}

              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="apellido"
                  label="Apellido"
                  name="apellido"
                  autoComplete="lname"
                  value={apellido}
                  onChange={(evento) => changeApellido(evento.target.value)}
                />
                {simpleValidator.current.message('apellido', apellido, 'required', {
                  className: 'text-danger',
                  messages: { default: "Debes ingresar un apellido." },
                  element: (message) => errorFormat(message)
                })}

              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Correo Electrónico"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(evento) => changeEmail(evento.target.value)}
                />
                {simpleValidator.current.message('email', email, 'required|email', {
                  className: 'text-danger',
                  messages: { default: "Formato de Mail inválido." },
                  element: (message) => errorFormat(message)
                })}
              </Grid>

              <Grid item xs={12}>
                <TextFieldPassword
                  sx={{ margin: 0 }}
                  fullWidth
                  password={password}
                  onChange={(evento) => changePassword(evento.target.value)}
                  validator={simpleValidator}
                  onKeyPress={handleKeyPress}
                />
              </Grid>

            </Grid>

            <LoadingButton
              buttonFullWidth={true}
              variant="contained"
              color="primary"
              buttonSx={submitStyle}
              onClickHandler={allFieldsValidSignUp}
              textButton="Registrarse"
              loading={loading}
              disabled={false}
            />

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/login" variant="body2" underline="hover">
                  Ya tiene una cuenta? Ingresa
                </Link>
              </Grid>
            </Grid>

          </form>

        </div>

        <Box mt={5}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}

const theme = createTheme({
  palette: {
    primary: { light: '#757ce8', main: '#3f50b5', dark: '#002884', contrastText: '#fff' },
    secondary: { light: '#ff7961', main: '#f44336', dark: '#ba000d', contrastText: '#000' },
    mode: 'light'
  }
});

const rootStyle = { height: '100vh' }
const paperStyle = { margin: theme.spacing(8, 4), display: 'flex', flexDirection: 'column', alignItems: 'center' }
const avatarStyle = { backgroundColor: theme.palette.common.white, width: 100, height: 100 }
const formStyle = { width: '100%', marginTop: theme.spacing(1) }
const submitStyle = { margin: theme.spacing(3, 0, 2) }

export default SignUp;