import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";

import PlanCard from "./PlanCard";
// import { userActiveStates, userStatesListShowAdmin } from "variables/user.variables";
import { getCommonSpecsOfPlans } from "utils/plans.utils";
import { mainColor, materialColor } from "variables/colors";
import SellosSelectDialog from "./SellosSelectDialog";

const BulletList = ({ itemsText }) => {
  const listItemStyle = {
    listStyleType: 'none',
    color: 'white',
    display: 'inline-block', // Change 'flex' to 'inline-block'
    width: '33.33%', // Set each item to take up one-third of the width
    boxSizing: 'border-box', // Add this to account for any padding or margins
    padding: '0 10px', // Add padding for spacing between items
  };

  const okSymbolStyle = {
    color: 'green',
    fontSize: '2em',
  };

  const okSymbol = '\u2714'; // OK symbol unicode character

  return (
    <ul style={{ padding: 0 }}>
      {itemsText.map((itemText, index) => (
        <li key={'list item' + index} style={listItemStyle}>
          <span key={'span' + index} style={okSymbolStyle}>{okSymbol}</span>
          <span key={'typo' + index} style={{ fontSize: 15, fontWeight: 500, color: "white", verticalAlign: "super" }}>
            {itemText}
          </span>
        </li>
      ))}
    </ul>
  );
};

const Plans = ({ planesFiltered, onlySellosPlans, upgrading }) => {

  let planBullets = getCommonSpecsOfPlans();

  const [sellosDialogOpen, setSellosDialogOpen] = useState(false);

  const cardStyle = {
    backgroundColor: materialColor, borderRadius: "1em",
    width: "95%", borderColor: mainColor
  };

  return (
    <Grid container justifyContent="center" padding={0}>

      <SellosSelectDialog open={sellosDialogOpen} setOpen={setSellosDialogOpen} sellosPlans={onlySellosPlans} upgrading={upgrading} />

      {planesFiltered.length > 0 &&
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sx={{ mt: "3em", mb: "2em", textAlign: 'center' }}>
              <b style={planesTitleStyle}>Planes</b>
            </Grid>

            <Grid container sx={{ ...cardStyle, padding: "16px 16px 0 16px" }}>
              <Grid item xs={12} textAlign='center' padding={0}>
                <Typography sx={{ fontSize: 20, fontWeight: 500 }} color="white">
                  Todos los planes incluyen
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <BulletList itemsText={planBullets} />
              </Grid>
            </Grid>

            <Grid container padding={2}>
              {planesFiltered.map(plan =>
                <Grid item xs={12} sm={6} lg={3} key={plan.id}>
                  <PlanCard upgrading={upgrading} setOpenSellosDialog={setSellosDialogOpen}
                    planInfo={plan} sellosPlans={onlySellosPlans} />
                </Grid>
              )}
            </Grid>

          </Grid>
        </Grid>}
    </Grid >
  );
}

export default Plans;

const planesTitleStyle = { fontSize: '50px', }