import * as React from 'react';
import { FormGroup, Grid } from '@mui/material';
import BasicCheckbox from './BasicCheckbox';
import CheckboxWithInfo from './CheckboxWithInfo';

const CheckboxGroup = ({ allInfo, onChange, labelGridSize }) => {

  return (

    <FormGroup>
      <Grid container>
        {allInfo.map((elemInfo, index) =>
          elemInfo.checkBoxHelper === ""
            ?
            <Grid key={index} item xs={labelGridSize ? labelGridSize : 12}>
              <BasicCheckbox key={index} checked={elemInfo.checked} onChecked={() => onChange(elemInfo)} label={elemInfo.label} />
            </Grid>
            :
            <Grid key={index} item xs={labelGridSize ? labelGridSize : 12}>
              <CheckboxWithInfo key={index} checked={elemInfo.checked} onChecked={() => onChange(elemInfo)} label={elemInfo.label}
                checkBoxHelper={elemInfo.checkBoxHelper} onClickInfo={elemInfo.onClickInfo} labelGridSize={10} labelTextAlign="start" />
            </Grid>
        )}
      </Grid>
    </FormGroup >
  );
}

export default CheckboxGroup;
