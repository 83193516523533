import React from 'react';
import { Grid, IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';

const CopyableEditText = ({ textToCopy, color, jsxElementText, handleEdit }) => {

  const editIconSx = { fontSize: "1.3rem" };
  const iconButtonSx = { color, padding: '4px' };

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log('Text copied:', textToCopy);
      })
      .catch((error) => {
        console.error('Error copying text:', error);
      });
  };

  return (
    <Grid container sx={{ display: 'flex', alignItems: 'center', flexWrap: 'unset' }}>
      <Grid item >
        <span>{jsxElementText}</span>
      </Grid>
      
      {textToCopy ?
        <Grid item >
          <IconButton sx={iconButtonSx} onClick={handleCopy}>
            <FileCopyIcon sx={editIconSx} />
          </IconButton>
        </Grid> : null}

      {handleEdit ?
        <Grid item >
          <IconButton sx={iconButtonSx} onClick={handleEdit}>
            <EditIcon sx={editIconSx} />
          </IconButton>
        </Grid> : null}
    </Grid>
  );
};

export default CopyableEditText;
