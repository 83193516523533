import {
  albumDeliveredStates, albumInfractionsStates, albumsDownsStates,
  albumsWithStateIssues, appleDeliveredStates
} from "variables/varias";

export const mapAlbumsCustomQueryNameToStatus = customQueryName => {
  if (customQueryName === 'online') return albumDeliveredStates;
  if (customQueryName === 'down') return albumsDownsStates;
  if (customQueryName === 'issues') return albumsWithStateIssues;
  if (customQueryName === 'apple-related') return appleDeliveredStates;
  if (customQueryName === 'infractions') return albumInfractionsStates;
  return "";
}