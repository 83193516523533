import React, { useState } from 'react';
import { TextField, Box, Grid, Button, CircularProgress } from '@mui/material';
import { toWithOutError } from 'utils';
import { mailingOutSupport } from 'services/BackendCommunication';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';

const OutsiderContact = () => {

  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');

  const [buttonState, setButtonState] = useState('none');
  const sendEmailIcon = <SendIcon />;

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const maxChars = 4000;

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setMessageError('Verifica que el email ingresado es correcto.');
      return;
    }
    if (message.length <= 0) {
      setMessageError('Debes enviar un mensaje.');
      return;
    }
    if (message.length > maxChars) {
      setMessageError('El mensaje no puede exceder los 4000 caracteres.');
      return;
    }
    const responseSendSupport = await toWithOutError(mailingOutSupport(name, email, message, dispatch));
    if (responseSendSupport === "ERROR") {
      setButtonState('error');
      return;
    };
    setButtonState('success');
  }

  const handleChangeMsg = (event) => {
    const { value } = event.target;
    setMessage(value);
    if (value.length > maxChars) {
      setMessageError('El mensaje no puede exceder los 4000 caracteres.');
    } else {
      setMessageError('');
    }
  };

  const handleChangeEmail = (event) => {
    const { value } = event.target;
    setEmail(value);
    if (messageError) setMessageError('');
  };

  return (
    <Grid container component="main">

      <Box textAlign='center'
        sx={{
          backgroundColor: '#f9f9f9', // Color de fondo gris claro
          padding: '24px',
          borderRadius: '8px', // Bordes redondeados
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Sombra para resaltar el formulario
          marginTop: '0px',
          marginBottom: '20px',
        }}>

        {messageError && <Alert severity="error">
          {messageError}
        </Alert>}

        <TextField
          fullWidth
          label="Nombre"
          variant="outlined"
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Correo electrónico"
          type="email"
          variant="outlined"
          margin="normal"
          value={email}
          onChange={handleChangeEmail}
          required
        />
        <TextField
          fullWidth
          label="Mensaje"
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
          value={message}
          onChange={handleChangeMsg}
          inputProps={{ maxLength: maxChars }}
          required
        />

        {buttonState === 'success' &&
          <CheckCircleIcon sx={{ color: 'green', fontSize: '48px' }} />}

        {buttonState === 'error' &&
          <CancelIcon sx={{ color: 'red', fontSize: '48px' }} />}

        {buttonState === 'none' &&
          <Button variant="contained" color="primary" type="submit" disabled={buttonState === 'loading'} onClick={handleSubmit}
            sx={{ backgroundColor: '#007bff', '&:hover': { backgroundColor: '#0056b3' }, position: 'relative' }}
            endIcon={sendEmailIcon}>
            Enviar
          </Button>}

        {buttonState === 'loading' &&
          <Button variant="contained" color="primary" type="submit" disabled={buttonState === 'loading'} onClick={handleSubmit}
            sx={{ backgroundColor: '#007bff', '&:hover': { backgroundColor: '#0056b3' }, position: 'relative' }}
          >
            Enviando...
            <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />
          </Button>}

      </Box>
    </Grid>
  );
}

export default OutsiderContact;