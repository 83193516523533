import React, { useRef, useState, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";

import Success from "components/Typography/Success";
import Danger from 'components/Typography/Danger.js';

import {
  Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton,
  Tooltip
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { createTrackLocalRedux } from '../../redux/actions/TracksActions';
import ButtonWithInputFile from 'components/CustomButtons/ButtonWithInputFile';

import InfoSwitch from "components/Switch/InfoSwitch";
import { toWithOutError, useForceUpdate } from "utils";
import ArtistInAddTrack from '../Artists/ArtistInAddTrack';
import { cloneDeepLimited } from '../../utils';
import AddCollaboratorsForm from '../../components/Forms/AddCollaboratorsForm';
import AddOtherArtistsTrackForm from '../../components/Forms/AddOtherArtistsTrackForm';
import TextFieldWithInfo from 'components/TextField/TextFieldWithInfo';
import { allAudioLocalesFuga, languagesFuga } from '../../variables/varias';
import { allFugaGenres } from 'variables/genres';
import { createSubgenreRedux } from '../../redux/actions/UserDataActions';
import TextFieldWithAddElement from '../../components/TextField/TextFieldAddElement';
import EditOrAddFieldsDialog from '../../components/Dialogs/EditOrAddFieldDialog';
import { defaultTrackData, isValidFormatISRC, readAndCheckAudioFile } from "utils/tracks.utils";
import { editAction, deleteAction } from '../../utils/tables.utils';
import InfoActionDialog from '../../components/Dialogs/InfoActionDialog';
import { spotifyUriIsValid, artistsWithUniqueName } from '../../utils/artists.utils';
import {
  changeOrResetIsrcSearch, invalidIsrcWarning, isrcNotExistsOrNotOwnerText, invalidCollNames,
  newTrackArtistsInfo, newTrackArtistsInfoTrackExists, tooltipoTrackExistsInFuga, warningIfTrackExists,
  assetVersionsHelper
} from "utils/textToShow.utils";
import { getTrackFugaByFieldValue } from 'services/BackendCommunication';
import BasicCheckbox from 'components/Checkbox/BasicCheckbox';
import { Info } from '@mui/icons-material';
import { labelCheckboxTrackExistsInFuga } from '../../utils/textToShow.utils';
import { invalidCollaboratorsNames } from '../../utils/collaborators';
import { createOurTrackFromFugaTrack } from "factory/tracks.factory";


export const NewTrackDialog = (props) => {

  let { openDialog, setOpenNewTrackDialog, setTracksDataTable, tracksDataTable, trackData, setTrackData } = props;

  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator());
  const forceUpdate = useForceUpdate();

  const currentUserData = useSelector(store => store.userData);
  const currentUserId = useSelector(store => store.userData.id);
  const userArtists = useSelector(store => store.artists.artists)
  let userArtistsFugaIds = userArtists.map(artist => artist.fugaId);
  let userArtistsNames = userArtists.map(artist => artist.name);

  const topElementRef = useRef(null);
  const scrollToTop = () => topElementRef.current ? topElementRef.current.scrollIntoView() : null;

  const [trackMissing, setTrackMissing] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [buttonState, setButtonState] = useState("none");
  const [isrcInvalid, setIsrcInvalid] = useState(false);
  const [isrcNotExistsOrNotOwner, setIsrcNotExistsOrNotOwner] = useState(false);
  const [openLowQualityAudioDialog, setOpenLowQualityAudioDialog] = useState({ open: false, title: "", text: [""] });
  const [openEditDialog, setOpenEditDialog] = useState({ open: false, title: "", subtitle: [""], values: "" });
  const [trackIsInstrumental, setTrackIsInstrumental] = useState(false);
  const [collNamesInvalid, setCollNamesInvalid] = useState(false);

  const handleCloseEditDialog = () => setOpenEditDialog({ open: false, title: "", subtitle: [""], values: "" });

  const handleCancelDialog = () => {
    setOpenNewTrackDialog(false);
    setTrackData(defaultTrackData(trackData, tracksDataTable.length));
  };

  const handleCreateTrack = () => {
    trackData.collaborators = trackData.collaborators.map(coll => { return { ...coll, name: coll.name.trim() } });
    dispatch(createTrackLocalRedux(trackData, currentUserId));
    setTracksDataTable([...tracksDataTable, [
      trackData.position,
      trackData.title,
      trackData.version,
      trackData.isrc,
      trackData.artists.length + trackData.allOtherArtists.length > 1 ? "SI" : "NO",
      trackData.track_language_name,
      trackData.explicit ? "NO" : "SI",
      editAction(trackData),
      deleteAction(trackData)
    ]]);
    handleCancelDialog();
  }

  const allFieldsValidCreateTrack = () => {

    if (trackData.existedInFuga) { handleCreateTrack(); return; }
    let allOtherArtistsTrack = artistsWithUniqueName(trackData.allOtherArtists);
    allOtherArtistsTrack = allOtherArtistsTrack.map(artist => { return { valid: spotifyUriIsValid(artist.spotify_uri), name: artist.name } });
    let invalidArtistUri = allOtherArtistsTrack.find(artistValid => artistValid.valid === false);

    if (invalidCollaboratorsNames(trackData.collaborators)) {
      setCollNamesInvalid(true);
      return;
    }

    if (invalidArtistUri) {
      scrollToTop();
      return;
    }
    if (!isValidFormatISRC(trackData.isrc)) {
      setIsrcInvalid(true);
      return;
    }
    else setIsrcInvalid(false);
    if (validator.current.allValid() && trackData.track.size > 0) {
      handleCreateTrack();
    } else {
      validator.current.showMessages();
      if (!trackData.track.size > 0) setTrackMissing(true);
      forceUpdate();
    }
  }

  const deleteArtistFromArtists = index => trackData.artists.filter((_, i) => i !== index);

  const getTrackFromLocal = async event => {
    let trackStatus = await readAndCheckAudioFile(event.target.files[0], new window.wavefile.WaveFile(), setOpenLowQualityAudioDialog);
    if (trackStatus === "SUCCESS") {
      setTrackData({ ...trackData, track: event.target.files[0] });
      setTrackMissing(false);
    }
  };

  const handleExplicitChange = newExplicitEvent => setTrackData({ ...trackData, explicit: newExplicitEvent.target.checked });
  const handleDeleteOtherArtist = indexOtherArtist => setTrackData({ ...trackData, artists: deleteArtistFromArtists(indexOtherArtist) })

  const handlerGenreChoose = event => {
    let genreId = allFugaGenres.find(g => g.name === event.target.value).id;
    setTrackData({ ...trackData, genre: genreId, genreName: event.target.value });
  }

  const handlerSubgenreChoose = event => {
    if (event.target.value === "Crea tu propio subgénero") return;
    let subgenreId = currentUserData.subgenerosPropios.find(g => g.name === event.target.value).id;
    setTrackData({ ...trackData, subgenre: subgenreId, subgenreName: event.target.value });
  }

  const handleCreateSubgenre = async subgenreName => {
    setOpenLoader(true);
    const createSubgenreResponse = await toWithOutError(dispatch(createSubgenreRedux(subgenreName, currentUserId)))

    if (createSubgenreResponse === "ERROR") {
      setButtonState("error");
      setOpenLoader(false);
      return "ERROR";
    }

    setTrackData({ ...trackData, subgenreName, subgenre: createSubgenreResponse.id });
    setOpenLoader(false);
    handleCloseEditDialog();
  }

  const handleAddSubgenre = () => setOpenEditDialog({
    open: true, title: "Crea un subgénero", subtitle: ["Puedes agregar el subgénero que desees."],
    handleConfirm: (newValue) => handleCreateSubgenre(newValue),
    initialValues: "", values: "", handleCloseDialog: handleCloseEditDialog, type: ''
  });

  const handleAddIsrcSearch = () => {
    setButtonState("none");
    setOpenEditDialog({
      open: true, title: "Agrega el Código ISRC", subtitle: warningIfTrackExists,
      handleConfirm: (newValue) => handleSearchIsrc(newValue),
      goBackTitleButton: "No tengo ISRC", type: '',
      initialValues: "", values: "", handleCloseDialog: handleResetIsrcDialog
    });
  }

  const handleResetIsrcDialog = () => {
    setTrackData(defaultTrackData(trackData, tracksDataTable.length))
    handleCloseEditDialog();
  }

  const handleSearchIsrc = async isrcToSearch => {
    let trimmedISRC = isrcToSearch.trim()
    if (trimmedISRC === "") { handleResetIsrcDialog(); return; }
    if (!isValidFormatISRC(trimmedISRC.toUpperCase())) { setIsrcInvalid(true); return }

    setOpenLoader(true);
    const trackFromFugaByIsrcAsArray = await toWithOutError(getTrackFugaByFieldValue(trimmedISRC, dispatch));
    if (trackFromFugaByIsrcAsArray === "NOT_EXISTS") { setIsrcNotExistsOrNotOwner(true); setButtonState("error"); setOpenLoader(false); return; };
    if (trackFromFugaByIsrcAsArray === "ERROR" || !trackFromFugaByIsrcAsArray[0].id) { setButtonState("error"); setOpenLoader(false); return "ERROR"; }

    let [trackFuga] = trackFromFugaByIsrcAsArray;
    let mainArtist = trackFuga.artists[0];
    if (!userArtistsFugaIds.includes(mainArtist.id)) {
      if (!userArtistsNames.includes(mainArtist.name)) {
        setIsrcNotExistsOrNotOwner(true); setButtonState("error"); setOpenLoader(false); return;
      }
    }

    let trackDataFromFuga = createOurTrackFromFugaTrack(trackFuga, trimmedISRC);
    setTrackData({ ...trackData, ...trackDataFromFuga });
    setButtonState("success")
    setOpenLoader(false);
    handleCloseEditDialog();
  }

  const handleChangePrimaryOtherArtist = (index, newPrimaryValue) => {
    const newArtists = cloneDeepLimited(trackData.artists);
    newArtists[index].primary = newPrimaryValue;
    setTrackData({ ...trackData, artists: newArtists });
  };

  const handlerAudioLocaleChoose = event => {
    let audio_locale = allAudioLocalesFuga.find(l => l.name === event.target.value);
    if (audio_locale === undefined) audio_locale.id = "ES";
    setTrackData({ ...trackData, audio_locale_id: audio_locale.id, audio_locale_name: event.target.value });
  }

  const handlerLanguageChoose = event => {
    let language = languagesFuga.find(l => l.name === event.target.value);
    if (language === undefined) language.id = "ES";
    setTrackData({ ...trackData, track_language_id: language.id, track_language_name: event.target.value });
  }

  const handleChangeISRC = event => {
    if (event.target.value.length <= 15) {
      setTrackData({ ...trackData, isrc: event.target.value.toUpperCase() });
      return;
    }

    if (!isValidFormatISRC(event.target.value.toUpperCase())) setIsrcInvalid(true);
    else setIsrcInvalid(false);
  }

  const handleCheckInstrumental = newInstrumentaEvent => {
    let isInstrumental = newInstrumentaEvent.target.checked
    setTrackData({
      ...trackData,
      audio_locale_name: isInstrumental ? "Instrumental" : trackData.audio_locale_name,
      audio_locale_id: isInstrumental ? "ZXX" : trackData.audio_locale_id
    })
    setTrackIsInstrumental(isInstrumental);
  }


  return (
    <Dialog open={openDialog} onClose={handleCancelDialog} aria-labelledby="form-dialog-title" maxWidth="xl" fullWidth>

      <InfoActionDialog id='low-quality-dialog' isOpen={openLowQualityAudioDialog.open} handleClose={() => setOpenLowQualityAudioDialog({ open: false, title: "", text: [""] })}
        title={openLowQualityAudioDialog.title} contentTexts={openLowQualityAudioDialog.text} />

      <InfoActionDialog id='isrc-invalid' isOpen={isrcInvalid} handleClose={() => setIsrcInvalid(false)}
        title={'El ISRC no es válido'} contentTexts={invalidIsrcWarning} />

      <InfoActionDialog id='coll-names-invalid' isOpen={collNamesInvalid} handleClose={() => setCollNamesInvalid(false)}
        title={'Nombre de colaboradores invalidos'} contentTexts={invalidCollNames} />


      <InfoActionDialog id='isrc-not-exist' isOpen={isrcNotExistsOrNotOwner} handleClose={() => setIsrcNotExistsOrNotOwner(false)}
        title={'El ISRC no existe o no es de tu propiedad'} contentTexts={isrcNotExistsOrNotOwnerText} />

      <EditOrAddFieldsDialog labelTextField={openEditDialog.title}
        loading={openLoader} buttonState={buttonState} editOptions={openEditDialog}
        setEditOptions={setOpenEditDialog} />

      <DialogTitle id="form-dialog-title" sx={{ fontSize: "2em" }}>Crear Nueva Canción</DialogTitle>
      <DialogContent>

        <Grid container item xs={12} sx={{ marginLeft: "6%" }} paddingTop={2}>
          <Grid item xs={7} textAlign="end" >
            <BasicCheckbox
              label={labelCheckboxTrackExistsInFuga}
              onChecked={handleAddIsrcSearch}
              checked={trackData.existedInFuga}
              color="#508062"
              labelColor="black"
            />
          </Grid>
          <Grid item xs={1} textAlign="start">
            <Tooltip title={tooltipoTrackExistsInFuga} >
              <IconButton>{<Info />}</IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        {trackData.existedInFuga && <DialogContentText textAlign='center'>
          {changeOrResetIsrcSearch}
        </DialogContentText>}

        <DialogTitle id="agregarArtist-title" sx={agregarArtistaTitleStyle}>Artistas de la Canción</DialogTitle>

        <DialogContentText>
          {trackData.existedInFuga ? newTrackArtistsInfoTrackExists : newTrackArtistsInfo}
        </DialogContentText>

        <Grid ref={topElementRef} container spacing={2} style={{ textAlign: "center" }} >

          <Grid container item xs={12} spacing={2} sx={{ marginTop: "10px" }}>
            {trackData.artists.length > 0
              ? trackData.artists.map((_, index) =>
                <ArtistInAddTrack
                  key={index}
                  index={index}
                  handleDelete={handleDeleteOtherArtist}
                  handleSliderChange={handleChangePrimaryOtherArtist}
                  artists={trackData.artists}
                  allOtherArtists={trackData.allOtherArtists} />)
              : []
            }
          </Grid>

          {!trackData.existedInFuga &&
            <AddOtherArtistsTrackForm
              checkBoxLabel="¿Quieres agregar otro artista?"
              checkBoxHelper="Agrega artistas Principales o Featuring que no aparecen en el Lanzamiento."
              checkBoxColor="#508062"
              buttonColor="#508062"
              setTrackData={setTrackData}
              trackData={trackData}
              validator={validator}
            />}

          <Grid item xs={12} >
            <DialogTitle id="info-general-dialog-title" sx={collaboratorsTitleStyle}>Información General</DialogTitle>
          </Grid>

          <>
            <Grid item xs={4}>
              <TextFieldWithInfo
                name="title"
                fullWidth
                disabled={trackData.existedInFuga}
                required
                label="Nombre de la Canción"
                value={trackData.title}
                onChange={(event) => setTrackData({ ...trackData, title: event.target.value })}
                helperText="Nombre exacto de la canción, respetando mayúsculas, minúsculas y acentos."
                validatorProps={{ restrictions: 'required|max:100', message: "Debes ingresar el nombre de la Canción.", validator }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextFieldWithInfo
                name="version"
                fullWidth
                disabled={trackData.existedInFuga}
                label="Versión de la Canción (opcional)"
                value={trackData.version}
                onChange={(event) => setTrackData({ ...trackData, version: event.target.value })}
                helperText={assetVersionsHelper}
                validatorProps={{ restrictions: 'max:100', message: "Máximo de 100 carácteres.", validator }}
              />
            </Grid>

            <Grid item xs={2}>
              <TextFieldWithInfo
                name="language"
                fullWidth
                disabled={trackData.existedInFuga}
                required
                select
                label="Idioma del nombre de la Canción"
                value={trackData.track_language_name}
                onChange={handlerLanguageChoose}
                selectItems={languagesFuga}
                selectKeyField="id"
                selectValueField="name"
              />
            </Grid>

            {!trackData.existedInFuga &&
              <Grid item xs={2} sx={{ marginTop: "1%" }}>
                <InfoSwitch
                  label={<b>¿Instrumental?</b>}
                  onChange={handleCheckInstrumental}
                  checked={trackIsInstrumental}
                  infoTooltip="Indica si la canción es instrumental o no."
                  infoAtLeft={false} />
              </Grid>}


            <Grid item xs={trackData.existedInFuga ? 6 : 5}>
              <TextFieldWithInfo
                name="audio_locale_name"
                fullWidth
                disabled={trackData.existedInFuga || trackIsInstrumental}
                required
                select
                label="Idioma de la Canción"
                value={trackData.audio_locale_name}
                onChange={handlerAudioLocaleChoose}
                selectItems={allAudioLocalesFuga}
                selectKeyField="id"
                selectValueField="name"
              />
            </Grid>

            {!trackData.existedInFuga &&
              <Grid item xs={2} sx={{ marginTop: "1%" }}>
                <InfoSwitch
                  label={<b>¿Explícito?</b>}
                  onChange={handleExplicitChange}
                  checked={trackData.explicit}
                  infoTooltip="Indica si la letra de la canción contiene lenguaje inapropiado."
                  infoAtLeft={false} />
              </Grid>}
          </>

          <Grid item xs={trackData.existedInFuga ? 6 : 5}>
            <TextFieldWithInfo
              name="isrc"
              fullWidth
              disabled={trackData.existedInFuga}
              label="ISRC (Formato: CC-XXX-00-12345)"
              value={trackData.isrc}
              onChange={handleChangeISRC}
              helperText="Completa sólo si ya tenés un Código ISRC. Formato: CC-XXX-00-12345"
            />
            {isrcInvalid && <Danger>El formato del ISRC es inválido. (Formato: CC-XXX-00-12345)</Danger>}
          </Grid>

          <Grid item xs={6}>
            <TextFieldWithInfo
              name="generosMusicales"
              fullWidth
              disabled={trackData.existedInFuga}
              required
              select
              label="Género Musical Principal"
              value={trackData.genreName || ""}
              onChange={handlerGenreChoose}
              selectItems={allFugaGenres}
              selectKeyField="id"
              selectValueField="name"
              validatorProps={{ restrictions: 'required', message: "Debés seleccionar un género principal.", validator }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldWithAddElement
              name="subgenerosMusicales"
              fullWidth
              disabled={trackData.existedInFuga}
              select
              label="Género Musical Secundario"
              value={trackData.subgenreName}
              onChange={handlerSubgenreChoose}
              selectItems={currentUserData.subgenerosPropios || []}
              selectKeyField="id"
              selectValueField="name"
              onClickAddElement={handleAddSubgenre}
              addPlaceholder="Crea tu propio subgénero"
            />
          </Grid>

          {!trackData.existedInFuga &&
            <Grid item xs={12}>
              <ButtonWithInputFile
                textButton="Subir Archivo de Audio"
                onClickHandler={getTrackFromLocal}
                fileType="audio/wav, audio/x-wav"
                color="#508062" />
              {trackData.track.size > 0 && <Success>{`Nombre del Archivo: ${trackData.track.name}`}</Success>}
              {trackMissing && <Danger>Debes agregar un archivo de Audio (wav)</Danger>}
            </Grid>}


          <Grid item xs={12} >
            <DialogTitle id="collaborators-dialog-title" sx={collaboratorsTitleStyle}>Colaboradores de la Canción</DialogTitle>
            <DialogContentText>Ingresa el <b>nombre y apellido</b> de lxs colaboradorxs.</DialogContentText>
            <DialogContentText>Si tu nombre de artista es tu nombre y apellido igual tienes que agregarte como colaborador.</DialogContentText>
            <DialogContentText> No se aceptan nombres artísticos, nombres de bandas, apodos ni abreviaciones.</DialogContentText>
          </Grid>

          <AddCollaboratorsForm setTrackData={setTrackData} trackData={trackData} validator={validator} disabled={trackData.existedInFuga} />

        </Grid>

      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancelDialog} sx={buttonColorStyle}>
          Atras
        </Button>
        <Button onClick={allFieldsValidCreateTrack} sx={buttonColorStyle}>
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const collaboratorsTitleStyle = { fontSize: "1.5em", textAlign: "center", paddingTop: "16px", paddingBottom: 0 };
const agregarArtistaTitleStyle = { fontSize: "1.5em", textAlign: "center" };
const buttonColorStyle = { color: "#508062" };