export const faqs = [
  {
    question: 'Trabajo con muchas Bandas o Artistas, como productor, sello, manager, etc. ¿Puedo trabajar con La Flota y ofrecer sus servicios con algún beneficio?',
    answer: [<p>¡Sí! Nuestros Planes incluyen de 1 a 100 Artistas y puedes administrarlas tú mismo.</p>,
    <p>También tenemos un sistema de referencias para este tipo de casos, en la sección invitar a un amigo desde tu panel de control, puedes invitar a personas a que se abran su cuenta con un descuento y obtienes el mismo crédito por cada artista que llegue a La Flota por tu recomendación.</p>,
      "Siempre estamos en contacto con estudios, sellos, productoras o distribuidoras.Si tienes una consulta, idea o propuesta por favor escríbenos para que podamos hablar."]
  },
  {
    question: 'Territorios en reportes de regalías',
    answer: 'Los territorios son los países donde se ejecutó la transmisión y que aparecen con dos letras en los reportes de regalías. Los mismos se identifican según la norma internacional ISO 3166-1 alfa-2, publicada por la Organización Internacional de Normalización (ISO). Cada código de dos letras identifica un país.',
  },
  {
    question: 'Subida de música: ¿Cuánto demora en estar online?',
    answer: [<p>🎶 El proceso de revisión se realiza dentro del día 1-2 de recibidos los archivos e información del Lanzamiento, para evitar retrasos es importante que el Artista corrobore que toda la información y archivos que envía son correctos y cumplen con los requisitos especificados. Las DSPs procesan los lanzamientos de Lunes a Viernes, por lo que si subís un disco el fin de semana recién lo recibirán el Lunes, luego para que aparezca efectivamente Online cada una tiene su demora específica según el caudal de procesamiento que tengan en la fecha elegida.</p>,
    <p>Generalmente Spotify, iTunes y Amazon son las más rápidas (de 1-3 días de aceptado un lanzamiento), las demás pueden tardar de 3 a 10 días en publicar, aunque muchas veces sucede antes. Te avisaremos por mail cuando el lanzamiento ya esté Online con los links de las DSPs principales como Spotify, iTunes, YouTube, Deezer, Tidal, Amazon. </p>,
    <p> 🚀 ¡Nuestra velocidad de subida es una de las más rápidas del mundo! 🚀</p>,
    <p>🙂 Es posible subir discos programados, a publicarse en una fecha específica del futuro (para ayudar a armar una campaña de marketing en torno a esa fecha). Para este tipo de lanzamientos programados recomendamos al menos 15 días de anticipación por si tiene algún error a corregir. </p>,
      "También es posible subir discos que hayan sido publicados en el pasado con su fecha original de lanzamiento. "
    ]
  },
  {
    question: 'Subí mi música a las tiendas a través de otro proveedor pero quiero pasarme a La Flota, ¿puedo hacerlo sin perder mis reproducciones ni cambiar de perfil?',
    answer: [
      <p>Claro que sí! Y nos alegra mucho que quieras trabajar con nosotros!! 🙂</p>,
      <p> Para realizar una migración de manera prolija y sin perder las estadísticas de tus lanzamiento necesitas 3 códigos: </p>,
      <p><b>URi</b> (Uniform Resource identifier → Identificador de Recursos Uniforme): cada perfil de artista tiene uno y con este código vas a crear a tu artista en el formulario de Nuevo Artista de La Flota para que enviemos todo a tu perfil existente. Ejemplo → <b>spotify:artist:3WrFJ7ztbogyGnTHbHJFl2</b></p>,
      <p><b>UPC</b> (Universal Product Code → Código Universal de Producto): es un número de 12 dígitos y cada lanzamiento tiene uno, sea Single, EP o álbum. Ejemplo → UPC: <b>018736850462</b>. Lo colocarás en nuestro formulario de Nuevo Lanzamiento. </p>,
      <p><b>ISRC</b> (International Standard Recording Code → Código Estándar Internacional de Grabación): El código ISRC siempre contendrá 12 caracteres, en la forma "CC-XXX-00-11111" y representa a cada versión de cada canción (una misma canción con diferentes versiones tiene distintos códigos ISRC). Ejemplo → <b>US-3DF-20-77192</b></p>,
      <><b>Composición del Código ISRC</b>
        <ul>
          <li><span class="code-part">"CC"</span> es el código de país del país en que se emitió el ISRC (no necesariamente la ubicación del artista o discográfica).</li>
          <li><span class="code-part">"XXX"</span> es el código de registro alfanumérico que identifica al emisor del Código ISRC.</li>
          <li><span class="code-part">"00"</span> son los dos últimos dígitos del año de registro (en el pasado, esto representaba el año de la grabación).</li>
          <li><span class="code-part">"11111"</span> son los 5 dígitos identificadores.</li>
        </ul></>,
    ]
  },
  {
    question: 'Soporte Técnico',
    answer: [
      <p>Ofrecemos Soporte Técnico para cualquier tipo de consulta a través de tu Panel de Control con respuesta rápida garantizada, de Lunes a Viernes. Fines de semana y feriados también podes escribirnos y te responderemos lo más rápido posible el siguiente día hábil. </p>,
      <><b>Nuestro contacto:</b>
        <ul>
          <li>soporte@laflota.com.ar</li>
        </ul></>,
      "Por el momento no ofrecemos atención telefónica, excepto en razones de fuerza mayor o casos puntuales."
    ],
  },
  {
    question: 'Si contrato sus servicios debo subir toda mi material a la vez o puedo ir subiendo de a poco?',
    answer: 'Puedes subir en cualquier momento que quieras!',
  },
  {
    question: 'Resubir música',
    answer: 'Si el Artista proporcionase datos erróneos de títulos o información del lanzamiento y quisiera reemplazarlos una vez que ya hayan sido subidos a las DSPs, o si quisieran reemplazar los archivos de Audio o de Arte de Tapa ya procesado es posible realizar la edición, menos en los casos que se quiera agregar o quitar canciones. Si ese es el caso, se debe eliminar el lanzamiento actual y subirlo nuevamente editado.',
  },
  {
    question: 'Requisitos, qué necesito para subir mi música?',
    answer: [
      <p>Para realizar un Lanzamiento son necesarias tres cosas: los datos de la música, el arte de tapa y los archivos de Audio. </p>,
      <p>Además, es necesario que la música te pertenezca o estés autorizado a publicarla por el dueño de los derechos de la música (generalmente es el compositor) y de las grabaciones. </p>,
      <p>Para mantener la máxima calidad, recomendamos entregar los archivos de Audio en formato WAV o FLAC, sin embargo, de no ser posible también aceptamos MP3, WMA. </p>,
      <p>El Arte de Tapa es muy importante y debe ser una imagen JPG cuadrada de buena calidad -no borrosa, ni pixelada- de al menos 2000x2000px, lo recomendado es 3000x3000px. </p>,
      <p>No es necesario que estés registrado en SADAIC ni en ninguna entidad para publicar música y/o recolectar regalías mecánicas en las tiendas. </p>
    ]

  },
  {
    question: 'Reproducciones artificiales & Copyright',
    answer: [
      <p>En La Flota nos alegra poder ayudar a lxs artistas a compartir su música con el mundo. Entendemos la importancia de compartir tu trabajo y llegar cada día a una audiencia más grande.</p>,
      <p>Por eso, consideramos necesario brindarte información para evitar malas prácticas y en consecuencia, penalizaciones que pueden perjudicar tu trabajo. </p>,
      <p>En un esfuerzo por mitigar el uso ilegítimo de material protegido por derechos de autor y la inflación artificial de las escuchas, La Flota y las DSP's monitoreamos continuamente los datos analíticos y de regalías y verificamos el contenido saliente para detectar posibles infracciones. </p>,
      <p>Un caso común de infracciones, es no contar con los derechos de la música que distribuyes, pueden ser remix, beats o composicicones de las cuales no tienes los derechos necesarios para publicarlas. En caso de hacerlo, pueden llegarte reclamos que generen la baja de tu música e incluso el bloqueo de tus perfiles. </p>,
      <p>Otra práctica a evitar es la de contratar servicios de promoción que prometan un número determinado de escuchas y aumento de tus seguidores, la inclusión en diferentes playlists donde te aseguren una cierta cantidad de escuchas “orgánicas”. </p>,
      <p>En su gran mayoría, estas campañas utilizan bots y diferentes sistemas para generar escuchas artificiales, estas prácticas son fácilmente detectables y generan el bloqueo de tu artista y la pérdida de las regalías generadas. Además de ser costosas, no llegan a un público real, que serían potenciales seguidores, si no que simplemente te aumentan el número de reproducciones. </p>,
      <p>Algunos de los sitios que <b>NO</b> recomendamos utilizar son: </p>,
      <><ul>
        <li><a href="https://www.viewpals.co/spotify/?gad_source=1&gclid=CjwKCAjwmYCzBhA6EiwAxFwfgFUuJ4lFEd5cy0bamuXwOx7lVqn0vUbe4q16GdU1I6qMqgnb2TPZexoCjOQQAvD_BwE" target="_blank">Viewpals Spotify</a></li>
        <li><a href="https://musicvertising.com/spotify-promotion/#pricing" target="_blank">Musicvertising Spotify Promotion</a></li>
        <li><a href="https://topmusicmarketingagency.com/services/trigger-the-algorithm-monthly-plans/" target="_blank">Top Music Marketing Agency - Trigger the Algorithm Monthly Plans</a></li>
      </ul></>,
      <>Las principales DSP's también poseen información al respecto:
        <ul>
          <li><a href="https://support.spotify.com/us/artists/article/third-party-services-that-guarantee-streams/" target="_blank">Spotify - Third-party services that guarantee streams</a></li>
          <li><a href="https://artists.apple.com/support/3913-protect-from-streaming-manipulation#:~:text=Any%20company%20or%20person%20that,are%20prohibited%20on%20our%20services." target="_blank">Apple Music - Protect from streaming manipulation</a></li>
          <li><a href="https://support.google.com/youtube/answer/3399767?hl=en" target="_blank">YouTube - Stream manipulation policy</a></li>
        </ul></>,
    ],
  },
  {
    question: 'Renovación anual de suscripción',
    answer: [
      <p>El año empieza a contar el día que compras tu Cuenta en La Flota. Durante este período tenés acceso a todas las funciones que La Flota tenga disponible para su respectivo Plan.</p>,
      <p>Lo difícil de ofrecer servicios de pago anual en Argentina, es como todos sabemos, adaptarse a la inflación. Sabemos que nuestros clientes valoran la posibilidad de abonar por Transferencia Bancaria, Mercado Pago  o Brubank.</p>,
      <p>También podes pagar por PayPal, Wise o Payoneer, muy útil si estás en el exterior. </p>,
      <p>Al faltar un mes para vencer el año se notifica al dueño de la cuenta por email para que renueve el año. Al renovar tu cuenta, podes seguir realizando lanzamientos ilimitados y mantener el material que ya tenes online (cobrando sus respectivas regalías).</p>,
      <p>Es responsabilidad del usuario estar atento a los mensajes y abonar llegada la fecha de vencimiento. De no renovarse la cuenta cumplido el año de servicio, la música que estuviese publicada se dará de baja de las DSPs. </p>,
      <p>Al cancelarse una cuenta, La Flota notificará por email al usuario y el mismo tendrá 3 meses para requerir cobrar todas las regalías que pueda haber generado su música durante su período publicando con La Flota. </p>,
      <p>De la misma manera, si quiere reactivar su cuenta y su música fue dada de baja, el usuario dará de alta nuevamente los lanzamientos que decida sin necesidad de resubir el material. </p>
    ],
  },
  {
    question: 'Regalías que recauda La Flota',
    answer: [
      <p>Para cada álbum entregado bajo nuestra licencia de agregación, recolectamos el 100% de las regalías de máster de los DSP's y pagamos a la cuenta que entregó el álbum.
        Es responsabilidad del usuario proveedor que subió el lanzamiento pagar las partes adicionales del álbum de acuerdo con sus acuerdos (productor, artistas involucrados, sello, etc.). Estamos investigando la posibilidad de configurar estas divisiones dentro de la plataforma, pero aún no está disponible.</p>,
      <p>La Flota no maneja ningún derecho de publicación ni regalías de Publishing, ni de composiciones originales, ni de portadas. Estos flujos de regalías están cubiertos por licencias entre DSP's, PRO's y editoriales. Sin embargo, estamos trabajando para poder recolectarlas también. </p>
    ],
  },
  {
    question: 'Regalías',
    answer: [<>
      <p>Puedes ver online o descargar los informes de regalías en la sección Regalías de tu Panel de Control en la app: <a href="https://app.laflota.com.ar/" target="_blank">La Flota</a></p>
    </>,
    <p>Al momento de cobrar las recaudaciones, los cargos de transferencia (varían según el método de pago) y cualquier impuesto sobre las ganancias y transferencias referentes a dinero de regalías correrán a través beneficiario.</p>,
    <p> 🧮 Podes ver tus informes cuando quieras y solicitar cobrar desde tu Panel de Control en la sección Regalías.</p>,
    <p>⏳ Las DSP's demoran 3 meses en reportar la información de streaming, descargas y ventas, junto con el dinero que hayas recaudado, es decir, reportan con 3 meses de diferido, por lo que recién a los 3 meses nos llegarán los reportes oficiales de tu primer mes y así sucesivamente. Se cargan el 15 de cada mes (o primer día habil de Argentina a partir del 15). </p>,
    <p>📋 Los Reportes Oficiales tienen hasta 9 dígitos decimales y podes acceder a ellos desde tu panel de regalías.</p>,
    <p>💰 Los retiros los podes solicitar en Pesos Argentinos (ARS) por depósito a cualquier CVU/CBU o como cupón de crédito para utilizar o regalar a tus amig@s. Si preferis en Dólares (USD) los depósitos pueden ser vía PayPal o Payoneer. </p>
    ],
  },
  {
    question: 'Reactivar suscripción',
    answer: [
      <p>Podes reactivar tu suscripción desde tu panel de control en la pestaña: <b>Suscripción</b></p>,
      <p>Si tu música fue dada de baja y todavía se encuentra en nuestro sistema, podrás darla de alta una vez que tengas tu suscripción activa. En este caso no es necesario resubir el material. </p>
    ],
  },
  {
    question: 'Presave · Smartlink',
    answer: "Debes indicarnos que quieres un pre-save enviandonos un mail a <b>soporte@laflota.com.ar</b> (pronto este proceso será más agil)"
  },
  {
    question: 'Nuestros Planes',
    answer: [<p>Nuestros Planes son anuales y permiten realizar lanzamientos ilimitados para diversa cantidad de Artistas, Bandas o Proyectos según tus necesidades.</p>,
    <p>Los planes son expandibles, por lo que podrías empezar con cuenta de 1 Artista Charly García e ir realizando un upgrade a planes mayores.</p>,
    <p>Podes verlos en nuestra app en la pestaña: <b>Suscripción</b></p>,
    ]
  },
  {
    question: 'Mi panel de Spotify For Artists muestra una fecha de estreno distinta a la que seleccioné',
    answer: `Si desde tu panel de Spotify for Artists se muestra una fecha de estreno anterior o posterior (generalmente de un día) de la fecha y horario que seleccionaste en La Flota, no te preocupes, esto es un comportamiento normal. La fecha de estreno será la que seleccionaste en tu panel de La Flota.`,
  },
  {
    question: 'Mi lanzamiento fue rechazado en YouTube',
    answer: [
      <p>Ten en cuenta que tu lanzamiento puede ser rechazado por YouTube si cumple con alguna de las siguientes características:</p>,
      <><ul>
        <li>"Lo mejor de/Best off" y compilaciones</li>
        <li>Mashups, mezclas de dj y remixes de otros artistas</li>
        <li>Música sobre la que no se posee los derechos no exclusivos: solo podemos entregar contenido a YouTube que nuestros clientes posean al 100 % de manera exclusiva</li>
        <li>Grabaciones de actuaciones (incluidos conciertos, eventos, discursos, espectáculos): las grabaciones de actuaciones en vivo están prohibidas para la comparación de ID de contenido.</li>
        <li>Contenido de dominio público</li>
        <li>Música para karaoke</li>
        <li>Efectos de sonido y sonidos genéricos (lluvia, cantos de pájaros, ruido de fondo, etc)</li>
        <li>Música de otro contenido (incluidos videos de juegos)</li>
        <li>Falsificaciones y contenido de calidad inaceptable</li>
        <li>Videos cortos</li>
      </ul></>,
      <p>Si crees que tu lanzamiento fue rechazado por error, por favor contactanos a soporte@laflota.com.ar.`,</p>
    ]
  },
  {
    question: 'Mi lanzamiento fue rechazado en Facebook/Instagram',
    answer: [
      <p>Ten en cuenta que tu lanzamiento puede ser rechazado por Facebook e Instagram si cumple con alguna de las siguientes características:</p>,
      <><ul>
        <li>efectos de sonido</li>
        <li>sound-alikes - sonidos parecidos (contenido que se crea para que suene extremadamente similar a otra música)</li>
        <li>música para karaoke</li>
        <li>soundbeds/música de fondo</li>
        <li>grabaciones de música clásica de composiciones en el</li>
        <li>música de dominio público</li>
        <li>meditación, yoga o música para dormir</li>
        <li>sets de DJ o mezclas continuas</li>
        <li>grabaciones de palabras habladas</li>
        <li>grabaciones de comedia</li>
        <li>grabaciones de películas (que no son la partitura musical de una película)</li>
        <li>discursos</li>
        <li>grabaciones de oración</li>
        <li>audiolibros</li>
        <li>podcast</li>
        <li>grabaciones de naturaleza o vida silvestre</li>
        <li>grabaciones de sonido ambiental</li>
        <li>grabaciones o descripciones de actos sexuales</li>
        <li>canciones en bucles de reproducción</li>
      </ul></>
    ],
  },
  {
    question: 'Medios de pago',
    answer: [
      <p>Aceptamos varios los medios de pago: </p>,
      <><ul>
        <li>Transferencias desde cualquier CBU/CVU en ARS o USD</li>
        <li>Mercado Pago</li>
        <li>PayPal</li>
        <li>Payoneer</li>
        <li>AirTm</li>
        <li>Binance</li>
        <li>Wise</li>
        <li>Western Union</li>
        <li>Criptomonedas</li>
      </ul></>,
      "Si consideras que necesitas otro medio de pago, escribinos y probablemente encontremos la manera. "
    ],
  },
  {
    question: "Invitar un amig@",
    answer: <p>Desde tu Panel de Control en la sección <b>Cupones</b> puedes Invitar a un amig@, enviándole un email con un cupón de descuento para que use en cualquiera de nuestros Planes. Por cada persona que hayas invitado vos que compre un Plan en La Flota recibís crédito a través de un cupón para que puedas aplicarlo en tu renovación en La Flota.</p>,
  },
  {
    question: 'Gestión de distribución digital',
    answer: [
      <p>Se generarán perfiles en las DSPs seleccionadas si no tienes, un UPC por cada lanzamiento (álbum, ep, single) e ISRC para cada canción que subas.</p>,
      <p>Desde tu panel de control podes crear a tus artistas, lanzamientos, ver y/o descargar tus reportes de regalías, obtener información, tutoriales y soporte técnico. </p>
    ],
  },
  {
    question: 'Formatos de Audio e Imagen',
    answer: [
      <p>Formatos de Archivos Requeridos por las DSPs (Audio e Imagen)</p>,
      <p>Los formatos de Audio que aceptan las tiendas son WAV (hasta 96khz-24bit) o FLAC. Recomendamos WAV 44.1khz/16bit o FLAC. También aceptamos MP3, pero debemos convertirlos a WAV para la subida. </p>,
      <p>Todas las publicaciones que haga un Artista, ya sea Single o Álbum deben contar con Arte de Tapa exclusivo para dicha publicación.</p>,
      <p>El Arte de Tapa es muy importante y debe ser una imagen cuadrada de buena calidad (ni borrosa ni pixelada), de mínimo 3000x3000px, formato JPG, colores RGB. </p>,
      <p>Si solo tenes una imagen rectangular (ej.: 3000x3200px) podes recortarla como te guste en nuestro formulario de subida para que cumpla con las dimensiones apropiadas. Si por algún motivo te es imposible acceder a una imagen de 3000x3000px podemos aceptar imagenes más pequeñas y las editaremos, aunque no lo recomendamos ya que hay perdida de calidad.</p>,
      <p>Si necesitas ayuda profesional podemos recomendarte diseñador@s!</p>,
    ],
  },
  {
    question: 'Firmé un contrato discográfico, ¿puedo irme de La Flota aunque no haya completado el año de subscripción?',
    answer: [
      <p>Absolutamente! Y nos alegramos de la nueva etapa en tu carrera.</p>,
      <p>En el caso de que te surja una oportunidad para la que necesites cambiar de distribuidor (por ejemplo si tu sello discográfico tiene su propio sistema de distribución), simplemente nos avisas y damos de baja tu música. Desde tu panel podes eliminar los lanzamientos o cancelar tu suscripción. </p>,
      <p>Todos los datos de tus lanzamientos los debes solicitar o ver desde tu panel para que puedas migrar tu material sin perder las reproducciones y estadísticas.</p>
    ],
  },
  {
    question: 'Estoy en el Exterior o no soy de Argentina, ¿puedo distribuir mi material?',
    answer:[
      <p>Claro que sí!, puedes hacerlo desde cualquier punto del planeta con conexión a Internet, y tu música irá a todo el mundo también. </p>,
      <p>Puedes abonar por  PayPal, Wise o Payoneer, o consultarnos directamente. </p>
    ],
  },
  {
    question: 'Cómo ingresar los nombres de los colaboradores de mis canciones',
    answer: [
      <p>Cuando crees tu lanzamiento te vamos a solicitar información de colaboradores, son todas las personas que participaron en la composición e interpretación de tu música. Hay dos campos que son obligatorios, compositor/a/x y liricista (en caso que haya letra). </p>,

    ],
  },
  // {
  //   question:,
  //   answer:,
  // },
  // {
  //   question:,
  //   answer:,
  // },
  // {
  //   question:,
  //   answer:,
  // },
];