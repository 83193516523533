export const USER_SUSPEND_INFRACTION = "USER_SUSPEND_INFRACTION";
export const USER_SUSPEND_NO_PAY = "USER_SUSPEND_NO_PAY";
export const USER_ACTIVE = "USER_ACTIVE";
export const USER_INACTIVE = "USER_INACTIVE";
export const USER_FREEZE = "USER_FREEZE";
export const USER_PENDING_PAY = "USER_PENDING_PAY";
export const USER_PENDING_PROOF = "USER_PENDING_PROOF";
export const USER_REJECTED_PROOF = "USER_REJECTED_PROOF";
export const USER_PENDING_PROOF_CHECK = "USER_PENDING_PROOF_CHECK"
export const USER_CANCELLED = "USER_CANCELLED";
export const USER_NEED_RENOVATION = "USER_NEED_RENOVATION";
export const USER_NEED_VERIFICATION_EMAIL = "USER_NEED_VERIFICATION_EMAIL";
export const USER_NEED_REFUND = "USER_NEED_REFUND";
export const USER_REFUND_COMPLETE = "USER_REFUND_COMPLETE";
export const USER_NEED_MIGRATION = "USER_NEED_MIGRATION";
export const USER_VIP = "USER_VIP";
export const USER_REJECTED_PAY = "USER_REJECTED_PAY";

export const userSuspectStates = [USER_SUSPEND_INFRACTION];
export const userStatusNotNeedAttention = [USER_ACTIVE, USER_VIP];
export const userActiveStates = [USER_ACTIVE, USER_NEED_RENOVATION, USER_VIP];
export const userNotActiveStates = [USER_INACTIVE, USER_FREEZE, USER_SUSPEND_NO_PAY, USER_SUSPEND_INFRACTION,
  USER_PENDING_PAY, USER_CANCELLED, USER_REJECTED_PAY];
export const userPendingPayStates = [USER_PENDING_PAY, USER_PENDING_PROOF, USER_PENDING_PROOF_CHECK, USER_NEED_REFUND, USER_REJECTED_PROOF, USER_REJECTED_PAY]

export const userStatesShowAdmin = {
  USER_PENDING_PAY: "Esperando pago",
  USER_PENDING_PROOF_CHECK: "Esperando Verificación",
  USER_PENDING_PROOF: "Esperando Comprobante",
  USER_REJECTED_PROOF: "Comprobante rechazado",
  USER_INACTIVE: "Inactiva",
  USER_NEED_RENOVATION: "Esperando renovacion",
  USER_NEED_VERIFICATION_EMAIL: "Email no Verificado",
  USER_ACTIVE: "Activo",
  USER_VIP: "Activo VIP",
  USER_FREEZE: "Hibernado",
  USER_SUSPEND_NO_PAY: "Suspendido ($)",
  USER_REJECTED_PAY: "Pago rechazado",
  USER_SUSPEND_INFRACTION: "Suspendido Infracción",
  USER_CANCELLED: "Cancelada",
  undefined: "Activo*",
  0: "Activo**",
  active: "Activo***",
  USER_REFUND_COMPLETE: "Reembolsado",
  USER_NEED_REFUND: "Pedido de Reembolso",
  USER_NEED_MIGRATION: "Necesita Migración"
}

export const userFilterUserStatusValues = ["Todos los estados",
  "Activo", "Esperando pago", "Pago rechazado", "Esperando Verificación", "Esperando Comprobante", "Comprobante rechazado", "Inactiva",
  "Esperando renovacion", "Email no Verificado", "Suspendido ($)", "Suspendido Infracción", "Hibernado", "Cancelada por User",
  "Reembolsado", "Pedido de Reembolso", "Necesita Migración", "VIP"
]

export const userStatesListShowAdmin = [
  { id: USER_PENDING_PAY, name: "Esperando pago" },
  { id: USER_REJECTED_PAY, name: "Pago rechazado" },
  { id: USER_PENDING_PROOF, name: "Esperando Comprobante" },
  { id: USER_PENDING_PROOF_CHECK, name: "Esperando Verificación" },
  { id: USER_REJECTED_PROOF, name: "Comprobante rechazado" },
  { id: USER_NEED_RENOVATION, name: "Esperando renovacion" },
  { id: USER_NEED_VERIFICATION_EMAIL, name: "Email no Verificado" },
  { id: USER_ACTIVE, name: "Activo" },
  { id: USER_VIP, name: "VIP" },
  { id: USER_INACTIVE, name: "Inactiva" },
  { id: USER_FREEZE, name: "Hibernado" },
  { id: USER_SUSPEND_NO_PAY, name: "Suspendido ($)" },
  { id: USER_SUSPEND_INFRACTION, name: "Suspendido Infracción" },
  { id: USER_CANCELLED, name: "Cancelada por User" },
  { id: USER_REFUND_COMPLETE, name: "Reembolsado" },
  { id: USER_NEED_REFUND, name: "Pedido de Reembolso" },
  { id: USER_NEED_MIGRATION, name: "Necesita Migración" }
]

export const userStatesShowUser = {
  USER_PENDING_PAY: "Esperando pago",
  USER_PENDING_PROOF_CHECK: "Esperando Verificación",
  USER_REJECTED_PAY: "Pago rechazado",
  USER_PENDING_PROOF: "Esperando Comprobante",
  USER_NEED_RENOVATION: "Esperando renovacion",
  USER_REJECTED_PROOF: "Comprobante rechazado",
  USER_NEED_VERIFICATION_EMAIL: "Email no Verificado",
  USER_ACTIVE: "Activo",
  USER_VIP: "Activo",
  USER_INACTIVE: "Inactiva",
  USER_FREEZE: "Hibernado",
  USER_SUSPEND_NO_PAY: "Suspendido ($)",
  USER_SUSPEND_INFRACTION: "Suspendido Infracción",
  USER_CANCELLED: "Cancelada",
  undefined: "Activo",
  USER_REFUND_COMPLETE: "Reembolsado",
  USER_NEED_REFUND: "Pedido de Reembolso",
  USER_NEED_MIGRATION: "Necesita Migración"
}

export const userStateColors = {
  USER_PENDING_PAY: "rgb(231, 190, 66)",
  USER_REJECTED_PAY: "red",
  USER_PENDING_PROOF_CHECK: "rgb(203, 156, 42)",
  USER_REJECTED_PROOF: "rgb(203, 156, 42)",
  USER_PENDING_PROOF: "rgb(231, 190, 66)",
  USER_INACTIVE: "red",
  USER_NEED_RENOVATION: "rgb(231, 190, 66)",
  USER_NEED_VERIFICATION_EMAIL: "rgb(111, 2, 2)",
  USER_ACTIVE: "rgb(93, 109, 15)",
  USER_VIP: "rgb(93, 109, 15)",
  USER_FREEZE: "rgb(93, 109, 15)",
  USER_SUSPEND_INFRACTION: "rgb(111, 2, 2)",
  USER_SUSPEND_NO_PAY: "rgb(111, 2, 2)",
  USER_CANCELLED: "red",
  USER_REFUND_COMPLETE: "red",
  USER_NEED_REFUND: "rgb(111, 2, 2)",
  undefined: "rgb(93, 109, 15)",
  0: "rgb(93, 109, 15)",
  active: "rgb(93, 109, 15)",
  USER_NEED_MIGRATION: "red"
}
