import React, { useState } from 'react';
import { DialogTitle, DialogContent, DialogActions, Dialog, Button, Select, MenuItem, Backdrop, CircularProgress } from '@mui/material/';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { paymentsPreferenceCreated } from 'redux/actions/PaymentsActions';
import { createMpPreferenceData } from 'factory/payments.factory';
import { SUB_ORDER_PLAN_SELECTED } from 'variables/financial';
import { toWithOutError } from 'utils';

const SellosSelectDialog = ({ open, setOpen, sellosPlans, upgrading }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actualPayment = useSelector(store => store.payments.actualPayment);
  const userData = useSelector(store => store.userData);
  const userPlanId = userData.plan || "";

  const [selectedOption, setSelectedOption] = useState(sellosPlans[0]?.id || "spinettalandia-5");
  const [sellosLoader, setSellosLoader] = useState(false);

  const possibleUpgradePlans = upgrading ? sellosPlans.filter(plan => plan.id !== userPlanId) : sellosPlans;
  const handleClose = () => {
    setOpen(false);
  };

  const handleGoToCheckout = async () => {
    setSellosLoader(true);
    let selloPlanSelected = sellosPlans.find(plan => plan.id === selectedOption);
    let preference_data = createMpPreferenceData(selloPlanSelected, actualPayment);
    let createPaymentDoc = await toWithOutError(dispatch(paymentsPreferenceCreated({
      status: SUB_ORDER_PLAN_SELECTED, preference_data, userId: userData.id, userEmail: userData.email, id: preference_data.external_reference,
      plan: selloPlanSelected.id
    }, userData)));
    if (createPaymentDoc === "ERROR") return;
    let checkoutUrl = upgrading ? `/admin/checkout?upgrading=true` : "/admin/checkout";
    navigate(checkoutUrl);
    setSellosLoader(false);
  }

  const handleOptionChange = async (event) => {
    setSelectedOption(event.target.value);
    let selloPlanSelected = sellosPlans.find(plan => plan.id === event.target.value);
    let preference_data = createMpPreferenceData(selloPlanSelected, actualPayment);
    dispatch(paymentsPreferenceCreated({
      status: SUB_ORDER_PLAN_SELECTED, preference_data, userId: userData.id, plan: selloPlanSelected.id,
      id: preference_data.external_reference, userEmail: userData.email
    }, userData));
  };

  if (!possibleUpgradePlans.length) return null;

  return (
    <div>

      <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth={false}>

        <Backdrop open={sellosLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogTitle sx={{ textAlign: 'center', fontSize: 30 }}>Elige la cantidad de Artistas</DialogTitle>
        <DialogContent>
          <Select
            value={selectedOption || possibleUpgradePlans[0]?.id}
            onChange={handleOptionChange}
            fullWidth
          >
            {possibleUpgradePlans.map(plan =>
              <MenuItem value={plan.id} key={plan.id}>
                {`${plan.title} (artistas: ${plan.maxArtists}) - AR$ ${plan.priceArs}/año - USD ${plan.priceUsd}/año - ${plan.discountText}`}
              </MenuItem>
            )}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Volver</Button>
          <Button onClick={handleGoToCheckout} variant="contained" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SellosSelectDialog;
