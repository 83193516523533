import * as ReducerTypes from 'redux/actions/Types';
import * as FirestoreServices from 'services/FirestoreServices.js';
// import * as FirestoreServices from 'services/FirestoreServices.js';
// import * as BackendCommunication from 'services/BackendCommunication.js';
// import { writeCloudLog } from '../../services/LoggingService';
import axios from 'axios';
import { to, toWithOutError } from 'utils';
import { MP_URL_CHECKOUT, SUB_CUPON, SUB_MP, SUB_MP_PENDING, SUB_ORDER_PENDING_PAY, SUB_PREF_REJECTED } from 'variables/financial';
import { createPaymentDocFS, deletePaymentDocFS, updatePaymentDocFS } from 'services/FirestoreServices';
import { paymentDefault } from 'factory/payments.factory';
import { getUserTsPaymentData } from 'utils/users.utils';

// ACTION CREATORS

export const paymentsAddLocal = payments => {
  return {
    type: ReducerTypes.PAYMENTS_ADD,
    payload: payments
  }
}

export const paymentsSignIn = payments => {
  return {
    type: ReducerTypes.PAYMENTS_ADD_AND_GET_ACTUAL,
    payload: payments
  }
}

export const getPaymentsByFieldRedux = (field, fieldValue, limit) => async dispatch => {
  let finalValues = field === "mpPaymentId" ? [Number(fieldValue), fieldValue] : fieldValue;
  let paymentsByField = await FirestoreServices.getElementsByField('payments', field, finalValues, dispatch, limit);
  if (paymentsByField === "EMPTY") return "EMPTY";
  if (Array.isArray(paymentsByField) && paymentsByField.length > 0) dispatch(paymentsAddLocal(paymentsByField));
  else return "ERROR";
  return paymentsByField;
}

export const getPaymentsByOuterIdRedux = searchValue => async dispatch => {
  let finalValues = [Number(searchValue), searchValue];
  let paymentsByField = await FirestoreServices.getElementsByField('payments', "mpPaymentId", finalValues, dispatch, 3);
  if (paymentsByField === "EMPTY") {
    paymentsByField = await FirestoreServices.getElementsByField('payments', "otherPaymentId", finalValues, dispatch, 3);
    if (paymentsByField === "EMPTY") return "EMPTY";
  }
  if (Array.isArray(paymentsByField) && paymentsByField.length > 0) dispatch(paymentsAddLocal(paymentsByField));
  else return "ERROR";
  return paymentsByField;
}

export const paymentsCreateRedux = payment => async dispatch => {
  let errorCreatingPaymentDoc = await toWithOutError(createPaymentDocFS(payment, dispatch));
  if (errorCreatingPaymentDoc === "ERROR") return "ERROR";
  dispatch({
    type: ReducerTypes.PAYMENTS_ADD,
    payload: [payment]
  });
  return "SUCCESS";
};

export const paymentsPreferenceCreated = ({ id, userId, userEmail, status, preference_data, plan }, userData) => async dispatch => {
  let paymentFsData = { ownerId: userId, ownerEmail: userEmail, id, preference_data, status, plan };
  let errorCreatingPaymentDoc = await toWithOutError(createPaymentDocFS({ ...paymentDefault(userData), ...paymentFsData }, dispatch));
  if (errorCreatingPaymentDoc === "ERROR") return "ERROR";
  dispatch({
    type: ReducerTypes.PAYMENTS_PAY_UPDATED,
    payload: { ...paymentDefault(userData), ...paymentFsData }
  });
  return "SUCCESS";
};

export const paymentsCheckoutRequested = (userData, preference_data, payment, isRenew) => async dispatch => {
  let errorCreatingCheckOut = "";
  if (payment.payment_method === SUB_CUPON)
    errorCreatingCheckOut = await to(paymentsBankPreferenceRequested(userData, preference_data, payment, isRenew, dispatch));
  if (payment.payment_method === SUB_MP)
    errorCreatingCheckOut = await to(paymentsMpPreferenceRequested(userData, preference_data, payment, isRenew, dispatch));
  if (payment.payment_method !== SUB_MP)
    errorCreatingCheckOut = await to(paymentsBankPreferenceRequested(userData, preference_data, payment, isRenew, dispatch));
  if (errorCreatingCheckOut === "ERROR") return "ERROR";
  return "SUCCESS";
};

const paymentsMpPreferenceRequested = async (userData, preference_data, payment, isRenew, dispatch) => {
  const { firstPaymentDate } = getUserTsPaymentData(userData.subscription, isRenew);
  let pref_data_without_category = { ...preference_data };
  let oldItem = { ...preference_data.items[0] };
  delete pref_data_without_category.items[0].priceOtherCurrency;
  let [errorCreatingCheckOut, checkoutOrderData] = await to(axios.post(MP_URL_CHECKOUT, pref_data_without_category));
  if (errorCreatingCheckOut) {
    return "ERROR";
  }
  const checkout_order = checkoutOrderData.data.responseBody
  let amountCupon = payment.currency === "USD" ? payment.cupon.amountUsd : payment.cupon.amountArs;
  let paymentFsData = {
    id: preference_data.external_reference,
    status: SUB_MP_PENDING,
    preference_data: { ...preference_data, items: [oldItem] },
    mpPaymentId: checkout_order.id,
    otherPaymentId: '',
    whenCreatedTS: new Date().getTime(),
    transactionAmount: oldItem.unit_price + amountCupon,
    firstPaymentDate
  }

  let [errorCreatingPaymentDoc] = await to(updatePaymentDocFS(paymentFsData, dispatch));
  if (errorCreatingPaymentDoc) return "ERROR";
  dispatch({
    type: ReducerTypes.PAYMENTS_PAY_UPDATED,
    payload: paymentFsData
  });
};

const paymentsBankPreferenceRequested = async (userData, preference_data, payment, isRenew, dispatch) => {
  const { firstPaymentDate } = getUserTsPaymentData(userData, isRenew);
  let oldItem = { ...preference_data.items[0] };
  let amountCupon = payment.currency === "USD" ? payment.cupon.amountUsd : payment.cupon.amountArs;
  let paymentFsData = {
    ownerId: userData.id, ownerEmail: userData.email, id: preference_data.external_reference, preference_data, status: SUB_ORDER_PENDING_PAY,
    mpPaymentId: '', otherPaymentId: '', payment_method: payment.payment_method, whenCreatedTS: new Date().getTime(),
    transactionAmount: oldItem.unit_price + amountCupon, currency: payment.currency, firstPaymentDate
  };
  let [errorCreatingPaymentDoc] = await to(updatePaymentDocFS(paymentFsData, dispatch));
  if (errorCreatingPaymentDoc) return "ERROR";

  dispatch({
    type: ReducerTypes.PAYMENTS_PAY_UPDATED,
    payload: paymentFsData
  });
};

export const paymentsPreferenceCreatedLocal = (status, preference_data) => {
  return {
    type: ReducerTypes.PAYMENTS_PAY_UPDATED,
    payload: { status, preference_data }
  };
};

export const paymentsPreferenceRejected = preference_error => {
  return {
    type: ReducerTypes.PAYMENTS_PAY_UPDATED,
    payload: { preference_error, status: SUB_PREF_REJECTED }
  };
};

export const paymentsPayUpdateLocal = newInfoWithId => {
  return {
    type: ReducerTypes.PAYMENTS_PAY_UPDATED,
    payload: newInfoWithId
  };
}
// Estas acciones se ejecutaran al recibir informacion del onSnapshot del 
// documento que creo en paymentsPreferenceCreated
export const paymentsPayUpdated = newInfoWithId => async dispatch => {
  let [errorCreatingPaymentDoc] = await to(updatePaymentDocFS(newInfoWithId, dispatch));
  if (errorCreatingPaymentDoc) return "ERROR";
  dispatch({
    type: ReducerTypes.PAYMENTS_PAY_UPDATED,
    payload: newInfoWithId
  });
  return "SUCCESS";
}

export const paymentsPayDelete = id => async dispatch => {
  let [errorDeletingPaymentDoc] = await to(deletePaymentDocFS(id, dispatch));
  if (errorDeletingPaymentDoc) return "ERROR";
  dispatch({
    type: ReducerTypes.PAYMENTS_PAY_DELETE,
    payload: id
  });
  return "SUCCESS";
}

export const paymentsResetAllInfo = () => {
  return {
    type: ReducerTypes.PAYMENTS_RESET_PAYMENT_INFO,
  }
}

export const paymentsSignOut = () => {
  return {
    type: ReducerTypes.PAYMENTS_SIGN_OUT,
  }
}
