import { IconButton, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material/';
import { fugaGreen, yellowPending, redWarning } from 'variables/colors';
import { CUPON, WISE, planesLaFlota } from 'variables/financial';
import { COPYRIGHT_INFRACTION } from 'variables/varias';
import { PAYPAL, DEPOSITO } from '../variables/financial';

export const statusPayout = [{ name: "Todos los estados", id: "all" }, { name: "Solicitados", id: "REQUESTED" }, { name: "Completados", id: "COMPLETED" }];

export const iconOpenActionsPayouts = (elemId, setOpenActionsDialog) => {
  return <IconButton onClick={() => setOpenActionsDialog({ open: true, payoutId: elemId })}>
    <Settings />
  </IconButton>
}

const getColorForPayoutStatus = payoutStatusId => {
  if (payoutStatusId === "REQUESTED") return yellowPending;
  if (payoutStatusId === "COMPLETED") return fugaGreen;
  if (payoutStatusId === COPYRIGHT_INFRACTION) return redWarning
}

const getTextForPayoutStatus = payoutStatusId => {
  if (payoutStatusId === "REQUESTED") return "Solicitado";
  if (payoutStatusId === "COMPLETED") return "Completado";
  if (payoutStatusId === COPYRIGHT_INFRACTION) return "En revisión: Infraction"
}

export const getPayoutStatus = payoutStatusId => {
  return <Typography sx={{ fontWeight: 600, color: getColorForPayoutStatus(payoutStatusId) }} > {getTextForPayoutStatus(payoutStatusId)}</Typography >
}

export const groupByNameToIdPayouts = groupByName => {
  const reducer = {
    "Usuario": "ownerEmail",
    "Mes del Pago": "transferMonth",
    "Moneda": "currency"
  }
  return reducer[groupByName] || "ownerEmail";
}

export const getQueryPayouts = (payouts, searchParams, filterParams) => {
  let payoutsResult = payouts;
  if (searchParams.field !== 'none')
    payoutsResult = payouts.filter(payout => payout[`${searchParams.field}`] === searchParams.value);

  if (filterParams.value !== 'all')
    payoutsResult = payoutsResult.filter(payout => payout[`${filterParams.field}`] === filterParams.value);

  return payoutsResult;
}

export const statusNameToStatusId = stateName => {
  return statusPayout.find(stateInfo => stateInfo.name === stateName)?.id || "all";
}

export const getPayIdField = payout => {
  if (payout.currency === "ARS") return 'otherPayId';
  if (payout.paypalEmail) return 'paypalId';
  if (payout.payoneerEmail) return 'payoneerId';
  return "otherPayId";
}

export const getPayoutById = (payouts, payoutId) => {
  if (!payouts || payouts.length === 0) return {};
  let payoutFounded = payouts.find(payout => payout.id === payoutId);
  if (!payoutFounded) return {};
  else return payoutFounded;
}

export const checkPayoutFormValidations = (medioDePago, validator) => {
  if (validator.current.fieldValid('telefono') && validator.current.fieldValid('userLastName')
    && validator.current.fieldValid('userName') && (medioDePago.account !== "bank" || validator.current.fieldValid('userCuit'))) {
    if (medioDePago.account === "bank") return validator.current.fieldValid('cbuCvuAlias');
    if (medioDePago.account === "paypal") return validator.current.fieldValid('paypalEmail');
    if (medioDePago.account === "payoneer") return validator.current.fieldValid('payoneerEmail');
    return true;
  }
  else return false;
}

export const getMethodPayFromPayout = payout => {
  // if (payout.payoneerEmail) return "Payoneer";
  if (payout.payoneerEmail) return "Wise";
  if (payout.paypalEmail) return "PayPal";
  if (payout.cbuCvuAlias) return "Depósito";
  if (payout.cupon) return "Cupón";
  return "Sin información";
}

export const getPaymentMethodDBFromPayout = payout => {
  // if (payout.payoneerEmail) return PAYONEER;
  if (payout.payoneerEmail) return WISE;
  if (payout.paypalEmail) return PAYPAL;
  if (payout.cbuCvuAlias) return DEPOSITO;
  if (payout.cupon) return CUPON;
  return "Sin información";
}


export const getPaymentId = (payout, isAdmin) => {
  if (payout.payoneerEmail) return payout.payoneerId || payout.id;
  if (payout.paypalEmail) return payout.paypalId || payout.id;
  if (payout.cbuCvuAlias) return isAdmin ? payout.mpId || payout.id : payout.otherPayId || payout.id;
  if (payout.cupon) return payout.otherPayId || payout.id;
  return payout.id;
}

export const getPlanPriceInUsd = userPlan => {
  let planInfo = planesLaFlota.find(plan => plan.id === userPlan);
  if (planInfo) return planInfo.priceUsd;
  else return 200;
}

export const getTotalsEmptyToShow = {
  email: <b>{"Totales"}</b>, cantPayouts: <b>{0}</b>,
  lastPayAskedDay: <b>{0}</b>, totalPayed: <b>{'USD 0,00'}</b>,
  pendingPay: <b>{'USD 0,00'}</b>
};

export const getPayIdFieldToEdit = payout => {
  if (payout.cupon === "1") return "otherPayId";
  if (payout.mpId) return "mpId";
  if (payout.payoneerId) return "payoneerId";
  if (payout.paypalId) return "paypalId";
  return "id";
}

export const getIdToEdit = payout => {
  if (payout.cupon === "1") return "Cupón ID";
  if (payout.mpId) return "MP ID";
  if (payout.payoneerId) return "Payoneer ID";
  if (payout.paypalId) return "PayPal ID";
  return "APP ID";
}