import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button, Grid, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { Delete } from '@mui/icons-material';
import ProgressButton from 'components/CustomButtons/ProgressButton';
import { deleteDiscountDialogText } from "utils/textToShow.utils";
import { toWithOutError } from 'utils';
import { useDispatch } from 'react-redux';
import { discountsDeleteRedux } from "redux/actions/DiscountsActions";
import { discountCanBeDeleted } from "utils/discounts.utils";
import { DISCOUNT_STATUS_INACTIVE } from "variables/discounts.variables";

const DeleteDiscountDialog = (props) => {
  const { isOpen, setIsOpen, discount, handleCloseActionDialog } = props;

  const dispatch = useDispatch();

  const [loadingDelete, setLoadingDelete] = useState(false);
  // const [actionState, setActionState] = useState('none')
  const [buttonState, setButtonState] = useState('delete');

  const handleDeleteDiscount = async () => {
    setLoadingDelete(true);
    let result = await toWithOutError(dispatch(discountsDeleteRedux(discount)));
    if (result === "ERROR") { setButtonState("error"); setLoadingDelete(false); }
    else {
      setLoadingDelete(false); setIsOpen(false); handleCloseActionDialog();
    }
  }

  let canBeDeleted = discountCanBeDeleted(discount);
  let canBeDeletedDialogText = canBeDeleted
    ? ["En este caso, el cupón se eliminará por completo."] : ["En este caso, el cupón pasará a estado INACTIVO"];

  if (!isOpen) return null; // Do not render anything if isOpen is false

  return (
    <Dialog
      // key={"delete-discount-" + discount.id}
      maxWidth="md"
      id="delete-discount-dialog"
      open={isOpen}
      onClose={() => setIsOpen(false)}>

      <DialogTitle id="title-discount-delete-dialog">
        <Typography sx={dialogTitleStyle}>{`Eliminar Cupón: ${discount.name}`}</Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="content-discount-delete-dialog">
          {deleteDiscountDialogText}
        </DialogContentText>

        <DialogContentText sx={{ fontWeight: 500 }} id="content-discount-can-delete-dialog">
          {canBeDeletedDialogText}
        </DialogContentText>
      </DialogContent>

      <DialogActions id="actions-discount-delete-dialog">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} >
            <Button
              variant="contained"
              fullWidth
              onClick={() => setIsOpen(false)}
              color="primary">
              Atrás
            </Button>
          </Grid>
          {discount.status !== DISCOUNT_STATUS_INACTIVE &&
            <Grid item xs={6}>
              <ProgressButton
                textButton={"Eliminar"}
                loading={loadingDelete}
                buttonState={buttonState}
                onClickHandler={handleDeleteDiscount}
                noneIcon={<Delete />}
                noFab={true} />
            </Grid>}
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDiscountDialog;

const dialogTitleStyle = {
  fontWeight: "500",
  fontSize: "20px",
  marginBottom: "3px",
}

DeleteDiscountDialog.defaultProps = {
  isOpen: false,
  textContent: "Confirma que quieres eliminar el cupón",
  title: "Eliminar Cupón"
}

DeleteDiscountDialog.propTypes = {
  textContent: PropTypes.string,
  textName: PropTypes.string,
  title: PropTypes.string.isRequired,
  deleteButtonText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}