import React, { useState, useEffect } from "react";
import {
  Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Grid, Divider,
  Backdrop, CircularProgress
} from '@mui/material';
import CheckboxGroup from "components/Checkbox/CheckboxGroup";
import BasicCheckbox from 'components/Checkbox/BasicCheckbox';
import { useDispatch } from 'react-redux';
import { albumAddDspsStore, albumReleasedMakeDeliveryActionsRedux } from '../../redux/actions/AlbumsActions';
import AppleCriteriaDialog from './AppleCriteriaDialog';
import { updateAddingAlbumRedux } from 'redux/actions/AlbumsActions';
import { contentTextDspsDialogReleased, contextTextDspsDialogNotReleased, deliveryActionsSuccess } from "utils/textToShow.utils";
import { toWithOutError } from 'utils';
import { getDspsDeliveryStatesFuga } from 'services/BackendCommunication';
import { adaptDspFugaStateToDialog, getDspStateLabel } from "utils/delivery.utils";
import SuccessDialog from 'components/Dialogs/SuccessDialog';
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import { deliveryActionsErrorText } from '../../utils/textToShow.utils';

const DspsDialog = ({ isOpen, setIsOpen, currentAlbumData, albumWasReleased, albumWasEdited, setAlbumIsEditing }) => {
  const dispatch = useDispatch();

  const defaultInfoActionDialogState = { open: false, beginner: "", title: "", text: [""] };

  const [openAppleCriteria, setOpenAppleCriteria] = useState(false);
  const [showAtLeastOneDspMustBeSelected, setShowAtLeastOneDspMustBeSelected] = useState(false);
  const [checkedDsps, setCheckedDsps] = useState([]);
  const [openLoaderDsps, setOpenLoaderDsps] = useState(false);
  const [deliveryActionState, setDeliveryActionState] = useState('none');
  const [openInfoActionDialog, setOpenInfoActionDialog] = useState(defaultInfoActionDialogState);

  useEffect(() => {
    const getDspsDeliveredAsCheckboxFromFuga = async () => {
      setOpenLoaderDsps(true);
      let albumDspsStateFromFuga = await toWithOutError(getDspsDeliveryStatesFuga(currentAlbumData.fugaId, currentAlbumData.ownerEmail, dispatch));
      let albumDspsStateWithChecked = adaptDspFugaStateToDialog(albumDspsStateFromFuga, currentAlbumData.state, albumWasEdited);
      dispatch(albumAddDspsStore(currentAlbumData, albumDspsStateWithChecked.filter(dspAndChecked => dspAndChecked.checked)));
      setCheckedDsps(albumDspsStateWithChecked);
      setOpenLoaderDsps(false);
    }

    if (albumWasReleased && isOpen) getDspsDeliveredAsCheckboxFromFuga();
    if (!albumWasReleased && isOpen) setCheckedDsps(currentAlbumData.dsps)
  }, [isOpen])

  useEffect(() => {
    if (!albumWasReleased && checkedDsps.filter(dsp => dsp.checked).length === 0) setShowAtLeastOneDspMustBeSelected(true);
    else setShowAtLeastOneDspMustBeSelected(false);
  }, [checkedDsps])

  const handleCheckAllDsps = () => {
    setCheckedDsps(checkedDsps.map(dsp => {
      let newStateDspInfo = { ...dsp, checked: true };
      let labelJsxAndString = getDspStateLabel(newStateDspInfo, currentAlbumData.state, albumWasEdited);
      return { ...newStateDspInfo, label: labelJsxAndString.jsx, labelString: labelJsxAndString.string }
    }));
  }

  const handleUncheckAllDsps = () => {
    setCheckedDsps(checkedDsps.map(dsp => {
      let newStateDspInfo = { ...dsp, checked: false };
      let labelJsxAndString = getDspStateLabel(newStateDspInfo, currentAlbumData.state, albumWasEdited)
      return { ...newStateDspInfo, label: labelJsxAndString.jsx, labelString: labelJsxAndString.string }
    }));
  }

  const handleCheckDsp = dsp => {
    setCheckedDsps(checkedDsps.map(actualDsp => {
      if (actualDsp.dspId !== dsp.dspId) return actualDsp;
      else {
        let labelJsxAndString = getDspStateLabel({ ...dsp, checked: !dsp.checked }, currentAlbumData.state, albumWasEdited);
        return {
          ...dsp, checked: !dsp.checked,
          label: labelJsxAndString.jsx,
          labelString: labelJsxAndString.string
        }
      }
    }));
  }

  const appleIsChecked = dsps => dsps.find(dsp => dsp.dspName === "Apple Music" && dsp.checked) &&
    !currentAlbumData.dsps.find(dsp => dsp.dspName === "Apple Music" && dsp.checked);

  const handleCloseDsps = () => {
    let onlyCheckedDsps = checkedDsps.filter(dspAndChecked => dspAndChecked.checked);
    if (!albumWasReleased && onlyCheckedDsps.length === 0) {
      setShowAtLeastOneDspMustBeSelected(true);
      return;
    }
    if (appleIsChecked(checkedDsps)) {
      setOpenAppleCriteria(true);
      return;
    }
    if (albumWasReleased || albumWasEdited) handleMakeDeliveryActions()
    else {
      dispatch(updateAddingAlbumRedux({ ...currentAlbumData, dsps: checkedDsps }));
      setIsOpen(false);
    }
  }

  let allCheckeds = checkedDsps.every(dsp => dsp.checked);
  let labelCheckAll = <p style={{ fontWeight: 500, color: 'black' }}>Selecciona todas las DSPs</p>
  let labelUncheckAll = <p style={{ fontWeight: 500, color: 'black' }}>Deselecciona todas las DSPs</p>

  const uncheckAppleDsp = () => {
    let dspsWithAppleUnchecked = checkedDsps.map(dsp => {
      if (dsp.dspName === "Apple Music") dsp.checked = false;
      return dsp;
    })
    setOpenAppleCriteria(false);
    setCheckedDsps(dspsWithAppleUnchecked);
    if (albumWasReleased || albumWasEdited) handleMakeDeliveryActions()
    else {
      dispatch(updateAddingAlbumRedux({ ...currentAlbumData, dsps: checkedDsps }));
      setIsOpen(false);
    }
  }

  const handleCloseAppleCriteriaOk = () => {
    setOpenAppleCriteria(false);
    if (albumWasReleased || albumWasEdited) handleMakeDeliveryActions()
    else {
      dispatch(updateAddingAlbumRedux({ ...currentAlbumData, dsps: checkedDsps }));
      setIsOpen(false);
    }
  }

  const handleGoBack = () => {
    setIsOpen(false);
    setCheckedDsps([]);
  }

  const handleCloseSuccessDeliver = () => {
    setDeliveryActionState('none');
    setCheckedDsps([]);
    setIsOpen(false);
  }

  const handleMakeDeliveryActions = async () => {
    setOpenLoaderDsps(true);
    let resultRedeliver = await toWithOutError(dispatch(albumReleasedMakeDeliveryActionsRedux(currentAlbumData, [...checkedDsps], albumWasEdited)));
    if (resultRedeliver.filter(res => res !== "SUCCESS").length !== 0) {
      setOpenInfoActionDialog({ open: true, title: "Error", text: deliveryActionsErrorText }); return "ERROR";
    }
    setOpenLoaderDsps(false); setDeliveryActionState('success'); setAlbumIsEditing(false);
  }

  return (
    <Grid>
      <AppleCriteriaDialog isOpen={openAppleCriteria} handleCloseOk={handleCloseAppleCriteriaOk}
        handleCloseUncheckApple={uncheckAppleDsp} />

      <Dialog
        maxWidth="md"
        id="info-dialog"
        fullWidth
        open={isOpen}
        onClose={handleGoBack}>

        <Backdrop open={openLoaderDsps}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <SuccessDialog isOpen={deliveryActionState === "success"} title="¡Felicitaciones!" contentTexts={deliveryActionsSuccess}
          handleClose={handleCloseSuccessDeliver} successImageSource="/images/success.jpg" />

        <InfoActionDialog id='dsp-info' isOpen={openInfoActionDialog.open} handleClose={handleGoBack}
          title={openInfoActionDialog.title} contentTexts={openInfoActionDialog.text} />

        <DialogTitle id="title-info-dialog">
          {"DSP's { Digital Service Providers · Proveedores de Servicios Digitales }"}
        </DialogTitle>

        <DialogContent>
          <DialogContentText key="released-or-not">
            {(albumWasReleased || albumWasEdited) ? contentTextDspsDialogReleased : contextTextDspsDialogNotReleased}
          </DialogContentText>

          <BasicCheckbox checked={allCheckeds} label={allCheckeds ? labelUncheckAll : labelCheckAll} onChecked={allCheckeds ? handleUncheckAllDsps : handleCheckAllDsps} />

          {showAtLeastOneDspMustBeSelected &&
            <DialogContentText key="one-dsp-must-be-selected">
              <p style={{ fontWeight: 500, fontSize: "medium", color: 'red' }}>Deberás elegir al menos una DSP</p>
            </DialogContentText>}

          <Grid item xs={12} textAlign="-moz-center">
            <Divider sx={dividerStyle} />
          </Grid>

          <CheckboxGroup allInfo={checkedDsps} onChange={handleCheckDsp} labelGridSize={6} />

        </DialogContent>

        <DialogActions>
          {albumWasReleased && <Button onClick={handleGoBack} color="primary">
            Atras
          </Button>}
          <Button onClick={handleCloseDsps} color="primary">
            Continuar
          </Button>
        </DialogActions>

      </Dialog>
    </Grid>
  )
}

export default DspsDialog;

const dividerStyle = { width: "100%", borderColor: "rgba(0,0,0,0.2)", borderBottomWidth: "0.15em" };
