import React, { useState, useEffect } from "react";

import SnackbarMui from "components/Snackbar/SnackbarMui";
import { useSelector, useDispatch } from 'react-redux';
import { cleanError, cleanSuccess } from '../../redux/actions/NotificationsHandlerActions';

const Notifications = () => {

  const notification = useSelector(store => store.notifications);
  const dispatch = useDispatch();

  const handleCloseNotification = type => {
    type === "error" ? dispatch(cleanError()) : dispatch(cleanSuccess());
    setOpenNotification(false);
  }

  const [openNotification, setOpenNotification] = useState(false);
  // TYPES: warning, info, success, error
  const [notificationType, setNotificationType] = useState("none");
  const [notiText, setNotiText] = useState("");

  // Error notification si el centralized error handler activa un error.
  useEffect(() => {
    if (notification.error) {
      setNotificationType("error");
      setNotiText(notification.errorMessage)
      setOpenNotification(true);
    }
    if (notification.success) {
      setNotificationType("success");
      setNotiText(notification.successMessage)
      setOpenNotification(true);
    }
  }, [notification]);

  const handleClose = () => handleCloseNotification(notificationType);
  const autoHide = notificationType === "error" ? null : notification.hide;

  return (
    <SnackbarMui
      open={openNotification}
      setOpen={setOpenNotification}
      type={notificationType}
      handleClose={handleClose}
      autoHide={autoHide}
      text={notiText} />
  );
}

export default Notifications;