
// import { editAction, deleteAction } from 'views/Tracks/NewTrackDialog';
import { DELIVERED, PENDING, UPC_MISSING, albumDeliveredStates, colorFromFugaState, ourAlbumStateWithEquivalence } from '../variables/varias';
import { forbiddenVersions } from '../variables/fuga';
import {
  deliveredSuccessText, publishedSuccessText, errorDeliveredText, deliveredSuccessTitle,
  errorDeliveredTitle, publishedSuccessTitle, noTracksWarningText, titleInvalidOldReleaseDate,
  invalidDateContentText, noCoverWarningText, noDateWarningText, noDateRelease,
  singleTrackDifferentNamesText, spotifyUriNotValidText, noTracksWarningTitle,
  noCoverTitle, titleInvalidPreCompraDate, singleTrackDifferentNamesTitle,
  redeliveredSuccessTitle, redeliveredSuccessText, deliveredAppleSuccessTitle,
  singleTrackDifferentVersionsText, singleTrackDifferentVersionsTitle,
} from './textToShow.utils';
import { artistsWithUniqueName } from './artists.utils';
import { spotifyUriIsValid } from './artists.utils';

import { updateAddingAlbumRedux } from 'redux/actions/AlbumsActions';
import { ourListOfDeliveryStates } from '../variables/varias';
import { formatDateWithOffsetAsDayJS, formatDayJsStringDate, oneDayInMS } from './timeRelated.utils';
import { DELIVERED_NEED_APPLE_REVISION } from '../variables/varias';
import { DELIVERED_APPLE_REJECTED } from 'variables/varias';
import dayjs from 'dayjs';
import { mapAlbumsCustomQueryNameToStatus } from './albums.queries.utils';

export const formatEquivalence = { Álbum: "ALBUM", EP: "EP", Single: "SINGLE" };

export const getFilteredAlbumsByUrl = (params, albums) => {
  if (params.view === "allOfArtist") return albums.filter(album => album.artistId === params.id);
  if (params.view === "allOfLabel") return albums.filter(album => album.label_name === params.label_name);
  if (params.view === "allOfUser") return albums.filter(album => album.ownerId === params.id);
  return albums;
}

export const filterAlbumsByAdminOrNot = (albums, isAdmin) => {
  let filteredAlbums = albums.filter(album => album.state !== "DELETED");
  if (!isAdmin) return filteredAlbums;
  return albums;
}

export const getOnlineAlbumsFromArtistId = (albums, artistId, userId) => {
  let filteredAlbums = albums.filter(album => (album.artistId === artistId
    && album.ownerId === userId && albumDeliveredStates.includes(album.state)));
  return filteredAlbums;
}

export const getTitleLanzamientos = (params, labels, artists) => {
  if (!Object.keys(params).length) return "Lanzamientos";
  let elemShowingAlbums = [...artists, ...labels].find(elem => elem.id === params.id || elem.name === params.label_name);
  return ` lanzamientos de ${elemShowingAlbums ? elemShowingAlbums.name : ""}`;
}

export const getAlbumById = (albums, albumId) => {
  let albumFounded = albums.find(a => a.id === albumId);
  if (!albumFounded) return {};
  else return albumFounded;
}

const firstDateIsLower = (aDayOfMonth, aMonth, aYear, otherDayOfMonth, otherMonth, otherYear) => {
  if (aYear < otherYear) return true;
  if (aYear > otherYear) return false;
  if (aYear === otherYear) {
    if (aMonth < otherMonth) return true;
    if (aMonth > otherMonth) return false;
    if (aMonth === otherMonth) return aDayOfMonth < otherDayOfMonth;
  }
}

export const checkPreOrderDate = albumData => {
  let { dayOfMonth, month, year, preOrder, preOrderDayOfMonth, preOrderMonth, preOrderYear } = albumData;
  if (preOrder) return firstDateIsLower(preOrderDayOfMonth, preOrderMonth, preOrderYear, dayOfMonth, month, year);
  return true;
}

export const checkOldReleaseDate = albumData => {
  let { dayOfMonth, month, year, oldRelease, originalYear, originalMonth, originalDayOfMonth } = albumData;
  if (oldRelease) return firstDateIsLower(originalDayOfMonth, originalMonth, originalYear, dayOfMonth, month, year);
  return true;
}

export const checkFieldsCreateAlbum = (currentAlbumData, myTracks, setOpenInvalidValueDialog, validator, showErrorAndScrollToTop) => {
  let allOtherArtistsFromTracksAndAlbum = artistsWithUniqueName([...currentAlbumData.allOtherArtists, ...myTracks.map(track => track.allOtherArtists).flat()]);
  allOtherArtistsFromTracksAndAlbum = allOtherArtistsFromTracksAndAlbum.map(artist => { return { valid: spotifyUriIsValid(artist.spotify_uri), name: artist.name } });
  let invalidArtistUri = allOtherArtistsFromTracksAndAlbum.find(artistValid => artistValid.valid === false);

  if (invalidArtistUri) {
    setOpenInvalidValueDialog({
      open: true, beginer: "invalid-uri", title: `El Spotify Uri del artista de nombre: ${invalidArtistUri.name}, es inválido.`, text: spotifyUriNotValidText
    });
    showErrorAndScrollToTop();
    return;
  }
  if (!currentAlbumData.c_line || !currentAlbumData.p_line || !currentAlbumData.title || !currentAlbumData.genreName) {
    showErrorAndScrollToTop(); return "NO_VALID"
  };
  if (!currentAlbumData.dayOfMonth || !currentAlbumData.month || !currentAlbumData.year) {
    setOpenInvalidValueDialog({ open: true, beginner: "no-date", title: noDateRelease, text: noDateWarningText });
    return;
  }
  if (myTracks.length === 0) {
    setOpenInvalidValueDialog({ open: true, beginner: "no-tracks", title: noTracksWarningTitle, text: noTracksWarningText });
    return;
  }
  if (currentAlbumData.oldRelease ? !checkOldReleaseDate(currentAlbumData) : false) {
    setOpenInvalidValueDialog({ open: true, beginner: "old-release", title: titleInvalidOldReleaseDate, text: invalidDateContentText });
    return;
  }
  if (currentAlbumData.preOrder ? !checkPreOrderDate(currentAlbumData) : false) {
    setOpenInvalidValueDialog({ open: true, beginner: "pre-order", title: titleInvalidPreCompraDate, text: invalidDateContentText });
    return;
  }
  if (!currentAlbumData.cover.size) {
    setOpenInvalidValueDialog({ open: true, beginner: "no-cover", title: noCoverTitle, text: noCoverWarningText });
    return;
  }
  if (myTracks.length === 1 && currentAlbumData.title !== myTracks[0].title) {
    setOpenInvalidValueDialog({ open: true, beginner: "single-track-name", title: singleTrackDifferentNamesTitle, text: singleTrackDifferentNamesText });
    return;
  }
  if (myTracks.length === 1 && currentAlbumData.version !== myTracks[0].version) {
    setOpenInvalidValueDialog({ open: true, beginner: "single-track-version", title: singleTrackDifferentVersionsTitle, text: singleTrackDifferentVersionsText });
    return;
  }
  if (validator.current.allValid()) return "ALL_VALID";
  else return "NO_VALID";

}

export const adaptTitleForSingleReleases = (album, tracks, dispatch) => {
  let newTitle = tracks.length === 1 ? tracks[0].title : album.title;
  dispatch(updateAddingAlbumRedux({ ...album, title: newTitle }));
}

export const adaptVersionForSingleReleases = (album, tracks, dispatch) => {
  let newVersion = tracks.length === 1 ? tracks[0].version : album.version;
  dispatch(updateAddingAlbumRedux({ ...album, version: newVersion }));
}

export const getArtistNameAndPrimaryOfAlbum = albumData => {
  if (!albumData) return [];
  if (!albumData.allOtherArtists) return albumData.nombreArtist ? [{ name: albumData.nombreArtist, primary: true }] : [];
  return [{ name: albumData.nombreArtist, primary: true },
  ...albumData.allOtherArtists.map(otherArtist => { return { name: otherArtist.name, primary: otherArtist.primary } })];
}

export const getOurStateFromFugaState = (fugaState, isAdmin, releaseDayjsFormat) => {
  const isReleased = releaseDayjsFormat ? new Date(releaseDayjsFormat) < new Date() : "undefined";
  if (fugaState === DELIVERED)
    return (isReleased || isReleased === "undefined") ? "Online" : "Entregado";

  if (fugaState === UPC_MISSING && !isAdmin) return ourAlbumStateWithEquivalence[PENDING];
  return ourAlbumStateWithEquivalence[fugaState];
}

export const getStateColor = (fugaState, isAdmin) => {
  if (!Object.keys(colorFromFugaState).includes(fugaState)) return "rgb(231, 190, 66)";
  if (fugaState === UPC_MISSING && !isAdmin) return colorFromFugaState[PENDING];
  return colorFromFugaState[fugaState];
}

export const validateUPC = upcCode => {
  if (upcCode === "") return true;
  if (Number(upcCode) && upcCode.length === 13) return true;
  else return false;
}

export const getFormatByCantOfTracks = (cantTracks, deliverToApple) => {
  let maxTracksSingle = deliverToApple ? 3 : 1;
  if (cantTracks <= maxTracksSingle) return "SINGLE";
  if (cantTracks > maxTracksSingle && cantTracks <= 6) return "EP";
  if (cantTracks > 6) return "ALBUM";
  return "Single";
}

export const getOurFormatByCantOfTracks = (cantTracks, deliverToApple) => {
  let maxTracksSingle = deliverToApple ? 3 : 1;
  if (cantTracks <= maxTracksSingle) return "Single";
  if (cantTracks > maxTracksSingle && cantTracks <= 6) return "EP";
  if (cantTracks > 6) return "Álbum";
  return "Single";
}

export const checkVersionField = versionField => {
  if (forbiddenVersions.includes(versionField.toLowerCase())) return "";
  else return versionField;
}

export const sortAlbumsByField = (albums, field) => {
  return albums.sort((aA, aB) => {
    if (aA[field] > aB[field]) return -1;
    else return 1;
  })
}

// DELIVERY
export const getDeliveredTitleDialog = deliveryState => {
  if (deliveryState === 'published') return publishedSuccessTitle;
  if (deliveryState === 'delivered') return deliveredSuccessTitle;
  if (deliveryState === 'delivered-others') return deliveredSuccessTitle;
  if (deliveryState === 'delivered-apple') return deliveredAppleSuccessTitle;
  if (deliveryState === 'redelivered') return redeliveredSuccessTitle;
  return errorDeliveredTitle;
}

export const getDeliveredContentTextDialog = deliveryState => {
  if (deliveryState === 'published') return publishedSuccessText;
  if (deliveryState === 'delivered') return deliveredSuccessText;
  if (deliveryState === 'redelivered') return redeliveredSuccessText;
  return errorDeliveredText;
}

export const createImageFromUrlData = async imageFetchData => {
  const imageBlob = await imageFetchData.blob();
  const imageObjectURL = URL.createObjectURL(imageBlob);
  return imageObjectURL;
}

export const stateNameToStateId = stateName => {
  return ourListOfDeliveryStates.find(stateInfo => stateInfo.name === stateName)?.id || "all";
}

export const generateAppleObservations = observationsObjectArray => {
  let observationText = "";
  observationsObjectArray.forEach((obsObject, index) => {
    if (index > 0) observationText = observationText + "\n" + obsObject.fullText;
    else observationText = obsObject.fullText;
  });
  return observationText;
}

export const albumCouldGenerateRoyalties = albumData => {
  const wasReleasedThisDay = new Date().getTime() < (albumData.whenCreatedTS + oneDayInMS);
  if (wasReleasedThisDay) return false;

  let saleAndReleaseDate = `${albumData.year}-${albumData.month}-${albumData.dayOfMonth}`;
  let saleAndReleaseAsDate = new Date(saleAndReleaseDate);
  const isReleased = saleAndReleaseAsDate.getTime() < new Date().getTime();
  if (isReleased) return true;

  let oldReleaseDate = false;
  if (albumData.oldRelease) oldReleaseDate = `${albumData.originalYear}-${albumData.originalMonth}-${albumData.originalDayOfMonth}`;
  const wasReleasedPreviously = oldReleaseDate && new Date(oldReleaseDate).getTime() < new Date().getTime();
  if (oldReleaseDate && wasReleasedPreviously) return true;

  return false;
}

export const albumCouldBeDeliveredToApple = album => {
  if (album.state === DELIVERED_NEED_APPLE_REVISION) return true;
  if (album.state === DELIVERED_APPLE_REJECTED) return true;
  if (album.deliverToApple) return true;
  if (album.dsps && Boolean(album.dsps.find(dspInfo => dspInfo.dspName === "Apple Music"))) return true;
  return false;
}

export const handleSetDate = (type, dateDayJsValue, currentAlbumData, dispatch) => {
  if (type === 'release-date') {
    dispatch(updateAddingAlbumRedux({
      ...currentAlbumData, dayOfMonth: dayjs(dateDayJsValue).date(), month: dayjs(dateDayJsValue).month() + 1, year: dayjs(dateDayJsValue).year(),
      releaseDayjsFormat: dateDayJsValue.format()
    }));
  }
  if (type === 'original-date') {
    dispatch(updateAddingAlbumRedux({
      ...currentAlbumData, originalDayOfMonth: dayjs(dateDayJsValue).date(), originalMonth: dayjs(dateDayJsValue).month() + 1, originalYear: dayjs(dateDayJsValue).year(),
      originalDayjsFormat: dateDayJsValue.format()
    }));
  }
  if (type === 'preorder-date') {
    dispatch(updateAddingAlbumRedux({
      ...currentAlbumData, preOrderDayOfMonth: dayjs(dateDayJsValue).date(), preOrderMonth: dayjs(dateDayJsValue).month() + 1, preOrderYear: dayjs(dateDayJsValue).year(),
      preOrderDayjsFormat: dateDayJsValue.format()
    }));
  }
}

export const handleOriginalDateCheckbox = (checkboxValue, currentAlbumData, dispatch) => {
  dispatch(updateAddingAlbumRedux({
    ...currentAlbumData, oldRelease: checkboxValue,
    originalDayOfMonth: dayjs(currentAlbumData.originalDayjsFormat).date(),
    originalMonth: dayjs(currentAlbumData.originalDayjsFormat).month() + 1,
    originalYear: dayjs(currentAlbumData.originalDayjsFormat).year()
  }));
}

export const handlePreorderDateCheckbox = (checkboxValue, currentAlbumData, dispatch) => {
  dispatch(updateAddingAlbumRedux({
    ...currentAlbumData, preOrder: checkboxValue,
    preOrderDayOfMonth: dayjs(currentAlbumData.preOrderDayjsFormat).date(),
    preOrderMonth: dayjs(currentAlbumData.preOrderDayjsFormat).month() + 1,
    preOrderYear: dayjs(currentAlbumData.preOrderDayjsFormat).year()
  }));
}


export const getShowArtistsNames = (stringArtistsNames, screenWidth) => {
  if (screenWidth > 1515) return stringArtistsNames.length > 65
    ? stringArtistsNames.slice(0, 60) + '...'
    : stringArtistsNames
  else {
    return stringArtistsNames.length > 35
      ? stringArtistsNames.slice(0, 35) + '...'
      : stringArtistsNames
  }
}

export const getAppleObsHeight = (appleObs, width) => {
  let height = width > 1427 ? appleObs.length * 1.8 : appleObs.length * 2.3;
  height = width > 1427 ? height + 3.4 : height + 4.2;
  return `${height}em`;
}

export const getAlbumsByCustomQuery = (albums, query) => {
  if (query.name === 'all') return albums;
  let albumsResult = albums;
  albumsResult = albumsResult.filter(album => album.name !== "");
  if (query.name === 'next-to-release') {
    let date = new Date();
    date.setDate(date.getDate() + 5);
    const nowInDayJsFormat = formatDateWithOffsetAsDayJS(new Date());
    const nextXDaysInDayJsFormat = formatDateWithOffsetAsDayJS(date);
    albumsResult = albumsResult.filter(album => album.releaseDayjsFormat && album.releaseDayjsFormat <= nextXDaysInDayJsFormat &&
      album.releaseDayjsFormat >= nowInDayJsFormat);
  }
  else {
    let statusToGet = mapAlbumsCustomQueryNameToStatus(query.name);
    albumsResult = albumsResult.filter(album => statusToGet.includes(album.state));
  }
  return albumsResult;
}

export const wipeOutForbiddenVersions = originalVersion => {
  const forbiddenWords = ["album", "albums", "original", "exclusivo", "exclusiva", "estudio", "Version", "Sencillo"];
  // Crear una expresión regular con todas las palabras, insensible a mayúsculas/minúsculas
  const regex = new RegExp(`\\b(${forbiddenWords.join("|")})\\b`, "i");
  if (regex.test(originalVersion)) return "";
  else return originalVersion;

}

export const getAlbumReleaseDate = album => {
  if (album.releaseDayjsFormat) return formatDayJsStringDate(album.releaseDayjsFormat);
  else return `${album.dayOfMonth}/${album.month}/${album.year}`;
}