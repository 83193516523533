import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import {
  Button, Grid, Dialog, DialogTitle, DialogContentText, DialogContent, MenuItem,
  DialogActions, OutlinedInput, Typography, FormControl, Select, Checkbox, ListItemText,
  InputLabel
} from '@mui/material';

import ProgressButton from 'components/CustomButtons/ProgressButton';
import { AddCircleOutline } from '@mui/icons-material/';
import SelectDateInputDDMMYYYY from 'components/Input/SelectDateInputDDMMYYYY';
import TextFieldWithInfo from 'components/TextField/TextFieldWithInfo';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditOrAddFieldsDialog = (props) => {

  const { labelTextField, loading, buttonState, editOptions, setEditOptions } = props;
  const { open, title, subtitle, initialValues, type, values, handleConfirm, optionsValues, handleCloseDialog, goBackTitleButton } = editOptions;

  const [dateValues, setDateValues] = useState(initialValues ? { ...initialValues } : "");
  const [fieldEdited, setFieldEdited] = useState(false);
  const [checkBoxesValues, setCheckBoxesValues] = useState([])
  const addCircleIcon = <AddCircleOutline />;

  useEffect(() => {
    if (values && values.length === 0) {
      setCheckBoxesValues([]);
    }
  }, [values]);

  const handlerDateUpdate = (eventValue, typeOfDate) => setDateValues({ ...dateValues, [typeOfDate]: eventValue });

  const handleChangeValue = event => {
    setFieldEdited(true);
    if (optionsValues && optionsValues.length > 0) {
      let cantElementsChecked = event.target.value.length;
      let newValueObject = {};
      if (cantElementsChecked === 0) type === 'multiple-select' && setCheckBoxesValues(event.target.value);
      else optionsValues.forEach(valueObject => {
        let elementToAdd = type === 'multiple-select' ? event.target.value[cantElementsChecked - 1] : event.target.value;
        if (valueObject.name === elementToAdd) {
          newValueObject = valueObject;
          type === 'multiple-select' && setCheckBoxesValues(event.target.value);
        }
      })
      setEditOptions({ ...editOptions, values: type === 'multiple-select' ? [...editOptions.values, newValueObject] : newValueObject });
    }
    else setEditOptions({ ...editOptions, values: event.target.value });
  }

  const getValue = () => {
    if (type === 'multiple-select') return checkBoxesValues;
    if (optionsValues) return fieldEdited ? values?.name || "" : initialValues || "";
    return fieldEdited ? values || "" : initialValues || "";
  }

  const handleClose = () => {
    setFieldEdited(false);
    handleCloseDialog()
  }

  const handleConfirmEdit = () => {
    handleConfirm(type === "date" ? dateValues : fieldEdited ? values : initialValues);
    setFieldEdited(false);
  }

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter' && open) handleConfirmEdit();
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      id="edit-or-add-dialog"
      open={open}
      onClose={handleClose}
    >

      <DialogTitle id="edit-or-add-field-dialog-title">
        <Typography sx={{ fontSize: "1.5em", fontWeight: 500 }} component="span">{title}</Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="edit-or-add-field-dialog-subtitle">
          {subtitle}
        </DialogContentText>

        {(type === "only-one-select" || !Boolean(type)) &&
          <TextFieldWithInfo
            name={labelTextField ? labelTextField : ""}
            fullWidth
            required
            label={labelTextField ? labelTextField : ""}
            value={getValue()}
            onChange={handleChangeValue}
            select={(optionsValues && optionsValues.length > 0) ? true : false}
            autoFocus
            selectItems={optionsValues}
            selectKeyField="id"
            selectValueField="name"
            onKeyPress={handleEnterKeyPress}
            pasteAddornment={true}
          />}

        {type === 'date' &&
          <SelectDateInputDDMMYYYY type="release-date" dayValue={dateValues.dayOfMonth} monthValue={dateValues.month} yearValue={dateValues.year}
            setDayOfMonth={event => handlerDateUpdate(event.target.value, "dayOfMonth")} setMonth={event => handlerDateUpdate(event.target.value, "month")}
            setYear={event => handlerDateUpdate(event.target.value, "year")} />
        }

        {type === 'multiple-select' &&
          <FormControl sx={{ marginLeft: 0, marginTop: '8px', width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">Selecciona una o varias opciones</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={getValue()}
              onChange={handleChangeValue}
              input={<OutlinedInput label="Selecciona una o varias opciones" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {optionsValues.map((optionObject) => (
                <MenuItem key={optionObject.id} value={optionObject.name}>
                  <Checkbox checked={checkBoxesValues.indexOf(optionObject.name) > -1} />
                  <ListItemText primary={optionObject.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }

      </DialogContent>

      <DialogActions id="edit-or-add-field-dialog-actions">
        <Grid container spacing={2}>

          <Grid item xs={6}>
            <Button
              variant="contained"
              sx={buttonMainColor}
              fullWidth
              onClick={handleCloseDialog}
              color="primary">
              {goBackTitleButton ? goBackTitleButton : "Atrás"}
            </Button>
          </Grid>

          <Grid item xs={6}>
            <ProgressButton
              textButton={"Confirmar"}
              loading={loading}
              buttonState={buttonState}
              onClickHandler={handleConfirmEdit}
              noneIcon={addCircleIcon}
              noFab={true}
              buttonFullWidth={true} />
          </Grid>

        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default EditOrAddFieldsDialog;

const buttonMainColor = { backgroundColor: "#9c27b0", '&:hover': { backgroundColor: "#9c27b0" } };

// EditFieldsDialog.defaultProps = {
//   isOpen: false,
//   textContent: "Confirma que quieres eliminar",
//   title: "Eliminar"
// }

// EditFieldsDialog.propTypes = {
//   textContent: PropTypes.string,
//   textName: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   deleteButtonText: PropTypes.string,
//   isOpen: PropTypes.bool.isRequired,
//   setIsOpen: PropTypes.func.isRequired,
//   deleteAction: PropTypes.func.isRequired
// }