import * as ReducerTypes from 'redux/actions/Types';
import * as FirestoreServices from 'services/FirestoreServices.js';
import * as BackendCommunication from 'services/BackendCommunication.js';
import { createArtistModel } from '../../services/CreateModels';
import { to, toWithOutError } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeepLimited } from '../../utils';
import { writeCloudLog } from '../../services/LoggingService';
import { albumsEditRedux, albumsDeleteOrTakenDownRedux, getAlbumsByFieldRedux } from 'redux/actions/AlbumsActions';
import { albumsRedeliverAllRedux } from 'redux/actions/AlbumsActions';
import { basicSuccess } from './NotificationsHandlerActions';
import { albumCouldGenerateRoyalties } from 'utils/albums.utils';
import { ARTIST_INACTIVE } from 'variables/artist.variables';

export const artistsAddStore = artists => {
  let newArtists = artists.filter(artist => artist.name !== "");
  return {
    type: ReducerTypes.ADD_ARTISTS,
    payload: newArtists
  }
}

export const artistsAttachFugaIdToArtistDoc = (artist, userId, ownerEmail) => async dispatch => {
  let userByEmailData = await FirestoreServices.getUserDataByEmailInFS(ownerEmail, dispatch);
  if (!userByEmailData.id) return "ERROR";

  let artistFromFuga = await BackendCommunication.getArtistByIdFuga(artist.fugaId, dispatch);
  if (artistFromFuga === "ERROR") return "ERROR";

  let artistIdentfiersFromFuga = await BackendCommunication.getArtistsIdentifierByIdFuga(artist.fugaId, dispatch);
  if (artistIdentfiersFromFuga === "ERROR") return "ERROR";

  artistIdentfiersFromFuga.forEach(identifierData => {
    if (identifierData.issuingOrganization.id === 746109) {
      artist.spotifyIdentifierIdFuga = identifierData.id;
      artist.spotify_uri = identifierData.identifier;
    }
    if (identifierData.issuingOrganization.id === 1330598) {
      artist.appleIdentifierIdFuga = identifierData.id;
      artist.apple_id = identifierData.identifier;
    }
  });

  artist.whenCreatedTS = new Date().getTime();
  artist.lastUpdateTS = artist.whenCreatedTS;
  artist.ownerId = userByEmailData.id; artist.ownerEmail = userByEmailData.email;
  artist.fugaId = artistFromFuga.data.response.id;
  artist.fugaPropietaryId = artistFromFuga.data.response.proprietary_id || artist.id;

  await FirestoreServices.createElementFS(artist, artist.id, artist.ownerId, "artists", "cantTotalArtists", 1, dispatch);

  dispatch({
    type: ReducerTypes.ADD_ARTISTS,
    payload: [artist]
  });

  return "SUCCESS";
}

// TypeOfArtists = ["artists", "artistsInvited"];
export const createArtistRedux = (isAdmin, artist, userId, ownerEmail, typeOfArtist, totalField) => async dispatch => {

  let userByEmailData = "";
  if (isAdmin) {
    userByEmailData = await FirestoreServices.getUserDataByEmailInFS(ownerEmail, dispatch);
    if (!userByEmailData.id) return "ERROR";
  }

  delete artist.imagenRef;
  let rawDataArtist = createArtistModel(artist, false);
  writeCloudLog("creating artist model to send fuga", rawDataArtist, { notError: "not error" }, "info");

  let artistFromThirdWebApi = await BackendCommunication.createArtistFuga(rawDataArtist, ownerEmail, dispatch);
  if (artistFromThirdWebApi === "ERROR") return "ERROR";

  artist.whenCreatedTS = new Date().getTime();
  artist.lastUpdateTS = artist.whenCreatedTS;
  artist.ownerId = isAdmin ? userByEmailData.id : userId;
  artist.ownerEmail = ownerEmail;

  artist.fugaId = artistFromThirdWebApi.data.response.id;
  artist.fugaPropietaryId = artistFromThirdWebApi.data.response.proprietary_id;
  artist.spotifyIdentifierIdFuga = artistFromThirdWebApi.data.response.spotifyIdentifierIdFuga;
  artist.appleIdentifierIdFuga = artistFromThirdWebApi.data.response.appleIdentifierIdFuga;

  await FirestoreServices.createElementFS(artist, artist.id, isAdmin ? userByEmailData.id : userId, typeOfArtist, totalField, 1, dispatch);

  // Si vengo de artistsInvited no debo agregarlos al Store de Artists.
  if (typeOfArtist !== "artists") return "SUCCESS";

  dispatch({
    type: ReducerTypes.ADD_ARTISTS,
    payload: [artist]
  });

  return "SUCCESS";
}

export const artistsCreateFromDGArtistsRedux = (ownerId, ownerEmail) => async dispatch => {

  let dgArtists = await BackendCommunication.getDgArtistsFuga(ownerEmail, dispatch);
  if (dgArtists === "NO_ARTISTS") return "NO_ARTISTS";
  if (dgArtists === "NO_USER") return "NO_USER";
  if (dgArtists.length === 0) return "NO_ARTISTS";


  const createDGArtistOneByOne = dgArtists.map(async dataDgArtist => {
    let cloneDataDgArtists = cloneDeepLimited(dataDgArtist);
    dataDgArtist.ownerId = ownerId;
    dataDgArtist.dashGoId = cloneDataDgArtists.id;
    dataDgArtist.id = uuidv4();
    dataDgArtist.biography = cloneDataDgArtists.bio || "";
    delete dataDgArtist.bio;

    let artistCreatedResult = await toWithOutError(dispatch(createArtistRedux(false, dataDgArtist, ownerId, ownerEmail, "artists", "totalArtists")));
    if (artistCreatedResult === "ERROR") return "ERROR";
    return "SUCCESS";
  });

  let [errorCreatingAllArtists] = await to(Promise.all(createDGArtistOneByOne));
  if (errorCreatingAllArtists) return "ERROR";
}

const cleanNotEditedFields = (allFields, fieldsEdited) => {
  Object.keys(allFields).forEach(prop => {
    if (!fieldsEdited[prop]) delete allFields[prop];
  })
  return allFields;
}

export const artistEditOneFieldRedux = (allArtistOldData, newArtistData, ownerEmail, editInFuga, showNotification) => async dispatch => {
  writeCloudLog(`editing artista ${allArtistOldData.name} y email: ${ownerEmail}`, newArtistData, { notError: "not error" }, "info");
  let editResult = "SUCCESS";

  // Cambios solo en la APP
  if (newArtistData.state || newArtistData.fugaId) {
    editResult = await toWithOutError(FirestoreServices.updateElementFS(allArtistOldData, newArtistData, allArtistOldData.id, "artists", dispatch));
    if (editResult === "ERROR") return "ERROR";
  }

  dispatch({ type: ReducerTypes.EDIT_ARTIST_WITH_ID, payload: { ...allArtistOldData, ...newArtistData } });
  showNotification && dispatch(basicSuccess("Artista Editado", 8000));
  return "SUCCESS";
}

export const updateArtistRedux = (oldArtistData, newArtistsFields, artistFugaId, photoFile, ownerEmail, fieldsEdited, isAdmin) => async dispatch => {
  let onlyEditedFields = cleanNotEditedFields(newArtistsFields, fieldsEdited);
  let rawDataArtist = createArtistModel(onlyEditedFields, true);
  let albumsToUpdateArtistName = []; let identifierEdited = false;

  writeCloudLog(`updating artist model to send fuga with ownerEmail: ${ownerEmail}`, rawDataArtist, { notError: "not error" }, "info");

  if (rawDataArtist.name || rawDataArtist.biography) {
    let artistFromThirdWebApi = await BackendCommunication.updateArtistFuga(rawDataArtist, artistFugaId, ownerEmail, dispatch);
    if (artistFromThirdWebApi === "ERROR") return "ERROR";
    if (rawDataArtist.name) {
      let responseUpdateRoyalties = await BackendCommunication.updateArtistNameInRoyalties(
        oldArtistData.name, rawDataArtist.name, oldArtistData.id, ownerEmail, dispatch);
      if (responseUpdateRoyalties === "ERROR") return "ERROR";
    }
  }

  if (rawDataArtist.spotify_uri !== undefined) {
    let rawIdentifierDataSpotify = { identifierField: "spotify_uri", identifierValue: rawDataArtist.spotify_uri };
    let spotifyIdentifierResponse = await BackendCommunication.updateArtistIdentifierFuga(oldArtistData.spotifyIdentifierIdFuga,
      rawIdentifierDataSpotify, artistFugaId, ownerEmail, dispatch);
    if (spotifyIdentifierResponse === "ERROR") return "ERROR";
    onlyEditedFields.spotifyIdentifierIdFuga = spotifyIdentifierResponse;
    identifierEdited = true;
  }

  if (rawDataArtist.apple_id !== undefined) {
    let rawIdentifierDataApple = { identifierField: "apple_id", identifierValue: rawDataArtist.apple_id };
    let appleIdentifierResponse = await BackendCommunication.updateArtistIdentifierFuga(oldArtistData.appleIdentifierIdFuga,
      rawIdentifierDataApple, artistFugaId, ownerEmail, dispatch);
    if (appleIdentifierResponse === "ERROR") return "ERROR";
    onlyEditedFields.appleIdentifierIdFuga = appleIdentifierResponse;
    identifierEdited = true;
  }

  if (identifierEdited || rawDataArtist.name) {
    albumsToUpdateArtistName = await toWithOutError(dispatch(getAlbumsByFieldRedux('artistId', oldArtistData.id, 200, isAdmin)));
    if (Array.isArray(albumsToUpdateArtistName)) {
      for (let album of albumsToUpdateArtistName) {
        let editInFuga = true;
        rawDataArtist.name && await toWithOutError(dispatch(albumsEditRedux(album, { nombreArtist: rawDataArtist.name }, album.ownerEmail, editInFuga)));
        await toWithOutError(dispatch(albumsRedeliverAllRedux(album, false)));
      }
    }
  }

  onlyEditedFields.id = oldArtistData.id;
  onlyEditedFields.lastUpdateTS = new Date().getTime();
  await FirestoreServices.updateElementFS(oldArtistData, onlyEditedFields, onlyEditedFields.id, "artists", dispatch);

  dispatch({
    type: ReducerTypes.EDIT_ARTIST_WITH_ID,
    payload: onlyEditedFields
  });

  dispatch(basicSuccess("Artista Editado", 8000));
  return "SUCCESS";
}

const artistsHasAlbumsReleased = async (artistId, dispatch) => {
  let albumsFinded = await toWithOutError(dispatch(getAlbumsByFieldRedux('artistId', artistId, 100)));
  if (albumsFinded === "ERROR") return { albumsFinded: "ERROR", atLeastOneAlbumRelased: "ERROR" };
  if (albumsFinded === "EMPTY") return { albumsFinded: "EMPTY", atLeastOneAlbumRelased: false };
  let atLeastOneAlbumRelased = albumsFinded.some(album => albumCouldGenerateRoyalties(album));
  return { albumsFinded, atLeastOneAlbumRelased };
}

// Delete or put ARTIST_INACTIVE
export const deleteArtistRedux = dataArtist => async dispatch => {
  let { albumsFinded, atLeastOneAlbumRelased } = await artistsHasAlbumsReleased(dataArtist.id, dispatch);
  if (atLeastOneAlbumRelased === "ERROR") return "ERROR";
  if (albumsFinded === "EMPTY" || !atLeastOneAlbumRelased) {
    // Muchas veces no se puede eliminar de FUGA por mantener alguna cancion o album enlazado...
    await BackendCommunication.deleteArtistFuga(dataArtist.fugaId, dispatch);
    await FirestoreServices.deleteElementFS(dataArtist, dataArtist.id, dataArtist.ownerId, "artists", "totalArtists", -1, 'delete-artist', dispatch);
  }
  else {
    await FirestoreServices.updateElementFS(dataArtist, { state: ARTIST_INACTIVE }, dataArtist.id, "artists", dispatch);
    let takeDownAlbums = albumsFinded.map(async album => {
      // quiero darlo de baja, por eso el TRUE, no eliminarlo. Y no se le cambió el estado todavía. por eso el "".
      let possibleRoyalties = true; let stateWasChangedAlready = ""; let showNotification = true;
      const takedownResult = await toWithOutError(dispatch(albumsDeleteOrTakenDownRedux(album, possibleRoyalties, stateWasChangedAlready, showNotification)));
      if (takedownResult === "ERROR") { return { status: "ERROR", albumId: album.id }; }
      else return { status: "SUCCESS", albumId: album.id };
    })
    await Promise.all(takeDownAlbums);
  }

  dispatch({
    type: atLeastOneAlbumRelased ? ReducerTypes.EDIT_ARTIST_WITH_ID : ReducerTypes.ARTIST_DELETE_WITH_ID,
    payload: atLeastOneAlbumRelased ? { id: dataArtist.id, state: ARTIST_INACTIVE } : dataArtist.id
  });

  dispatch(basicSuccess("Artista Eliminado", 8000));
  return "SUCCESS";
}

export const getArtistByFieldRedux = (field, fieldValue, limit) => async (dispatch, getState) => {
  let artistsByField = await FirestoreServices.getElementsByField('artists', field, fieldValue, dispatch, limit);
  if (artistsByField === "EMPTY") return "EMPTY";
  if (Array.isArray(artistsByField) && artistsByField.length > 0) dispatch(artistsAddStore(artistsByField));
  else return "ERROR";
  return artistsByField || [];
}

export const getArtistByFieldsRedux = (fields, fieldsValue, limit) => async dispatch => {
  let artistsByFields = await FirestoreServices.getElementsByFields('artists', fields, fieldsValue, dispatch, limit);
  if (artistsByFields === "EMPTY") return "EMPTY";
  if (Array.isArray(artistsByFields) && artistsByFields.length > 0) dispatch(artistsAddStore(artistsByFields));
  else return "ERROR";
  return artistsByFields || [];
}

export const artistsCreateAlbumFromFugaId = (artist, albumFugaId) => async dispatch => {
  let { ownerEmail, ownerId, id, name } = artist;
  let creatingResult = await BackendCommunication.createAlbumFromFugaToArtist(albumFugaId, ownerEmail, ownerId, id, name, dispatch);
  if (creatingResult === "ERROR") return "ERROR";
  dispatch(basicSuccess("Album creado y enlazado al Artista", 8000));
  return "SUCCESS";
}

// export const artistSuspendByInfractionRedux = (artistData, actions, infractionObs) => async dispatch => {
//   let infractionState = artistData.state;
//   let { sendEmail, hideRoyalties, takedown } = actions;
//   let { ownerEmail, id } = artistData; let artistName = artistData.name;
//   let allResults = [];

//   if (sendEmail) allResults.push(await BackendCommunication.sendSuspendArtistCopyright(infractionState, ownerEmail, artistName, infractionObs, dispatch))
//   if (hideRoyalties) allResults.push(await BackendCommunication.hideOrShowRoyaltiesToUser(artistData.upc, [], "not-show", dispatch));
//   if (takedown) allResults.push(await dispatch(albumsDeleteOrTakenDownRedux(artistData, true, infractionState)));
//   return allResults;
// }

export const saveAddingArtistName = artistTempName => {
  return {
    type: ReducerTypes.ADDING_ARTIST_NAME,
    payload: artistTempName
  }
}

export const saveAddingArtistBiography = artistTempBio => {
  return {
    type: ReducerTypes.ADDING_ARTIST_BIO,
    payload: artistTempBio
  }
}

export const saveAddingArtistImagenUrlAndReference = (imagenUrl, imagenRef) => {
  return {
    type: ReducerTypes.ADDING_ARTIST_IMAGEN_URL,
    payload: { imagenUrl, imagenRef }
  }
}

export const saveAddingArtistId = artistTempId => {
  return {
    type: ReducerTypes.ADDING_ARTIST_ID,
    payload: artistTempId
  }
}

export const saveAddingArtistSpotifyUri = artistTempSpotifyUri => {
  return {
    type: ReducerTypes.ADDING_ARTIST_SPOTIFY_URI,
    payload: artistTempSpotifyUri
  }
}

export const saveAddingArtistAppleId = artistTempAppleId => {
  return {
    type: ReducerTypes.ADDING_ARTIST_APPLE_ID,
    payload: artistTempAppleId
  }
}

export const saveAddingArtistFugaId = artistTempAppleId => {
  return {
    type: ReducerTypes.ADDING_ARTIST_FUGA_ID,
    payload: artistTempAppleId
  }
}

export const saveAddingArtistOwnerEmail = artistTempAppleId => {
  return {
    type: ReducerTypes.ADDING_ARTIST_EMAIL,
    payload: artistTempAppleId
  }
}

export const updateAddingArtistRedux = newAddingArtist => {
  return {
    type: ReducerTypes.ADDING_ARTIST_UPDATE,
    payload: newAddingArtist
  }
}
