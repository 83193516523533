import firebaseApp from 'firebaseConfig/firebase.js';
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, updatePassword, updateEmail, signInWithPopup, GoogleAuthProvider, deleteUser } from "firebase/auth";
import { to, toWithOutError } from 'utils';
import { loginErrorStore } from 'redux/actions/AuthActions';
import { logPasswordChangedAnalyticEvent } from './GoogleAnalytics';
import { userDataUpdateRedux } from 'redux/actions/UserDataActions';
import { writeCloudLog } from './LoggingService';

const auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();
auth.languageCode = 'sp';

export const reauthenticateWithCredentialOk = async currentPassword => {
  const credential = EmailAuthProvider.credential(auth.currentUser.email, currentPassword);
  let [errorReatuhenticating] = await to(reauthenticateWithCredential(auth.currentUser, credential));
  if (errorReatuhenticating) {
    console.log("Error reauthenticating user: ", errorReatuhenticating);
    return "ERROR";
  }
  return "SUCCESS";
}

export const authUpdateEmail = async (newEmail, dispatch) => {
  let [errorUpdatingEmail] = await to(updateEmail(auth.currentUser, newEmail));
  if (errorUpdatingEmail) {
    dispatch(loginErrorStore({ errorMsg: "Hubo un problema al cambiar el correo. Intente nuevamente.", error: errorUpdatingEmail }));
    return "ERROR";
  }
  return "SUCCESS";
}

export const authUpdatePassword = async (newPassword, userId, userEmail, dispatch) => {
  let [errorUpdatingPassword] = await to(updatePassword(auth.currentUser, newPassword));
  if (errorUpdatingPassword) {
    dispatch(loginErrorStore({ errorMsg: "Hubo un problema al cambiar la contraseña. Intente nuevamente.", error: errorUpdatingPassword }));
    return "ERROR";
  }
  const resultChangingEmailInUsersDoc = await toWithOutError(dispatch(userDataUpdateRedux({ password: newPassword, id: auth.currentUser.uid }, false)));
  if (resultChangingEmailInUsersDoc === "ERROR") return "ERROR";
  logPasswordChangedAnalyticEvent({ email: auth.currentUser.email });
  writeCloudLog(`Changing password to userId: ${userId} y email: ${userEmail} from profile`, { userId, userEmail }, { notError: "not error" }, "info");
  return "SUCCESS";
}

export const signInWithGoogle = async () => {

  let [errorSignInWithGoogle, resultSignInWithGoogle] = await to(signInWithPopup(auth, provider));
  if (errorSignInWithGoogle) console.log("ERROR SIGN IN WITH GOOGLE: ", errorSignInWithGoogle);

  const user = resultSignInWithGoogle.user;
  return user;
}

export const deleteCurrentAuthUser = async () => {
  const user = auth.currentUser;
  deleteUser(user);
}
