import React, { useEffect, useState } from "react";
import { Grid, LinearProgress, Typography, Box, Button } from '@mui/material';
import IconButtonWithInputFile from '../../components/CustomButtons/IconButtonWithInputFile';
import { toWithOutError } from "utils";
import { useDispatch, useSelector } from 'react-redux';
import { UPLOADING, START_UPLOAD, NO_UPLOADS, WAITING_UPLOAD, SUCCESSFULLY_UPLOADED } from 'variables/uploads.variables';
import { Check } from '@mui/icons-material/';
import { greenSuccess } from 'variables/colors';
import useWindowDimensions from '../../customHooks/useWindowDimensions';
import { getLoadingStatus, getUploadText } from "utils/upload.utils";
import {
  uploadsSignOut, finishUploadRedux, uplaodsAddSkeletonStore, uploadsAddChunkRedux,
  uploadsStartOneRedux, uploadsStartOneStore
} from 'redux/actions/UploadsActions';

const SingleUpload = ({ fileId, assetName, caller }) => {

  const dispatch = useDispatch();

  const userEmail = useSelector(store => store.userData.email);
  const uploads = useSelector(store => store.uploads);
  const uploadContext = uploads.find(upload => upload.fileId === fileId) || NO_UPLOADS;

  const [errorUploading, setErrorUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [newAssetName, setNewAssetName] = useState(assetName);
  const windowDimensions = useWindowDimensions();
  const widthWindow = windowDimensions.width;

  const uploadFileName = uploadContext.fileToUpload ? uploadContext.fileToUpload.name : ""
  const isFromAlbumInfo = caller === 'album-info';

  useEffect(() => {
    if (uploadContext !== NO_UPLOADS && uploadContext.status === UPLOADING) {
      if (uploadContext.fileSize > 0) fileUpload(uploadContext);
    }
    if (uploadContext !== NO_UPLOADS && uploadContext.status === START_UPLOAD) getFileContext();
  }, [uploadContext]);

  useEffect(() => {
    if (uploadContext !== NO_UPLOADS && isFromAlbumInfo && uploadContext.status === WAITING_UPLOAD) {
      dispatch(uploadsStartOneStore(fileId));
    }
  }, [uploadContext.status])

  const getFileContext = async () => {
    setProgress(0);
    let uploadStatusResponse = await toWithOutError(dispatch(uploadsStartOneRedux(fileId, uploadContext.fileToUpload, userEmail, isFromAlbumInfo)));
    if (uploadStatusResponse === "ERROR") { setErrorUploading({ fileId: uploadContext.fileId, error: 'connection' }); return };
    if (uploadStatusResponse === "NO_FILE") { setErrorUploading({ fileId: uploadContext.fileId, error: 'NO_FILE' }); return };
    return uploadStatusResponse;
  };

  const fileUpload = async upload => {
    const { totalChunks, fugaUuid, fileToUpload, startChunk, endChunk, fileName, totalChunksUploaded } = uploadContext;

    if (totalChunksUploaded < totalChunks) {
      let chunk = fileToUpload.slice(startChunk, endChunk);
      await uploadChunk(chunk, upload);
    }
    else {
      let finishResponse = await toWithOutError(dispatch(finishUploadRedux(fileId, fileName, fugaUuid, userEmail)));
      if (finishResponse === "ERROR") return;
    }
  };

  const uploadChunk = async (chunk, upload) => {

    let uploadOneChunkResponse = await toWithOutError(dispatch(uploadsAddChunkRedux(upload, chunk, userEmail)));
    if (uploadOneChunkResponse === "ERROR") return;

    const prog = upload.fileSize ? (uploadOneChunkResponse.endChunk / upload.fileSize) * 100 : 0.1;
    setProgress(prog);
  };

  // Este será para que en ALBUM TOTAL INFO pueda cambiar los archivos.
  const setFileObject = (e) => {
    if (isFromAlbumInfo) {
      let newFile = e.target.files[0];
      dispatch(uploadsSignOut());
      dispatch(uplaodsAddSkeletonStore(fileId, newFile));
      setNewAssetName(newFile.name);
    }
    return;
  };

  let successUpload = uploadContext.status === SUCCESSFULLY_UPLOADED;

  const gridTextOverflowSx = { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingLeft: (widthWindow < 1200 && successUpload) ? '10px' : '0px' };
  const iconCheckSx = { fontSize: widthWindow > 1200 ? '25px' : "20px", color: 'white', backgroundColor: greenSuccess, borderRadius: '50%' }
  const textToShowInName = isFromAlbumInfo ? newAssetName : assetName + " (" + uploadFileName + ")";

  return (

    <Grid item container paddingBottom={2}>

      <Grid item container sx={{ width: '85%' }} padding={1}>

        <Grid item container xs={12}>
          {successUpload && <Grid item width="8%"><Check sx={iconCheckSx} /></Grid>}
          <Grid item width={successUpload ? "82%" : "90%"} sx={gridTextOverflowSx}><b>{textToShowInName}</b></Grid>
          <Grid item width="5%">
            <Typography sx={{ fontSize: '1em', fontWeight: 500 }} variant="body2" color="text.secondary">{`${Math.round(progress,)}%`}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ width: '95%', mr: 1 }}>
            <LinearProgress variant="determinate" key={'progress ' + fileId} color={successUpload ? 'success' : 'primary'} value={progress} sx={{ width: "100%" }} />
          </Box>
        </Grid>

      </Grid>

      <Grid item sx={{ width: '15%' }}>
        <IconButtonWithInputFile
          id={fileId}
          subText={getUploadText(isFromAlbumInfo, uploadContext.status)}
          disabled={!isFromAlbumInfo}
          status={getLoadingStatus(uploadContext.status)}
          textButton={'Upload ' + fileId}
          onClickHandler={setFileObject}
          fileType='audio/x-wav'
          color='primary' />
      </Grid>

      {/* <Grid item xs={12}>
        <Button onClick={() => setProgress(100)}>Set Progress 100</Button>
        <Button onClick={() => setProgress(25)}>Set Progress 25</Button>
        <Button onClick={() => setProgress(0)}>Set Progress 0</Button>
      </Grid> */}

    </Grid>
  );
}

export default SingleUpload;
