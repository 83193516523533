import React from 'react';
import { IconButton, Typography, Grid } from '@mui/material';
import { getIconByStatus } from 'utils/upload.utils';

const IconButtonWithInputFile = ({ id, subText, status, onClickHandler, fileType, color, disabled }) => {
  const buttonAdd = { backgroundColor: color, '&:hover': { backgroundColor: color }, padding: 0 }

  return (
    <Grid sx={{ width: '60px', textAlign: 'center', padding: 0 }}>
      <IconButton
        key={'button' + id}
        sx={buttonAdd}
        component="label">
        {!disabled && <input hidden id={'input id ' + id} name="upload-track" type="file" accept={fileType} onChange={onClickHandler} />}
        {getIconByStatus(status)}
      </IconButton>
      <Typography sx={{ fontSize: '12px', fontWeight: '500', margin: 0, padding: 0 }}>{subText}</Typography>
    </Grid>
  );
}

export default IconButtonWithInputFile;