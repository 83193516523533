import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Grid, Typography, IconButton } from "@mui/material/";
import { useDispatch, useSelector } from 'react-redux';
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import { container, grayColor } from "assets/jss/material-dashboard-react.js";
import useFirestoreQuery from '../../customHooks/useFirestoreQuery';
import { getDocIfLastUpdateFS, getElementsAdminQueryFS } from '../../services/FirestoreServices';
import { userDataAddInfoStore } from '../../redux/actions/UserDataActions';
import { paymentsAddLocal, paymentsPayUpdateLocal } from "redux/actions/PaymentsActions.js";
import { sortPaymentsByField } from "utils/payments.utils.js";
import { getCantDaysInMS } from "utils/timeRelated.utils.js";
import { userIsAdmin } from "utils/users.utils.js";
import { dashboardAdminTitle, dashboardUserTitle } from "utils/textToShow.utils.js";
import useWindowDimensions from "customHooks/useWindowDimensions.js";
import { Menu } from "@mui/icons-material";
import { mainBlue } from "variables/colors.js";
import { useNavigate } from 'react-router-dom';
import { signOutFromFirebase } from "redux/actions/AuthActions.js";

const Navbar = (props) => {

  const { handleDrawerToggle, openSidebar } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector(store => store.userData);
  const actualPayment = useSelector(store => store.payments.actualPayment);
  const paymentsFromStore = useSelector(store => store.payments.payments);
  const isAdmin = userIsAdmin(userData.rol);

  const { width } = useWindowDimensions();
  const isMobile = width < 1200;

  const stateUserSnap = useFirestoreQuery(getDocIfLastUpdateFS("users", userData?.id || 0, userData?.lastUpdateTS || new Date().getTime()));
  const statePaymentSnap = useFirestoreQuery(actualPayment.id &&
    getDocIfLastUpdateFS("payments", actualPayment.id || 0, actualPayment?.lastUpdateTS || new Date().getTime()));

  let sortedPayments = sortPaymentsByField(paymentsFromStore, "lastUpdateTS");
  const statePaymentsSnap = useFirestoreQuery(
    getElementsAdminQueryFS("payments", 100,
      sortedPayments[0]?.lastUpdateTS || (new Date().getTime() - getCantDaysInMS(30)), isAdmin ? 'admin' : userData?.id, "lastUpdateTS")
  );

  useEffect(() => {
    const checkSessionTimeout = () => {
      const now = new Date().getTime();
      const sixHours = 6 * 60 * 60 * 1000;
      const lastLogin = userData.lastTimeSignedIn;
      const timeDiff = now - lastLogin;
      const isInNewAlbumPage = location.pathname === '/admin/new-album';
      if (userData && (timeDiff > sixHours) && !isInNewAlbumPage) {
        dispatch(signOutFromFirebase())
          .then(() => navigate("/login"))
          .catch(error => console.error("Error Sign Out: ", error));
      };
    };

    const intervalId = setInterval(checkSessionTimeout, 1000 * 60); // check every minute
    return () => clearInterval(intervalId);
  }, [userData, location]);

  useEffect(() => {
    if (statePaymentsSnap.status === "loading") return "Loading...";
    if (statePaymentsSnap.status === "error") return `Error al cargar los ultimos Users: ${statePaymentsSnap.error.message}`;
    if (statePaymentsSnap.status === "success" && statePaymentsSnap.data.length > 0) dispatch(paymentsAddLocal(statePaymentsSnap.data));
  }, [statePaymentsSnap]);

  useEffect(() => {
    if (stateUserSnap.status === "loading") return "Loading...";
    if (stateUserSnap.status === "error") return `Error al cargar los ultimos Albums: ${stateUserSnap.error.message}`;
    if (stateUserSnap.status === "success" && stateUserSnap.data.length > 0) dispatch(userDataAddInfoStore(stateUserSnap.data[0]));
  }, [stateUserSnap]);

  useEffect(() => {
    if (statePaymentSnap.status === "loading") return "Loading...";
    if (statePaymentSnap.status === "error") return `Error al cargar los ultimos Albums: ${statePaymentSnap.error.message}`;
    if (statePaymentSnap.status === "success" && statePaymentSnap.data.length > 0) dispatch(paymentsPayUpdateLocal(statePaymentSnap.data[0]));
  }, [statePaymentSnap]);

  return (
    <AppBar sx={appBarStyle}>
      <Toolbar sx={{ ...containerStyle, height: "70px" }}>
        <Grid container>
          {isMobile
            ? <Grid item container xs={10} sx={{ textAlign: "center", ...flexStyle }}>
              <Grid item xs={1}>
                <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} edge="start"
                  sx={{ mr: 2, ...(openSidebar && { display: 'none' }), color: mainBlue }}
                >
                  <Menu />
                </IconButton>
              </Grid>
              <Grid item xs={11}>
                <Typography sx={{ fontSize: isMobile ? "1.5em" : "2.2em", fontWeigth: 500 }}>{isAdmin ? dashboardAdminTitle : dashboardUserTitle}</Typography>
              </Grid>
            </Grid>
            : <Grid item xs={10} sx={{ textAlign: "center", ...flexStyle }}>
              <Typography sx={{ fontSize: isMobile ? "1.5em" : "2.2em", fontWeigth: 500, textAlign: "initial" }}>{isAdmin ? dashboardAdminTitle : dashboardUserTitle}</Typography>
            </Grid>}

          <Grid item xs={2} sx={{ textAlign: "center", ...flexStyle }}>
            <AdminNavbarLinks />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar >
  );
}

export default Navbar;

Navbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};

const appBarStyle = {
  backgroundColor: "transparent",
  boxShadow: "none",
  borderBottom: "0",
  marginBottom: "0",
  position: "relative",
  width: "100%",
  height: "70px",
  paddingTop: "0px",
  // zIndex: "1029",
  color: grayColor[7],
  border: "0",
  borderRadius: "3px",
  padding: "10px 0",
  transition: "all 150ms ease 0s",
  minHeight: "20px",
  display: "block"
}

const containerStyle = {
  ...container,
  minHeight: "70px"
}
const flexStyle = {
  flex: 1,
  height: "70px"
}