
import { Grid, IconButton, CircularProgress, Fab, Typography, Skeleton, Link } from '@mui/material';
import { Delete, Edit, Check, AddCircle, Settings, PeopleAlt } from '@mui/icons-material/';
import { getAlbumReleaseDate, getStateColor } from 'utils/albums.utils';
import { secondsToMmSs } from './timeRelated.utils';
import { getOurStateFromFugaState } from 'utils/albums.utils';
import SingleUpload from 'views/Uploads/SingleUpload';
import { validate } from 'uuid';

export const trackUploadProgress = (progressTrack, buttonSuccessStyle) => {
  return (
    progressTrack < 100
      ? <CircularProgress variant="determinate" value={progressTrack} size={36} />
      : <Fab aria-label="uploadSucces" sx={buttonSuccessStyle} component="span">
        <Check size={24} />
      </Fab>
  )
}

export const moreInfoActionRender = (userId, handleMorInfo) => {
  return <IconButton onClick={() => handleMorInfo(userId)} aria-label="Ir al elemento" target="_blank">
    <AddCircle />
  </IconButton>
}

export const moreInfoAlbumRender = albumId => {
  let targetOriginUrl = window.location.origin === "http://localhost:3001" ? "http://localhost:3001" : "https://app.laflota.com.ar";
  return <IconButton href={`${targetOriginUrl}/admin/albums/${albumId}`} aria-label="Ir al elemento" target="_blank">
    <AddCircle />
  </IconButton>
}

export const goToEditArtistIcon = artistId => {
  let targetOriginUrl = window.location.origin === "http://localhost:3001" ? "http://localhost:3001" : "https://app.laflota.com.ar";
  return <IconButton href={`${targetOriginUrl}/admin/edit-artist/${artistId}`} aria-label="Editar artista" target="_blank">
    <Edit />
  </IconButton>
}

export const getLocalLinkTo = (urlPostAdmin, linkName) => {
  let targetOriginUrl = window.location.origin === "http://localhost:3001" ? "http://localhost:3001" : "https://app.laflota.com.ar";
  return <Link sx={{ fontSize: '1rem' }} href={`${targetOriginUrl}/admin/${urlPostAdmin}`} target="_blank" variant="body2" underline="hover">
    {linkName}
  </Link>
}

export const getOutLinkTo = (url, linkName, sx) => {
  return <Link sx={sx} href={url} target="_blank" variant="body2" underline="hover">
    {linkName}
  </Link>
}

export const iconOpenActions = (elementId, elementField, setOpenActionsDialog) => {
  return <IconButton onClick={() => setOpenActionsDialog({ open: true, [elementField]: elementId })}>
    <Settings />
  </IconButton>
}

export const iconOpenClaimers = (elementId, elementField, setOpenActionsDialog,) => {
  return <IconButton onClick={() => setOpenActionsDialog({ open: true, [elementField]: elementId })}>
    <PeopleAlt />
  </IconButton>
}

export const deleteAction = (track, handleDeleteTrack) => {
  return (
    <Grid item xs={6}>
      <IconButton color="inherit" size="small" onClick={() => handleDeleteTrack(track)}>
        <Delete fontSize="inherit" />
      </IconButton>
    </Grid>
  );
};

export const editAction = (track, handleEditTrack) => {
  return (
    <Grid item xs={12}>
      <IconButton color="inherit" size="small" onClick={() => handleEditTrack(track)}>
        <Edit fontSize="inherit" />
      </IconButton>
    </Grid>
  )
};

export const getTracksAsDataTable = (tracksTotalInfo, handleEditTrack, handleDeleteTrack) => {
  return tracksTotalInfo.map(trackWithAllInfo => [
    trackWithAllInfo.position,
    trackWithAllInfo.title,
    trackWithAllInfo.version,
    trackWithAllInfo.isrc,
    editAction(trackWithAllInfo, handleEditTrack),
    deleteAction(trackWithAllInfo, handleDeleteTrack)
  ]);
}


export const getAlbumsPropsForUsersDataTable = (albums, isAdmin, handleGoToAlbum) => {
  let albumDataTable = [];
  albums.forEach(album => {
    const stateInfoStyle = { color: getStateColor(album.state ? album.state : "", false), fontSize: "1em", fontWeight: 600 };
    albumDataTable.push([
      moreInfoAlbumRender(album.id, handleGoToAlbum),
      album.version ? album.title + " (" + album.version + ")" : album.title,
      album.nombreArtist,
      getStateInfo(stateInfoStyle, album.state, isAdmin, album.releaseDayjsFormat),
      album.upc || "",
      album.format,
      new Date(album.whenCreatedTS).toLocaleDateString(),
      `${album.dayOfMonth}/${album.month}/${album.year}`,
    ])
  });
  return albumDataTable;
}

const getStateInfo = (sx, state, isAdmin, releaseDayjsFormat) => {
  return <Typography sx={sx} > {state ? getOurStateFromFugaState(state, isAdmin, releaseDayjsFormat) : "Ir al Album para ver el Estado"}</Typography>
}

export const getAlbumsPropsForAdminDataTable = (albums, handleOpenUserDialog, handleGoToAlbum, setOpenActionsDialog,) => {
  let albumDataTable = [];
  albums.forEach(album => {
    const stateInfoStyle = { color: getStateColor(album.state ? album.state : "", true), fontSize: "1em", fontWeight: 600 };
    albumDataTable.push([
      iconOpenActions(album.id, 'albumId', setOpenActionsDialog),
      moreInfoAlbumRender(album.id, handleGoToAlbum),
      album.version ? album.title + " (" + album.version + ")" : album.title,
      getLocalLinkTo(`artists?view=artist&id=${album.artistId}`, album.nombreArtist),
      getStateInfo(stateInfoStyle, album.state, true, album.releaseDayjsFormat),
      moreInfoActionRender(album.ownerId, handleOpenUserDialog),
      album.upc ? getOutLinkTo(`https://next.fugamusic.com/product/${album.fugaId}`, album.upc, { fontSize: '1rem' }) : "",
      album.format,
      new Date(album.whenCreatedTS).toLocaleDateString(),
      getAlbumReleaseDate(album),
    ])
  });
  return albumDataTable;
}

const trackSingleUpload = (isEditing, fileId, audioFile) => {
  let audioName = <b style={{ color: 'red' }}>falta archivo/cargar</b>;
  if (audioFile && audioFile.original_filename)
    audioName = validate(audioFile.original_filename.slice(0, 36))
      ? audioFile.original_filename.slice(37) : audioFile.original_filename;
  return isEditing
    ? (
      <Grid container item key={'grid' + fileId}>
        <SingleUpload fileId={fileId} assetName={audioName} caller='album-info' />
      </Grid>
    )
    : audioName
}

export const getTracksDataTableFromFugaAssets = (fugaTracksAssets, isEditing) => {
  let tracksDataTable = [];
  fugaTracksAssets.forEach(fugaTrackAsset => {
    let dataToShow = [
      fugaTrackAsset.sequence,
      fugaTrackAsset.asset_version ? fugaTrackAsset.name + " (" + fugaTrackAsset.asset_version + ")" : fugaTrackAsset.name,
      fugaTrackAsset.isrc,
      fugaTrackAsset.display_artist,
      secondsToMmSs(fugaTrackAsset.duration),
      trackSingleUpload(isEditing, fugaTrackAsset.id, fugaTrackAsset.audio)
    ];
    tracksDataTable.push(dataToShow)
  });
  return tracksDataTable;
}

export const getTracksDataTableFromOurAssets = (ourTracksAssets, handleEditTrack, handleDeleteTrack) => {
  let tracksDataTable = [];
  ourTracksAssets.forEach(ourTrackAsset => {
    tracksDataTable.push([
      ourTrackAsset.position,
      ourTrackAsset.title,
      ourTrackAsset.isrc || "no provisto",
      ourTrackAsset.primary_artist,
      ourTrackAsset.track.name,
      // editAction(fugaTrackAsset, handleEditTrack),
      // deleteAction(fugaTrackAsset, handleDeleteTrack),
    ])
  });
  return tracksDataTable;
}

const loadingSkeleton = () => (
  <Skeleton variant="rectangular" sx={{ height: '30px', mx: 1 }} />
);

export const getSkeletonRows = (keysObjectCant, rowsPerPage) => {
  return [...Array(rowsPerPage)].map(() => {
    let skeletonRow = {};
    [...Array(keysObjectCant)].forEach((_, key) => skeletonRow[key] = loadingSkeleton());
    return skeletonRow;
  })
}