import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const OkDialog = ({ open, onClose, onOk, text, title }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title ? title : "Confirmación"}</DialogTitle>
      <DialogContent>
        <p>Confirma que quieres realizar esta acción</p>
        {text}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Atrás
        </Button>
        <Button onClick={onOk} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OkDialog;
