import React from 'react';
import { Grid, Button } from '@mui/material';
import AlbumLinksButton from './AlbumLinksButton';

const AlbumActionButtons = ({
  canEditOrRedelivery,
  canEditRelease,
  isEditing,
  areMissingTracks,
  handleEditOrRedeliver,
  handleCancelEditing,
  handleActionDialog,
  handleOpenDsps,
  albumData,
  buttonColorStyle
}) => {
  return (
    <Grid item xs={12} textAlign='center' sx={{ display: 'block ruby' }} padding={1}>
      {canEditOrRedelivery && canEditRelease && (
        <Grid item padding={1}>
          <Button
            width="60px"
            variant="contained"
            onClick={handleEditOrRedeliver}
            sx={buttonColorStyle}
          >
            {isEditing ? (areMissingTracks ? 'Distribuir' : 'Redistribuir') : 'Editar'}
          </Button>
        </Grid>
      )}

      {isEditing && (
        <Grid item padding={1}>
          <Button variant="contained" onClick={handleCancelEditing} sx={buttonColorStyle}>
            Cancelar
          </Button>
        </Grid>
      )}

      {!isEditing && canEditRelease && (
        <Grid item padding={1}>
          <Button variant="contained" onClick={handleActionDialog} sx={buttonColorStyle}>
            Opciones
          </Button>
        </Grid>
      )}

      {canEditOrRedelivery && canEditRelease && !isEditing && (
        <Grid item padding={1}>
          <Button variant="contained" onClick={handleOpenDsps} sx={buttonColorStyle}>
            DSP's
          </Button>
        </Grid>
      )}

      {!isEditing && (
        <AlbumLinksButton albumData={albumData} buttonColorStyle={buttonColorStyle} />
      )}
    </Grid>
  );
};

export default AlbumActionButtons;
