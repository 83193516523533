import React, { useState } from "react";
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid,
  DialogContentText, TextField, Backdrop, CircularProgress
} from '@mui/material';
import { loginOtpDialogSubitle, otpDialogTitle } from "utils/textToShow.utils";
import { nodeEnv, to } from "utils";
import TextFieldWithInfo from "components/TextField/TextFieldWithInfo";

const TwoAuthLogin = ({ isOpen, userId, handleOk, handleClose, handleVerifiedOTP, email }) => {

  const [loading, setLoading] = useState(false);
  const [otpVerified, setOtpVerified] = useState({ open: false, otp: "", checked: false });

  const insertOtp = text => setOtpVerified({ ...otpVerified, otp: text.trim() });

  const handleCloseActions = () => handleClose();

  const handleCheckOtpAndGenerateLogin = async () => {
    setLoading(true);
    if (nodeEnv === "development") { await handleOk(); return; }
    const [errorCheckingOTP, otpResponse] = await to(handleVerifiedOTP(userId, otpVerified.otp));
    if (errorCheckingOTP || otpResponse === "ERROR_OTP") console.log({ errorCheckingOTP, otpResponse });
    if (otpResponse === "SUCCESS") await handleOk();
    setLoading(false);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleCheckOtpAndGenerateLogin();
    }
  };

  return (
    <>
      <Dialog maxWidth="md" id="info-dialog" open={isOpen} onClose={handleCloseActions}>

        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogTitle id="title-info-dialog">
          {`Solicitud del usuario: ${email}`}
        </DialogTitle>

        <DialogContent>

          <DialogContentText key={'subtitle'}>
            <span style={{ fontSize: "14px" }}>{loginOtpDialogSubitle}</span>
          </DialogContentText>

          <Grid container direction="column" paddingTop={2}>
            {!otpVerified.checked &&
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <TextFieldWithInfo
                  required
                  autoFocus
                  label="Código OTP"
                  name="otp"
                  value={otpVerified.otp}
                  onChange={(evento) => insertOtp(evento.target.value)}
                  onKeyPress={handleKeyDown} 
                  pasteAddornment={true}
                />
              </Grid>}

          </Grid>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseActions} color="primary">
            Atrás
          </Button>
          <Button onClick={handleCheckOtpAndGenerateLogin} color="primary">
            Verificar OTP
          </Button>
        </DialogActions>

      </Dialog>
    </>
  )
}

export default TwoAuthLogin;