import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Grid } from '@mui/material';
import { toWithOutError } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import DeleteDialog from '../../components/Dialogs/DeleteDialog';
import { addAlbumWithFugaIdToArtistsSubtitle, deleteArtistDialogText, oacAdminAcceptText, oacAdminRejectText } from '../../utils/textToShow.utils';
import { Delete, Edit, AddCircle } from '@mui/icons-material/';
import { useNavigate } from 'react-router-dom';
import { mainBlue, lightBlue, greenSuccess, redDelete } from 'variables/colors';
import EditOrAddFieldsDialog from '../../components/Dialogs/EditOrAddFieldDialog';
import { userIsAdmin } from 'utils/users.utils';
import { getArtistCanEdit, getArtistById } from "utils/artists.utils";
import { artistEditOneFieldRedux, artistsCreateAlbumFromFugaId, deleteArtistRedux } from 'redux/actions/ArtistsActions';
import { artistStatesShowAdmin, artistStatesListShowAdmin, ARTIST_SUSPEND_INFRACTION, ARTIST_SUSPEND_NO_PAY, artistStateCantRelease, artistStateColorsAdmin, artistStateColorsUser, artistStatesShowUser, linkToOACRequest, ARTIST_INACTIVE, artistOacStates, ARTIST_OAC_REQUESTED, ARTIST_OAC_REJECTED, ARTIST_ACTIVE } from "variables/artist.variables";
import ArtistInfractionDialog from './ArtistInfractionDialog';
import CopyableEditText from "components/TextField/CopyableEditText";
import { getOutLinkTo } from "utils/tables.utils";
import InfoActionDialog from "components/Dialogs/InfoActionDialog";
import { artistSendEmailOacAction } from "services/BackendCommunication";


const ArtistActionsDialog = (props) => {

  const { isOpen, handleClose, artistId, caller } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const artistsFromStore = useSelector(store => store.artists.artists);
  const calledFromTotalInfoArtist = caller === 'total-info-album';
  const userData = useSelector(store => store.userData);
  const isAdmin = userIsAdmin(userData.rol);

  const artist = getArtistById(artistsFromStore, artistId);

  const artistIsDeleted = artist.state === ARTIST_INACTIVE;
  const artistCanEdit = getArtistCanEdit(artist.state, userData.userStatus);
  const artistWithPossibleRoyalties = false;
  const artistOacAction = artistOacStates.includes(artist.state);

  const userStatusToShow = isAdmin ? artistStatesShowAdmin[artist.state] : artistStatesShowUser[artist.state];
  const userStatusColor = isAdmin ? artistStateColorsAdmin[artist.state] : artistStateColorsUser[artist.state];

  const defaultInfoDialog = { title: "", subtitle: [""], open: false };
  const [infoDialogText, setInfoDialogText] = useState(defaultInfoDialog);

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [buttonState, setButtonState] = useState('none');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState({ open: false, title: "", subtitle: [""], values: "", type: "" })
  const [openInfractionDialog, setOpenInfractionDialog] = useState(false);

  const handleCloseDelete = () => setOpenDeleteDialog(false);

  const handleDelete = async () => {
    setLoadingDelete(true);
    let result = await toWithOutError(dispatch(deleteArtistRedux(artist)));
    if (result === "ERROR") { setButtonState("error"); setLoadingDelete(false); }
    else {
      if (calledFromTotalInfoArtist) navigate('/admin/dashboard')
      else setLoadingDelete(false); setOpenDeleteDialog(false); handleClose();
    }
  }

  const goToArtistInfoAndEdit = () => navigate(`/admin/edit-artist/${artist.id}`);

  const handleEditArtistState = () => setOpenEditDialog({
    open: true, title: "Cambiar el estado del Artista", subtitle: ["Solo se cambiará en la APP"],
    handleConfirm: (newValue) => handleConfirmEditArtist(newValue, 'state'),
    initialValues: artistStatesShowAdmin[artist.state] || "", optionsValues: artistStatesListShowAdmin,
    values: "", handleCloseDialog: handleCloseEditDialog, type: "only-one-select"
  });

  const handleEditFugaId = () => setOpenEditDialog({
    open: true, title: "Enlazar artista con Fuga", subtitle: ["Enlazar artista con identificador de FUGA."],
    handleConfirm: (newValue) => handleConfirmEditArtist(newValue, 'fugaId'),
    initialValues: artist.fugaId || "", values: "", type: '', handleCloseDialog: handleCloseEditDialog
  });

  const handleEditOAC = () => isAdmin ? setOpenEditDialog({
    open: true, title: "Agregar o editar YouTube OAC", subtitle: ["Enlazar artista con canal de YouTube."],
    handleConfirm: (newValue) => handleConfirmEditArtist(newValue, 'ytOAC'),
    initialValues: artist.ytOAC || "", values: "", type: '', handleCloseDialog: handleCloseEditDialog
  }) : navigate("/admin/youtube-for-artists");

  const handleAddAlbumWithFugaId = () => setOpenEditDialog({
    open: true, title: "Agregar lanzamiento al Artista", subtitle: addAlbumWithFugaIdToArtistsSubtitle,
    handleConfirm: (newValue) => handleAddAlbumToArtist(newValue),
    initialValues: "", values: "", type: '', handleCloseDialog: handleCloseEditDialog
  });

  const handleAddAlbumToArtist = async fugaIdAlbum => {
    setButtonState("loading");
    let creatingAlbum = await toWithOutError(dispatch(artistsCreateAlbumFromFugaId(artist, fugaIdAlbum)));
    if (creatingAlbum === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
  }

  const handleConfirmEditArtist = async (newValue, fieldName) => {
    setButtonState("loading");
    let finalValue = fieldName === 'state' ? newValue.id : newValue;
    if (fieldName === 'ytOAC')
      await toWithOutError(dispatch(artistEditOneFieldRedux(artist, { state: ARTIST_OAC_REQUESTED }, artist.ownerEmail, false, true)));
    let editResult = await toWithOutError(dispatch(artistEditOneFieldRedux(artist, { [fieldName]: finalValue }, artist.ownerEmail, false, true)));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
    if (fieldName === 'state' && (newValue.id === ARTIST_SUSPEND_INFRACTION || newValue.id === ARTIST_SUSPEND_NO_PAY)) {
      setOpenInfractionDialog(true);
    }
  }

  const handleCloseEditDialog = () => setOpenEditDialog({ open: false, title: "", subtitle: [""], values: "" });

  const handleOacReject = () => {
    setInfoDialogText({ title: "Rechazar OAC", subtitle: oacAdminRejectText, open: true, action: 'reject' });
  }
  const handleOacAccept = () => {
    setInfoDialogText({ title: "Aceptar OAC", subtitle: oacAdminAcceptText, open: true, action: 'accept' });
  }

  const handleSendConfirmOacAction = async () => {
    const resultSendingOacAction = await artistSendEmailOacAction(
      artist.ownerEmail, artist.name, artist.ytOAC, infoDialogText.action);
    if (resultSendingOacAction === "ERROR") console.log("ERRROR: ", resultSendingOacAction);

    if (infoDialogText.action === 'accept') await toWithOutError(dispatch(artistEditOneFieldRedux(artist, { state: ARTIST_ACTIVE }, artist.ownerEmail, false, true)));
    else await toWithOutError(dispatch(artistEditOneFieldRedux(artist, { state: ARTIST_OAC_REJECTED }, artist.ownerEmail, false, true)));
    setInfoDialogText(defaultInfoDialog);
  }

  const jsxArtistStatus = <DialogContentText key={'artist status'}>
    Estado del artista: <b style={{ color: userStatusColor }}>{userStatusToShow}</b>
  </DialogContentText>

  const jsxSpotifyUri = <DialogContentText key={'artist spotify uri'}>
    Spotify Uri: <b>{`${artist.spotify_uri}` || "será asignado por Spotify"}</b>
  </DialogContentText>

  const jsxFugaId = <DialogContentText key={'artist fugaId'}>
    ID Fuga: {getOutLinkTo(`https://next.fugamusic.com/artist/${artist.fugaId}`, artist.fugaId, upcLinkSx)}
  </DialogContentText>

  const jsxAppleId = <DialogContentText key={'artist apple id'}>
    Apple ID: <b>{`${artist.apple_id}` || "será asignado por Apple"}</b>
  </DialogContentText>

  const jsxYtOAC = <DialogContentText key={'artist youtube oac'}>
    YouTube OAC: <b>{artist.ytOAC ? artist.ytOAC : isAdmin ? "sin asignar" : linkToOACRequest}</b>
  </DialogContentText>

  const jsxIdUser = <DialogContentText key={'artist ownerID'}>
    ID Usuario: <b>{`${artist.ownerId}`}</b>
  </DialogContentText>

  const jsxEmailUser = <DialogContentText key={'user ownerEmail'}>
    Email Usuario: <b>{`${artist.ownerEmail}`}</b>
  </DialogContentText>

  return (
    <>
      <DeleteDialog isOpen={openDeleteDialog} setIsOpen={setOpenDeleteDialog} handleClose={handleCloseDelete}
        title={"Eliminar Artista"} textName={artist.name} textContent={deleteArtistDialogText}
        deleteAction={handleDelete} deleteButtonText={"Eliminar"} openLoader={loadingDelete} buttonState={'delete'}
      />

      <InfoActionDialog key={"oac-dialog"} id='oac-dialog' isOpen={infoDialogText.open} handleOk={handleSendConfirmOacAction}
        handleClose={() => setInfoDialogText(defaultInfoDialog)} title={infoDialogText.title} contentTexts={infoDialogText.subtitle} />

      <EditOrAddFieldsDialog loading={buttonState === "loading"}
        buttonState={buttonState} editOptions={openEditDialog} setEditOptions={setOpenEditDialog} />

      {openInfractionDialog && <ArtistInfractionDialog artistData={artist} openInfractionDialog={openInfractionDialog}
        setOpenInfractionDialog={setOpenInfractionDialog} />}

      <Dialog maxWidth="sm" id="artist-dialog" open={isOpen} onClose={handleClose}>

        <DialogTitle id="title-info-dialog" sx={{ textAlign: 'center' }}>
          {`${isAdmin ? 'Acciones rápidas del Artista' : 'Artista'}: ${artist.name}`}
        </DialogTitle>

        <DialogContent>

          <Grid item xs={12} paddingBottom={1}>

            {isAdmin && artist.state &&
              <CopyableEditText color='grey' jsxElementText={jsxArtistStatus} handleEdit={handleEditArtistState} />}

            {isAdmin && artist.ownerEmail &&
              <CopyableEditText textToCopy={artist.ownerEmail} color='grey' jsxElementText={jsxEmailUser} />}

            {isAdmin && artist.ownerId &&
              <CopyableEditText textToCopy={artist.ownerId} color='grey' jsxElementText={jsxIdUser} />}

            {isAdmin && artist.fugaId &&
              <CopyableEditText textToCopy={artist.fugaId} color='grey' jsxElementText={jsxFugaId} handleEdit={handleEditFugaId} />}

            <CopyableEditText textToCopy={artist.spotify_uri} color='grey' jsxElementText={jsxSpotifyUri} />
            <CopyableEditText textToCopy={artist.apple_id} color='grey' jsxElementText={jsxAppleId} />

            {isAdmin
              ? <CopyableEditText textToCopy={artist.ytOAC} color='grey' jsxElementText={jsxYtOAC} handleEdit={handleEditOAC} />
              : <CopyableEditText textToCopy={artist.ytOAC} color='grey' jsxElementText={jsxYtOAC} handleEdit={handleEditOAC} />}

          </Grid>

          {isAdmin && <DialogContentText key={'artist text 0'}>
            Las acciones que se lleven a cabo en Editar, se verán reflejadas en Fuga.
          </DialogContentText>}
          {isAdmin && <DialogContentText key={'artist text 1'}>
            Si se edita la metadata de un Artista, al mismo tiempo se realizará el redelivery de sus lanzamientos.
          </DialogContentText>}

          {!artistIsDeleted && <Grid container paddingTop={2}>

            {isAdmin && <Grid item xs={12} lg={12} padding={1}>
              <Button
                onClick={handleAddAlbumWithFugaId}
                sx={{ backgroundColor: mainBlue, color: 'white', '&:hover': { backgroundColor: lightBlue } }}
                endIcon={<AddCircle />}
                fullWidth>
                Album con FUGA ID
              </Button>
            </Grid>}

            {isAdmin && artistOacAction && <Grid item xs={6} lg={6} padding={1}>
              <Button
                onClick={handleOacAccept}
                sx={{ backgroundColor: greenSuccess, color: 'white', '&:hover': { backgroundColor: greenSuccess } }}
                fullWidth>
                Confirmar OAC
              </Button>
            </Grid>}

            {isAdmin && artistOacAction && <Grid item xs={6} lg={6} padding={1}>
              <Button
                onClick={handleOacReject}
                sx={{ backgroundColor: redDelete, color: 'white', '&:hover': { backgroundColor: redDelete } }}
                fullWidth>
                Rechazar OAC
              </Button>
            </Grid>}

            {(isAdmin || (artistCanEdit && !calledFromTotalInfoArtist)) && <Grid item xs={6} lg={6} padding={1}>
              <Button
                onClick={goToArtistInfoAndEdit}
                sx={{ backgroundColor: mainBlue, color: 'white', '&:hover': { backgroundColor: lightBlue } }}
                endIcon={<Edit />}
                fullWidth>
                Editar
              </Button>
            </Grid>}

            {!artistIsDeleted && <Grid item xs={6} lg={6} padding={1}>
              <Button
                onClick={() => setOpenDeleteDialog(true)}
                sx={{ backgroundColor: redDelete, color: 'white', '&:hover': { backgroundColor: redDelete } }}
                endIcon={<Delete />}
                fullWidth>
                {artistWithPossibleRoyalties ? "Baja" : "Eliminar"}
              </Button>
            </Grid>}

          </Grid>}

        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Atrás
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ArtistActionsDialog;

ArtistActionsDialog.defaultProps = {
  isOpen: false,
}

ArtistActionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

const upcLinkSx = { lineHeight: 1.5, marginLeft: "0.2em", fontWeight: 400, fontSize: "1rem" };