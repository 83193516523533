import React, { useState } from 'react';
import {
  Button, DialogContent, DialogContentText, DialogTitle, Grid, CircularProgress, Backdrop
} from '@mui/material';
import { SUB_ORDER_NEED_PROOF, SUB_ORDER_PLAN_SELECTED } from 'variables/financial';
import { useDispatch, useSelector } from 'react-redux';
import { formatThousandsPoint, toWithOutError } from 'utils';
import { paymentsPayUpdated } from 'redux/actions/PaymentsActions';
import { getJsxAlias, getJsxCVU, getPaymentIdJsx } from 'utils/payments.utils';
import { userDataUpdateRedux } from 'redux/actions/UserDataActions';
import { USER_PENDING_PROOF } from 'variables/user.variables';
import { createPayedPaymentInfo } from 'factory/payments.factory';
import { getNewUserNeedCheckInfoFromPayment } from 'factory/users.factory';

const TransferencePayment = ({ onClose, show }) => {

  const dispatch = useDispatch();
  const userData = useSelector(store => store.userData);
  const actualPayment = useSelector(store => store.payments?.actualPayment);
  const preferenceData = actualPayment.preference_data;
  const preferenceItemData = preferenceData?.items.length > 0 ? preferenceData?.items[0] : {};
  
  const [openLoader, setOpenLoader] = useState(false);

  const handleOk = async () => {
    setOpenLoader(true);
    let id = actualPayment.id;
    let status = SUB_ORDER_NEED_PROOF;
    let userStatus = USER_PENDING_PROOF;
    let updatePaymentNewData = createPayedPaymentInfo(actualPayment);
    let resultUpdatePay = await toWithOutError(dispatch(paymentsPayUpdated({ ...updatePaymentNewData, id, status })));
    if (resultUpdatePay === "ERROR") { setOpenLoader(false); return; }
    let fromPaymentAction = true;
    let newSubInfo = getNewUserNeedCheckInfoFromPayment(userData, updatePaymentNewData);
    let resultUpdateUser = await toWithOutError(
      dispatch(userDataUpdateRedux({ ...newSubInfo, userStatus, id: actualPayment.ownerId }, fromPaymentAction)));
    if (resultUpdateUser === "ERROR") console.log("ERROR UPDATING USER DATA");
    setOpenLoader(false);
    onClose();
  }

  const handleCancel = () => {
    // dispatch(paymentsPayUpdateLocal({ ...actualPayment, status: SUB_ORDER_PLAN_SELECTED }));
    onClose();
  }

  if (!show) return null;

  return (
    <Grid sx={{ maxWidth: "600px" }}>
      <Backdrop open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DialogTitle sx={{ textAlign: "center", padding: "0px 8px 0px 8px" }}>Datos de Pago</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <DialogContentText sx={{ fontSize: "3rem", fontWeight: 500, textAlign: 'center', paddingTop: 0 }} key={'amount-bank-pay'}>
          {preferenceItemData.currency_id + " " + formatThousandsPoint(preferenceItemData.unit_price)}
        </DialogContentText>
        <DialogContentText paddingBottom={1}>
          Para pagarnos por transferencia directa en ARS, puedes realizarlo a la siguiente cuenta de <b>Mercado Pago:</b>
        </DialogContentText>
        <DialogContentText>
          {getJsxCVU}
        </DialogContentText>
        <DialogContentText>
          {getJsxAlias}
        </DialogContentText>
        <DialogContentText paddingBottom={1} paddingTop={1}>
          Para facilitar el reconocimiento del pago y asociarlo a tu cuenta, si tu banco lo permite, ingresa el <b>Número de Referencia </b>
          que te mostramos abajo.
        </DialogContentText>
        <DialogContentText>
          {getPaymentIdJsx(actualPayment.id)}
        </DialogContentText>
      </DialogContent>

      <Grid container>
        <Grid item xs={6}>
          <Button onClick={handleCancel}>Cancelar</Button>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'end' }}>
          <Button onClick={handleOk}>Ok</Button>
        </Grid>
      </Grid>

    </Grid>

  );
}

export default TransferencePayment;
