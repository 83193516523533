//TODO pasar a delivery.utils
//DELIVERY STATES
export const PENDING = "PENDING";
export const LOADING = "LOADING";
export const TRACKS_MISSING = "TRACKS_MISSING";
export const UPC_MISSING = "UPC_MISSING";
export const PUBLISHED = "PUBLISHED";
export const DELIVERED = "DELIVERED";
export const CANCELLED = "CANCELLED";
export const TAKEN_DOWN = "TAKEN_DOWN";
export const DELETED = "DELETED";
export const DELIVERED_NEED_APPLE_REVISION = "DELIVERED_NEED_APPLE_REVISION";
export const DELIVERED_APPLE_USER_CORRECTION = "DELIVERED_APPLE_USER_CORRECTION";
export const DELIVERED_APPLE_REJECTED = "DELIVERED_APPLE_REJECTED";
export const COPYRIGHT_INFRACTION = "COPYRIGHT_INFRACTION";
export const INVESTIGATE_INFRACTION = "INVESTIGATE_INFRACTION";
export const DEAD_ALBUMS = "DEAD_ALBUMS";

export const userPendingStates = [PENDING, LOADING, UPC_MISSING];
export const appleDeliveredStates = [DELIVERED_APPLE_REJECTED, DELIVERED_APPLE_USER_CORRECTION, DELIVERED_NEED_APPLE_REVISION];
export const allAlbumStates = [
  PENDING, LOADING, TRACKS_MISSING, UPC_MISSING, PUBLISHED, DELIVERED,
  CANCELLED, TAKEN_DOWN, DELETED, ...appleDeliveredStates, COPYRIGHT_INFRACTION, INVESTIGATE_INFRACTION
];
export const albumDeliveredStates = [DELIVERED, ...appleDeliveredStates];
export const albumInfractionsStates = [COPYRIGHT_INFRACTION, INVESTIGATE_INFRACTION];
export const albumsWithStateIssues = [LOADING, PENDING, UPC_MISSING];
export const albumsDownsStates = [CANCELLED, TAKEN_DOWN, DELETED, DEAD_ALBUMS];

//DELIVERY ACTIONS
export const ACTION_TAKEDOWN = "TAKEDOWN";
export const ACTION_DELIVER = "DELIVER";
export const ACTION_REDELIVER = "REDELIVER";

//DSPS STATES
export const DSP_STALLED = "STALLED";
export const DSP_DELIVER = "DELIVERED";
export const DSP_REJECTED = "REJECTED";
export const DSP_NOT_ADDED = "NOT_ADDED";
export const DSP_ADDED = "ADDED";
export const DSP_CANCELLED = "CANCELLED";
export const DSP_TAKEN_DOWN = "TAKEN_DOWN";

//OUR DELIVERY DSP ACTIONS
export const OUR_DSP_REDELIVER = "our_dsp_redelivery";
export const OUR_DSP_DELIVER = "our_dsp_deliver";
export const OUR_DSP_FIRST_DELIVER = "our_dsp_first_deliver";
export const OUR_DSP_NONE_ACTION = "our_dsp_none";
export const OUR_DSP_TAKEDOWN = "our_dsp_takedown";

export const colorFromSubIdState = {
  CANCELADA: "rgb(224, 9, 9)",
  EN_ESPERA: "rgb(231, 190, 66)",
  ACTIVA: "rgb(10, 109, 15)",
  PENDIENTE_DE_PAGO: "rgb(231, 190, 66)",
}

export const languages = [
  "Spanish", "English", "None", "Afrikaans", "Arabic", "Bulgarian", "Cantonese", "Catalan", "Chinese", "Croatian", "Czech", "Danish", "Dutch", "Estonian", "Finnish", "French",
  "German", "Greek", "Hebrew", "Hindi", "Hungarian", "Icelandic", "Indonesian", "Italian", "Japanese", "Kazakh", "Korean", "Lao", "Latvian", "Lithuanian",
  "Malay", "Norwegian", "Polish", "Portuguese", "Romanian", "Russian", "Slovak", "Slovenian", "Swedish", "Tagalog", "Tamil", "Telugu", "Thai",
  "Turkish", "Ukrainian", "Urdu", "Vietnamese", "Zulu"
];

export const provincias = [
  { value: "RN", provincia: "Rio Negro" },
  { value: "BA", provincia: "Buenos Aires" },
  { value: "CABA", provincia: "Ciudad Autonoma de Buenos Aires" },
  { value: "SJ", provincia: "San Juan" },
  { value: "TdF", provincia: "Tierra del Fuego" },
  { value: "SC", provincia: "Santa Cruz" },
  { value: "SF", provincia: "Santa Fe" },
  { value: "LR", provincia: "La Rioja" },
  { value: "J", provincia: "Jujuy" },
  { value: "S", provincia: "Salta" },
  { value: "M", provincia: "Misiones" },
  { value: "C", provincia: "Corrientes" },
  { value: "Ch", provincia: "Chaco" },
  { value: "Cb", provincia: "Córdoba" },
  { value: "F", provincia: "Formosa" },
  { value: "T", provincia: "Tucumán" },
  { value: "SdE", provincia: "Santiago del Estero" },
  { value: "MZ", provincia: "Mendoza" },
  { value: "ER", provincia: "Entre Rios" },
  { value: "CT", provincia: "Chubut" },
  { value: "N", provincia: "Neuquén" },
  { value: "LP", provincia: "La Pampa" },
  { value: "Ca", provincia: "Catamarca" },
  { value: "SL", provincia: "San Luis" }
];
export const provinciasSorted = provincias.sort((pA, pB) => {
  if (pA.provincia < pB.provincia) return -1;
  else return 1;
})

export const appleRejectionReasonsList = [
  { id: "ARTIST_ART_MUST_COINCIDE", name: "Artistas en arte de Tapa no coinciden", fullText: "Los nombres de artistas que aparecen en el arte de tapa, deben estar presentes y coincidir 100% con la metadata del álbum." },
  { id: "TITLE_ART_MUST_COINCIDE", name: "Título en arte de Tapa no coincide", fullText: "Título del álbum/canción debe coincidir 100% con el que aparece en el arte de tapa. " },
  { id: "PIXELED_ART", name: "Imágen pixelada o borrosa, etc", fullText: "Imágenes pixeladas, borrosas, con transparencias, rotadas y/o recortadas, no son aceptadas, enviar por mail una nueva." },
  { id: "GENERIC_ART", name: "Arte de tapa genérico", fullText: "Arte de tapa genérico (no contiene datos del lanzamiento) " },
  { id: "EXTRA_INFO_IN_TITLE_OR_ARTIST", name: "Información extra en título del lanzamiento/artista", fullText: "Eliminar nombres del sello y/o cualquier texto que no sea específico del nombre del álbum/single o nombre de artista." },
  { id: "PARENTAL_ADVISORY", name: "Parental Advisory confuso en Arte de Tapa", fullText: "La etiqueta de Parental Advisory o Explicit Content en el arte de tapa se dede incluir de manera clara sin modificar ni entorpecer la lectura de la misma, enviar por mail una nueva." },
  { id: "REUSED_ART", name: "Arte de Tapa ya utilizado", fullText: "El arte de tapa ya fue usado en otro lanzamiento" },
  { id: "URL_OTHER_DSPS_REFERENCE", name: "Arte de tapa con URL o referencias a otras DSP's", fullText: "No se aceptan imágenes con URL a otros sitios o referencias a las DSP" },
  { id: "CODES_FISIC_FORMAT", name: "Arte de tapa con referencias a formatos fisicos o UPC", fullText: "Quitar las referencias a formatos físicos, VHS, CD, DVD, o código de barras en el arte de tapa" },
  { id: "NUDES", name: "No se acepta pornografía", fullText: "No se acepta pornografía (desnudos sutiles o pechos está ok)" },
  { id: "NAZI_PROP", name: "Propaganda NAZI", fullText: "No se acepta propaganda NAZI en arte de tapa" },
  { id: "SOUNDTRACK", name: "Agregar Soundtrack al título", fullText: "Si seleccionaste el género Soundtrack, el titulo debe ser Original Soundtrack of (Título de la canción/álbum)" },
  { id: "CLASSICAL", name: "Género Clasico, agregar secundario Classical Crossover", fullText: "Si seleccionaste Género Clásico, agregar como género secundario Classical Crossover" },
  { id: "CLASSICAL_FEATS", name: "En género Clasico no se admiten Feats", fullText: "El género Clásico sólo se admiten artistas Principales, por favor quitar los Feat." },
  { id: "DELETE_SILENCE", name: "Eliminar silencio", fullText: "Eliminar silencio al final del track, no debe superar los 10 segundos." },
  { id: "INSTRUMENTAL", name: "Indicar los track instrumentales y eliminar el colaborador Lyricist", fullText: "Indicar los track instrumentales y eliminar el colaborador Lyricist." },
  { id: "VERSIONS", name: "Versión no aceptada", fullText: "Sólo incluir en el campo versión los siguientes datos si corresponde: Acústico, Vivo, Remasterizado (en este caso incluir el año; ejemplo, Remasterizado 2022)" },
  { id: "FEATS_AS_COLLS", name: "Quitar los artistas Feats como colaboradores", fullText: "Quitar los artistas Feat como colaboradores, sólo ingresarlos junto al artista principal." },
  { id: "VOL_PART", name: `Abreviar Volumen" y "Parte" como: Vol. 1 / Pt. 1`, fullText: `Abreviar "Volumen" y "Parte" como: Vol. 1 / Pt. 1` },
  { id: "WRONG_COLLS", name: "Roles no aceptados", fullText: "Eliminar los roles Contributing Artists, Video Editor, Solist, por favor editar por alguno de los siguientes: Composer, Producer, Lyricist, Mixer, Engineer, Writer, Remixer." },
]

export const ourListOfDeliveryStates = [
  { id: "LOADING", name: "Cargando" },
  { id: "PENDING", name: "Pendiente" },
  { id: "TRACKS_MISSING", name: "Faltan archivos" },
  { id: "UPC_MISSING", name: "Falta UPC" },
  { id: "PUBLISHED", name: "Aprobado" },
  { id: "DELIVERED", name: "En las DSP's" },
  { id: "TAKEN_DOWN", name: "Dado de baja" },
  { id: "COPYRIGHT_INFRACTION", name: "Infracción" },
  { id: "INVESTIGATE_INFRACTION", name: "Investigando infracción" },
  { id: "DELETED", name: "Eliminado" },
  { id: "DELIVERED_NEED_APPLE_REVISION", name: "Apple en revisión" },
  { id: "DELIVERED_APPLE_REJECTED", name: "Apple rechazada" },
  { id: "DELIVERED_APPLE_USER_CORRECTION", name: "Apple en nueva revisión" }
]

export const ourAlbumStateWithEquivalence = {
  LOADING: "Cargando",
  PENDING: "Pendiente",
  TRACKS_MISSING: "Faltan archivos",
  UPC_MISSING: "Falta UPC",
  PUBLISHED: "Aprobado",
  DELIVERED: "En las DSP's",
  TAKEN_DOWN: "Dado de baja",
  DELETED: "Eliminado",
  COPYRIGHT_INFRACTION: "Infracción",
  INVESTIGATE_INFRACTION: "Investigando infracción",
  DELIVERED_NEED_APPLE_REVISION: "En las DSP's. Apple revisión",
  DELIVERED_APPLE_REJECTED: "En las DSP's. Apple rechazada",
  DELIVERED_APPLE_USER_CORRECTION: "En las DSP's. Apple nueva revisión"
}

export const colorFromFugaState = {
  LOADING: "rgb(231, 190, 66)",
  PENDING: "rgb(231, 190, 66)",
  TRACKS_MISSING: "rgb(111, 2, 2)",
  UPC_MISSING: "rgb(111, 2, 2)",
  PUBLISHED: "rgb(64, 68, 46)",
  DELIVERED: "rgb(93, 109, 15)",
  TAKEN_DOWN: "rgb(170, 3, 3)",
  DELETED: "rgb(111, 2, 2)",
  COPYRIGHT_INFRACTION: "rgb(111, 2, 2)",
  INVESTIGATE_INFRACTION: "rgba(57, 1, 1, 0.61)",
  DELIVERED_NEED_APPLE_REVISION: "rgb(231, 190, 66)",
  DELIVERED_APPLE_REJECTED: "#c90",
  DELIVERED_APPLE_USER_CORRECTION: "rgb(64, 68, 46)"
}

export const colorFromDeliveryDspLabel = {
  "Procesando": "rgb(231, 190, 66)",
  "Realizar delivery": "rgb(135, 162, 14)",
  "Online": "rgb(93, 109, 15)",
  "Dar de baja": "rgb(170, 3, 3)",
  "Rechazada": "rgb(111, 2, 2)",
  "Esperando revisión": "rgb(231, 190, 66)",
  "Necesita correción": "#c90",
  "Realizar re-delivery": "rgb(135, 162, 14)",
  "No realizar cambios": "rgb(231, 190, 66)",
  "dar de baja": "rgb(170, 3, 3)",
  "Volver a poner Online": "rgb(93, 109, 15)",
  "Online, en proceso de dar de baja": "rgb(170, 3, 3)",
  "Cancelado / Dado de baja": "rgb(170, 3, 3)",
  "Cancelado, realizar re-delivery": "rgb(135, 162, 14)",
  "Cancelado, re-delivery en proceso": "rgb(135, 162, 14)",
  "Cancelar re-delivery": "rgb(170, 3, 3)"
}

export const ciudades = [
  { value: "Viedma", localidad: "Viedma" },
  { value: "CABA", localidad: "CABA" },
  { value: "Patagones", localidad: "Patagones" },
  { value: "Las Grutas", localidad: "Las Grutas" }
].sort((function (cA, cB) {
  if (cA.value < cB.value) return -1;
  else return 1;
}));

export const languagesFuga = [
  {
    "id": "ES",
    "name": "Spanish"
  },
  {
    "id": "EN",
    "name": "English"
  },
  {
    "id": "AA",
    "name": "Afar"
  },
  {
    "id": "AB",
    "name": "Abkhazian"
  },
  {
    "id": "AE",
    "name": "Avestan"
  },
  {
    "id": "AF",
    "name": "Afrikaans"
  },
  {
    "id": "AK",
    "name": "Akan"
  },
  {
    "id": "AM",
    "name": "Amharic"
  },
  {
    "id": "AN",
    "name": "Aragonese"
  },
  {
    "id": "AR",
    "name": "Arabic"
  },
  {
    "id": "AS",
    "name": "Assamese"
  },
  {
    "id": "AV",
    "name": "Avaric"
  },
  {
    "id": "AY",
    "name": "Aymara"
  },
  {
    "id": "AZ",
    "name": "Azerbaijani"
  },
  {
    "id": "BA",
    "name": "Bashkir"
  },
  {
    "id": "BE",
    "name": "Belarusian"
  },
  {
    "id": "BG",
    "name": "Bulgarian"
  },
  {
    "id": "BHO",
    "name": "Bhojpuri"
  },
  {
    "id": "BI",
    "name": "Bislama"
  },
  {
    "id": "BM",
    "name": "Bambara"
  },
  {
    "id": "BN",
    "name": "Bengali"
  },
  {
    "id": "BO",
    "name": "Tibetan"
  },
  {
    "id": "BR",
    "name": "Breton"
  },
  {
    "id": "BS",
    "name": "Bosnian"
  },
  {
    "id": "CA",
    "name": "Catalan"
  },
  {
    "id": "CE",
    "name": "Chechen"
  },
  {
    "id": "CH",
    "name": "Chamorro"
  },
  {
    "id": "CMN_HANS",
    "name": "Chinese (Simplified)"
  },
  {
    "id": "CMN_HANT",
    "name": "Chinese (Traditional)"
  },
  {
    "id": "CO",
    "name": "Corsican"
  },
  {
    "id": "CPE",
    "name": "Creole, English based"
  },
  {
    "id": "CPF",
    "name": "Creole, French based"
  },
  {
    "id": "CPP",
    "name": "Creole, Portuguese based"
  },
  {
    "id": "CR",
    "name": "Cree"
  },
  {
    "id": "CS",
    "name": "Czech"
  },
  {
    "id": "CU",
    "name": "Church Slavic"
  },
  {
    "id": "CV",
    "name": "Chuvash"
  },
  {
    "id": "CY",
    "name": "Welsh"
  },
  {
    "id": "DA",
    "name": "Danish"
  },
  {
    "id": "DE",
    "name": "German"
  },
  {
    "id": "DV",
    "name": "Divehi"
  },
  {
    "id": "DZ",
    "name": "Dzongkha"
  },
  {
    "id": "EE",
    "name": "Ewe"
  },
  {
    "id": "EL",
    "name": "Greek"
  },
  {
    "id": "EO",
    "name": "Esperanto"
  },
  {
    "id": "ET",
    "name": "Estonian"
  },
  {
    "id": "EU",
    "name": "Basque"
  },
  {
    "id": "FA",
    "name": "Persian"
  },
  {
    "id": "FF",
    "name": "Fulah"
  },
  {
    "id": "FI",
    "name": "Finnish"
  },
  {
    "id": "FJ",
    "name": "Fijian"
  },
  {
    "id": "FO",
    "name": "Faroese"
  },
  {
    "id": "FR",
    "name": "French"
  },
  {
    "id": "FY",
    "name": "Western Frisian"
  },
  {
    "id": "GA",
    "name": "Irish"
  },
  {
    "id": "GD",
    "name": "Gaelic"
  },
  {
    "id": "GL",
    "name": "Galician"
  },
  {
    "id": "GN",
    "name": "Guarani"
  },
  {
    "id": "GU",
    "name": "Gujarati"
  },
  {
    "id": "GV",
    "name": "Manx"
  },
  {
    "id": "HA",
    "name": "Hausa"
  },
  {
    "id": "HAT",
    "name": "Haitian Creole"
  },
  {
    "id": "HE",
    "name": "Hebrew"
  },
  {
    "id": "HI",
    "name": "Hindi"
  },
  {
    "id": "HO",
    "name": "Hiri Motu"
  },
  {
    "id": "HR",
    "name": "Croatian"
  },
  {
    "id": "HT",
    "name": "Haitian"
  },
  {
    "id": "HU",
    "name": "Hungarian"
  },
  {
    "id": "HY",
    "name": "Armenian"
  },
  {
    "id": "HZ",
    "name": "Herero"
  },
  {
    "id": "IA",
    "name": "Interlingua"
  },
  {
    "id": "ID",
    "name": "Indonesian"
  },
  {
    "id": "IE",
    "name": "Interlingue"
  },
  {
    "id": "IG",
    "name": "Igbo"
  },
  {
    "id": "II",
    "name": "Sichuan Yi"
  },
  {
    "id": "IK",
    "name": "Inupiaq"
  },
  {
    "id": "IO",
    "name": "Ido"
  },
  {
    "id": "IS",
    "name": "Icelandic"
  },
  {
    "id": "IT",
    "name": "Italian"
  },
  {
    "id": "IU",
    "name": "Inuktitut"
  },
  {
    "id": "JA",
    "name": "Japanese"
  },
  {
    "id": "JA_KANA",
    "name": "Japanese Katakana"
  },
  {
    "id": "JA_LATN",
    "name": "Japanese Roman"
  },
  {
    "id": "JV",
    "name": "Javanese"
  },
  {
    "id": "KA",
    "name": "Georgian"
  },
  {
    "id": "KG",
    "name": "Kongo"
  },
  {
    "id": "KI",
    "name": "Kikuyu"
  },
  {
    "id": "KJ",
    "name": "Kuanyama"
  },
  {
    "id": "KK",
    "name": "Kazakh"
  },
  {
    "id": "KL",
    "name": "Kalaallisut"
  },
  {
    "id": "KM",
    "name": "Central Khmer"
  },
  {
    "id": "KN",
    "name": "Kannada"
  },
  {
    "id": "KO",
    "name": "Korean"
  },
  {
    "id": "KR",
    "name": "Kanuri"
  },
  {
    "id": "KS",
    "name": "Kashmiri"
  },
  {
    "id": "KU",
    "name": "Kurdish"
  },
  {
    "id": "KV",
    "name": "Komi"
  },
  {
    "id": "KW",
    "name": "Cornish"
  },
  {
    "id": "KY",
    "name": "Kirghiz"
  },
  {
    "id": "LA",
    "name": "Latin"
  },
  {
    "id": "LB",
    "name": "Luxembourgish"
  },
  {
    "id": "LG",
    "name": "Luganda"
  },
  {
    "id": "LI",
    "name": "Limburgan"
  },
  {
    "id": "LN",
    "name": "Lingala"
  },
  {
    "id": "LO",
    "name": "Lao"
  },
  {
    "id": "LT",
    "name": "Lithuanian"
  },
  {
    "id": "LU",
    "name": "Luba-Katanga"
  },
  {
    "id": "LV",
    "name": "Latvian"
  },
  {
    "id": "MG",
    "name": "Malagasy"
  },
  {
    "id": "MAG",
    "name": "Magahi"
  },
  {
    "id": "MAI",
    "name": "Maithili"
  },
  {
    "id": "MH",
    "name": "Marshallese"
  },
  {
    "id": "MI",
    "name": "Maori"
  },
  {
    "id": "MK",
    "name": "Macedonian"
  },
  {
    "id": "ML",
    "name": "Malayalam"
  },
  {
    "id": "MN",
    "name": "Mongolian"
  },
  {
    "id": "MR",
    "name": "Marathi"
  },
  {
    "id": "MS",
    "name": "Malay"
  },
  {
    "id": "MT",
    "name": "Maltese"
  },
  {
    "id": "MY",
    "name": "Burmese"
  },
  {
    "id": "NA",
    "name": "Nauru"
  },
  {
    "id": "NB",
    "name": "Norwegian Bokmål"
  },
  {
    "id": "ND",
    "name": "North Ndebele"
  },
  {
    "id": "NE",
    "name": "Nepali"
  },
  {
    "id": "NG",
    "name": "Ndonga"
  },
  {
    "id": "NL",
    "name": "Dutch"
  },
  {
    "id": "NN",
    "name": "Norwegian Nynorsk"
  },
  {
    "id": "NO",
    "name": "Norwegian"
  },
  {
    "id": "NR",
    "name": "South Ndebele"
  },
  {
    "id": "NV",
    "name": "Navajo"
  },
  {
    "id": "NY",
    "name": "Chichewa"
  },
  {
    "id": "OC",
    "name": "Occitan"
  },
  {
    "id": "OJ",
    "name": "Ojibwa"
  },
  {
    "id": "OM",
    "name": "Oromo"
  },
  {
    "id": "OR",
    "name": "Oriya"
  },
  {
    "id": "OS",
    "name": "Ossetian"
  },
  {
    "id": "PA",
    "name": "Punjabi"
  },
  {
    "id": "PI",
    "name": "Pali"
  },
  {
    "id": "PL",
    "name": "Polish"
  },
  {
    "id": "PS",
    "name": "Pushto"
  },
  {
    "id": "PT",
    "name": "Portuguese"
  },
  {
    "id": "QU",
    "name": "Quechua"
  },
  {
    "id": "RM",
    "name": "Romansh"
  },
  {
    "id": "RN",
    "name": "Rundi"
  },
  {
    "id": "RO",
    "name": "Romanian"
  },
  {
    "id": "RU",
    "name": "Russian"
  },
  {
    "id": "RW",
    "name": "Kinyarwanda"
  },
  {
    "id": "SA",
    "name": "Sanskrit"
  },
  {
    "id": "SC",
    "name": "Sardinian"
  },
  {
    "id": "SD",
    "name": "Sindhi"
  },
  {
    "id": "SE",
    "name": "Northern Sami"
  },
  {
    "id": "SG",
    "name": "Sango"
  },
  {
    "id": "SI",
    "name": "Sinhala"
  },
  {
    "id": "SK",
    "name": "Slovak"
  },
  {
    "id": "SL",
    "name": "Slovene"
  },
  {
    "id": "SM",
    "name": "Samoan"
  },
  {
    "id": "SN",
    "name": "Shona"
  },
  {
    "id": "SO",
    "name": "Somali"
  },
  {
    "id": "SQ",
    "name": "Albanian"
  },
  {
    "id": "SR",
    "name": "Serbian"
  },
  {
    "id": "SS",
    "name": "Swati"
  },
  {
    "id": "ST",
    "name": "Sotho, Southern"
  },
  {
    "id": "SU",
    "name": "Sundanese"
  },
  {
    "id": "SV",
    "name": "Swedish"
  },
  {
    "id": "SW",
    "name": "Swahili"
  },
  {
    "id": "TA",
    "name": "Tamil"
  },
  {
    "id": "TE",
    "name": "Telugu"
  },
  {
    "id": "TG",
    "name": "Tajik"
  },
  {
    "id": "TH",
    "name": "Thai"
  },
  {
    "id": "TI",
    "name": "Tigrinya"
  },
  {
    "id": "TK",
    "name": "Turkmen"
  },
  {
    "id": "TL",
    "name": "Tagalog"
  },
  {
    "id": "TN",
    "name": "Tswana"
  },
  {
    "id": "TO",
    "name": "Tonga (Tonga Islands)"
  },
  {
    "id": "TR",
    "name": "Turkish"
  },
  {
    "id": "TS",
    "name": "Tsonga"
  },
  {
    "id": "TT",
    "name": "Tatar"
  },
  {
    "id": "TW",
    "name": "Twi"
  },
  {
    "id": "TY",
    "name": "Tahitian"
  },
  {
    "id": "UG",
    "name": "Uighur"
  },
  {
    "id": "UK",
    "name": "Ukrainian"
  },
  {
    "id": "UR",
    "name": "Urdu"
  },
  {
    "id": "UZ",
    "name": "Uzbek"
  },
  {
    "id": "VE",
    "name": "Venda"
  },
  {
    "id": "VI",
    "name": "Vietnamese"
  },
  {
    "id": "VO",
    "name": "Volapük"
  },
  {
    "id": "WA",
    "name": "Walloon"
  },
  {
    "id": "WO",
    "name": "Wolof"
  },
  {
    "id": "XH",
    "name": "Xhosa"
  },
  {
    "id": "YI",
    "name": "Yiddish"
  },
  {
    "id": "YUE_HANT",
    "name": "Cantonese"
  },
  {
    "id": "YO",
    "name": "Yoruba"
  },
  {
    "id": "ZA",
    "name": "Zhuang"
  },
  {
    "id": "ZH",
    "name": "Chinese"
  },
  {
    "id": "ZU",
    "name": "Zulu"
  }
]

export const allAudioLocalesFuga = [
  {
    "id": "ZXX",
    "name": "Instrumental"
  },
  {
    "id": "ES",
    "name": "Spanish"
  },
  {
    "id": "EN",
    "name": "English"
  },
  {
    "id": "AA",
    "name": "Afar"
  },
  {
    "id": "AB",
    "name": "Abkhazian"
  },
  {
    "id": "AE",
    "name": "Avestan"
  },
  {
    "id": "AF",
    "name": "Afrikaans"
  },
  {
    "id": "AK",
    "name": "Akan"
  },
  {
    "id": "AM",
    "name": "Amharic"
  },
  {
    "id": "AN",
    "name": "Aragonese"
  },
  {
    "id": "AR",
    "name": "Arabic"
  },
  {
    "id": "AS",
    "name": "Assamese"
  },
  {
    "id": "AV",
    "name": "Avaric"
  },
  {
    "id": "AY",
    "name": "Aymara"
  },
  {
    "id": "AZ",
    "name": "Azerbaijani"
  },
  {
    "id": "BA",
    "name": "Bashkir"
  },
  {
    "id": "BE",
    "name": "Belarusian"
  },
  {
    "id": "BG",
    "name": "Bulgarian"
  },
  {
    "id": "BHO",
    "name": "Bhojpuri"
  },
  {
    "id": "BI",
    "name": "Bislama"
  },
  {
    "id": "BM",
    "name": "Bambara"
  },
  {
    "id": "BN",
    "name": "Bengali"
  },
  {
    "id": "BO",
    "name": "Tibetan"
  },
  {
    "id": "BR",
    "name": "Breton"
  },
  {
    "id": "BS",
    "name": "Bosnian"
  },
  {
    "id": "CA",
    "name": "Catalan"
  },
  {
    "id": "CE",
    "name": "Chechen"
  },
  {
    "id": "CH",
    "name": "Chamorro"
  },
  {
    "id": "CO",
    "name": "Corsican"
  },
  {
    "id": "CPE",
    "name": "Creole, English based"
  },
  {
    "id": "CPF",
    "name": "Creole, French based"
  },
  {
    "id": "CPP",
    "name": "Creole, Portuguese based"
  },
  {
    "id": "CR",
    "name": "Cree"
  },
  {
    "id": "CS",
    "name": "Czech"
  },
  {
    "id": "CU",
    "name": "Church Slavic"
  },
  {
    "id": "CV",
    "name": "Chuvash"
  },
  {
    "id": "CY",
    "name": "Welsh"
  },
  {
    "id": "DA",
    "name": "Danish"
  },
  {
    "id": "DE",
    "name": "German"
  },
  {
    "id": "DV",
    "name": "Divehi"
  },
  {
    "id": "DZ",
    "name": "Dzongkha"
  },
  {
    "id": "EE",
    "name": "Ewe"
  },
  {
    "id": "EL",
    "name": "Greek"
  },
  {
    "id": "EO",
    "name": "Esperanto"
  },
  {
    "id": "ET",
    "name": "Estonian"
  },
  {
    "id": "EU",
    "name": "Basque"
  },
  {
    "id": "FA",
    "name": "Persian"
  },
  {
    "id": "FF",
    "name": "Fulah"
  },
  {
    "id": "FI",
    "name": "Finnish"
  },
  {
    "id": "FJ",
    "name": "Fijian"
  },
  {
    "id": "FO",
    "name": "Faroese"
  },
  {
    "id": "FR",
    "name": "French"
  },
  {
    "id": "FY",
    "name": "Western Frisian"
  },
  {
    "id": "GA",
    "name": "Irish"
  },
  {
    "id": "GD",
    "name": "Gaelic"
  },
  {
    "id": "GL",
    "name": "Galician"
  },
  {
    "id": "GN",
    "name": "Guarani"
  },
  {
    "id": "GU",
    "name": "Gujarati"
  },
  {
    "id": "GV",
    "name": "Manx"
  },
  {
    "id": "HA",
    "name": "Hausa"
  },
  {
    "id": "HAT",
    "name": "Haitian Creole"
  },
  {
    "id": "HE",
    "name": "Hebrew"
  },
  {
    "id": "HI",
    "name": "Hindi"
  },
  {
    "id": "HO",
    "name": "Hiri Motu"
  },
  {
    "id": "HR",
    "name": "Croatian"
  },
  {
    "id": "HT",
    "name": "Haitian"
  },
  {
    "id": "HU",
    "name": "Hungarian"
  },
  {
    "id": "HY",
    "name": "Armenian"
  },
  {
    "id": "HZ",
    "name": "Herero"
  },
  {
    "id": "IA",
    "name": "Interlingua"
  },
  {
    "id": "ID",
    "name": "Indonesian"
  },
  {
    "id": "IE",
    "name": "Interlingue"
  },
  {
    "id": "IG",
    "name": "Igbo"
  },
  {
    "id": "II",
    "name": "Sichuan Yi"
  },
  {
    "id": "IK",
    "name": "Inupiaq"
  },
  {
    "id": "IO",
    "name": "Ido"
  },
  {
    "id": "IS",
    "name": "Icelandic"
  },
  {
    "id": "IT",
    "name": "Italian"
  },
  {
    "id": "IU",
    "name": "Inuktitut"
  },
  {
    "id": "JA",
    "name": "Japanese"
  },
  {
    "id": "JV",
    "name": "Javanese"
  },
  {
    "id": "KA",
    "name": "Georgian"
  },
  {
    "id": "KG",
    "name": "Kongo"
  },
  {
    "id": "KI",
    "name": "Kikuyu"
  },
  {
    "id": "KJ",
    "name": "Kuanyama"
  },
  {
    "id": "KK",
    "name": "Kazakh"
  },
  {
    "id": "KL",
    "name": "Kalaallisut"
  },
  {
    "id": "KM",
    "name": "Central Khmer"
  },
  {
    "id": "KN",
    "name": "Kannada"
  },
  {
    "id": "KO",
    "name": "Korean"
  },
  {
    "id": "KR",
    "name": "Kanuri"
  },
  {
    "id": "KS",
    "name": "Kashmiri"
  },
  {
    "id": "KU",
    "name": "Kurdish"
  },
  {
    "id": "KV",
    "name": "Komi"
  },
  {
    "id": "KW",
    "name": "Cornish"
  },
  {
    "id": "KY",
    "name": "Kirghiz"
  },
  {
    "id": "LA",
    "name": "Latin"
  },
  {
    "id": "LB",
    "name": "Luxembourgish"
  },
  {
    "id": "LG",
    "name": "Luganda"
  },
  {
    "id": "LI",
    "name": "Limburgan"
  },
  {
    "id": "LN",
    "name": "Lingala"
  },
  {
    "id": "LO",
    "name": "Lao"
  },
  {
    "id": "LT",
    "name": "Lithuanian"
  },
  {
    "id": "LU",
    "name": "Luba-Katanga"
  },
  {
    "id": "LV",
    "name": "Latvian"
  },
  {
    "id": "MAG",
    "name": "Magahi"
  },
  {
    "id": "MAI",
    "name": "Maithili"
  },
  {
    "id": "MG",
    "name": "Malagasy"
  },
  {
    "id": "MH",
    "name": "Marshallese"
  },
  {
    "id": "MI",
    "name": "Maori"
  },
  {
    "id": "MK",
    "name": "Macedonian"
  },
  {
    "id": "ML",
    "name": "Malayalam"
  },
  {
    "id": "MN",
    "name": "Mongolian"
  },
  {
    "id": "MR",
    "name": "Marathi"
  },
  {
    "id": "MS",
    "name": "Malay"
  },
  {
    "id": "MT",
    "name": "Maltese"
  },
  {
    "id": "MY",
    "name": "Burmese"
  },
  {
    "id": "NA",
    "name": "Nauru"
  },
  {
    "id": "NB",
    "name": "Norwegian Bokmål"
  },
  {
    "id": "ND",
    "name": "North Ndebele"
  },
  {
    "id": "NE",
    "name": "Nepali"
  },
  {
    "id": "NG",
    "name": "Ndonga"
  },
  {
    "id": "NL",
    "name": "Dutch"
  },
  {
    "id": "NN",
    "name": "Norwegian Nynorsk"
  },
  {
    "id": "NO",
    "name": "Norwegian"
  },
  {
    "id": "NR",
    "name": "South Ndebele"
  },
  {
    "id": "NV",
    "name": "Navajo"
  },
  {
    "id": "NY",
    "name": "Chichewa"
  },
  {
    "id": "OC",
    "name": "Occitan"
  },
  {
    "id": "OJ",
    "name": "Ojibwa"
  },
  {
    "id": "OM",
    "name": "Oromo"
  },
  {
    "id": "OR",
    "name": "Oriya"
  },
  {
    "id": "OS",
    "name": "Ossetian"
  },
  {
    "id": "PA",
    "name": "Punjabi"
  },
  {
    "id": "PI",
    "name": "Pali"
  },
  {
    "id": "PL",
    "name": "Polish"
  },
  {
    "id": "PS",
    "name": "Pushto"
  },
  {
    "id": "PT",
    "name": "Portuguese"
  },
  {
    "id": "QU",
    "name": "Quechua"
  },
  {
    "id": "RM",
    "name": "Romansh"
  },
  {
    "id": "RN",
    "name": "Rundi"
  },
  {
    "id": "RO",
    "name": "Romanian"
  },
  {
    "id": "RU",
    "name": "Russian"
  },
  {
    "id": "RW",
    "name": "Kinyarwanda"
  },
  {
    "id": "SA",
    "name": "Sanskrit"
  },
  {
    "id": "SC",
    "name": "Sardinian"
  },
  {
    "id": "SD",
    "name": "Sindhi"
  },
  {
    "id": "SE",
    "name": "Northern Sami"
  },
  {
    "id": "SG",
    "name": "Sango"
  },
  {
    "id": "SI",
    "name": "Sinhala"
  },
  {
    "id": "SK",
    "name": "Slovak"
  },
  {
    "id": "SL",
    "name": "Slovene"
  },
  {
    "id": "SM",
    "name": "Samoan"
  },
  {
    "id": "SN",
    "name": "Shona"
  },
  {
    "id": "SO",
    "name": "Somali"
  },
  {
    "id": "SQ",
    "name": "Albanian"
  },
  {
    "id": "SR",
    "name": "Serbian"
  },
  {
    "id": "SS",
    "name": "Swati"
  },
  {
    "id": "ST",
    "name": "Sotho, Southern"
  },
  {
    "id": "SU",
    "name": "Sundanese"
  },
  {
    "id": "SV",
    "name": "Swedish"
  },
  {
    "id": "SW",
    "name": "Swahili"
  },
  {
    "id": "TA",
    "name": "Tamil"
  },
  {
    "id": "TE",
    "name": "Telugu"
  },
  {
    "id": "TG",
    "name": "Tajik"
  },
  {
    "id": "TH",
    "name": "Thai"
  },
  {
    "id": "TI",
    "name": "Tigrinya"
  },
  {
    "id": "TK",
    "name": "Turkmen"
  },
  {
    "id": "TL",
    "name": "Tagalog"
  },
  {
    "id": "TN",
    "name": "Tswana"
  },
  {
    "id": "TO",
    "name": "Tonga (Tonga Islands)"
  },
  {
    "id": "TR",
    "name": "Turkish"
  },
  {
    "id": "TS",
    "name": "Tsonga"
  },
  {
    "id": "TT",
    "name": "Tatar"
  },
  {
    "id": "TW",
    "name": "Twi"
  },
  {
    "id": "TY",
    "name": "Tahitian"
  },
  {
    "id": "UG",
    "name": "Uighur"
  },
  {
    "id": "UK",
    "name": "Ukrainian"
  },
  {
    "id": "UR",
    "name": "Urdu"
  },
  {
    "id": "UZ",
    "name": "Uzbek"
  },
  {
    "id": "VE",
    "name": "Venda"
  },
  {
    "id": "VI",
    "name": "Vietnamese"
  },
  {
    "id": "VO",
    "name": "Volapük"
  },
  {
    "id": "WA",
    "name": "Walloon"
  },
  {
    "id": "WO",
    "name": "Wolof"
  },
  {
    "id": "XH",
    "name": "Xhosa"
  },
  {
    "id": "YI",
    "name": "Yiddish"
  },
  {
    "id": "YUE",
    "name": "Cantonese"
  },
  {
    "id": "YO",
    "name": "Yoruba"
  },
  {
    "id": "ZA",
    "name": "Zhuang"
  },
  {
    "id": "ZH",
    "name": "Chinese"
  },
  {
    "id": "ZU",
    "name": "Zulu"
  }
]
