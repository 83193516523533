import { Replay, FileUpload, Pending, ChangeCircle } from '@mui/icons-material/';
import { Grid, Fab, CircularProgress, Tooltip } from '@mui/material';
import { grayColor } from 'assets/jss/material-dashboard-react.js';
import { UPLOADING, ERROR_UPLOADING, SUCCESSFULLY_UPLOADED } from 'variables/uploads.variables';

export const getIconByProgress = progress => {
  if (progress >= 100) return <ChangeCircle sx={{ color: 'black', fontSize: '1.5em' }} />;
  if (progress > 0) return <FileUpload sx={{ color: 'black', fontSize: '1.5em' }} />;
  return <Pending sx={{ color: 'black', fontSize: '1.5em' }} />;
}

export const getIconByStatus = loadStatus => {
  if (loadStatus === "SUCCESS") return <ChangeCircle sx={{ color: 'black', fontSize: '1.5em' }} />;
  if (loadStatus === "LOADING") return <FileUpload sx={{ color: 'black', fontSize: '1.5em' }} />;
  if (loadStatus === "ERROR") return <Replay sx={{ color: 'red', fontSize: '1.5em' }} />;
  return <Pending sx={{ color: 'black', fontSize: '1.5em' }} />;
}

export const getUploadText = (isFromAlbumInfo, uploadStatus) => {
  if (uploadStatus === SUCCESSFULLY_UPLOADED) return 'Completada';
  if (uploadStatus === UPLOADING) return 'Cargando';
  if (uploadStatus === ERROR_UPLOADING) return 'Error'
  return isFromAlbumInfo ? 'Cambiar' : 'Esperando';
}

const getIconTooltipTextByProgress = progress => {
  if (progress === 100) return "Carga finalizada: podés cambiar el archivo de audio si te has equivocado.";
  if (progress > 0) return "Cargando archivo";
  return "Esperando para cargar archivo";
}

const fabProgressSx = { color: '#0604047a', position: 'absolute', zIndex: 1 };
const buttonNoneSx = {
  backgroundColor: '#99999980', '&:hover': { backgroundColor: grayColor[1] },
  height: "40px", width: "40px"
}

export const getFabButton = (progress, errorUploading, setFileObject, fileId) => {
  return <Grid>
    <Tooltip title={getIconTooltipTextByProgress(progress)}>
      <Fab aria-label="waiting" sx={buttonNoneSx}>
        {getIconByProgress(progress)}
        {progress < 100 && <CircularProgress size={45} sx={fabProgressSx} />}
      </Fab>
    </Tooltip>
  </Grid>
}

export const allUploadsAreFinish = uploads => {
  if (uploads.length === 0) return false;
  return uploads.every(upload => upload.status === SUCCESSFULLY_UPLOADED || upload.status === ERROR_UPLOADING)
}

export const getLoadingStatus = uploadStatus => {
  if (uploadStatus === ERROR_UPLOADING) return "ERROR";
  if (uploadStatus === SUCCESSFULLY_UPLOADED) return "SUCCESS";
  if (uploadStatus === UPLOADING) return "LOADING";
  return 'NONE';
}