import * as ReducerTypes from 'redux/actions/Types';
const initialState = { error: false, success: false }

const NotificationHandlerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReducerTypes.ERROR_FROM_BACKEND:
      return action.payload;

    case ReducerTypes.ERROR_FROM_FIRESTORE:
      return action.payload;

    case ReducerTypes.ERROR_BASIC_MSG:
      return action.payload;

    case ReducerTypes.ERROR_CLEAN_LAST:
      return { ...state, error: false }

    case ReducerTypes.SUCCESS_NOTIFICATION:
      return action.payload

    case ReducerTypes.SUCCESS_CLEAN_LAST:
      return { ...state, success: false }

    default:
      return state;
  }
};

export default NotificationHandlerReducer;