import React from "react";
import { Grid, Dialog, DialogContent, Typography, LinearProgress, Divider } from '@mui/material';
import CardUploads from "views/Uploads/CardUploads";
import BasicStepper from "components/Stepper/BasicStepper";
import PersonShower from "components/Persons/PersonShower";
import Table from 'components/Table/Table';
import { getTracksDataTableFromOurAssets } from "utils/tables.utils";
import AlbumCard from 'views/Albums/AlbumCard';
import CheckboxGroup from 'components/Checkbox/CheckboxGroup';

const NewAlbumLoading = ({ loading, activeStep, artists, collaborators, tracks, albumData }) => {

  const tracksFugaIds = tracks.map(track => track.fugaId);

  const steps = [
    <p>Creando<br /> artistas</p>, <p>Metadata del <br />lanzamiento</p>, <p>Metadata de <br />las canciones</p>,
    <p>Agregando <br />colaboradores</p>, <p>Cargando <br />archivos</p>, 'Delivery'
  ];

  const onlyCheckedDsps = albumData.dsps.length > 0 ? albumData.dsps.filter(dsp => dsp.checked) : [{ label: "" }];

  return (
    <Grid>

      <Dialog
        maxWidth='lg'
        id="dialog-new-release"
        fullWidth
        open={loading}
        PaperProps={{ style: { height: "80%" } }}
        onClose={() => console.log()}>

        <DialogContent sx={{ paddingBottom: '20px' }}>
          <Typography variant='h3' textAlign='center'>Generando tu lanzamiento</Typography>
          <Typography sx={{ fontSize: '16px', paddingBottom: '10px' }} textAlign='center'>Por favor, espera a que terminen todos los pasos.</Typography>

          <BasicStepper steps={steps} activeStep={activeStep} />

          <Grid sx={{ width: '100%', paddingBottom: '1em', paddingTop: '1em' }}>
            <LinearProgress />
          </Grid>

          {activeStep === 0 && <Grid container item xs={12} spacing={2}>
            {artists.map(artist => <PersonShower id={artist.key} person={artist} personSubtitle={artist.primary ? "Principal" : "Featuring"} />)}
          </Grid>}

          {activeStep === 1 && <Grid container>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}><AlbumCard dataAlbum={albumData} index={0} showActions={false} cardSx={{ backgroundColor: '#76767640' }} /></Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          }

          {activeStep === 2 && <Grid container sx={{ justifyContent: 'center', paddingLeft: "3em", paddingRight: "3em" }}>
            <Table
              tableHeaderColor="primary"
              tableHead={["N°", "Nombre", "ISRC", "Artista Principal", "Archivo de Audio"]}
              tableData={getTracksDataTableFromOurAssets(tracks)}
            />
          </Grid>}

          {activeStep === 3 && <>
            <Typography sx={{ fontSize: '22px', fontWeight: 500 }}>Asignando los colaboradores a cada canción</Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>No te preocupes si vez colaboradores con un mismo nombre y mismo rol.</Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Esto es así porque a cada canción se le debe asignar cada colaborador y pueden estar repetidos.</Typography>
            <Divider sx={{ borderWidth: "1px", marginBottom: '1em', marginTop: '1em' }} flexItem />
            <Grid container item xs={12} spacing={2}>
              {collaborators.map(coll => <PersonShower id={coll.key} person={coll} personSubtitle={coll.role} personNumber={coll.trackNumber} />)}
            </Grid>
          </>}

          {activeStep === 4 && <Grid container >
            <Grid textAlign='center' item xs={12}>
              <Typography sx={{ fontSize: '22px', fontWeight: 500 }}>Cargando archivos de audio</Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Este proceso puede tardar muchos minutos, dependiendo de la cantidad y el tamaño de los archivos y de tu velocidad de internet.</Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Si sucediera un error al cargar algún archivo, se continuará con otro archivo. <br /> <b>Luego deberás volver a subirlo, editando el lanzamiento.</b></Typography>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}><CardUploads uploadsIds={tracksFugaIds} /></Grid>
            <Grid item xs={2}></Grid>
          </Grid>}


          {activeStep === 5 && <Grid>
            <Typography sx={{ fontSize: '22px', fontWeight: 500 }}>Realizando el Delivery a las siguientes DSP's</Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Recuerda que el lanzamiento se procesará con la fecha que seleccionaste, pero según la demanda, los lanzamientos pueden demorar hasta 1 - 2 días, en aprobarse y procesarse.</Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>A la vez las DSPs tienen tiempos variables, y por último puede haber errores o que necesitemos corregir aspectos de tu lanzamiento.</Typography>
            <Divider sx={{ borderWidth: "1px", marginBottom: '1em', marginTop: '1em' }} flexItem />
            <CheckboxGroup allInfo={onlyCheckedDsps} onChange={() => console.log("")} labelGridSize={4} />
          </Grid>}


        </DialogContent>

      </Dialog>
    </Grid>
  );
}

export default NewAlbumLoading;