import React, { useState } from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { faqs } from './faqs';

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredFaqs = faqs.filter((faq) => {
    const answerText = faq.answer.toString().toLowerCase();
    return faq.question.toLowerCase().includes(searchTerm) || answerText.includes(searchTerm);
  });

  return (
    <div>
      <Box mb={2} paddingLeft={6} paddingRight={6}>
        <TextField
          label="Busca tu pregunta!"
          variant="outlined"
          fullWidth
          onChange={handleSearchChange}
        />
      </Box>

      <div
        style={{
          height: '600px',
          overflowY: 'auto',
          backgroundColor: '#f9f9f9', // Color de fondo gris claro
          padding: '16px', // Espaciado interno para que el contenido no quede pegado al borde
          borderRadius: '8px', // Bordes redondeados para un estilo más suave
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Sombra para resaltar el formulario
          marginBottom: '20px'
        }}
      >
        {filteredFaqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '8px' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography textAlign='left' fontWeight='bold'>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography textAlign='left'>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
