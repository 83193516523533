/*eslint-disable*/
import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { Drawer, Hidden, Typography, Grid, List, ListItem, ListItemText, Divider, Icon, Button, Menu, MenuItem, Link } from "@mui/material";

// core components

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userIsAdmin, userIsRRSS } from 'utils/users.utils';
import { userIsTester } from 'utils/users.utils';
import useWindowDimensions from "customHooks/useWindowDimensions";

const useStyles = makeStyles(styles);

const Sidebar = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const url = useLocation();
  const currentUser = useSelector(store => store.userData);
  const { width } = useWindowDimensions();

  // Submenues
  const [anchorElSupport, setAnchorElSupport] = useState(null);
  const [anchorElDsps, setAnchorElDsps] = useState(null);
  const openSupport = Boolean(anchorElSupport);
  const openDsps = Boolean(anchorElDsps);
  const handleClickSupport = (event) => setAnchorElSupport(event.currentTarget);
  const handleClickDsps = (event) => setAnchorElDsps(event.currentTarget);

  const handleClose = () => {
    setAnchorElDsps(null);
    setAnchorElSupport(null);
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    return url.pathname === routeName;
  }

  const { color, logo, image, logoText, routes } = props;

  const showLink = (path) => {
    const isAdmin = userIsAdmin(currentUser.rol);
    const isAdminOrTester = isAdmin || userIsTester(currentUser.rol);

    if (path === '/dashboard' && isAdmin) return false;
    if (path === '/dashboard-admin' && !isAdmin) return false;
    if (path === '/stats' && (!isAdmin || userIsRRSS(currentUser.rol))) return false;
    if (path === '/retiros' && !isAdmin) return false;
    if (path === '/users' && (!isAdmin && !userIsRRSS(currentUser.rol))) return false;
    if (path === '/test' && (!isAdminOrTester)) return false;

    if (['/support', '/dsps', '/marketing'].includes(path) && isAdmin) return false;
    return true;
  }

  const createSubmenu = (prop, listItemClasses, whiteFontClasses) => (
    <ListItem className={classes.itemLink + listItemClasses}>
      {<prop.icon className={classNames(classes.itemIcon, whiteFontClasses)} />}
      <Typography onClick={prop.path === "/support" ? handleClickSupport : handleClickDsps}
        sx={{ cursor: 'pointer', fontFamily: 'Roboto', fontWeight: 300, lineHeight: "1.75rem", color: 'white' }}>
        {prop.name}
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={prop.path === "/support" ? anchorElSupport : anchorElDsps}
        open={prop.path === "/support" ? openSupport : openDsps}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {prop.linkNames.map((linkName, index) =>
          <MenuItem onClick={handleClose}>
            <Link href={prop.links[index]} sx={{ color: "black" }} target="_blank" variant="body2" underline="none">
              {linkName}
            </Link>
          </MenuItem>
        )}
      </Menu>
    </ListItem>
  )

  const createMenu = (prop, listItemClasses, whiteFontClasses) => (
    <NavLink
      to={prop.layout + prop.path}
      className={"  " + classes.item}
      key={prop.name}
    >
      <ListItem className={classes.itemLink + listItemClasses}>
        {typeof prop.icon === "string" ? (
          <Icon
            className={classNames(classes.itemIcon, whiteFontClasses)}
          >
            {prop.icon}
          </Icon>
        ) : (
          <prop.icon
            className={classNames(classes.itemIcon, whiteFontClasses)}
          />
        )}
        <ListItemText
          primary={prop.name}
          disableTypography={true}
        />
      </ListItem>
    </NavLink>
  )

  const createLink = (prop, listItemClasses, whiteFontClasses) => (
    <ListItem className={classes.itemLink + listItemClasses}>
      {<prop.icon className={classNames(classes.itemIcon, whiteFontClasses)} />}
      <Link href={prop.link} sx={{ color: "white", fontFamily: 'Roboto', fontWeight: 300, lineHeight: "1.75rem" }} target="_blank" underline="none">
        {prop.name}
      </Link>
    </ListItem>
  )

  const generateTypeOfLateralMenu = (prop, listItemClasses, whiteFontClasses) => {
    if (prop.type === 'submenu') return createSubmenu(prop, listItemClasses, whiteFontClasses);
    if (prop.type === 'link') return createLink(prop, listItemClasses, whiteFontClasses);
    return createMenu(prop, listItemClasses, whiteFontClasses);
  }

  const linkByDevice = device => {
    return (
      <List className={classes.list} onClick={device === "mobile" ? props.handleDrawerToggle : null}>
        {routes.map((prop, key) => {

          let listItemClasses;
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path)
          });

          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
          });

          return showLink(prop.path)
            ? (
              generateTypeOfLateralMenu(prop, listItemClasses, whiteFontClasses)
            )
            : null;

        })}
      </List>
    )
  }

  var brand = (
    <div className={classes.logo}>
      <a
        href="https://www.laflota.com.ar/"
        className={classNames(classes.logoLink)}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );

  return (
    <div>
      {width < 1200
        ? <Drawer
          variant="temporary"
          anchor={"left"}
          open={props.open}
          classes={{ paper: classNames(classes.drawerPaper) }}
          onClose={props.handleDrawerToggle}
          ModalProps={{ keepMounted: true }}// Better open performance on mobile.
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {linkByDevice("mobile")}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
        : <Drawer
          anchor={"left"}
          variant="permanent"
          open
          classes={{ paper: classNames(classes.drawerPaper) }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{linkByDevice("desktop")}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>}
    </div>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};

export default Sidebar;