import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Grid
} from '@mui/material';
import { toWithOutError } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import SuccessDialog from '../../components/Dialogs/SuccessDialog';
import { Delete, Edit } from '@mui/icons-material/';
import { mainBlue } from 'variables/colors';
import { lightBlue } from '../../variables/colors';
import EditOrAddFieldsDialog from '../../components/Dialogs/EditOrAddFieldDialog';
import { userIsAdmin } from 'utils/users.utils';
import { paymentStateColors, paymentStatesListShowAdmin, paymentStatesShowAdmin, paymentsMethodsListToSelect, subIdToSubString } from "variables/financial";
import { getDateWithHoursFromTs } from "utils/timeRelated.utils";
import { getOtherPaymentId, getOtherPaymentIdField, getPaymentById, paymentCouldHadProof } from "utils/payments.utils";
import { paymentsPayDelete, paymentsPayUpdated } from "redux/actions/PaymentsActions";
import ProgressButton from "components/CustomButtons/ProgressButton";
import PayProofDialog from "views/Subscription/PayProofDialog";
import CopyableEditText from "components/TextField/CopyableEditText";


const PaymentActionsDialog = (props) => {

  const { isOpen, handleClose, paymentId } = props;

  const dispatch = useDispatch();
  const paymentsFromStore = useSelector(store => store.payments.payments);
  const userData = useSelector(store => store.userData);
  const isAdmin = userIsAdmin(userData.rol);

  const payment = getPaymentById(paymentsFromStore, paymentId);

  const paymentStatusToShow = paymentStatesShowAdmin[payment.status] || "SIN ESTADO";
  const paymentStatusColor = paymentStateColors[payment.status];

  const [actionState, setActionState] = useState('none')
  const [buttonState, setButtonState] = useState('none');
  const [openEditDialog, setOpenEditDialog] = useState({ open: false, title: "", subtitle: [""], values: "", type: "" });
  const [openPaymentLoader, setOpenPaymentLoader] = useState(false);
  const [openProofDialogInPayment, setOpenProofDialogInPayment] = useState({ open: false, payment: "" });

  let successDialogTitle = "";
  let successDialogText = [""];

  const handleDelete = async () => {
    setOpenPaymentLoader(true);
    let result = await toWithOutError(dispatch(paymentsPayDelete(payment.id)));
    if (result === "ERROR") { setButtonState("error"); setOpenPaymentLoader(false); }
    else {
      setOpenPaymentLoader(false); handleClose();
    }
    setButtonState('none'); setOpenPaymentLoader(false);
  }

  const handleEditPaymentState = () => setOpenEditDialog({
    open: true, title: "Cambiar el estado del Pago", subtitle: ["Solo se cambiará en la APP"],
    handleConfirm: (newValue) => handleConfirmEditPayment(newValue, 'status'),
    initialValues: paymentStatusToShow, optionsValues: paymentStatesListShowAdmin,
    values: "", handleCloseDialog: handleCloseEditDialog, type: "only-one-select"
  });

  const handleEditPaymentOtherId = () => setOpenEditDialog({
    open: true, title: "Cambiar o agregar ID Externo del Pago", subtitle: [""],
    handleConfirm: (newValue) => handleConfirmEditPayment(newValue || "", getOtherPaymentIdField(payment)),
    handleCloseDialog: handleCloseEditDialog, initialValues: getOtherPaymentId(payment) === "-" ? "" : getOtherPaymentId(payment)
  });

  const handleEditAmountBruto = () => setOpenEditDialog({
    open: true, title: "Cambiar Monto Bruto del Pago", subtitle: [""],
    handleConfirm: (newValue) => handleConfirmEditPayment(Number(newValue), 'transactionAmount'),
    handleCloseDialog: handleCloseEditDialog, initialValues: payment.transactionAmount
  });

  const handleEditAmountNeto = () => setOpenEditDialog({
    open: true, title: "Cambiar Monto Neto del Pago", subtitle: [""],
    handleConfirm: (newValue) => handleConfirmEditPayment(Number(newValue), 'netReceivedAmount'),
    handleCloseDialog: handleCloseEditDialog, initialValues: payment.netReceivedAmount
  });

  const handleEditPaymentMethod = () => setOpenEditDialog({
    open: true, title: "Cambiar Métdo del Pago", subtitle: [""],
    handleConfirm: (newValue) => handleConfirmEditPayment(newValue, 'payment_method'),
    handleCloseDialog: handleCloseEditDialog, initialValues: subIdToSubString[payment.payment_method] || "",
    type: 'only-one-select', values: "", optionsValues: paymentsMethodsListToSelect
  })

  const handleConfirmEditPayment = async (newValue, fieldName) => {
    setButtonState("loading");
    let finalValue = newValue.id ? newValue.id : newValue;
    let editResult = await toWithOutError(dispatch(paymentsPayUpdated({ ...payment, [fieldName]: finalValue })));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
  }

  const handleCloseEditDialog = () => setOpenEditDialog({ open: false, title: "", subtitle: [""], values: "" });
  const handleClosePaymentActionsDialog = () => handleClose();

  const jsxOtherPaymentId = <DialogContentText key={'payment outer ID'}>
    ID Externo: <b>{`${getOtherPaymentId(payment)}`}</b>
  </DialogContentText>

  const jsxAmountBruto = <DialogContentText key={'payment amount bruto'}>
    Monto Bruto: <b>{`${payment.transactionAmount}`}</b>
  </DialogContentText>

  const jsxAmountNeto = <DialogContentText key={'payment amount neto'}>
    Monto Neto: <b>{`${payment.netReceivedAmount}`}</b>
  </DialogContentText>

  const jsxPaymentMethod = <DialogContentText key={'payment method'}>
    Método de Pago: <b>{`${subIdToSubString[payment.payment_method]}`}</b>
  </DialogContentText>

  return (
    <>

      <PayProofDialog open={openProofDialogInPayment.open} setOpen={setOpenProofDialogInPayment} showOrLoad={'show'} payment={openProofDialogInPayment.payment} />

      <SuccessDialog isOpen={actionState === 'proccessing'}
        title={successDialogTitle} contentTexts={successDialogText}
        handleClose={() => setActionState('none')} successImageSource="/images/success.jpg" size="sm" />

      <EditOrAddFieldsDialog loading={buttonState === "loading"}
        buttonState={buttonState} editOptions={openEditDialog} setEditOptions={setOpenEditDialog} />

      <Dialog
        maxWidth="sm"
        id="info-dialog"
        open={isOpen}
        onClose={handleClosePaymentActionsDialog}>

        <DialogTitle id="title-info-dialog">
          {`Acciones para el Pago de: ${payment.ownerEmail}`}
        </DialogTitle>

        <DialogContent>

          <Grid item xs={12} paddingBottom={1}>

            <DialogContentText key={'info payment actions'} paddingBottom={1}>
              Si quieres eliminar definitivamente el pago, presiona Eliminar, si no,
              cambia el estado.
            </DialogContentText>

            {payment.id && <DialogContentText key={'payment ID'}>
              ID Pago: <b>{`${payment.id}`}</b>
            </DialogContentText>}

            <CopyableEditText textToCopy={getOtherPaymentId(payment)} color='grey'
              jsxElementText={jsxOtherPaymentId} handleEdit={handleEditPaymentOtherId} />

            <CopyableEditText jsxElementText={jsxAmountBruto} handleEdit={handleEditAmountBruto} />
            <CopyableEditText jsxElementText={jsxAmountNeto} handleEdit={handleEditAmountNeto} />
            <CopyableEditText jsxElementText={jsxPaymentMethod} handleEdit={handleEditPaymentMethod} />

            {payment.id && paymentCouldHadProof(payment.status) && <DialogContentText key={'pay proof'}>
              Comprobante si lo tiene: <b onClick={() => setOpenProofDialogInPayment({ open: true, payment })}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                Ver el comprobante de pago.
              </b>
            </DialogContentText>}

            {payment.ownerEmail && <DialogContentText key={'payment ownerEmail'}>
              Mail del Usuario: <b>{`${payment.ownerEmail}`}</b>
            </DialogContentText>}

            {payment.ownerId && <DialogContentText key={'payment ownerId'}>
              ID del Usuario: <b>{`${payment.ownerId}`}</b>
            </DialogContentText>}

            {payment.status !== undefined && <DialogContentText key={'payment status'}>
              Estado Pago: <b style={{ color: paymentStatusColor }}>{paymentStatusToShow}</b>
            </DialogContentText>}

            {payment.plan && <DialogContentText key={'when created pay'}>
              Fecha del Pago: <b>{getDateWithHoursFromTs(payment.lastPaymentDate)}</b>
            </DialogContentText>}

            {payment.plan && <DialogContentText key={'last update pay'}>
              Última actualizacion del Pago: <b>{getDateWithHoursFromTs(payment.lastUpdateTS)}</b>
            </DialogContentText>}

          </Grid>

          <Grid container>

            {isAdmin && <Grid item xs={6} padding={1}>
              <Button
                onClick={handleEditPaymentState}
                sx={{ backgroundColor: mainBlue, color: 'white', '&:hover': { backgroundColor: lightBlue } }}
                endIcon={<Edit />}
                fullWidth>
                Estado
              </Button>
            </Grid>}

            <Grid item xs={6} padding={1}>
              <ProgressButton
                textButton={"Eliminar"}
                loading={openPaymentLoader}
                buttonState={'delete'}
                onClickHandler={handleDelete}
                noneIcon={<Delete />}
                noFab={true}
                buttonFullWidth={true} />
            </Grid>

          </Grid>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleClosePaymentActionsDialog} color="primary">
            Atrás
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PaymentActionsDialog;

PaymentActionsDialog.defaultProps = {
  isOpen: false,
}

PaymentActionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}