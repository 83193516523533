import React, { useEffect, useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Grid
} from '@mui/material';
import BasicCheckbox from 'components/Checkbox/BasicCheckbox';
import MpButton from 'views/PaymentCompaniesSolutions/MpButton';
import { useDispatch, useSelector } from 'react-redux';
import { SUB_BANK, SUB_CUPON, SUB_MP, SUB_ORDER_PLAN_SELECTED, paymentMethodsUsd, termsAndConditionsLink } from 'variables/financial';
import TransferencePayment from 'views/Payments/TransferencePayment';
import TransferenceUsdPayment from 'views/Payments/TransferenceUsdPayment';
import { formatThousandsPoint } from 'utils';
import CuponPayment from 'views/Payments/CuponPayment';
import { paymentsPayUpdateLocal } from 'redux/actions/PaymentsActions';

const FinalInstructionsDialog = (props) => {

  const dispatch = useDispatch();
  const actualDiscount = useSelector(store => store.discounts?.actualDiscount);
  const actualPayment = useSelector(store => store.payments.actualPayment);
  const preferenceData = actualPayment.preference_data;
  const preferenceItemData = preferenceData?.items?.length > 0 ? preferenceData?.items[0] : {};

  const { open, onClose, acceptTerms, handleAcceptTermsChange } = props;
  const [showFinalPayButton, setShowFinalPayButton] = useState(false);
  const [showMpWait, setShowMpWait] = useState(false);

  const isCuponPay = actualPayment.payment_method === SUB_CUPON;
  const isMpPay = actualPayment.payment_method === SUB_MP;
  const isBankPay = actualPayment.payment_method === SUB_BANK;
  const isUsdPay = paymentMethodsUsd.includes(actualPayment.payment_method);

  useEffect(() => {
    if (acceptTerms) setShowFinalPayButton(true);
    else setShowFinalPayButton(false);
  }, [acceptTerms]);

  const handleTermsChange = event => {
    if (isMpPay) setShowMpWait(true);
    handleAcceptTermsChange(event);
  }

  const handleCancelFinalPay = () => {
    // dispatch(paymentsPayUpdateLocal({ ...actualPayment, status: SUB_ORDER_PLAN_SELECTED }));
    setShowMpWait(false);
    onClose();
  }

  const handleCloseAccidentalyDialog = () => {
    setShowMpWait(false);
    onClose();
  }
  const toPayText = `${preferenceItemData.currency_id} ${formatThousandsPoint(preferenceItemData.unit_price)}`;

  return (
    <Dialog open={open} onClose={handleCloseAccidentalyDialog}>
      <DialogTitle sx={{ textAlign: "center", fontSize: "1.5rem", paddingTop: "8px" }}>Términos y Condiciones</DialogTitle>
      <DialogContent sx={{ padding: "0 24px 0 24px" }}>
        <DialogContentText paddingBottom={1}>
          Por favor, lee y acepta los {termsAndConditionsLink} antes de continuar:
        </DialogContentText>

        <BasicCheckbox
          label={"Aceptar términos y condiciones"}
          onChecked={handleTermsChange}
          checked={acceptTerms}
        />

        {showMpWait && <DialogContentText paddingBottom={1} sx={{ fontSize: "14px" }}>
          El boton de Mercado Pago puede tardar unos segundos en aparecer
        </DialogContentText>}

      </DialogContent>
      <DialogActions sx={{ justifyContent: !showFinalPayButton ? 'left' : 'center' }}>
        {isMpPay && showFinalPayButton &&
          <Grid container justifyContent='center'>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontSize: "3rem", fontWeight: 500, textAlign: 'center', paddingTop: 0 }} key={'amount-pay'}>
                {toPayText}
              </DialogContentText>
            </Grid>

            <Grid item xs={6}>
              <MpButton show={showFinalPayButton} />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={handleCancelFinalPay}>Cancelar</Button>
            </Grid>
          </Grid>}
        {isCuponPay &&
          <Grid container justifyContent='center'>
            <CuponPayment show={showFinalPayButton} onClose={handleCancelFinalPay} />
          </Grid>}
        {isBankPay &&
          <Grid container justifyContent='left'>
            <TransferencePayment show={showFinalPayButton} onClose={handleCancelFinalPay} />
          </Grid>}
        {isUsdPay &&
          <Grid container justifyContent='left'>
            <TransferenceUsdPayment show={showFinalPayButton} onClose={handleCancelFinalPay} />
          </Grid>}
        {!showFinalPayButton && <Button onClick={handleCancelFinalPay}>Cancelar</Button>}
      </DialogActions>
    </Dialog >
  );
}

export default FinalInstructionsDialog;
