import React from "react";
import { styled } from '@mui/material/styles';
import {
  Table, TableBody, TableCell, TableContainer, TablePagination,
  TableHead, TableRow, Paper, Typography, Tooltip
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import TablePaginationActions from './TablePaginationActions';
import { getSkeletonRows } from 'utils/tables.utils';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CustomizedTable = (props) => {
  const { rows, headers, headersHeight, columnsWidth, totalCount, handleChangePage, page, headerColor, needPaginationInTable,
    handleChangeRowsPerPage, rowsPerPage, maxWidthText, maxLengthChars, rowsHeight, rowsAlignHeaders, rowsAlignCells, loading } = props;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: headerColor,
      color: theme.palette.common.white,
      padding: '8px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
      height: rowsHeight,
    },
  }));

  const getRowsToShow = () => {
    if (loading) return getSkeletonRows(headers.length, 10);
    else return needPaginationInTable ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows;
  }

  let rowsToShow = getRowsToShow();

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 850 }}>
        <Table sx={{ minWidth: 700 }} stickyHeader aria-label="customized table">
          <TableHead sx={{ height: headersHeight }}>
            <TableRow key='headers'>
              {headers.map((headerName, index) => (
                <StyledTableCell key={headerName} sx={{ width: columnsWidth[index] }} align={rowsAlignHeaders}>{headerName}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsToShow.map((row, index) => (
              <StyledTableRow key={'row' + index}>
                {Object.values(row).map((rowValue, index) => (
                  <StyledTableCell key={'data' + rowValue + index} sx={{ textAlign: rowsAlignCells, width: columnsWidth[index] }}>
                    {rowValue && rowValue.length > maxLengthChars
                      ? <Tooltip key={index} title={rowValue} >
                        <Typography component="span" noWrap>{rowValue.slice(0, maxLengthChars) + "..."}</Typography>
                      </Tooltip>
                      : <Typography component="span" noWrap>{rowValue}</Typography>
                    }
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalCount !== 0 && <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        colSpan={3}
        count={totalCount}
        component="div"
        rowsPerPage={rowsPerPage}
        labelRowsPerPage='Filas por página:'
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'filas por página', },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />}
    </Paper>

  );
}

export default CustomizedTable;