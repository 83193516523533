import React, { useState, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button } from '@mui/material';

import TextFieldWithInfo from '../../components/TextField/TextFieldWithInfo';
import { Save } from '@mui/icons-material';
import useForm from '../../customHooks/useForm';
import ProgressButton from 'components/CustomButtons/ProgressButton';
import SimpleReactValidator from 'simple-react-validator';
import { useForceUpdate } from '../../utils';
import { toWithOutError } from 'utils';
import { useDispatch } from 'react-redux';
import { getPaymentModelFromCreatePayment, paymentDefault } from "factory/payments.factory";
import { paymentsCreateRedux } from "redux/actions/PaymentsActions";
import { paymentStatesListShowAdmin, paymentsMethodsListToSelect, planesLaFlota } from "variables/financial";
import CalendarInput from "components/Input/CalendarInput";
import { getNextYearDateFromTsToTs } from "utils/timeRelated.utils";
import InfoActionDialog from "components/Dialogs/InfoActionDialog";
import { getDiscountByNameFS, getUserDataByEmailInFS } from "services/FirestoreServices";
import { discountNotFoundText, userNotFoundText } from "utils/textToShow.utils";
import { getCuponReducedInfo } from "factory/discounts.factory";

const PaymentCreateDialog = ({ isOpen, handleCloseDialog }) => {

  const dispatch = useDispatch()
  const validator = useRef(new SimpleReactValidator());
  const forceUpdate = useForceUpdate();

  const [openLoader, setOpenLoader] = useState(false);
  const [buttonState, setButtonState] = useState("none");
  const [buttonText, setButtonText] = useState("Finalizar");

  const today = new Date().getTime();
  const [newLastPaymentDate, setNewLastPaymentDate] = useState(today);
  const [newNextPaymentDate, setNewNextPaymentDate] = useState(getNextYearDateFromTsToTs(today));
  const [cuponName, setCuponName] = useState("");
  const [openAlertDialog, setOpenAlertDialog] = useState({ open: false, title: "", text: [""] });

  let defaultData = paymentDefault({});

  const [formData, setForm] = useForm(defaultData);

  let { ownerEmail, status, id, mpPaymentId, payment_method, currency,
    netReceivedAmount, otherPaymentId, transactionAmount, plan } = formData;

  const allFieldsValidCreateDiscount = () => {
    let fieldsErrors = validator.current.fields;
    if (fieldsErrors.ownerEmail) {
      createPayment();
    }
    else {
      validator.current.showMessages();
      forceUpdate();
    }
  }

  const createPayment = async () => {
    setOpenLoader(true); 
    let discountByName = "";

    let paymentInfo = {
      ...formData,
      nextPaymentDate: new Date(newNextPaymentDate).getTime(),
      lastPaymentDate: new Date(newLastPaymentDate).getTime()
    };

    const userByEmail = await getUserDataByEmailInFS(ownerEmail, dispatch);
    if (userByEmail === "ERROR") {
      setOpenAlertDialog({ open: true, title: "Usuario no encontrado", text: userNotFoundText });
      setOpenLoader(false); return;
    }

    if (cuponName) {
      discountByName = await getDiscountByNameFS(cuponName, dispatch);
      if (["ERROR", "EMPTY"].includes(discountByName)) {
        setOpenAlertDialog({ open: true, title: "Cupón no encontrado", text: discountNotFoundText(cuponName) });
        setOpenLoader(false); return;
      }
      paymentInfo.cupon = getCuponReducedInfo(discountByName);
    }

    let paymentModelFromCreatePayment = getPaymentModelFromCreatePayment(paymentInfo, userByEmail)
    let result = await toWithOutError(dispatch(paymentsCreateRedux(paymentModelFromCreatePayment)));
    setOpenLoader(false);
    if (result === "SUCCESS") { setButtonState("success"); handleCloseCreateDiscount(); }
    else { setButtonState("error"); setButtonText("Error"); }
  }

  const handleEditLastPaymentDate = newDateValue => {
    setNewLastPaymentDate(newDateValue.format());
  }

  const handleEditNextPaymentDate = newDateValue => {
    setNewNextPaymentDate(newDateValue.format());
  }

  const handleCloseCreateDiscount = () => {
    setButtonState("none");
    handleCloseDialog();
  }

  const handleCloseAlertDialog = () => setOpenAlertDialog({ open: false, title: "", text: [''] });

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      id="collaborative-dialog"
      sx={{ padding: "1em" }}
      open={isOpen}
      onClose={handleCloseCreateDiscount}>

      <InfoActionDialog id='alert-payout' isOpen={openAlertDialog.open} handleClose={handleCloseAlertDialog}
        title={openAlertDialog.title} contentTexts={openAlertDialog.text} />


      <DialogTitle sx={{ fontSize: '2em', fontWeigth: 500, textAlign: 'center' }} id="add-payment-dialog">
        Crear Pago
      </DialogTitle>

      <DialogContent padding={0}>
        <Grid container sx={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <ul style={{ textAlign: 'left' }}>
              <li>No todos los campos son obligatorios.</li>
              <li>Si no tenemos ningun ID, el sistema creará un ID nuestro, automáticamente.</li>
            </ul>
          </Grid>

          <Grid container item xs={12}>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                fullWidth
                required
                name="ownerEmail"
                label="Email del Usuario"
                value={ownerEmail}
                onChange={setForm}
                validatorProps={{ restrictions: 'email|max:100', message: "Debes ingresar un email.", validator: validator }} />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name="id"
                fullWidth
                label="Nuestro ID, si no tenemos, dejar el asignado"
                value={id}
                onChange={setForm}
              />
            </Grid>

            <Grid item xs={5} padding={1}>
              <TextFieldWithInfo
                name="transactionAmount"
                fullWidth
                required
                label="Monto Bruto"
                value={transactionAmount}
                onChange={setForm}
                validatorProps={{ restrictions: 'required|number|min:0', message: "Debes ingresar el monto bruto.", validator: validator }}
              />
            </Grid>

            <Grid item xs={5} padding={1}>
              <TextFieldWithInfo
                name="netReceivedAmount"
                fullWidth
                required
                label="Monto Neto"
                value={netReceivedAmount}
                onChange={setForm}
                validatorProps={{ restrictions: 'required|number|min:0', message: "Debes ingresar el monto neto.", validator: validator }}
              />
            </Grid>

            <Grid item xs={2} padding={1}>
              <TextFieldWithInfo
                name="currency"
                fullWidth
                required
                label="Moneda del Pago"
                value={currency}
                onChange={setForm}
                select
                selectItems={[{ id: "ARS", name: "ARS" }, { id: "USD", name: "USD" }]}
                selectKeyField="id"
                selectValueField="name"
              />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name="payment_method"
                fullWidth
                required
                label="Metodo de Pago"
                value={payment_method}
                onChange={setForm}
                select
                selectItems={paymentsMethodsListToSelect}
                selectKeyField="id"
                selectValueField="name"
                validatorProps={{ restrictions: 'required', message: "Selecciona el Método de Pago.", validator: validator }}
              />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name="status"
                fullWidth
                required
                label="Estado del Pago"
                value={status}
                onChange={setForm}
                select
                selectItems={paymentStatesListShowAdmin}
                selectKeyField="id"
                selectValueField="name"
                validatorProps={{ restrictions: 'required', message: "Selecciona el Estado de Pago.", validator: validator }}
              />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name="mpPaymentId"
                fullWidth
                label="MP ID, si tenemos"
                value={mpPaymentId}
                onChange={setForm}
              />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name="otherPaymentId"
                fullWidth
                label="Otro ID, si tenemos"
                value={otherPaymentId}
                onChange={setForm}
              />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name="plan"
                fullWidth
                required
                label="Plan"
                value={plan}
                onChange={setForm}
                select
                selectItems={planesLaFlota}
                selectKeyField="id"
                selectValueField="name"
                validatorProps={{ restrictions: 'required', message: "Selecciona el Plan pagado.", validator: validator }}
              />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name="cuponName"
                fullWidth
                label="Nombre del Cupón"
                value={cuponName}
                onChange={(event) => setCuponName(event.target.value)}
              />
            </Grid>

            <Grid item xs={6} padding={1}>
              <CalendarInput selectedDate={newLastPaymentDate} width={"100%"}
                label="Fecha de este Pago" onChangeDate={handleEditLastPaymentDate} />
            </Grid>

            <Grid item xs={6} padding={1}>
              <CalendarInput selectedDate={newNextPaymentDate} width={"100%"}
                label="Fecha Renovación" onChangeDate={handleEditNextPaymentDate} />
            </Grid>

          </Grid>

        </Grid>
      </DialogContent >

      <DialogActions sx={{ overflow: "scroll" }}>
        <ProgressButton textButton={buttonText} loading={openLoader} buttonState={buttonState}
          onClickHandler={allFieldsValidCreateDiscount} noneIcon={<Save sx={{ color: "rgba(255,255,255, 1)" }} />} noFab={false} />
      </DialogActions>

      <DialogActions>
        <Button onClick={handleCloseCreateDiscount}>
          Atras
        </Button>
      </DialogActions>
    </Dialog >
  );
}

export default PaymentCreateDialog;
