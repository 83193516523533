import React from "react";
import { Grid, Paper, Typography } from '@mui/material';
import ArtistAddedIcon from '../../views/Icons/ArtistAddedIcon';
import { subtitleColor } from "variables/colors";

const PersonShower = ({ id, person, personSubtitle, personNumber }) => {

  return (
    <Grid key={id} item xs={3} justifyContent="center">
      <Paper key={id} elevation={0} sx={artistPaperStyle} >
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <ArtistAddedIcon sx={artistAddedIconStyle} asIconButton={false} />
          </Grid>

          <Grid container item xs={9} direction="column" justifyContent="center" alignItems="center">
            <Grid item xs={5} sx={nameGridStyle}>
              <Typography sx={nameStyle} noWrap>{person.name}</Typography>
            </Grid>
            <Grid container item xs={4}>
              <Grid item xs={8} sx={switchGridStyle}>
                <Typography variant='body1' sx={{ color: subtitleColor }}>{personSubtitle}</Typography>
              </Grid>
              {personNumber && <Grid item xs={4} sx={switchGridStyle}>
                <Typography variant='body1' sx={{ color: subtitleColor }}>{`Track: ${personNumber}`}</Typography>
              </Grid>}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default PersonShower;

const artistPaperStyle = { width: "100%", height: "60px", border: "3px solid rgba(0, 0, 0, 0.15)" };
const artistAddedIconStyle = { width: "62px" };
const nameStyle = { color: "#508062", fontWeight: "400", fontSize: "20px", textAlign: "initial" };
const switchGridStyle = { width: "100%", textAlign: "start" };
const nameGridStyle = { width: "100%" };