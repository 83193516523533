import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUsersPropsForDataTable, getUsersByQuery, userIsAdmin,
  userStatusNameToStatusId, getUsersByCustomQuery, sortUsersByFieldOrQuery
} from "utils/users.utils";
import { toWithOutError } from 'utils';
import { getSearchedUserRedux, getUsersByFieldRedux, getUsersByFieldsRedux, usersAddStore } from "../../redux/actions/UsersActions";
import useFirestoreQuery from '../../customHooks/useFirestoreQuery';
import { getElementsAdminQueryFS } from "services/FirestoreServices";
import { getArtistByFieldRedux } from '../../redux/actions/ArtistsActions';

import CustomizedTable from 'components/Table/CustomizedTable';
import { cohesiveViola, whiteColor } from "variables/colors";
import AccountingBar from "components/Navbars/AccountingBar";
import { getAlbumsByFieldRedux } from 'redux/actions/AlbumsActions';
import UserActionsDialog from './UserActionsDialog';
import PayProofDialog from "views/Subscription/PayProofDialog";
import { getCantDaysInMS } from "utils/timeRelated.utils";
import { userFilterUserStatusValues } from "variables/user.variables";
import { getPaymentsByFieldRedux } from "redux/actions/PaymentsActions";
import { querySignOut } from "redux/actions/QueryActions";

const UsersTable = (props) => {

  const { setOpenErrorSearch, setOpenEmptySearch } = props;

  const dispatch = useDispatch();
  const currentUserData = useSelector(store => store.userData);
  const queryStore = useSelector(store => store.query);
  const users = useSelector(store => store.users);
  const rol = currentUserData.rol;

  const [usersLoading, setUsersLoading] = useState(false);

  // COSAS DEL BUSCADOR
  const [emailSearchValue, setEmailSearchValue] = useState("");
  const [artistSearchValue, setArtistSearchValue] = useState("");
  const [albumNameSearchValue, setAlbumNameSearchValue] = useState("");
  const [upcSearchValue, setUpcSearchValue] = useState("");
  const [paymentIdSearchValue, setPaymentIdSearchValue] = useState("");

  const [usersFiltered, setUsersFiltered] = useState(users);

  let defaultFilterParams = {
    field: "userStatus", values: [], query: "all",
    groupBy: { id: 'all', name: "Todos los estados" }, orderBy: { field: 'lastPayUpdateTS', order: 'desc' }
  }

  const [openUserPayProof, setOpenUserPayProof] = useState({ open: false, userId: "" });
  const [openUserActionsDialog, setOpenUserActionsDialog] = useState({ open: false, userId: "" });
  const [usersTableIsOpen, setUsersTableIsOpen] = useState(true);
  const [pageUsers, setPageUsers] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchUsersParams, setSearchUsersParams] = useState({ field: 'none', value: "" });
  const [filterUsersParams, setFilterUsersParams] = useState(defaultFilterParams);

  useEffect(() => {
    cleanSearchResults();
  }, [])
  
  useEffect(() => {
    if (queryStore.name === 'all' || queryStore.collection !== 'users') return;
    let usersCustomQueried = getUsersByCustomQuery(users, queryStore);
    setUsersFiltered(usersCustomQueried);
    setUsersLoading(false);
  }, [queryStore]);

  useEffect(() => {
    if (queryStore.name !== 'all') return;
    let groupBy = filterUsersParams.groupBy;
    let usersQueried = getUsersByQuery(users, searchUsersParams, { field: filterUsersParams.field, value: groupBy.id });
    setUsersFiltered(usersQueried);
    setUsersLoading(false);
  }, [users, filterUsersParams.orderBy])


  let sortedUsers = sortUsersByFieldOrQuery(usersFiltered, "lastPayUpdateTS", queryStore);

  const stateUsersSnap = useFirestoreQuery(
    getElementsAdminQueryFS("users", 50,
      sortedUsers[0]?.lastPayUpdateTS || new Date().getTime() - getCantDaysInMS(14), 'admin', "lastPayUpdateTS"))

  useEffect(() => {
    if (stateUsersSnap.status === "loading") return "Loading...";
    if (stateUsersSnap.status === "error") return `Error al cargar los ultimos Users: ${stateUsersSnap.error.message}`;
    if (stateUsersSnap.status === "success" && stateUsersSnap.data.length > 0) dispatch(usersAddStore(stateUsersSnap.data));
  }, [stateUsersSnap])

  const usersTableElements = getUsersPropsForDataTable(sortedUsers, setOpenUserActionsDialog, setOpenUserPayProof) || [];
  const usersTableHeaders = userIsAdmin(rol) ? ["Acc.", "Email", "Creación Usuario", "Plan", "Estado", "Último Pago", "Renovación", "Método", "ID Pago", "Cupón", "Monto Bruto", "Monto Neto", "Moneda"] : ["Email", "Nombre", "Plan"];
  const usersTableWidth = userIsAdmin(rol) ? ["2%", "10%", "8%", "3%", "12%", "10%", "12%", "1%", "5%", "5%", "8%", "8%", "5%"] : ["40%", "40%", "20%"];

  const cleanSearchResults = () => {
    setSearchUsersParams({ field: 'none', values: '' });
    setFilterUsersParams(defaultFilterParams);
    setUsersFiltered(users);
    setEmailSearchValue("");
    setPaymentIdSearchValue("");
    setArtistSearchValue("");
    setAlbumNameSearchValue("");
    setUpcSearchValue("");
    dispatch(querySignOut());
  }

  const onSearchEmailHandler = async email => {
    setUsersLoading(true);

    let userFinded = users.find(userFromStore => userFromStore.email === email);
    if (!userFinded) {
      setSearchUsersParams({ field: 'email', values: email });
      userFinded = await toWithOutError(dispatch(getSearchedUserRedux(email)));
    }

    if (userFinded === "ERROR") { setUsersLoading(false); setOpenErrorSearch(true); setUsersFiltered([]); return "ERROR"; }
    if (userFinded === "EMPTY") { setUsersLoading(false); setOpenEmptySearch(true); setUsersFiltered([]); return "EMPTY"; }

    setSearchUsersParams({ field: 'id', values: userFinded.id });
    setUsersFiltered([userFinded]);
    setPageUsers(0);
    setUsersLoading(false);
  }

  const onSearchArtistNameHandler = async artistName => {
    setUsersLoading(true);

    let artistsWithName = await toWithOutError(dispatch(getArtistByFieldRedux('name', artistName.trim(), 100)));
    if (artistsWithName === "ERROR") { setUsersLoading(false); setOpenErrorSearch(true); setUsersFiltered([]); return "ERROR"; }
    if (artistsWithName === "EMPTY") { setUsersLoading(false); setOpenEmptySearch(true); setUsersFiltered([]); return "EMPTY"; }

    setSearchUsersParams({ field: 'id', values: artistsWithName.map(artist => artist.ownerId) });
    let usersFinded = await toWithOutError(dispatch(getUsersByFieldRedux('id', artistsWithName.map(artist => artist.ownerId), 20)));
    if (usersFinded === "ERROR") { setUsersLoading(false); setOpenErrorSearch(true); setUsersFiltered([]); return "ERROR"; }
    if (usersFinded === "EMPTY") { setUsersLoading(false); setOpenEmptySearch(true); setUsersFiltered([]); return "EMPTY"; }
    setPageUsers(0);
    setUsersLoading(false);
  }

  const onSearchPaymentIdHandler = async paymentId => {
    setUsersLoading(true);
    let paymentDoc = [];
    if (paymentId.includes("LF"))
      paymentDoc = await toWithOutError(dispatch(getPaymentsByFieldRedux('id', paymentId, 1)));
    else paymentDoc = await toWithOutError(dispatch(getPaymentsByFieldRedux('mpPaymentId', paymentId, 1)));
    if (paymentDoc === "ERROR") { setUsersLoading(false); setOpenErrorSearch(true); setUsersFiltered([]); return "ERROR"; }
    if (paymentDoc === "EMPTY") { setUsersLoading(false); setOpenEmptySearch(true); setUsersFiltered([]); return "EMPTY"; }

    setSearchUsersParams({ field: 'id', values: paymentDoc[0].ownerId });
    let usersFinded = await toWithOutError(dispatch(getUsersByFieldRedux('id', paymentDoc[0].ownerId, 1)));
    if (usersFinded === "ERROR") { setUsersLoading(false); setOpenErrorSearch(true); setUsersFiltered([]); return "ERROR"; }
    if (usersFinded === "EMPTY") { setUsersLoading(false); setOpenEmptySearch(true); setUsersFiltered([]); return "EMPTY"; }
    setPageUsers(0);
    setUsersLoading(false);
  }

  const onSearchAlbumNameHandler = async releaseName => {
    setUsersLoading(true);

    let albumsWithName = await toWithOutError(dispatch(getAlbumsByFieldRedux('title', releaseName.trim(), 20)));
    if (albumsWithName === "ERROR") { setUsersLoading(false); setOpenErrorSearch(true); setUsersFiltered([]); return "ERROR"; }
    if (albumsWithName === "EMPTY") { setUsersLoading(false); setOpenEmptySearch(true); setUsersFiltered([]); return "EMPTY"; }

    setSearchUsersParams({ field: 'id', values: albumsWithName.map(album => album.ownerId) });
    let usersFinded = await toWithOutError(dispatch(getUsersByFieldRedux('id', albumsWithName.map(album => album.ownerId), 20)));
    if (usersFinded === "ERROR") { setUsersLoading(false); setOpenErrorSearch(true); setUsersFiltered([]); return "ERROR"; }
    if (usersFinded === "EMPTY") { setUsersLoading(false); setOpenEmptySearch(true); setUsersFiltered([]); return "EMPTY"; }
    setPageUsers(0);
    setUsersLoading(false);
  }

  const onSearchUpcHandler = async upc => {
    setUsersLoading(true);

    let albumsWithUPC = await toWithOutError(dispatch(getAlbumsByFieldRedux('upc', upc, 5)));
    if (albumsWithUPC === "ERROR") { setUsersLoading(false); setOpenErrorSearch(true); setUsersFiltered([]); return "ERROR"; }
    if (albumsWithUPC === "EMPTY") { setUsersLoading(false); setOpenEmptySearch(true); setUsersFiltered([]); return "EMPTY"; }

    setSearchUsersParams({ field: 'id', values: albumsWithUPC.map(album => album.ownerId) });
    let usersFinded = await toWithOutError(dispatch(getUsersByFieldRedux('id', albumsWithUPC.map(album => album.ownerId), 5)));
    if (usersFinded === "ERROR") { setUsersLoading(false); setOpenErrorSearch(true); setUsersFiltered([]); return "ERROR"; }
    if (usersFinded === "EMPTY") { setUsersLoading(false); setOpenEmptySearch(true); setUsersFiltered([]); return "EMPTY"; }
    setPageUsers(0);
    setUsersLoading(false);
  }

  const handleEnterKeyPress = (event, searchProps) => {
    if (event.key === 'Enter') {
      if (searchProps.name === "Email") onSearchEmailHandler(searchProps.value.trim().toLowerCase());
      if (searchProps.name === "Artista") onSearchArtistNameHandler(searchProps.value);
      if (searchProps.name === "Nombre Lanzamiento") onSearchAlbumNameHandler(searchProps.value);
      if (searchProps.name === "UPC") onSearchUpcHandler(searchProps.value.trim());
      if (searchProps.name === "Nuestro/MP ID") onSearchPaymentIdHandler(searchProps.value.trim())
    }
  }

  const emailSearchProps = { name: "Email", handleEnterKeyPress, onSearchHandler: onSearchEmailHandler, value: emailSearchValue.trim().toLowerCase(), setValue: setEmailSearchValue };
  const artistSearchProps = { name: "Artista", handleEnterKeyPress, onSearchHandler: onSearchArtistNameHandler, value: artistSearchValue, setValue: setArtistSearchValue };
  const albumNameSearchProps = { name: "Nombre Lanzamiento", handleEnterKeyPress, onSearchHandler: onSearchAlbumNameHandler, value: albumNameSearchValue, setValue: setAlbumNameSearchValue };
  const upcSearchProps = { name: "UPC", handleEnterKeyPress, onSearchHandler: onSearchUpcHandler, value: upcSearchValue.trim(), setValue: setUpcSearchValue };
  const ourPaymentIdSearchProps = { name: "Nuestro/MP ID", handleEnterKeyPress, onSearchHandler: onSearchPaymentIdHandler, value: paymentIdSearchValue.trim(), setValue: setPaymentIdSearchValue };

  const allSearchers = [emailSearchProps, artistSearchProps, albumNameSearchProps, upcSearchProps, ourPaymentIdSearchProps];
  let appBarSx = { borderRadius: '0em', backgroundColor: whiteColor };

  const handleChangePage = (event, newPage) => {
    setPageUsers(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  let usersTableParams = {
    rows: usersTableElements, headers: usersTableHeaders, columnsWidth: usersTableWidth, needPaginationInTable: true,
    totalCount: usersFiltered.length, handleChangePage, page: pageUsers, headerColor: cohesiveViola, handleChangeRowsPerPage, rowsPerPage,
    headersHeight: 65, maxLengthChars: 50, maxWidthText: 300, rowsAlignHeaders: 'center', rowsAlignCells: 'center', rowsHeight: 30,
    loading: usersLoading,
  };

  const albumsGroupByState = userFilterUserStatusValues;

  const handleChangeGroupBy = async groupByName => {
    setUsersLoading(true);
    setFilterUsersParams({
      ...filterUsersParams,
      groupBy: { id: userStatusNameToStatusId(groupByName), name: groupByName || "Todos los estados" }
    });
    let fields = userStatusNameToStatusId(groupByName) !== 'all' ? ['userStatus'] : [];
    let values = userStatusNameToStatusId(groupByName) !== 'all' ? [userStatusNameToStatusId(groupByName)] : [];
    if (searchUsersParams.field !== 'none') { fields.push(searchUsersParams.field); values.push(searchUsersParams.value) };

    let usersFinded = await toWithOutError(dispatch(getUsersByFieldsRedux(fields, values, rowsPerPage)));
    if (usersFinded === "ERROR") { setUsersLoading(false); setOpenErrorSearch(true); setUsersFiltered([]); return "ERROR"; }
    if (usersFinded === "EMPTY") { setUsersLoading(false); setOpenEmptySearch(true); setUsersFiltered([]); return "EMPTY"; }
  }

  const groupByProps = { helper: "Agrupar según", values: albumsGroupByState, field: "userStatus", handleChangeGroupBy, value: filterUsersParams.groupBy }

  return (
    <Grid item xs={12} sx={{ textAlign: "center" }}>

      <PayProofDialog open={openUserPayProof.open} setOpen={setOpenUserPayProof} showOrLoad={'show'} userId={openUserPayProof.userId} />

      {openUserActionsDialog.open && <UserActionsDialog isOpen={openUserActionsDialog.open} handleClose={() => setOpenUserActionsDialog({ open: false, userId: "" })}
        userId={openUserActionsDialog.userId} caller={'user-table'} />}

      <Grid item xs={12} padding={0} >
        <AccountingBar searchArrayProps={allSearchers} cleanSearchResults={cleanSearchResults}
          appBarSx={appBarSx} appBarTitle='Usuarios' mainSearchColor={cohesiveViola} isOpen={usersTableIsOpen}
          handleCollapseTable={() => setUsersTableIsOpen(!usersTableIsOpen)} groupByArray={[groupByProps]} />
      </Grid>

      {usersTableIsOpen && <Grid item xs={12} sx={{ margin: 'auto' }}>
        <CustomizedTable {...usersTableParams} />
      </Grid>}
    </Grid>
  )
}

export default UsersTable;