import { PENDING, userPendingStates } from "variables/varias";

export const albumFilterStates = ["Todos los estados", "Faltan archivos", "En las DSP's", "Cargando", "Apple en revisión", "Apple en nueva revisión", "Apple rechazada",
  "Investigando infracción", "Infracción", "Pendiente", "Aprobado", "Eliminado", "Dado de baja"];

export const albumFilterStatesForUsers = ["Todos los estados", "En las DSP's", "Faltan archivos", "Pendiente", "Apple en revisión", "Apple en nueva revisión", "Apple rechazada",
  "Infracción", "Dado de baja"];

export const getQueryAlbums = (albums, searchParams, filterParams, isAdmin) => {
  let albumsResult = albums;
  if (searchParams.field !== 'none')
    albumsResult = albums.filter(album => album[`${searchParams.field}`] === searchParams.value);

  if (filterParams.value !== 'all') {
    if (!isAdmin && filterParams.value === PENDING) {
      return albumsResult.filter(album => userPendingStates.includes(album[`${filterParams.field}`]));
    }
    albumsResult = albumsResult.filter(album => album[`${filterParams.field}`] === filterParams.value);
  }
  return albumsResult
} 
