import {
  USER_ACTIVE, USER_CANCELLED, USER_INACTIVE, USER_NEED_RENOVATION,
  USER_PENDING_PAY, USER_PENDING_PROOF, USER_PENDING_PROOF_CHECK, USER_SUSPEND_NO_PAY
} from 'variables/user.variables';

export const mapCustomQueryNameToStatuses = customQueryName => {
  if (customQueryName === 'actives') return [USER_ACTIVE];
  if (customQueryName === 'cancelled') return [USER_CANCELLED, USER_SUSPEND_NO_PAY, USER_INACTIVE];
  if (customQueryName === 'pending') return [USER_PENDING_PAY, USER_PENDING_PROOF, USER_PENDING_PROOF_CHECK];
  if (customQueryName === 'need-renovation') return [USER_NEED_RENOVATION];
  if (customQueryName === 'need-verification-proof') return [USER_PENDING_PROOF_CHECK];
  return "";
}