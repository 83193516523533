// Auth Types
export const EMAIL_CHANGED = 'email_changed';
export const PASSWORD_CHANGED = 'password_changed';
export const SIGN_IN = 'sign_in';
export const SIGN_IN_ERR = 'sign_in_err';
export const SIGN_OUT = 'sign_out';
export const SIGN_OUT_ERROR = "sign_out_error";
export const SIGN_IN_POST_SIGNUP = "sign_in_post_signup";

// SignUp Types
export const SIGNUP_SUCCESS = "sign_up_succes";
export const SIGNUP_ERROR = "signup_error";
export const REVERT_SIGNUP_SUCCED = "revert_signup_succed";

// UserData Types
export const USER_DATA_EDIT_PERFIL = "user_data_edit_perfil";
export const USER_DATA_SIGN_IN = 'user_data_sign_in';
export const USER_DATA_SIGN_OUT = 'user_data_sign_out';
export const USER_DATA_ADD_IMAGE = 'user_data_add_image';
export const USER_DATA_ADD_SUBGENERO = "user_data_add_subgenero";

// Artists Types
export const ADD_ARTISTS = "add_artists";
export const ARTISTS_SIGN_OUT = "artists_sign_out";
export const ADDING_ARTIST_UPDATE = "adding_artist_update";
export const ADDING_ARTIST_NAME = "adding_artist_name";
export const ADDING_ARTIST_BIO = "adding_artist_bio";
export const ADDING_ARTIST_IMAGEN_URL = "adding_artist_imagen_url";
export const ADDING_ARTIST_ID = "adding_artist_id";
export const ARTIST_DELETE_WITH_ID = "artist_delete_with_id";
export const EDIT_ARTIST_WITH_ID = "edit_artist_with_id";
export const ADDING_ARTIST_SPOTIFY_URI = "adding_artist_spotify_uri";
export const ADDING_ARTIST_APPLE_ID = "adding_artist_apple_id";
export const ADDING_ARTIST_FUGA_ID = "adding_artist_fuga_id";
export const ADDING_ARTIST_EMAIL = "adding_artist_email";

// Labels Types
export const ADD_LABELS = "add_labels";
export const LABELS_SIGN_OUT = "labels_sign_out";
export const LABEL_DELETE_WITH_ID = "label_delete_with_id";

//Albums Types
export const ADD_ALBUMS = "add_albums";
export const ALBUMS_REORDER = "albums_reorder";
export const ALBUMS_EDIT_BY_ID = "albums_edit_by_id";
export const ALBUMS_SIGN_OUT = "albums_sign_out";
export const ALBUMS_UPDATE_ADDING_ALBUM = "albums_update_adding_album";
export const ALBUMS_CLEAN_ADDING_ALBUM = "albums_clean_adding_album";
export const ALBUMS_UPDATE_ADDING_ALBUM_IMAGEN_URL_AND_FILE = "albums_update_adding_album_imagen_url_and_file";
export const ALBUMS_UPDATE_OTHER_ARTIST_NAME = "albums_update_other_artist_name";
export const ALBUMS_UPDATE_OTHER_ARTIST_IDENTIFIER = "albums_update_other_artist_identifier";
export const ALBUMS_UPDATE_OTHER_ARTIST_PRIMARY = "albums_update_other_artist_primary";
export const ALBUMS_DELETE_BY_ID = "albums_delete_by_id";

//Tracks Types
export const ADD_TRACKS = "add_tracks";
export const ADD_UPLOADING_TRACKS = "add_uploading_tracks";
export const TRACKS_SIGN_OUT = "tracks_sign_out";
export const EDIT_TRACK = "edit_track";
export const EDIT_TRACK_POST_UPLOAD_IN_DB = "edit_track_post_upload_in_db";
export const SET_TRACK_UPLOAD_PROGRESS = "set_track_upload_progress";
export const TRACK_UPLOADING_DELETE = "track_uploading_delete";
export const TRACK_UPLOADING_EDIT = "track_uploading_edit";
export const TRACKS_UPLOADING_CLEAN = "tracks_uploading_clean";

// ArtistsInvited Types
export const INVITED_ARTISTS_ADD = "invited_artists_add";
export const INVITED_ARTIST_EDIT_WITH_ID = "invited_artist_edit_with_id";
export const INVITED_ARTIST_DELETE_WITH_ID = "invited_artist_delete_with_id";
export const INVITED_ARTISTS_SIGN_OUT = "invited_artists_sign_out";

// Collaborators Types
export const ADD_COLLABORATORS = "add_collaborators";
export const COLLABORATORS_SIGN_OUT = "collaborators_sign_out";

//Notifications Handler
//ERRORS
export const ERROR_FROM_BACKEND = "error_from_backend";
export const ERROR_FROM_FIRESTORE = "error_from_firestore";
export const ERROR_CLEAN_LAST = "error_clean_last";
export const ERROR_BASIC_MSG = "error_basic_msg";
//SUCCESS
export const SUCCESS_NOTIFICATION = "success_notification";
export const SUCCESS_CLEAN_LAST = "succes_clean_last";

//Activities
export const ACTIVITIES_ADD = "activities_add";
export const ACTIVITIES_DELETE_BY_ID = "activities_delete_by_id";
export const ACTIVITIES_SIGN_OUT = "activities_sign_out";

//Users
export const USERS_ADD = "users_add";
export const USERS_DELETE_BY_ID = "users_delete_by_id";
export const USERS_SIGN_OUT = "users_sign_out";
export const USERS_EDIT_BY_ID = "users_edit_by_id";

//Royalties
export const ROYALTIES_ADD = "royalties_add";
export const ROYALTIES_SIGN_OUT = "royalties_sign_out";

//Payouts
export const PAYOUTS_ADD = "payouts_add";
export const PAYOUTS_ADD_AND_DELETE_OTHERS = "payouts_add_and_delete_others";
export const PAYOUTS_SIGN_OUT = "payouts_sign_out";
export const PAYOUTS_DELETE_BY_ID = "payouts_delete_by_id";
export const PAYOUTS_EDIT_BY_ID = "payouts_edit_by_id";

//Uploads
export const UPLOADS_START = "uploads_start";
export const UPLOADS_ADD = "uploads_add";
export const UPLOADS_EDIT = "uploads_edit";
export const UPLOADS_DELETE_BY_ID = "uploads_delete";
export const UPLOADS_SIGN_OUT = "uploads_sign_out";

//Payments
export const PAYMENTS_ADD = "payments_add";
export const PAYMENTS_PAY_UPDATED = "payments_pay_updated";
export const PAYMENTS_PAY_DELETE = "payments_pay_delete";
export const PAYMENTS_DELETE_WITH_ID = "payments_delete_with_id";
export const PAYMENTS_RESET_PAYMENT_INFO = "payments_reset_payment_info";
export const PAYMENTS_SIGN_OUT = "payments_sign_out";
export const PAYMENTS_ADD_AND_GET_ACTUAL = "payments_add_and_get_actual";

// Discounts
export const DISCOUNTS_CREATE = "discounts_create";
export const DISCOUNTS_ACTUAL_UPDATE = "discounts_actual_update";
export const DISCOUNTS_UPDATE_WITH_ID = "discounts_update_with_id";
export const DISCOUNTS_DELETE_BY_ID = "discounts_delete_by_id";
export const DISCOUNTS_SIGN_OUT = "discounts_sign_out";

// QUERIES
export const QUERY_SET = "query_set";
export const QUERY_SIGN_OUT = "query_sign_out";