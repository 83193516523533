import { DISCOUNT_BY_CREDIT_FRIENDS, DISCOUNT_BY_FRIENDS, DISCOUNT_STATUS_ACTIVE, DISCOUNT_STATUS_CLAIMED, discountStateAndNamesList, discountStateColors, discountsStatusToShow, discountsTypesToShow } from "variables/discounts.variables";
import { iconOpenActions, iconOpenClaimers } from "./tables.utils";
import { getDateWithHoursFromTs } from "./timeRelated.utils";
import { Typography } from '@mui/material';
import CopyableEditText from "components/TextField/CopyableEditText";
import { discountCreditAtTop, discountsCreateCreditByFriendsText, discountsCreateCreditWhenOneIsActive, errorSubtitle, errorTitle, incompatibleSubtitle, incompatibleTitle, invititationInRenewSubtitle, invititationInRenewTitle, noMoreExistanceSubtitle, noMoreExistanceTitle, notActiveSubtitle, notActiveTitle, notFoundSubitle, notFoundTitle } from "./textToShow.utils";

export const getQueryDiscounts = (discounts, searchParams, filterParams, isAdminOrUserId) => {
  let discountsResult = discounts;
  discountsResult = discountsResult.filter(discount => discount.name !== "");

  if (isAdminOrUserId !== 'admin')
    discountsResult = discountsResult.filter(discount => discount.ownerId === isAdminOrUserId);

  if (searchParams.field !== 'none')
    discountsResult = discounts.filter(discount => searchParams.value === discount[`${searchParams.field}`]);

  if (filterParams.value !== 'all')
    discountsResult = discountsResult.filter(discount => discount[`${filterParams.field}`] === filterParams.value);

  return discountsResult;
}

export const sortDiscountsByField = (discounts, field, ownerId) => {
  return discounts.sort((uA, uB) => {
    if (uA[field] > uB[field]) return - 1;
    else return 1
  });
}

export const getDiscountStatusToShow = (sx, discountStatusCode) => {
  return <Typography sx={sx} >{discountsStatusToShow[discountStatusCode] || "SIN ESTADO"}</Typography>
}

export const textCopyable = text => {
  return <CopyableEditText textToCopy={text} color={'grey'} jsxElementText={text} />
}

export const getDiscountsPropsForDataTableUser = (discounts, setOpenShowClaimers) => {
  let discountsPropsToTable = [];
  discounts.forEach(discount => {
    const statusInfoStyle = { color: discountStateColors[discount.status], fontSize: "1.1em", fontWeight: 600 };
    discountsPropsToTable.push([
      textCopyable(discount.name),
      discountsTypesToShow[discount.type],
      discount.amountOfEqualDiscounts,
      discount.cantUsed,
      discount.percentageOrAmount === "percentage" ? "%" : discount.currency,
      discount.percentageOrAmount === "percentage" ? discount.percentage : discount.amount,
      getDateWithHoursFromTs(discount.createdTS),
      iconOpenClaimers(discount.id, 'discountId', setOpenShowClaimers),
      getDiscountStatusToShow(statusInfoStyle, discount.status),
    ]);
  });
  return discountsPropsToTable;
}
export const getDiscountsPropsForDataTableAdmin = (discounts, setOpenDiscountActionsDialog) => {
  let discountsPropsToTable = [];
  discounts.forEach(discount => {
    const statusInfoStyle = { color: discountStateColors[discount.status], fontSize: "1.1em", fontWeight: 600 };
    discountsPropsToTable.push([
      iconOpenActions(discount.id, 'discountId', setOpenDiscountActionsDialog),
      textCopyable(discount.name),
      discountsTypesToShow[discount.type],
      discount.amountOfEqualDiscounts,
      discount.cantUsed,
      <b style={{ fontWeight: 600 }}>{discount.percentageOrAmount === "percentage" ? "%" : discount.currency}</b>,
      <b style={{ fontWeight: 600 }}>{discount.percentageOrAmount === "percentage" ? discount.percentage : discount.amount}</b>,
      discount.ownerEmail || "-",
      getDateWithHoursFromTs(discount.createdTS),
      getDiscountStatusToShow(statusInfoStyle, discount.status),
      getDateWithHoursFromTs(discount.lastUpdateTS),
    ]);
  });
  return discountsPropsToTable;
}

export const getDiscountById = (discounts, discountId) => {
  let discountFounded = discounts.find(discount => discount.id === discountId);
  if (!discountFounded) return {};
  else return discountFounded;
}

export const getDiscountStatusAndNameByStatusId = statusId => {
  const statusAndName = discountStateAndNamesList.find(statusAndName => statusAndName.id === statusId);
  return statusAndName || "";
}

export const discountCanBeDeleted = discount => {
  let wasAsigned = Boolean(discount.ownerEmail) || Boolean(discount.claimersEmail);
  let wasUsed = discount.status === DISCOUNT_STATUS_CLAIMED;
  if (wasUsed || wasAsigned) return false;
  else return true;
}

export const hasInviteFriendsDiscount = (discounts, ownerId) => {
  return Boolean(discounts.find(discount => (discount.ownerId === ownerId && discount.type === DISCOUNT_BY_FRIENDS)));
}

export const getInviteFriendsDiscount = (discounts, ownerId) => {
  return discounts.find(discount => (discount.ownerId === ownerId) && (discount.type === DISCOUNT_BY_FRIENDS)) || "";
}

export const getCreditFriendsDiscountActive = (discounts, ownerId) => {
  return discounts.find(discount => (discount.ownerId === ownerId) &&
    (discount.status === DISCOUNT_STATUS_ACTIVE) &&
    (discount.type === DISCOUNT_BY_CREDIT_FRIENDS)) || "";
}

// Acá podria chequear si al fecha ya caduco cuando le pongamos vencimiento.
export const discountaIsAvailable = (discount, userId, isRenew) => {
  if (discount.status !== DISCOUNT_STATUS_ACTIVE) return "NOT_ACTIVE";
  if (discount.amountOfEqualDiscounts <= discount.cantUsed) return "NO_MORE_EXISTANCE";
  if (discount.type === DISCOUNT_BY_FRIENDS && discount.ownerId === userId) return "OWNER_AND_FRIEND";
  if (discount.type === DISCOUNT_BY_FRIENDS && isRenew) return "USING_FRIEND_WITH_RENEW";
  return "AVAILABLE";
}

export const getCreditAvailableFromFriendsDiscount = discount => {
  if (discount.type !== DISCOUNT_BY_FRIENDS) return 0;
  return (discount.cantUsed * discount.percentage) - discount.usedAsFriendsCredit;
}

export const getDiscountCreditText = (discounts, userId) => {
  const inviteFriendsDiscount = getInviteFriendsDiscount(discounts, userId);
  const creditFriendsDiscount = getCreditFriendsDiscountActive(discounts, userId);
  let creditAvailable = getCreditAvailableFromFriendsDiscount(inviteFriendsDiscount);
  if (creditFriendsDiscount.percentage === 100) return discountCreditAtTop;
  if (creditFriendsDiscount.percentage < 100 && creditAvailable > 0) return discountsCreateCreditWhenOneIsActive;
  return discountsCreateCreditByFriendsText;
}

const searchDiscountsStateToTexts = {
  NOT_ACTIVE: { title: notActiveTitle, subtitle: notActiveSubtitle },
  NO_MORE_EXISTANCE: { title: noMoreExistanceTitle, subtitle: noMoreExistanceSubtitle },
  OWNER_AND_FRIEND: { title: incompatibleTitle, subtitle: incompatibleSubtitle },
  USING_FRIEND_WITH_RENEW: { title: invititationInRenewTitle, subtitle: invititationInRenewSubtitle },
  ERROR: { title: errorTitle, subtitle: errorSubtitle },
  EMPTY: { title: notFoundTitle, subtitle: notFoundSubitle }
}

export const getInfoTexts = searchDiscountState => {
  return searchDiscountsStateToTexts[searchDiscountState] || { title: "Error", subtitle: ["Se produjo un error, por favor contactar con soporte."] };
}