export const firebaseConfig = {
  apiKey: "AIzaSyBuh7X03uyflFDPoEBOJGJQZ8ZAeOijk_M",
  authDomain: "laflota-dashboard.firebaseapp.com",
  projectId: "laflota-dashboard",
  storageBucket: "laflota-dashboard.appspot.com",
  messagingSenderId: "153347616213",
  appId: "1:153347616213:web:a06fba945efac9938c615c",
  measurementId: "G-HKV1N5GZMB"
};

export const firebaseConfigLaFlotaDev = {
  apiKey: "AIzaSyBx8_YFUQZBJbVB98FoO7lc3yX2x92P4MI",
  authDomain: "laflota-dev.firebaseapp.com",
  databaseURL: "https://laflota-dev-default-rtdb.firebaseio.com",
  projectId: "laflota-dev",
  storageBucket: "laflota-dev.appspot.com",
  messagingSenderId: "370927626912",
  appId: "1:370927626912:web:7bae412fdbe8ab92f2f7c5",
  measurementId: "G-7RPE49DFMW"
}

