import { Skeleton } from '@mui/material';
import { truncateFloat, formatAllNumber } from 'utils';
import { dspReducer, reduceGroupByField, sortAccountingRows, sumEqualDSPNames } from 'utils/royalties.utils';
import { formatThousandsPoint } from '../utils';

const stringReducer = string => {
  return string.length > 143
    ? string.slice(0, 40) + '...'
    : string;
}

export const getRoyaltyHeadersForUser = [
  { name: "Mes", width: "5%" }, { name: "Artista", width: "13%" },
  { name: "DSP", width: "7%" }, { name: "Track", width: "13%" }, { name: "Lanzamiento", width: "13%" }, { name: "ISRC", width: "5%" },
  { name: "UPC", width: "5%" }, { name: "Regalías", width: "5%" }, { name: "Moneda", width: "5%" }, { name: "Territorio", width: "5%" },
  { name: "Usuario", width: "5%" }, { name: "Tipo de venta", width: "9%" }, { name: "Cantidad", width: "5%" },
  { name: "Stream Id", width: "5%" }
]

export const groupByNameToId = name => {
  let nameToIdReducer = {
    "DSP's": 'dsp',
    'Mes del Reporte': 'reportedMonth',
    'Territorios': 'territory',
    'Artistas': 'releaseArtist',
    'Tracks': 'assetTitle',
    'Lanzamientos': 'releaseTitle',
  }
  return nameToIdReducer[name] || "dsp";
}

export const getAccountingHeadersForUser = groupByProp => [
  { name: groupByProp.name, width: "25%" }, { name: "Streams", width: "15%" },
  { name: "Descargas", width: "10%" }, { name: "Regalías (USD)", width: "25%" },
]

export const accountingGroupByValues = ["DSP's", "Mes del Reporte", "Artistas", "Tracks", "Territorios", "Lanzamientos"];

export const createRoyaltyRowForUser = royaltyFromDB => {
  return {
    saleStartDate: royaltyFromDB.saleStartDate.slice(0, 7),
    releaseArtist: stringReducer(royaltyFromDB.releaseArtist),
    dsp: dspReducer(royaltyFromDB.dsp),
    assetTitle: stringReducer(royaltyFromDB.assetTitle),
    releaseTitle: stringReducer(royaltyFromDB.releaseTitle),
    isrc: royaltyFromDB.isrc,
    upc: royaltyFromDB.upc,
    netRevenue: truncateFloat(royaltyFromDB.ourNetRevenue, 9, '.'),
    netRevenueCurrency: royaltyFromDB.ourNetRevenueCurrency,
    territory: royaltyFromDB.territory,
    saleUserType: royaltyFromDB.saleUserType ? royaltyFromDB.saleUserType : "YT User",
    saleType: royaltyFromDB.saleType === "Video Stream (Audio + Video)" ? "Video Stream (Video + Audio)" : royaltyFromDB.saleType,
    assetQuantity: royaltyFromDB.assetQuantity,
    id: royaltyFromDB.saleId,
  }
}

export const createAccountingRowForUser = (accountingFromDB, groupByProp) => {
  return {
    [groupByProp.name]: reduceGroupByField(groupByProp.id, accountingFromDB[groupByProp.id]),
    streams: formatThousandsPoint(accountingFromDB.streams),
    downloads: formatThousandsPoint(accountingFromDB.downloads),
    netRevenueUSD: 'USD ' + formatAllNumber(accountingFromDB.revenuesUSD, 2, '.'),
  }
}

export const getAccountingRows = (accRows, groupBy, maxRows, orderByProp) => {
  if (!Array.isArray(accRows) || accRows.length === 0) return [];
  let squashedAccRows = sumEqualDSPNames(accRows, groupBy);
  let sortedAccRows = sortAccountingRows(squashedAccRows, orderByProp);
  return sortedAccRows.map(accountingRow => createAccountingRowForUser(accountingRow, groupBy)).slice(0, maxRows)
}

export const getTotalesAccountingRow = accountingValues => {
  let totals = { dsp: "Totales", netRevenueUSD: 0, streams: 0, downloads: 0 };
  if (!Array.isArray(accountingValues) || accountingValues.length === 0) return totals;
  accountingValues.forEach(accVal => {
    totals.streams += dspReducer(accVal.dsp) === "iTunes" ? 0 : accVal.streams;
    totals.downloads += dspReducer(accVal.dsp) === "iTunes" ? accVal.downloads + accVal.streams : accVal.downloads;
    totals.netRevenueUSD += accVal.revenuesUSD;
  })

  return {
    dsp: <b>{totals.dsp}</b>,
    streams: <b>{formatThousandsPoint(totals.streams)}</b>,
    downloads: <b>{formatThousandsPoint(totals.downloads)}</b>,
    netRevenueUSD: <b>{'USD ' + formatAllNumber(totals.netRevenueUSD, 2, '.')}</b>
  }
}