import { OUR_DSP_DELIVER, OUR_DSP_FIRST_DELIVER, OUR_DSP_NONE_ACTION, OUR_DSP_REDELIVER, OUR_DSP_TAKEDOWN } from "variables/varias"

export const colorFromDeliveryDspStateLabel = {
  "Procesando": "rgb(231, 190, 66)",
  "Procesando redelivery": "rgb(135, 162, 14)",
  "Realizar delivery": "rgb(135, 162, 14)",
  "Online": "rgb(93, 109, 15)",
  "Dado de baja": "rgb(170, 3, 3)",
  "Rechazada": "rgb(111, 2, 2)",
  "Esperando revisión": "rgb(231, 190, 66)",
  "Esperando nueva revisión": "rgb(231, 190, 66)",
  "Rechazada, necesita corrección": "#c90",
}

export const colorFromDeliveryDspActionLabel = {
  "aplicar cambios": "rgb(135, 162, 14)",
  "realizar delivery": "rgb(135, 162, 14)",
  "no aplicar cambios": "rgb(231, 190, 66)",
  "dar de baja": "rgb(170, 3, 3)",
  "reintentar delivery": "rgb(135, 162, 14)",
  "no se pueden realizar acciones": "rgb(231, 190, 66)",
  "esperar": "rgb(231, 190, 66)",
  "en proceso de dar de baja, esperar": "rgb(170, 3, 3)",
  "volver a poner Online": "rgb(93, 109, 15)"
}

export const newDictLabelsActionToNewDspState = {
  //APPLE AND OTHERS
  "Online: aplicar cambios": OUR_DSP_REDELIVER,
  "Online": OUR_DSP_NONE_ACTION,
  "Online: no aplicar cambios": OUR_DSP_NONE_ACTION,
  "Online: dar de baja": OUR_DSP_TAKEDOWN,
  //
  "Rechazada: aplicar cambios": OUR_DSP_REDELIVER,
  "Rechazada: no aplicar cambios": OUR_DSP_NONE_ACTION,
  "Rechazada: reintentar delivery": OUR_DSP_REDELIVER,
  "Rechazada": OUR_DSP_NONE_ACTION,
  "Procesando: no se pueden realizar acciones": OUR_DSP_NONE_ACTION,
  //APPLE STUFF (EN APPLE DEBEMOS MANEJAR NOSOTROS LOS DELIVERS, todos los cambios los aplicamos como FIRST_DELIVER)
  "Esperando revisión: aplicar cambios": OUR_DSP_FIRST_DELIVER,
  "Esperando revisión": OUR_DSP_NONE_ACTION,
  "Esperando revisión: no aplicar cambios": OUR_DSP_NONE_ACTION,
  "Esperando revisión: dar de baja": OUR_DSP_TAKEDOWN,
  "Esperando nueva revisión: aplicar cambios": OUR_DSP_FIRST_DELIVER,
  "Esperando nueva revisión": OUR_DSP_NONE_ACTION,
  "Esperando nueva revisión: no aplicar cambios": OUR_DSP_NONE_ACTION,
  "Esperando nueva revisión: dar de baja": OUR_DSP_TAKEDOWN,
  "Rechazada, necesita corrección: aplicar cambios": OUR_DSP_FIRST_DELIVER,
  "Rechazada, necesita corrección": OUR_DSP_NONE_ACTION,
  "Rechazada, necesita corrección: no aplicar cambios": OUR_DSP_NONE_ACTION,
  "Rechazada, necesita corrección: dar de baja": OUR_DSP_TAKEDOWN,
  //OTHER STUFF
  "Procensando redelivery: esperar": OUR_DSP_NONE_ACTION,
  "Online: en proceso de dar de baja, esperar": OUR_DSP_NONE_ACTION,
  "Dado de baja: no aplicar cambios": OUR_DSP_NONE_ACTION,
  "Dado de baja: volver a poner Online": OUR_DSP_DELIVER,
  "Dado de baja": OUR_DSP_NONE_ACTION,
  "Realizar delivery": OUR_DSP_FIRST_DELIVER
}