import * as ReducerTypes from 'redux/actions/Types';
import * as FirestoreServices from 'services/FirestoreServices.js';
import * as BackendCommunication from 'services/BackendCommunication.js';
import { toWithOutError } from 'utils';
import { albumsAddStore } from 'redux/actions/AlbumsActions';
import { artistsAddStore } from './ArtistsActions';
import { artistEditOneFieldRedux, getArtistByFieldRedux } from 'redux/actions/ArtistsActions';
import { ARTIST_SUSPEND_INFRACTION, ARTIST_SUSPEND_NO_PAY } from 'variables/artist.variables';
import { basicSuccess } from './NotificationsHandlerActions';
import { USER_PENDING_PAY, USER_SUSPEND_NO_PAY } from 'variables/user.variables';
import { albumsDeleteOrTakenDownRedux, getAlbumsByFieldRedux } from './AlbumsActions';
import { albumInfractionsStates } from 'variables/varias';

export const usersAddStore = users => {
  return { type: ReducerTypes.USERS_ADD, payload: users }
}

export const usersSignOut = () => {
  return { type: ReducerTypes.USERS_SIGN_OUT }
}

export const userEditById = ({ id, data }) => {
  return { type: ReducerTypes.USERS_EDIT_BY_ID, payload: { id, data } }
}

export const createUserRedux = userData => async dispatch => {
  dispatch(usersAddStore([userData]))
}

export const getSearchedUserRedux = email => async dispatch => {
  let userSearched = await FirestoreServices.getUserDataByEmailInFS(email, dispatch);
  if (userSearched === "EMPTY") return "EMPTY";
  if (userSearched?.email === email) dispatch(usersAddStore([userSearched]));
  else return "ERROR";
  return userSearched;
}

export const getUsersByFieldRedux = (field, fieldValues, limit) => async dispatch => {
  let usersSearched = await FirestoreServices.getElementsByField("users", field, fieldValues, dispatch, limit);
  if (usersSearched === "EMPTY") return "EMPTY";
  if (usersSearched !== "ERROR") dispatch(usersAddStore(usersSearched));
  else return "ERROR";
  return usersSearched;
}

export const getUsersByFieldsRedux = (fields, fieldValues, limit) => async dispatch => {
  let usersSearched = await FirestoreServices.getElementsByFields("users", fields, fieldValues, dispatch, limit);
  if (usersSearched === "EMPTY") return "EMPTY";
  if (usersSearched !== "ERROR") dispatch(usersAddStore(usersSearched));
  else return "ERROR";
  return usersSearched;
}

export const usersGetAlbumsOfUserByIdRedux = userId => async dispatch => {
  let albumsSearched = await FirestoreServices.getElements(userId, "albums", dispatch, 100);
  if (albumsSearched === "ERROR") return albumsSearched;
  if (albumsSearched.length === 0) return "EMPTY_SEARCH";

  dispatch(albumsAddStore(albumsSearched));
  return "SUCCESS";
}

export const usersGetArtistsOfUserByIdRedux = userId => async dispatch => {
  let artistsSearched = await FirestoreServices.getElements(userId, "artists", dispatch, 100);
  if (artistsSearched === "ERROR") return artistsSearched;
  if (artistsSearched.length === 0) return "EMPTY_SEARCH";

  dispatch(artistsAddStore(artistsSearched));
  return "SUCCESS";
}

export const editUserRedux = (oldUserData, newUserData, fromPaymentAction, passwordChanged, oldEmailChanged, showNotification) => async dispatch => {
  let userUpdated = "";
  if (oldEmailChanged) {
    userUpdated = await toWithOutError(BackendCommunication.editUserCredentialsAndElementsFS(oldEmailChanged,
      newUserData.email, newUserData.password, dispatch));
    if (userUpdated === "ERROR") return "ERROR";
  }

  if (passwordChanged && !oldEmailChanged) {
    userUpdated = await toWithOutError(BackendCommunication.editUserDataAndCredentialsFS(newUserData, dispatch));
    if (userUpdated === "ERROR") return "ERROR";
  }

  userUpdated = await toWithOutError(FirestoreServices.editUserDataWithOutCredentials({ id: oldUserData.id, ...newUserData }, fromPaymentAction, dispatch));
  if (userUpdated === "ERROR") return "ERROR";

  dispatch(usersAddStore([{ ...oldUserData, ...newUserData }]));
  showNotification && basicSuccess(`Usuario cancelado: ${newUserData.email}`, 8000)
  return "SUCCESS";
}

// Es probable que el user no este logeado. Debo hacerlo desde el backend
export const userUpdateStatePostMailVerifcation = userId => async dispatch => {
  let newInfo = { userStatus: USER_PENDING_PAY, lastPayUpdateTS: new Date().getTime() };
  let userUpdated = await toWithOutError(BackendCommunication.editNotLoggedUserDataFS(userId, newInfo, dispatch));
  if (userUpdated === "ERROR") return "ERROR";
  return "SUCCESS";
}

export const usersGetOneByIdRedux = (userId, fromFS) => async (dispatch, getState) => {
  let user = {};
  if (!fromFS) {
    user = getState().users.find(user => user.id === userId);
    if (user && user.id) return user;
  }

  //APLICO EL RETRY: VER COMO SE PODRÍA HACER EN LA FUNCION DE FIRESTORE ERROR, PERO ASI AL MENOS FUNCIONA.
  user = await FirestoreServices.getUserDocFS(userId, dispatch);
  if (user === "ERROR") return "ERROR";
  if (user === "RETRY") user = await FirestoreServices.getUserDocFS(userId, dispatch);
  if (user === "ERROR") return "ERROR";
  dispatch(usersAddStore([user.data()]));
  return user.data();
}

export const usersDeleteByIdStore = userId => {
  return {
    type: ReducerTypes.LABEL_DELETE_WITH_ID,
    payload: userId
  };
}

export const usersUnsubscribeRedux = user => async dispatch => {
  let suspendUserResult = await toWithOutError(dispatch(editUserRedux(user, { userStatus: USER_SUSPEND_NO_PAY }, true, false, false, true)));
  if (suspendUserResult === "ERROR") return "ERROR";

  const artistsFinded = await dispatch(getArtistByFieldRedux('ownerId', user.id, 50));
  if (artistsFinded === "ERROR" || !Array.isArray(artistsFinded)) return "ERROR";

  let updateAllArtists = artistsFinded.map(async artist => {
    if (artist.state === ARTIST_SUSPEND_INFRACTION) return { status: "SUCCESS", artistId: artist.id };
    const editResult = await toWithOutError(dispatch(artistEditOneFieldRedux(artist, { state: ARTIST_SUSPEND_NO_PAY }, artist.ownerEmail, false, false)));
    if (editResult === "ERROR") { return { status: "ERROR", artistId: artist.id }; }
    else return { status: "SUCCESS", artistId: artist.id };
  });

  const albumsFinded = await dispatch(getAlbumsByFieldRedux('ownerId', user.id, 200));
  if (albumsFinded === "ERROR" || !Array.isArray(albumsFinded)) return "ERROR";

  let takeDownAlbums = albumsFinded.map(async album => {
    if (albumInfractionsStates.includes(album.state)) return { status: "SUCCESS", albumId: album.id };
    // quiero darlo de baja, por eso el TRUE, no eliminarlo. Y no se le cambió el estado todavía. por eso el "".
    let possibleRoyalties = true; let stateWasChangedAlready = ""; let showNotification = true;
    const takedownResult = await toWithOutError(dispatch(albumsDeleteOrTakenDownRedux(album, possibleRoyalties, stateWasChangedAlready, showNotification)));
    if (takedownResult === "ERROR") { return { status: "ERROR", albumId: album.id }; }
    else return { status: "SUCCESS", albumId: album.id };
  })

  const resultsSuspendArtists = await Promise.all(updateAllArtists);
  const resultTakedownAlbums = await Promise.all(takeDownAlbums);

  return [...resultsSuspendArtists, resultTakedownAlbums];
}