import { v4 as uuidv4 } from 'uuid';
import { peopleRoles } from 'variables/collaborators.variables';

export const deleteWeirdCharacters = text => {
  return text.normalize('NFD')
    .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
    .normalize();
}

export const getAllPeopleToCreateFromUploadingTracks = uploadedTracks => {
  let people = [];
  uploadedTracks.forEach(track => {
    track.collaborators.forEach(coll => {
      if (!coll.personExistsInFuga && !people.map(p => p.name).includes(coll.name)) people.push({ name: coll.name });
    })
  });
  return people;
}

const getPersonIdFromPeople = (peopleWithId, personName) => {
  let peopleWiIdNotNull = peopleWithId.filter(person => person !== null && person.id !== null);
  let result = peopleWiIdNotNull.find(person =>
    deleteWeirdCharacters(person.name.toLowerCase()) === deleteWeirdCharacters(personName.toLowerCase()));
  if (result && result.id !== "") return result.id;
}

export const getAllCollaboratorsToAttachFromUploadingTracks = (uploadedTracks, peopleWithId, ownerId, ownerEmail) => {
  let collaboratorsForEachTrack = [];
  uploadedTracks.forEach(track => {
    track.collaborators.forEach(coll => {
      if (coll.name !== "") {
        coll.roles.forEach(collRol => {
          let collToAdd = {
            trackFugaId: track.fugaId, id: uuidv4(), added: false, ownerEmail, ownerId,
            name: coll.name, role: getRoleIdByName(collRol), person: getPersonIdFromPeople(peopleWithId, coll.name)
          }
          // Chequeo que no se agregaron mismos colaboradores con mismo rol a un Track.
          if (!collaboratorsForEachTrack.find(collAdded => (collAdded.trackFugaId === collToAdd.trackFugaId)
            && (collAdded.name === collToAdd.name) && (collAdded.role === collToAdd.role)))
            collaboratorsForEachTrack.push(collToAdd);
        })
      }
    })
  });
  return collaboratorsForEachTrack;
}

export const getAllCollaboratorsBasicInfoFromTracks = uploadedTracks => {
  let collaboratorsForEachTrack = [];
  uploadedTracks.forEach((track, trackIndex) => {
    track.collaborators.forEach(coll => {
      if (coll.name !== "") {
        coll.roles.forEach(collRol => {
          let collToShow = { name: coll.name, role: getRoleIdByName(collRol), key: track.id + coll.name + getRoleIdByName(collRol), trackNumber: trackIndex + 1 };
          if (!collaboratorsForEachTrack.find(collAdded => collToShow.key === collAdded.key))
            collaboratorsForEachTrack.push(collToShow);
        })
      }
    })
  });
  return collaboratorsForEachTrack;
}

export const mapFugaCollaboratorsToOurs = fugaColls => {
  let collaborators = [];
  fugaColls.forEach(fugaColl => {
    let result = { name: fugaColl.person.name, roles: [getRoleNameById(fugaColl.role.id)] };
    collaborators.push(result);
  })
  return collaborators;
}

export const getRolesNamesById = roles => {
  return roles.map(rolId => peopleRoles.find(rolObject => rolObject.id === rolId)?.name || "")
}

export const getRoleNameById = roleId => {
  return peopleRoles.find(rolObject => rolObject.id === roleId)?.name || "";
}

export const getRoleIdByName = roleName => {
  return peopleRoles.find(rolObject => rolObject.name === roleName)?.id || "";
}

export const invalidCollaboratorsNames = colls => {
  let names = colls.map(coll => coll.name);
  if (names.some(name => name.split(' ').length < 2)) return true;
  return false;
}