import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import AnalyticEcommerce from 'components/Card/AnalyticEcommerce';
import { useDispatch } from 'react-redux';
import { Paper } from '../../../node_modules/@mui/material/index';
import { defaultCountAlbums } from 'services/AlbumStats';
import { querySetStore } from 'redux/actions/QueryActions';
import { getArtistsBasicCounts, getCustomQueryArtists } from 'services/ArtistStats';
import { formatThousandsPoint } from 'utils';

const ArtistsCountStats = () => {

  const dispatch = useDispatch();

  const [artistsCounts, setArtistsCounts] = useState(defaultCountAlbums);

  useEffect(() => {
    const getArtistCounts = async () => {
      let countsArtists = await getArtistsBasicCounts(dispatch);
      setArtistsCounts(countsArtists);
      return countsArtists;
    }
    getArtistCounts();
  }, []);

  const handleGetCustomQuerys = async customQueryName => {
    let resultQuery = await getCustomQueryArtists(customQueryName, 30, dispatch);
    dispatch(querySetStore({ name: customQueryName, limit: 30, collection: 'artists' }));
  }

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('all')}>
          <AnalyticEcommerce title="Totales" count={formatThousandsPoint(artistsCounts.totalArtists)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('active')}>
          <AnalyticEcommerce title="Activos" count={formatThousandsPoint(artistsCounts.activeArtists)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('suspend-no-pay')}>
          <AnalyticEcommerce title="Suspendidos ($)" count={formatThousandsPoint(artistsCounts.moneySuspended)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('infractions')}>
          <AnalyticEcommerce title="Suspendidos (©)" count={formatThousandsPoint(artistsCounts.withInfractions)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('oac-related')}>
          <AnalyticEcommerce title="OACs" count={formatThousandsPoint(artistsCounts.withOacRequests)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('inactive')}>
          <AnalyticEcommerce title="Inactivos" count={formatThousandsPoint(artistsCounts.inactiveArtists)} />
        </Paper>
      </Grid>

      <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

    </Grid>
  );
}

export default ArtistsCountStats;