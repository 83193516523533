import * as ReducerTypes from 'redux/actions/Types';
import { to } from 'utils';
import { reauthenticateFromAuth } from './AuthActions';
// import firebaseApp from "../../firebaseConfig/firebase";

// import {
//   getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword,
//   signOut
// } from "firebase/auth";

// const auth = getAuth(firebaseApp);

const checkIfIsNetworkError = error => {
  if (error.message === "Network Error") return "Error de conexión, reintentaremos una vez más."
  else return error.message;
}

const handleError = error => {
  if (!error.response) return { msg: checkIfIsNetworkError(error), error };
  if (!error.response.data) return { msg: "Error inesperado", error };
  return { msg: error.response.data.errorMsgToFrontEnd, error: error.response.data }
}

export const createBackendError = errorFromBackend => {
  let { msg, error } = handleError(errorFromBackend);
  return {
    type: ReducerTypes.ERROR_FROM_BACKEND,
    payload: { errorMessage: msg, error, isFrom: "backend" }
  };
}

export const createFireStoreError = (errorMessage, error, func) => async (dispatch, getState) => {
  let resultReauth = "ERROR";
  if (error && error.code === "permission-denied") {
    let user = getState().auth;
    [resultReauth] = await to(reauthenticateFromAuth(user));
  }
  if (error && (error.code !== "permission-denied" || resultReauth === "ERROR_REAUTH")) {
    dispatch({
      type: ReducerTypes.ERROR_FROM_FIRESTORE,
      payload: { errorMessage, error, isFrom: "firestore" }
    });
  }
  return "RETRY";
}

export const basicError = (errorMessage) => {
  return {
    type: ReducerTypes.ERROR_BASIC_MSG,
    paylaod: { errorMessage }
  }
}

export const cleanError = () => {
  return {
    type: ReducerTypes.ERROR_CLEAN_LAST
  }
};

//=============================================================================SUCCESS============================================================\\

export const basicSuccess = (successMessage, hide) => {
  return {
    type: ReducerTypes.SUCCESS_NOTIFICATION,
    payload: { successMessage, error: false, success: true, hide }
  }
};

export const cleanSuccess = () => {
  return {
    type: ReducerTypes.SUCCESS_CLEAN_LAST
  }
}