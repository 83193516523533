import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export const monthsWithIdAndName = [
  { id: 1, name: 'Enero' },
  { id: 2, name: 'Febrero' },
  { id: 3, name: 'Marzo' },
  { id: 4, name: 'Abril' },
  { id: 5, name: 'Mayo' },
  { id: 6, name: 'Junio' },
  { id: 7, name: 'Julio' },
  { id: 8, name: 'Agosto' },
  { id: 9, name: 'Septiembre' },
  { id: 10, name: 'Octubre' },
  { id: 11, name: 'Noviembre' },
  { id: 12, name: 'Diciembre' }
]

export const monthsSpanish = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
  'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

export const getLocalHoursAndMinutes = utcTimeString => {
  let hours = utcTimeString.slice(0, 2);
  let minutes = utcTimeString.slice(3, 5);
  const utcDate = dayjs.utc();
  const time = utcDate.set('hour', hours).set('minute', minutes);
  return time.utc().local().format("HH:mm")
}

export const getArrayYears = (olderYear, lastYear) => {
  return Array.from({ length: (lastYear + 1) - olderYear }).map((_, index) => olderYear + index).reverse();
}

export const getLocalDateString = stringDateUS => {
  let stringDateLocal = stringDateUS.slice(0, 10);
  stringDateLocal = stringDateLocal.split("-");
  return `${stringDateLocal[2]}/${stringDateLocal[1]}/${stringDateLocal[0]}`
}

export const getActualYear = () => {
  return new Date().getYear() + 1900;
}

export const getNextYearDate = () => {
  let date = new Date()
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getYear() + 1900 + 1;
  return `${day} de ${monthsSpanish[month]}, ${year}`;
}

export const getTextDate = dateTS => {
  let date = new Date(dateTS)
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getYear() + 1900;
  return `${day} de ${monthsSpanish[month]}, ${year}`;
}

export const getNextYearDateFromTsToTs = dateTS => {
  if (!dateTS || dateTS === 0) {
    let today = new Date();
    today.setFullYear(today.getFullYear() + 1);
    return today.getTime();
  }
  const dateOriginal = new Date(dateTS);
  dateOriginal.setFullYear(dateOriginal.getFullYear() + 1);
  return dateOriginal.getTime();
}

export const unaHoraEnMilisegundos = 3600000;
export const oneDayInMS = 24 * unaHoraEnMilisegundos;
export const getCantDaysInMS = cantDays => oneDayInMS * cantDays;

export const secondsToMmSs = timeInSeconds => {
  let minutes = Math.floor(timeInSeconds / 60);
  let seconds = timeInSeconds - minutes * 60;
  return `${minutes}:${seconds > 9 ? seconds : "0" + seconds}`;
}

export const getDateWithHoursFromTs = timestamp => {
  if (!Number.isInteger(timestamp) || timestamp === 0) return "-"
  // Crear un objeto Date usando el timestamp
  let fecha = new Date(timestamp); // Multiplicar por 1000 si el timestamp está en segundos, no en milisegundos

  // Obtener los componentes de fecha y hora
  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por lo tanto se suma 1
  let año = fecha.getFullYear();
  let hora = fecha.getHours();
  let minutos = fecha.getMinutes();

  // Formatear los componentes de fecha y hora con ceros iniciales si es necesario
  let diaFormateado = dia < 10 ? "0" + dia : dia;
  let mesFormateado = mes < 10 ? "0" + mes : mes;
  let añoFormateado = año.toString().slice(-2); // Obtener los últimos dos dígitos del año
  let horaFormateada = hora < 10 ? "0" + hora : hora;
  let minutosFormateados = minutos < 10 ? "0" + minutos : minutos;

  // Construir la cadena de fecha en el formato deseado: DD/MM/YY HH:MM
  let fechaString = diaFormateado + "/" + mesFormateado + "/" + añoFormateado + " " + horaFormateada + ":" + minutosFormateados;
  return fechaString;
}

export const differenceInDaysFromTwoTS = (dateTsOne, dateTsTwo) => {
  if (!dateTsOne || !dateTsTwo) return "ERROR";
  const timeDifference = dateTsOne - dateTsTwo;
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
}

export const formatDateWithOffsetAsDayJS = date => {
  // Obtener la fecha en formato ISO sin el offset de la zona horaria
  const datePart = date.toISOString().split('.')[0];

  // Calcular el offset de la zona horaria en horas y minutos
  const offsetMinutes = date.getTimezoneOffset();
  const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
  const offsetMins = Math.abs(offsetMinutes % 60);
  const offsetSign = offsetMinutes > 0 ? "-" : "+";

  // Formatear el offset con el signo correcto
  const offsetFormatted = `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMins).padStart(2, '0')}`;

  // Concatenar la fecha en formato ISO con el offset de la zona horaria
  return `${datePart}${offsetFormatted}`;
};

export const formatDayJsStringDate = dayJsDateString => {
  const date = new Date(dayJsDateString);
  const day = String(date.getDate()).padStart(2, '0'); // Asegura que tenga 2 dígitos
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11, por eso se suma 1
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
