import React, { useState, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button } from '@mui/material';

import TextFieldWithInfo from '../../components/TextField/TextFieldWithInfo';
import { Save } from '@mui/icons-material';
import useForm from '../../customHooks/useForm';
import ProgressButton from 'components/CustomButtons/ProgressButton';
import SimpleReactValidator from 'simple-react-validator';
import { useForceUpdate } from '../../utils';
import { toWithOutError } from 'utils';
import { useDispatch } from 'react-redux';
import { defaultDiscountFormData } from "factory/discounts.factory";
import { discountsCreateRedux, discountsUpdateWithId } from "redux/actions/DiscountsActions";
import { discountTypesAndNamesList, discountsStringsShowToId } from "variables/discounts.variables";
import { v4 as uuidv4 } from 'uuid';

const DiscountsCreateDialog = ({ isOpen, handleCloseDialog }) => {

  const dispatch = useDispatch()
  const validator = useRef(new SimpleReactValidator());
  const forceUpdate = useForceUpdate();

  const editing = isOpen.action === 'edit';

  const [openLoader, setOpenLoader] = useState(false);
  const [buttonState, setButtonState] = useState("none");
  const [buttonText, setButtonText] = useState("Finalizar");

  let defaultData = defaultDiscountFormData;

  const [formData, setForm] = useForm(defaultData);
  let { ownerEmail, percentageOrAmount, amount,
    currency, percentage, name, amountOfEqualDiscounts, type } = formData;

  const allFieldsValidCreateDiscount = () => {
    let fieldsErrors = validator.current.fields;
    if (fieldsErrors.name && fieldsErrors.ownerEmail && fieldsErrors.type && (fieldsErrors.amount || fieldsErrors.percentage)) {
      createOrEditDiscount();
    }
    else {
      validator.current.showMessages();
      forceUpdate();
    }
  }

  const createOrEditDiscount = async () => {
    setOpenLoader(true);
    let result = "ERROR";

    result = await toWithOutError(dispatch(editing
      ? discountsUpdateWithId()
      : discountsCreateRedux({
        ...formData, percentage: Number(percentage), type: discountsStringsShowToId[formData.type], id: uuidv4(), createdTS: new Date().getTime(),
        lastUpdateTS: new Date().getTime(), percentageOrAmount: formData.percentageOrAmount === "Porcentaje" ? "percentage" : "amount",
      })));

    setOpenLoader(false);
    if (result === "SUCCESS") {
      setButtonState("success");
      handleCloseCreateDiscount();
    }
    else {
      setButtonState("error");
      setButtonText("Error");
    }
  }

  const handleCloseCreateDiscount = () => {
    setButtonState("none");
    handleCloseDialog();
  }

  let title = isOpen.action === 'add' ? "Agregar Cupón" : "Editar Cupón";

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      id="collaborative-dialog"
      open={isOpen.open}
      onClose={handleCloseCreateDiscount}>

      <DialogTitle sx={{ fontSize: '2em', fontWeigth: 500, textAlign: 'center' }} id="add-artist-dialog">
        {title}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1} sx={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <p>Acá se pueden crear cupones que tengan o no un dueño, por ejemplo: </p>
            <ul style={{ textAlign: 'left' }}>
              <li>
                El cupón tendrá un dueño predefinido igual al benificiario, si es creado sólo para un usuario en particular,
                sea por Canje de Regalías, regalo, sorteo, etc.
              </li>
              <li>
                Puede pasar que un cupón no tenga dueño ni beneficiario en un principio.
              </li>
              <li>
                En el caso de que se genere un cupón por alguien que invitó a un usuario, el dueño es el que invitó a ese usuario y el
                beneficiario será aquel que lo use.
              </li>
            </ul>
            <p style={{ textAlign: 'left' }}>El nombre del Cupón será el que el usuario deberá usar para obtener el beneficio.</p>
          </Grid>

          <Grid container item xs={12}>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name="percentageOrAmount"
                fullWidth
                required
                label="Porcentaje o Monto Fijo"
                value={percentageOrAmount}
                onChange={setForm}
                select
                selectItems={[{ id: "percentage", name: "Porcentaje" }, { id: "amount", name: "Monto Fijo" }]}
                selectKeyField="id"
                selectValueField="name"
              />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name="currency"
                fullWidth
                required
                label="Moneda del Monto Fijo"
                value={currency}
                onChange={setForm}
                select
                selectItems={[{ id: "ARS", name: "ARS" }, { id: "USD", name: "USD" }]}
                selectKeyField="id"
                selectValueField="name"
              />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name={percentageOrAmount === "Porcentaje" ? "percentage" : "amount"}
                fullWidth
                required
                label={percentageOrAmount === "Porcentaje" ? "Porcentaje de Descuento" : "Monto fijo de Descuento"}
                value={percentageOrAmount === "Porcentaje" ? percentage : amount}
                onChange={setForm}
                validatorProps={{
                  restrictions: percentageOrAmount === "Porcentaje" ? 'required|max:101|min:0' : 'required|min:0',
                  message: "Selecciona el monto o porcentage de descuento.", validator: validator
                }} />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name="name"
                fullWidth
                required
                label="Nombre del Cupón"
                value={name}
                onChange={setForm}
                validatorProps={{
                  restrictions: 'required|max:100|min:0',
                  message: "Ingresa el nombre del descuento.", validator: validator
                }}
              />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name="amountOfEqualDiscounts"
                fullWidth
                required
                label="Cantidad del mismo cupón"
                value={amountOfEqualDiscounts}
                onChange={setForm}
              />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                name="type"
                fullWidth
                required
                label="Tipo de Cupón"
                value={type}
                onChange={setForm}
                select
                selectItems={discountTypesAndNamesList}
                selectKeyField="id"
                selectValueField="name"
                validatorProps={{
                  restrictions: 'required|max:100|min:0',
                  message: "Ingresa el tipo del descuento.", validator: validator
                }}
              />
            </Grid>

            <Grid item xs={6} padding={1}>
              <TextFieldWithInfo
                fullWidth
                name="ownerEmail"
                label="Email del Dueño"
                value={ownerEmail}
                onChange={setForm}
                validatorProps={{ restrictions: 'email|max:100', message: "Debes ingresar un email.", validator: validator }} />
            </Grid>

          </Grid>

        </Grid>
      </DialogContent >

      <DialogActions sx={{ overflow: "scroll" }}>
        <ProgressButton textButton={buttonText} loading={openLoader} buttonState={buttonState}
          onClickHandler={allFieldsValidCreateDiscount} noneIcon={<Save sx={{ color: "rgba(255,255,255, 1)" }} />} noFab={false} />
      </DialogActions>

      <DialogActions>
        <Button onClick={handleCloseCreateDiscount}>
          Atras
        </Button>
      </DialogActions>
    </Dialog >
  );
}

export default DiscountsCreateDialog;
