import React, { useState, useEffect } from 'react';
import {
  Typography, List, ListItem, Radio, ListItemText, Grid, FormControlLabel, Tooltip
} from '@mui/material';
import Image from 'mui-image';
import { useDispatch, useSelector } from 'react-redux';
import { paymentsPayUpdated } from 'redux/actions/PaymentsActions';
import {
  SUB_BANK, SUB_MP, SUB_MP_PENDING, SUB_ORDER_NEED_CHECK, SUB_ORDER_NEED_PROOF, SUB_PAYPAL,
  SUB_ORDER_PLAN_SELECTED, mpPayImage, payOptionInChooseReview, subPaymentMethodToString, SUB_ORDER_REJECTED_PROOF
} from 'variables/financial';
import { getPaymentChooseValue, getPaymentJsxDataByMethod, paymentIsFree, paymentStatusIsSuccesfull } from 'utils/payments.utils';
import { getNextRenewDate } from 'utils/users.utils';
import { getArsPrefItem, getUsdPrefItem } from 'factory/payments.factory';
import { formatThousandsPoint } from 'utils';
import useQuery from 'customHooks/useQuery';

const Review = ({ discountIsApplied }) => {

  const params = useQuery();
  const isRenew = params.renew === 'true';

  const dispatch = useDispatch();
  const userData = useSelector(store => store.userData);
  const actualPayment = useSelector(store => store.payments.actualPayment);
  const preferenceData = actualPayment.preference_data;
  const preferenceItemData = preferenceData?.items?.length > 0 ? preferenceData?.items[0] : {};

  const paymentMethod = actualPayment.payment_method;
  const noNeedToPay = paymentIsFree(actualPayment);
  // const discountIsPercentage = actualDiscount.percentageOrAmount === "percentage";

  const getInitialPaymentChoose = () => {
    return paymentMethod
      ? getPaymentChooseValue(paymentMethod)
      : payOptionInChooseReview[0]
  }

  const [payOption, setPayOption] = useState(getInitialPaymentChoose());

  const elems = payOptionInChooseReview;

  // const pendingPay = paymentStatusIsPendingPay(actualPayment.status);
  const proofRejected = actualPayment.status === SUB_ORDER_REJECTED_PROOF;
  const needProof = actualPayment.status === SUB_ORDER_NEED_PROOF;
  const needCheck = actualPayment.status === SUB_ORDER_NEED_CHECK;
  const finishedPay = paymentStatusIsSuccesfull(actualPayment.status);
  const justPlanSelected = actualPayment.status === SUB_ORDER_PLAN_SELECTED;
  const mpPayCancelled = actualPayment.status === SUB_MP_PENDING;
  const discountWasApplied = (finishedPay || needCheck) && (actualPayment.cupon.name !== "" && actualPayment.cupon.name !== "MIGRATED");

  const planDescription = actualPayment.currency === "ARS"
    ? preferenceItemData.description + " | USD " + preferenceItemData.priceOtherCurrency
    : preferenceItemData.description + " | ARS " + preferenceItemData.priceOtherCurrency;

  const handlePayOptionCheck = elem => setPayOption(elem);

  useEffect(() => {
    if (needCheck || needProof || finishedPay || noNeedToPay) return;
    if (payOption.value === 'other-ars')
      dispatch(paymentsPayUpdated({
        payment_method: SUB_BANK, currency: "ARS",
        preference_data: { ...actualPayment.preference_data, items: [getArsPrefItem(preferenceItemData)] }
      }));
    if (payOption.value === 'other-usd')
      dispatch(paymentsPayUpdated({
        payment_method: SUB_PAYPAL, currency: "USD",
        preference_data: { ...actualPayment.preference_data, items: [getUsdPrefItem(preferenceItemData)] }
      }));
    if (payOption.value === 'mp')
      dispatch(paymentsPayUpdated({
        payment_method: SUB_MP, currency: "ARS",
        preference_data: { ...actualPayment.preference_data, items: [getArsPrefItem(preferenceItemData)] }
      }));
  }, [payOption])

  const oldAmountToPay = Number(preferenceItemData.unit_price) + ((actualPayment.currency === "ARS" && (discountIsApplied || discountWasApplied))
    ? Number(actualPayment?.cupon?.amountArs) || 0
    : (discountIsApplied || discountWasApplied)
      ? Number(actualPayment?.cupon?.amountUsd) || 0 : 0);
  const oldToPayText = `${preferenceItemData.currency_id} ${formatThousandsPoint(oldAmountToPay)} / año`;
  const toPayTextWithDiscountText = `${preferenceItemData.currency_id} ${formatThousandsPoint(preferenceItemData.unit_price)} / año`;
  const toPayWithDiscountTotal = <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>{toPayTextWithDiscountText}</Typography>
  const toPayWithDiscountItem = <Typography variant="body2">{toPayTextWithDiscountText}</Typography>
  const oldToPay = <Typography variant="body2" sx={{ textDecoration: "line-through" }}>{oldToPayText}</Typography>

  const showChoosedMethod = noNeedToPay || proofRejected || (!justPlanSelected && (needProof || needCheck));
  const showSelectMethod = (justPlanSelected || mpPayCancelled || !(needProof || needCheck)) && !noNeedToPay && !finishedPay && !proofRejected;
  const showMethodPostPay = finishedPay && (!needProof && !needCheck);

  return (
    <React.Fragment>
      <Typography sx={titleStyles} gutterBottom paddingTop={2}>
        Resumen de Orden
      </Typography>
      <List disablePadding>

        <ListItem key={'item'} sx={{ py: 1, px: 0 }}>
          <ListItemText key={'item1'} primary={preferenceItemData.title} secondary={planDescription} />
          {(discountIsApplied || discountWasApplied)
            ? <ListItemText key={'item2'} sx={{ textAlign: 'end' }} primary={toPayWithDiscountItem} secondary={oldToPay} />
            : <Typography variant="body2">{oldToPayText}</Typography>}
        </ListItem>

        <ListItem key={'total'} sx={{ py: 1, px: 0 }}>
          <ListItemText key={'total1'} primary={"Total del Pedido"}
            secondary={`Primera Renovación: ${getNextRenewDate(finishedPay, userData.subscription, isRenew, false)}`} />
          {(discountIsApplied || discountWasApplied)
            ? <ListItemText key={'total2'} sx={{ textAlign: 'end' }} primary={toPayWithDiscountTotal} secondary={oldToPay} />
            : <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>{oldToPayText}</Typography>}
        </ListItem>

      </List>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ ...titleStyles, mt: 2 }} gutterBottom >
            Detalles de Facturación
          </Typography>
          <Grid>
            <b style={cardDSPNameStyles}>Nombre y apellido: </b><b style={cardCodeTextStyle} >{`${userData.nombre + " " + userData.apellido}`}</b>
          </Grid>
          <Grid>
            <b style={cardDSPNameStyles}>Email: </b><b style={cardCodeTextStyle} >{userData.email}</b>
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography sx={{ ...titleStyles, mt: 2 }} gutterBottom>
            Elección de Pago
          </Typography>
          {showSelectMethod && <>
            <Tooltip onClick={() => setPayOption(elems[0])} title="Pagar por Mercado Pago con plata en cuenta o tarjetas.">
              <Grid container>
                <Grid item xs={6}>
                  <FormControlLabel value={elems[0].value} label={elems[0].label}
                    control={<Radio
                      checked={payOption.value === elems[0].value}
                      onChange={() => handlePayOptionCheck(elems[0])}
                      value={elems[0].value}
                      name={`radio ${elems[0].value}`}
                    />} />
                </Grid>
                <Grid item xs={6}>
                  <Image
                    style={imageStyle}
                    alt="mp-checkout-image"
                    duration={30}
                    src={mpPayImage}
                  />
                </Grid>
              </Grid>
            </Tooltip>
            <Grid container>
              <Grid item xs={12} paddingTop={2}>
                <FormControlLabel value={elems[1].value} label={elems[1].label}
                  control={<Radio
                    checked={payOption.value === elems[1].value}
                    onChange={() => handlePayOptionCheck(elems[1])}
                    value={elems[1].value}
                    name={`radio ${elems[1].value}`}
                  />} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} paddingTop={2}>
                <FormControlLabel value={elems[2].value} label={elems[2].label}
                  control={<Radio
                    checked={payOption.value === elems[2].value}
                    onChange={() => handlePayOptionCheck(elems[2])}
                    value={elems[2].value}
                    name={`radio ${elems[2].value}`}
                  />} />
              </Grid>
            </Grid>
          </>}
          {showChoosedMethod && <Grid container>
            <b style={paymentChoseStyle}>{`Has elegido pagar por: ${subPaymentMethodToString(paymentMethod, noNeedToPay)}`}</b>
            {noNeedToPay ? null : getPaymentJsxDataByMethod(paymentMethod)}
          </Grid>}

          {showMethodPostPay && <Grid container>
            <b style={paymentChoseStyle}>{`Has pagado por: ${subPaymentMethodToString(paymentMethod, noNeedToPay)}`}</b>
            {discountWasApplied && <>
              <span style={paymentChoseStyle}>Utilizando el siguiente Cupón:</span>
              <span style={cuponNameStyle}>{actualPayment.cupon.name}</span>
            </>}
          </Grid>}

        </Grid>
      </Grid>
    </React.Fragment >
  );
}

export default Review;

const imageStyle = { borderRadius: 0, width: "", height: "" };
const cardCodeTextStyle = { color: "rgba(0,0,0,0.5)", whiteSpace: "nowrap", margin: "0", fontWeight: 400, fontSize: "18px", marginTop: "1em", marginBottom: "0" };
const cardDSPNameStyles = { color: "rgba(0,0,0,0.8)", margin: "0", fontWeight: 600, fontSize: "18px", marginTop: "1em", marginBottom: "0" };
const paymentChoseStyle = { color: "rgba(0,0,0,0.7)", fontWeight: 450, fontSize: "18px", marginBottom: "8px", marginRight: "4px" };
const titleStyles = { color: "rgba(0,0,0,0.8)", fontWeight: 450, fontSize: '25px' }
const cuponNameStyle = { color: "rgb:(0,0,0)", fontWeight: 600, fontSize: "18px" };