import React, { useEffect, useState } from "react";
import { Grid, Typography, Link, Button, Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { default as OurCard } from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { getUserStatusColor, getUserSubscriptionDataToShow, userIsActive, userNeedEmailVerification, userStatusHasPendingPay } from '../../utils/users.utils';
import Plans from "./Plans";
import { getPlanFromPayment, paymentStatusIsStarted } from "utils/payments.utils";
import ActivePaymentDialog from "./ActivePaymentDialog";
import { getPlanesLaFlota } from "services/FirestoreServices";
import { paymentsPayUpdateLocal, paymentsPreferenceCreated } from "redux/actions/PaymentsActions";
import Checkout from "./Checkout";
import { useNavigate } from 'react-router-dom';
import { SUB_ORDER_PLAN_SELECTED } from "variables/financial";
import { createMpPreferenceData } from "factory/payments.factory";
import { toWithOutError } from "utils";
import { differenceInDaysFromTwoTS } from "utils/timeRelated.utils";
import { USER_NEED_MIGRATION } from "variables/user.variables";

const Subscription = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(store => store.userData);
  const actualPayment = useSelector(store => store.payments.actualPayment);
  let userStatus = userData.userStatus;
  const needEmailVerification = userNeedEmailVerification(userStatus);
  const nextPaymentDateTS = userData?.subscription?.nextPaymentDate || "";

  const subInfoToShow = getUserSubscriptionDataToShow(userStatus, nextPaymentDateTS);
  const daysToRenew = differenceInDaysFromTwoTS(nextPaymentDateTS, new Date().getTime());
  const renewIsNear = daysToRenew >= 0 && daysToRenew <= 30;
  const subsIsExpired = daysToRenew < 0;
  const subStatusStyle = {
    color: getUserStatusColor(userStatus, nextPaymentDateTS),
    fontWeight: 500, fontSize: "30px", marginBottom: "0"
  };
  const needMigration = userStatus === USER_NEED_MIGRATION;
  const isUserActive = userIsActive(userStatus);

  const [subsOpenLoader, setSubsOpenLoader] = useState(false);
  const [planSelected, setPlanSelected] = useState({});
  const [activePaymentIsOpen, setActivePaymentIsOpen] = useState(false);
  const [planesFiltered, setPlanesFiltered] = useState([]);
  const [onlySellosPlans, setOnlySellosPlans] = useState([]);
  const [showLastPayment, setShowLastPayment] = useState(false);
  const [wantToUpgrade, setWantToUpgrade] = useState(false);
  const [showPlans, setShowPlans] = useState(false);
  
  const handleClosePaymentDialog = () => setActivePaymentIsOpen(false);

  useEffect(() => {
    const getLaFlotaPlanes = async () => {
      const planesLaFlota = await getPlanesLaFlota(dispatch);
      setPlanesFiltered(planesLaFlota.filter(plan => plan.status === 'available' && plan.maxArtists <= 5));
      setOnlySellosPlans(planesLaFlota.filter(plan => plan.status === 'available' && plan.maxArtists >= 5));
    }
    getLaFlotaPlanes();
  }, []);

  useEffect(() => {
    if (planesFiltered.length > 0 && !actualPayment.id) {
      setPlanSelected(getPlanFromPayment(actualPayment, [...new Set([...planesFiltered, ...onlySellosPlans])]));
      if (Boolean(actualPayment.id)) {
        dispatch(paymentsPayUpdateLocal(actualPayment));
        setActivePaymentIsOpen(true)
      }
    }
  }, [onlySellosPlans]);

  useEffect(() => {
    if (needEmailVerification || needMigration) { setShowLastPayment(false); setShowPlans(false); return; }
    if (userStatusHasPendingPay(userStatus) && paymentStatusIsStarted(actualPayment.status)) {
      setShowLastPayment(true); setShowPlans(false);
    }
    else { setShowLastPayment(false); setShowPlans(true) };
  }, [userData, actualPayment]);

  const handleShowLastPay = () => {
    setShowPlans(false);
    setWantToUpgrade(false);
    setShowLastPayment(true);
  }

  const handleShowPlans = () => {
    setShowLastPayment(false);
    setWantToUpgrade(false);
    setShowPlans(true);
  }

  const handleWantToUpgrade = () => {
    setShowPlans(false);
    setShowLastPayment(false);
    setWantToUpgrade(true);
  }

  const handleWantToRenew = async () => {
    setSubsOpenLoader(true);
    let planes = [...planesFiltered, ...onlySellosPlans];
    let planToRenew = planes.find(plan => plan.id === userData.plan) || "charly-garcia";
    let preference_data = createMpPreferenceData(planToRenew, actualPayment);
    let createPaymentDoc = await toWithOutError(dispatch(paymentsPreferenceCreated({
      status: SUB_ORDER_PLAN_SELECTED, preference_data, userId: userData.id, id: preference_data.external_reference,
      plan: planToRenew.id, userEmail: userData.email
    }, userData)));
    if (createPaymentDoc === "ERROR") { setSubsOpenLoader(false); return };
    setSubsOpenLoader(false);
    navigate('/admin/checkout?renew=true');
  }

  return (
    <Grid container justifyContent="center">

      {planSelected && <ActivePaymentDialog isOpen={activePaymentIsOpen} handleClose={handleClosePaymentDialog}
        payment={actualPayment} plan={planSelected} />}

      <Grid item xs={12}>
        <OurCard>

          <Backdrop open={subsOpenLoader}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <CardHeader color="primary" style={{ textAlign: "center" }}>
            <Typography sx={cardTitleWhiteStyles}>Suscripción</Typography>
            <p sx={cardCategoryWhiteStyles}>Aquí verás el estado de tu suscripción a La Flota</p>
          </CardHeader>

          <CardBody>
            <Grid container justifyContent="center" textAlign="center">

              <Grid item xs={12} sx={{ marginTop: "2em" }}>
                <b style={cardDSPNameStyles}>Estado de tu cuenta: </b><b style={subStatusStyle}>
                  {subInfoToShow.statusToShow}
                </b>
              </Grid>

              {renewIsNear && <Grid item xs={12} padding={1}>
                <span style={{ color: "darkorange", fontWeight: 500, fontSize: '25px' }}>
                  {`Renovación próxima: en ${daysToRenew} ${daysToRenew === 1 ? "día" : "días"}`}
                </span>
              </Grid>}

              {subsIsExpired && <Grid item xs={12} padding={1}>
                <span style={{ color: "rgb(175, 10, 10)", fontWeight: 500, fontSize: '25px' }}>
                  {`Cuenta expirada: hace ${-1 * (daysToRenew)} ${daysToRenew === -1 ? "día" : "días"}`}
                </span>
              </Grid>}

              {subInfoToShow.subtitle && <Grid item xs={12}>
                <Grid item xs={12}>
                  {subInfoToShow.subtitle}
                </Grid>
                {subInfoToShow.contact && <Link href="https://laflota.com.ar/contacto/"
                  target="_blank" style={linkToContactStyle} rel="noreferrer">
                  Comunícate con nosotros si crees que es un error.
                </Link>}
              </Grid>}

              {renewIsNear ?
                <Grid item xs={12} padding={1}>
                  <span>Falta poco para que expire tu cuenta. Ya puedes realizar el pago de la misma.</span>
                  <br />
                  <span>Recuerda que una vez pasada la fecha de Renovación, procederemos a dar de baja la cuenta.</span>
                  <br />
                  <span>Siempre puedes usar parte de tus regalías disponibles como forma de pago.</span>
                  <br />
                  <span>Si no realizas el pago a tiempo pero dispones de regalías para poder pagarla, la renovación será automática.</span>
                </Grid> : null}

              {subsIsExpired ?
                <Grid item xs={12} padding={0}>
                  <span>{`Tu cuenta ha expirado pero te damos ${5 - (-1 * daysToRenew)} ${daysToRenew === 1 ? "días" : "día"} más,  para que puedas realizar el pago.`}</span>
                  <br />
                  <span>De lo contrario, procederemos a dar de baja la cuenta.</span>
                  <br />
                  <span>Siempre puedes usar parte de tus regalías disponibles como forma de pago.</span>
                </Grid> : null}


              {isUserActive ?
                <Grid item xs={12} paddingTop={1}>
                  {(renewIsNear || subsIsExpired) && <Button onClick={handleWantToRenew} variant='contained' sx={{ mt: 1, ml: 1 }}>Renovar Plan</Button>}
                  <Button disabled={showPlans} onClick={handleShowPlans} variant='contained' sx={{ mt: 1, ml: 1 }}>Ver Planes</Button>
                  <Button disabled={showLastPayment} onClick={handleShowLastPay} variant='contained' sx={{ mt: 1, ml: 1 }}>Último Pago</Button>
                  {false && <Button disabled={wantToUpgrade} onClick={handleWantToUpgrade} variant='contained' sx={{ mt: 1, ml: 1 }}>Cambiar Plan</Button>}
                </Grid> : null
              }

            </Grid>
            {(wantToUpgrade || showPlans) && <Plans planesFiltered={planesFiltered} onlySellosPlans={onlySellosPlans} upgrading={wantToUpgrade} />}
            {showLastPayment && <Checkout paymentToShow={actualPayment} />}
          </CardBody>
        </OurCard>
      </Grid>

    </Grid >
  );
}

export default Subscription;

const cardDSPNameStyles = { color: "rgba(0,0,0,0.9)", margin: "0", fontWeight: 500, fontSize: "30px", marginBottom: "0" };
const cardCategoryWhiteStyles = { color: "rgba(255,255,255,.62)", margin: "0", fontSize: "14px", marginTop: "0", marginBottom: "0" };
const linkToContactStyle = { fontSize: "14px", textDecorationLine: 'underline' };
const cardTitleWhiteStyles = {
  color: "rgba(255,255,255,255)", marginTop: "0px", minHeight: "auto", fontWeight: "300px", fontSize: "40px",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", marginBottom: "3px", textDecoration: "none"
}
