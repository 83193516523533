import { generateTestTracksData } from 'factory/tracks.factory';
import { getAllCollaboratorsBasicInfoFromTracks } from 'utils/collaborators';

export const testArtist = {
  biography: "Juliassss",
  ownerId: "f1XEmZVlBdRHletHlqoLOqkAqVT2",
  spotify_uri: "",
  spotifyIdentifierIdFuga: 5876185510,
  imagenUrl: "https://firebasestorage.googleapis.com/v0/b/laflota-dev.appspot.com/o/artistsPhotos%2F361b5219-5ec1-476d-a19d-75d019c6a8d1?alt=media&token=bd066738-a958-4916-aeda-4da7d4f35712",
  id: "361b5219-5ec1-476d-a19d-75d019c6a8d1",
  fugaPropietaryId: "361b5219-5ec1-476d-a19d-75d019c6a8d1",
  name: "Julieta Venegas TEST",
  whenCreatedTS: 1644438565559,
  ownerEmail: "tester@laflota.com.ar",
  lastUpdateTS: 1659022121168,
  apple_id: "",
  fugaId: 5097651765
}

export const testLabel = {
  details: "",
  fugaId: 5201217544,
  id: "1b23ae61-eeb0-4d9f-a92b-ed89596f6e89",
  lastUpdateTS: 1646325302110,
  name: "TEST JAVI LABEL",
  ownerId: "f1XEmZVlBdRHletHlqoLOqkAqVT2",
  whenCreatedTS: 1646325302110,
}

const spotifyCheckedDsp = {
  checkBoxHelper: "",
  checked: true,
  dspId: 746109,
  dspName: "Spotify",
  fugaId: 4963279799,
  label: "Spotify",
}

const ytDspChecked = {
  checkBoxHelper: "",
  checked: true,
  dspId: 3405271817,
  dspName: "YouTube Music & Content ID",
  fugaId: 4963279860,
  label: "YouTube Music & Content ID",
}

const ytDspNotChecked = {
  checkBoxHelper: "",
  checked: false,
  dspId: 3405271817,
  dspName: "YouTube Music & Content ID",
  fugaId: 4963279860,
  label: "YouTube Music & Content ID",
}

const tracksTitlesTest = ["Nueva cancion", "segunda cancion", "dale perro tercera"];
const tracksTest = generateTestTracksData(tracksTitlesTest);
export const getCollaboratorsTest = getAllCollaboratorsBasicInfoFromTracks(tracksTest);

export const testAlbumComplete = {
  albumCreated: false,
  allOtherArtists: [testArtist],
  appleAdapted: false,
  appleObs: "",
  artistFugaId: 5355841695,
  artistId: "d00ea067-2660-4371-a03f-295ff374a92f",
  basicFieldsComplete: true,
  c_line: "F-elo",
  c_year: 2022,
  cover: { size: 344167, name: "Untitled design.jpg" },
  dayOfMonth: 4,
  disc_number: "",
  dsps: [spotifyCheckedDsp,spotifyCheckedDsp,spotifyCheckedDsp,spotifyCheckedDsp,spotifyCheckedDsp,spotifyCheckedDsp,spotifyCheckedDsp,spotifyCheckedDsp,spotifyCheckedDsp, ytDspChecked, spotifyCheckedDsp, ytDspChecked, spotifyCheckedDsp, ytDspChecked, spotifyCheckedDsp, ytDspChecked, spotifyCheckedDsp, ytDspChecked, spotifyCheckedDsp, ytDspChecked, spotifyCheckedDsp, ytDspChecked, spotifyCheckedDsp, ytDspChecked, spotifyCheckedDsp, ytDspChecked, spotifyCheckedDsp, ytDspChecked, ytDspNotChecked],
  format: "Single",
  fugaId: 6551159930,
  genre: "SOUNDTRACK",
  genreName: "Soundtrack",
  id: "48d48c8a-cd01-4c30-bda0-e7f1f9dccf1a",
  imagenUrl: "https://firebasestorage.googleapis.com/v0/b/laflota-dev.appspot.com/o/covers%2F48d48c8a-cd01-4c30-bda0-e7f1f9dccf1a?alt=media&token=45f9590f-b5c8-478c-9ead-a9ba26bbe37b",
  labelFugaId: 5133484624,
  label_name: "F-elo",
  languageId: "EN",
  languageName: "English",
  lastUpdateTS: 1670179835567,
  month: 12,
  nombreArtist: "F-elo",
  oldRelease: false,
  originalDayOfMonth: "",
  originalMonth: "",
  originalYear: "",
  ownerEmail: "encabofelo@gmail.com",
  ownerId: "Y9quEjUnh1UinZuwironIH6VmZW2",
  p_line: "Felipe Encabo",
  p_year: 2022,
  preOrder: true,
  preOrderDate: "",
  preOrderDayOfMonth: 2,
  preOrderMonth: 12,
  preOrderYear: 2022,
  preview: false,
  state: "DELIVERED_NEED_APPLE_REVISION",
  subgenre: 5132363596,
  subgenreName: "Instrumental",
  title: "101 Falling Code",
  upc: "",
  version: "",
  whenCreatedTS: 1670179772561,
  year: 2022
}