import React, { useEffect, useState, useRef } from 'react';
import {
  CssBaseline, TextField, Typography, Link, Box, Grid, Paper, Button
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

import * as actions from 'redux/actions/AuthActions.js';
import Copyright from 'components/Copyright/Copyright.js';
import Danger from 'components/Typography/Danger.js';

import { createTheme } from '@mui/material/styles';
import TextFieldPassword from 'components/TextField/TextFieldPassword';
import { toWithOutError, useForceUpdate } from 'utils';
import LoadingButton from 'components/CustomButtons/LoadingButton';
import { Image } from 'mui-image'
import useQuery from 'customHooks/useQuery';
import {
  emailResendedSubtitle, emailSendedSubtitle, errorResetPasswordSubtitle,
  errorResetPasswordTitle, expiredResetPassSubtitle, expiredResetPassTitle, insertNewPasswordText,
  needOtpCodeText, noResetPasswordDocSubtitle, noResetPasswordDocTitle, otpNotEqual, passwordLossText
} from 'utils/textToShow.utils';
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import {
  checkOTPResetPasswordLinkServer,
  createResetPasswordLinkServer, editUserDataAndCredentialsFS,
  getResetPasswordLinkServer, updateResetPasswordLinkServer
} from 'services/BackendCommunication';

const PasswordReset = () => {

  // PONER EL TICK VERDE AL CONFIRMAR EL CAMBIO DE CONTRASE:A
  const params = useQuery();
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const forceUpdate = useForceUpdate();

  let errorFormat = (message) => (
    <Danger color="error" variant="h6">{message}</Danger>
  )

  const email = useSelector(store => store.auth.email);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [password, setPassword] = useState("");
  const [disableAskForReset, setDisableAskForReset] = useState(false);

  const defaultInfoDialog = { title: "", subtitle: [""], open: false };
  const [infoDialogText, setInfoDialogText] = useState(defaultInfoDialog);
  const [otpVerified, setOtpVerified] = useState({ open: false, otp: "", checked: false });
  const [resetLinkDoc, setResetLinkDoc] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    const getResetPassDocByUserId = async code => {
      setLoading(true);
      const resetLinkIfExists = await getResetPasswordLinkServer(code, dispatch);
      if (resetLinkIfExists === "ERROR") { setInfoDialogText({ title: errorResetPasswordTitle, subtitle: errorResetPasswordSubtitle, open: true }); return; }
      if (resetLinkIfExists === "NOT_EXISTS") { setInfoDialogText({ title: noResetPasswordDocTitle, subtitle: noResetPasswordDocSubtitle, open: true }); return; };
      setResetLinkDoc(resetLinkIfExists);
      setLoading(false);
    }

    if (params.step === "change") getResetPassDocByUserId(params.code);
    else setDisableAskForReset(false);
  }, [params])

  useEffect(() => {
    if (resetLinkDoc.status) {
      let now = new Date().getTime();
      if (now > resetLinkDoc.linkDiesInTS || resetLinkDoc.status === "SUCCESS")
        setInfoDialogText({ title: expiredResetPassTitle, subtitle: expiredResetPassSubtitle, open: true });
      else setOtpVerified({ open: true, otp: "" });
    }
  }, [resetLinkDoc])

  const allFieldsValidSignUp = async () => {
    setLoading(true);
    if (otpVerified.checked) {
      if (password !== repeatedPassword) { setPasswordError("Las contraseñas no coinciden"); setLoading(false); return; }
      if (!simpleValidator.current.fields?.password) { simpleValidator.current.showMessages(); setLoading(false); return; }
      const changePassResult = await editUserDataAndCredentialsFS({ email, password }, dispatch);
      if (changePassResult === "ERROR") { setPasswordError("Error al actualizar la contraseña"); return "ERROR"; }
      const resultUpdatingResetDoc = await updateResetPasswordLinkServer(email, { status: "SUCCESS", linkDiesInTS: 0 }, dispatch);
      if (resultUpdatingResetDoc === "ERROR") return "ERROR";
      setInfoDialogText({ title: "Contraseña cambiada correctamente", subtitle: [""], open: true });
    }
    else if (simpleValidator.current.allValid()) {
      await createLinkDoc();
      setDisableAskForReset(true);
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
    setLoading(false);
  }

  const createLinkDoc = async () => {
    setPasswordError(false);
    let result = await toWithOutError(createResetPasswordLinkServer(email, dispatch));
    if (result === "NEW_LINK") {
      setInfoDialogText({ title: "Email enviado", subtitle: emailSendedSubtitle, open: true });
      return;
    }
    if (result === "RESEND_OLD_LINK") {
      setInfoDialogText({ title: "Ya existe un pedido vigente", subtitle: emailResendedSubtitle, open: true });
      return;
    }
    if (["ERROR", "ERROR_CREATING_LINK"].includes(result))
      setPasswordError("Hubo un error al realizar la operacion. Intenta nuevamente");
    if (result === "USER_NOT_EXISTS") setPasswordError("No encontramos ningún usuario con este Correo");
  };

  const changeEmail = text => dispatch(actions.emailChanged(text.trim()));
  const changePassword = text => setPassword(text);
  const changeRepeatedPassword = text => setRepeatedPassword(text);

  const insertOtp = text => setOtpVerified({ ...otpVerified, otp: text.trim() });

  const checkOtp = async () => {
    const otpChecked = await checkOTPResetPasswordLinkServer(params.code, otpVerified.otp, dispatch);
    if (otpChecked === "SUCCESS") setOtpVerified({ ...otpVerified, checked: true });
    else setInfoDialogText({ title: "OTP no coincide", subtitle: otpNotEqual, open: true });
  }

  const handleGoToLogin = () => params.code ? navigate('/login') : setInfoDialogText(defaultInfoDialog);

  return (
    <Grid container component="main" sx={rootStyle}>
      <CssBaseline />

      <InfoActionDialog key={"info-dialog"} id='info-dialog' isOpen={infoDialogText.open} handleOk={handleGoToLogin}
        handleClose={() => setInfoDialogText(defaultInfoDialog)} title={infoDialogText.title} contentTexts={infoDialogText.subtitle} />

      <Grid item xs={false} sm={4} md={7} >
        <Image src="/images/login-full.jpg" alt="image" duration={30} />
      </Grid>

      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

        <div style={paperStyle}>

          <Box sx={avatarStyle}>
            <Image src="/images/login-avatar.png" alt="logo" duration={30} />
          </Box>

          <Typography component="h1" variant="h5">
            Reestablecimiento de Contraseña
          </Typography>

          <Typography padding={2} sx={{ color: "#929292", textAlign: 'center' }}>
            {params.code
              ? (otpVerified.open && !otpVerified.checked) ? needOtpCodeText : insertNewPasswordText
              : passwordLossText}
          </Typography>

          <form style={formStyle} noValidate>

            {passwordError &&
              <Alert style={{ marginBottom: "16px" }} severity="error">
                {passwordError}
              </Alert>}

            <Grid container spacing={2}>

              {!otpVerified.checked && otpVerified.open &&
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <TextField
                    variant="outlined"
                    required
                    id="otp"
                    label="Código OTP"
                    name="otp"
                    value={otpVerified.otp}
                    onChange={(evento) => insertOtp(evento.target.value)}
                  />
                </Grid>}

              {!params.code &&
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Correo Electrónico"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(evento) => changeEmail(evento.target.value.toLowerCase())}
                  />
                  {simpleValidator.current.message('email', email, 'required|email', {
                    className: 'text-danger',
                    messages: { default: "Formato de Mail inválido." },
                    element: (message) => errorFormat(message)
                  })}
                </Grid>}

              {otpVerified.checked && <>
                <Grid item xs={12}>
                  <TextFieldPassword
                    sx={{ margin: 0 }}
                    fullWidth
                    password={password}
                    onChange={event => changePassword(event.target.value)}
                    validator={simpleValidator}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldPassword
                    sx={{ margin: 0 }}
                    id="repeatedPassword"
                    label="Repite la Contraseña"
                    fullWidth
                    password={repeatedPassword}
                    onChange={event => changeRepeatedPassword(event.target.value)}
                    validator={simpleValidator}
                  />
                </Grid>
              </>}

            </Grid>

            {(otpVerified.checked || !otpVerified.open) && <LoadingButton
              buttonFullWidth={true}
              variant="contained"
              color="primary"
              buttonSx={submitStyle}
              onClickHandler={allFieldsValidSignUp}
              textButton={otpVerified.checked ? "Confirmar Contraseña" : "Restablecer Contraseña"}
              loading={loading}
              disabled={!disableAskForReset ? disableAskForReset : password !== repeatedPassword}
            />}

            {(!otpVerified.checked && otpVerified.open) &&
              <Grid item xs={12} textAlign='center' padding={2}>
                <Button variant='contained' onClick={checkOtp}>
                  Verificar Código OTP
                </Button>
              </Grid>}

          </form>

        </div>

        <Box mt={5}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}

const theme = createTheme({
  palette: {
    primary: { light: '#757ce8', main: '#3f50b5', dark: '#002884', contrastText: '#fff' },
    secondary: { light: '#ff7961', main: '#f44336', dark: '#ba000d', contrastText: '#000' },
    mode: 'light'
  }
});

const rootStyle = { height: '100vh' }
const paperStyle = { margin: theme.spacing(8, 4), display: 'flex', flexDirection: 'column', alignItems: 'center' }
const avatarStyle = { backgroundColor: theme.palette.common.white, width: 100, height: 100 }
const formStyle = { width: '100%', marginTop: theme.spacing(1) }
const submitStyle = { margin: theme.spacing(3, 0, 2) }

export default PasswordReset;