import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import { Grid, Typography, Button } from '@mui/material';
import SimpleReactValidator from "simple-react-validator";
import { albumCleanUpdatingAlbum, albumsEditRedux, createAlbumRedux, updateAddingAlbumRedux } from "redux/actions/AlbumsActions";
import { v4 as uuidv4 } from 'uuid';

import { allFugaGenres } from "variables/genres";
import TracksTable from "components/Table/TracksTable";
import { NewTrackDialog } from "views/Tracks/NewTrackDialog";
import { deleteTrackInTracksUploading, tracksCleanUploadingTracks, createAllTracksAssetsToAlbumRedux, newUploadAllTracksFilesToAlbumRedux } from "redux/actions/TracksActions";

import ProgressButton from "components/CustomButtons/ProgressButton";
import { Save, AddCircleOutline, Edit } from '@mui/icons-material/';
import {
  albumCoverHelperText, lanzamientoColaborativoTooltip, oldReleaseCheckBoxHelper,
  preSaleCheckBoxHelper, releaseDateInfoTooltip, imageConstraintsMessage, filesMissingTitle, filesMissingText, versionsHelper, upcWarningText
} from '../../utils/textToShow.utils';
import { toWithOutError, to, useForceUpdate } from "utils";
import { manageAddImageToStorage } from "services/StorageServices";
import TextFieldWithInfo from "components/TextField/TextFieldWithInfo";
import AddOtherArtistsAlbumForm from 'components/Forms/AddOtherArtistsAlbumForm';
import ImageInput from "components/Input/ImageInput";
import { createOtherArtistsRedux } from '../../redux/actions/ArtistsInvitedActions';
import { updateAddingAlbumImageUrlAndCoverRedux, albumsPublishAndDeliveryRedux } from '../../redux/actions/AlbumsActions';
import CheckboxWithInfo from '../../components/Checkbox/CheckboxWithInfo';
import { createCollaboratorsRedux } from "redux/actions/CollaboratorsActions";
import { UPC_MISSING, languagesFuga } from '../../variables/varias';
import TextFieldWithInfoImage from '../../components/TextField/TextFieldWithInfoImage';
import NewArtist from 'views/Artists/NewArtist';
import { getAllOtherArtistsFromAlbumAndTrack, artistsWithUniqueName, artistCanMakeRelease } from '../../utils/artists.utils';
import SuccessDialog from '../../components/Dialogs/SuccessDialog';
import TextFieldWithAddElement from '../../components/TextField/TextFieldAddElement';
import EditOrAddFieldsDialog from '../../components/Dialogs/EditOrAddFieldDialog';
import { createSubgenreRedux } from "redux/actions/UserDataActions";
import InfoActionDialog from '../../components/Dialogs/InfoActionDialog';
import { createLabelRedux } from "redux/actions/LabelsActions";
import { checkIfAnyTrackIsExplicit } from "utils/tracks.utils";
import { trackUploadProgress, getTracksAsDataTable } from '../../utils/tables.utils';
import useScript from '../../customHooks/useScript';
import DspsDialog from "views/DSP/DspsDialog";
import {
  checkFieldsCreateAlbum, getDeliveredContentTextDialog, getDeliveredTitleDialog, handleSetDate,
  handleOriginalDateCheckbox, adaptTitleForSingleReleases, adaptVersionForSingleReleases
} from '../../utils/albums.utils';
import { getArrayYears } from '../../utils/timeRelated.utils';
import { userCanMakeRelease, userIsAdmin, userIsTester } from 'utils/users.utils';
import { testArtist, testLabel } from "factory/newAlbum.factory";
import { usersGetOneByIdRedux } from 'redux/actions/UsersActions';
import { getArtistByFieldRedux } from "redux/actions/ArtistsActions";
import NewAlbumLoading from "./NewAlbumLoading";
import { getAllCollaboratorsBasicInfoFromTracks } from "utils/collaborators";
import { uploadsSignOut } from "redux/actions/UploadsActions";
import { ERROR_UPLOADING } from "variables/uploads.variables";
import { allUploadsAreFinish } from "utils/upload.utils";
import CalendarInput from 'components/Input/CalendarInput';
import dayjs from 'dayjs';
import TypographyWithInfo from "components/Typography/TypographyWithInfo";

const NewAlbum = ({ editing }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const forceUpdate = useForceUpdate();

  const userData = useSelector(store => store.userData);
  const testingAll = userIsAdmin(userData.rol) || userIsTester(userData.rol);

  const currentUserId = userData.id; const currentUserEmail = userData.email;
  const currentAlbumData = useSelector(store => store.albums.addingAlbum);

  const uploads = useSelector(store => store.uploads);

  let myArtists = useSelector(store => store.artists.artists);
  myArtists = testingAll ? [testArtist] : myArtists;
  let myLabels = useSelector(store => store.labels.labels);
  myLabels = testingAll ? [testLabel] : myLabels;

  const myTracks = useSelector(store => store.tracks.uploadingTracks);
  const artistInvited = useSelector(store => store.artistsInvited);
  // aca deberia tener guardado la cantidad de albumes en el userDoc, y de artists, y labels.
  const cantAlbumsFromUser = "";


  const topElementRef = useRef(null);
  const scrollToTop = () => topElementRef.current ? topElementRef.current.scrollIntoView() : null;

  useScript("https://cdn.jsdelivr.net/npm/wavefile");

  const [canMakeNewRelease, setCanMakeNewRelease] = useState(true);
  const [myActiveArtists, setMyActiveArtists] = useState(myArtists);

  useEffect(() => {
    const checkIfCanMakeRelease = async userId => {
      if (testingAll) return true;
      let artistsData = [];
      const userData = await toWithOutError(dispatch(usersGetOneByIdRedux(userId, true)));
      if (!userCanMakeRelease(userData.userStatus)) { setCanMakeNewRelease(false); return }
      else artistsData = await toWithOutError(dispatch(getArtistByFieldRedux('ownerId', currentUserId, 100)));
      if (artistsData === "EMPTY") return setMyActiveArtists([])
      let activeArtists = Array.isArray(artistsData) ? artistsData.filter(artist => artistCanMakeRelease(artist.state)) : [];
      if (activeArtists.length === 0) { setCanMakeNewRelease(false); return }
      else setMyActiveArtists(activeArtists);
    }

    checkIfCanMakeRelease(currentUserId);

  }, [])

  useEffect(() => {
    setTracksDataTable(getTracksAsDataTable(myTracks, handleEditTrack, handleDeleteTrack) || [[]]);
  }, [myTracks])

  useEffect(() => {
    if (currentAlbumData.appleAdapted) allFieldsValidCreateAlbum();
  }, [currentAlbumData.appleAdapted])

  const changeAlbumId = () => dispatch(updateAddingAlbumRedux({ ...currentAlbumData, id: uuidv4() }));
  const putAlbumIdOnEditingArtist = () => dispatch(updateAddingAlbumRedux({ ...currentAlbumData, id: currentAlbumData.id }));

  useEffect(() => {
    if (!editing) changeAlbumId();
    else putAlbumIdOnEditingArtist();
  }, [])


  const handleDeleteTrack = trackInfo => dispatch(deleteTrackInTracksUploading(trackInfo.id));
  const handleEditTrack = trackInfo => {
    setTrackData(trackInfo);
    setOpenNewTrackDialog(true);
  }

  const [openAddArtistDialog, setOpenAddArtistDialog] = useState(false);

  const [progress, setProgress] = useState(0);
  const [messageForCover, setMessageForCover] = useState("");
  const [tracksDataTable, setTracksDataTable] = useState(getTracksAsDataTable(myTracks, handleEditTrack, handleDeleteTrack) || [[]]);

  const [openNewTrackDialog, setOpenNewTrackDialog] = useState(false);
  const [openLoaderLabelCreate, setOpenLoaderLabelCreate] = useState(false);
  const [openLoaderSubgenreCreate, setOpenLoaderSubgenreCreate] = useState(false);
  const [openInvalidValueDialog, setOpenInvalidValueDialog] = useState({ open: false, beginner: "", title: "", text: [""] });
  const [openLoader, setOpenLoader] = useState(false);

  const [deliveryState, setDeliveryState] = useState('none');
  const [openSelectDSP, setOpenSelectDSP] = useState(false);
  const [openMissingFilesDialog, setOpenMissingFilesDialog] = useState({ open: false, title: "", text: [""] });
  const [openEditDialog, setOpenEditDialog] = useState({ open: false, title: "", subtitle: [""], values: "" });

  const [creatingAlbumState, setCreatingAlbumState] = useState("none");
  const [buttonState, setButtonState] = useState("none");
  const [buttonText, setButtonText] = useState("Finalizar");
  const [selloInStore, setSelloInStore] = useState(false);

  //NEW FOR LOADING FEEDBACK
  const [activeLoadingSteps, setActiveLoadingSteps] = useState(-1);
  const [artistsToCreate, setArtistsToCreate] = useState([]);
  const [collaboratorsToCreate, setCollaboratorsToCreate] = useState([]);
  const [albumDataPostFuga, setAlbumDataPostFuga] = useState({});

  const [trackData, setTrackData] = useState({
    disc_number: cantAlbumsFromUser, explicit: false, allOtherArtists: [],
    subgenre: currentAlbumData.subgenre, genreName: currentAlbumData.genreName,
    subgenreName: currentAlbumData.subgenreName, genre: currentAlbumData.genre,
    position: tracksDataTable.length + 1, title: "", track: { size: 0, name: "" }, artists: [],
    price: "", lyrics: "", isrc: "", track_language_name: currentAlbumData.languageName,
    track_language_id: currentAlbumData.languageId, progress: 0, id: "", preview: currentAlbumData.preview,
    collaborators: [{ name: "", roles: ["Compositor"] }, { name: "", roles: ["Liricista"] }],
    preOrder: currentAlbumData.preOrder, audio_locale_name: "", existedInFuga: false,
    audio_locale_id: "", fugaId: "", version: ""
  });

  useEffect(() => {

    const deliver = async filesMissing => {
      if (!albumDataPostFuga.upc) { handleMissingUpc(); return; };
      if (filesMissing.length > 0) { handleMissingFiles(filesMissing); return; };
      creatingAlbumState === "collaborators-created" ? await handleDelivery(albumDataPostFuga) : setCreatingAlbumState("success");
      setOpenLoader(false);
      dispatch(uploadsSignOut());
    }

    if (allUploadsAreFinish(uploads)) {
      let filesMissing = uploads.filter(upload => upload.status === ERROR_UPLOADING);
      setActiveLoadingSteps(5);
      deliver(filesMissing);
    }

  }, [uploads])

  const handleCloseEditDialog = () => setOpenEditDialog({ open: false, title: "", subtitle: [""], values: "" });

  const handleSelectDSPs = () => setOpenSelectDSP(true);
  let successDialogTitle = getDeliveredTitleDialog(deliveryState);
  let successDialogText = getDeliveredContentTextDialog(deliveryState);

  const handleDelivery = async albumUploaded => {
    let deliverToApple = Boolean(albumUploaded.dsps.find(dspInfo => dspInfo.dspName === "Apple Music"));
    let responsePublishAndDelivery = await toWithOutError(dispatch(albumsPublishAndDeliveryRedux(albumUploaded, albumUploaded.dsps, 'all', deliverToApple)));
    if (responsePublishAndDelivery === "ERROR") return "ERROR";
    if (responsePublishAndDelivery === "PUBLISHED") { setDeliveryState('published'); return; }
    if (responsePublishAndDelivery === "DELIVERED") { setDeliveryState('delivered'); return; }
    handleCloseSuccessUpload();
  }

  const handleMissingUpc = async () => {
    await toWithOutError(dispatch(albumsEditRedux(currentAlbumData, { state: UPC_MISSING }, currentAlbumData.ownerEmail, false, {})));
    setCreatingAlbumState("success");
    setOpenLoader(false);
    dispatch(uploadsSignOut());
  }

  const handleMissingFiles = async (filesMissing) => {
    setOpenMissingFilesDialog({ open: true, title: filesMissingTitle, text: filesMissingText(filesMissing, myTracks) });
    await toWithOutError(dispatch(albumsEditRedux(currentAlbumData, { state: "TRACKS_MISSING" }, currentAlbumData.ownerEmail, false, {})));
  }

  const coverLabelArtistAllValids = () => {
    if (!currentAlbumData.cover?.size) setMessageForCover("Debes seleccionar el Arte de Tapa");
    if (!currentAlbumData.nombreArtist) validator.current.showMessageFor('nombreArtist');
    if (!currentAlbumData.label_name) validator.current.showMessageFor('label_name');
    if (needArtistLabelCover) {
      handleSelectDSPs();
      dispatch(updateAddingAlbumRedux({ ...currentAlbumData, basicFieldsComplete: true }));
    }
  }

  const allFieldsValidCreateAlbum = () => {
    let validationResult = checkFieldsCreateAlbum(currentAlbumData, myTracks, setOpenInvalidValueDialog, validator, scrollToTop);
    if (validationResult === "ALL_VALID") createAlbum();
    else {
      validator.current.showMessages();
      forceUpdate();
    }
  }

  const handleCloseInfoActionDialog = () => {
    if (openInvalidValueDialog.beginner === "single-track-name") adaptTitleForSingleReleases(currentAlbumData, myTracks, dispatch);
    if (openInvalidValueDialog.beginner === "single-track-version") adaptVersionForSingleReleases(currentAlbumData, myTracks, dispatch);
    setOpenInvalidValueDialog({ open: false, beginner: "", title: "", text: [""] });
    createAlbum();
  }

  const createAlbum = async () => {
    setOpenLoader(true); setActiveLoadingSteps(0); dispatch(uploadsSignOut());
    let albumDataFromFuga = ""; let responseTracksFromFuga = ""; let internalState = "";

    // Los artistas que estaban en un track existente ya existen tanto en FUGA como en FS
    const myNewTracks = myTracks.filter(track => track.fugaId === "");
    const allOtherArtistsNotRepeatedFromTracksAndAlbum = artistsWithUniqueName([...currentAlbumData.allOtherArtists, ...myNewTracks.map(track => track.allOtherArtists).flat()]);
    setArtistsToCreate(allOtherArtistsNotRepeatedFromTracksAndAlbum);

    const otherPrimaryArtistsOfTheAlbumCreatedInFuga = await toWithOutError(dispatch(createOtherArtistsRedux(
      allOtherArtistsNotRepeatedFromTracksAndAlbum, currentUserId, currentUserEmail, artistInvited, currentAlbumData)))
    if (otherPrimaryArtistsOfTheAlbumCreatedInFuga === "ERROR") {
      setButtonState("error"); setButtonText("Error"); setOpenLoader(false);
      return;
    }
    else { internalState = "artists-created"; setCreatingAlbumState("artists-created"); setActiveLoadingSteps(1); }

    if (internalState === "artists-created") {
      const explicitAlbum = checkIfAnyTrackIsExplicit(myTracks);
      albumDataFromFuga = await toWithOutError(dispatch(createAlbumRedux(currentAlbumData, currentUserId, currentUserEmail, explicitAlbum, myTracks, artistInvited)));
      if (albumDataFromFuga === "ERROR") {
        setButtonState("error"); setButtonText("Error"); setOpenLoader(false);
        return;
      }
      else {
        internalState = "album-created"; setCreatingAlbumState("album-created"); setActiveLoadingSteps(2);
        setAlbumDataPostFuga(albumDataFromFuga);
      }
    }

    if (internalState === "album-created") {
      responseTracksFromFuga = await toWithOutError(dispatch(createAllTracksAssetsToAlbumRedux(myTracks, albumDataFromFuga.id,
        albumDataFromFuga.fugaId, currentUserId, currentUserEmail, artistInvited, otherPrimaryArtistsOfTheAlbumCreatedInFuga)));
      if (responseTracksFromFuga === "ERROR") {
        setButtonState("error"); setButtonText("Error"); setOpenLoader(false);
        return;
      }
      else { internalState = "tracks-assets-created"; setCreatingAlbumState("tracks-assets-created"); setActiveLoadingSteps(3); }
    }

    if (internalState === "tracks-assets-created") {
      setCollaboratorsToCreate(getAllCollaboratorsBasicInfoFromTracks(myTracks));
      const tracksCollaboratorsResponse = await toWithOutError(dispatch(createCollaboratorsRedux(responseTracksFromFuga,
        currentUserId, currentUserEmail)))
      if (tracksCollaboratorsResponse !== "ERROR") {
        internalState = "collaborators-created"; setCreatingAlbumState("collaborators-created");
        setActiveLoadingSteps(4);
      }
    }

    if (internalState === "tracks-assets-created" || internalState === "collaborators-created") {
      dispatch(newUploadAllTracksFilesToAlbumRedux(responseTracksFromFuga, albumDataFromFuga.fugaId, currentUserEmail));
    }
  }

  const onClickAddImage = async (event) => {
    dispatch(updateAddingAlbumImageUrlAndCoverRedux({ imagenUrl: "", cover: "" }));
    setMessageForCover("");
    let img = new Image()
    let imageFile = event.target.files[0];
    if (imageFile.type !== "image/jpeg") {
      setOpenInvalidValueDialog({
        open: true, beginer: "not-jpg-image", title: "La imagen debe tener formato JPG/JPEG",
        text: ["Por favor, selecciona una imagen con ese formato."]
      });
      return;
    }
    img.src = window.URL.createObjectURL(imageFile)
    img.onload = async () => {
      if (img.width >= 3000 && img.height >= 3000 && img.width <= 6000 && img.height <= 6000 && img.width === img.height) {
        setMessageForCover("");
        let saveAsThumbnail = true;
        let [errorAddingFile, urlAndFile] = await to(manageAddImageToStorage(saveAsThumbnail, imageFile, currentAlbumData.id,
          'covers', 1048576 * 20, setMessageForCover, setProgress, currentUserEmail));
        if (errorAddingFile) {
          setMessageForCover("Ha ocurrido un error, por favor, intente nuevamente. ");
          return;
        }
        dispatch(updateAddingAlbumImageUrlAndCoverRedux({ imagenUrl: urlAndFile.url, cover: urlAndFile.file }));
        setMessageForCover("");
      }
      else setMessageForCover(imageConstraintsMessage);
    }
  }

  const handleClickAddTrack = () => {
    let artistFromLaFlota = [{ name: currentAlbumData.nombreArtist, fugaId: currentAlbumData.artistFugaId, primary: true, id: currentAlbumData.artistId }];
    setTrackData({
      ...trackData, fugaId: "", position: tracksDataTable.length + 1, artists: [...artistFromLaFlota,
      ...getAllOtherArtistsFromAlbumAndTrack(artistFromLaFlota[0], artistsWithUniqueName(currentAlbumData.allOtherArtists),
        artistsWithUniqueName([...myTracks.map(track => track.allOtherArtists).flat()]))], version: currentAlbumData.version,
      preview: currentAlbumData.preview, preOrder: currentAlbumData.preOrder, isrc: "", explicit: false, existedInFuga: false
    })
    setOpenNewTrackDialog(true);
  }

  const getArtistIdAndFugaIdFromName = artistName => {
    let artistResult = myArtists.filter(artist => artist.name === artistName)[0];
    return { artistFugaId: artistResult.fugaId, artistId: artistResult.id };
  }

  const handlerArtistChoose = event => {
    const { artistFugaId, artistId } = getArtistIdAndFugaIdFromName(event.target.value);
    dispatch(updateAddingAlbumRedux({ ...currentAlbumData, nombreArtist: event.target.value, artistFugaId, artistId }));
    setTrackData({ ...trackData, primary_artist: event.target.value, artistFugaId });
  };

  const getLabelIdFromName = labelName => myLabels.filter(label => label.name === labelName)[0].fugaId;

  const handlerLabelChoose = event => {
    if (event.target.value === "Crea un nuevo sello") return;
    const labelFugaId = getLabelIdFromName(event.target.value);
    dispatch(updateAddingAlbumRedux({ ...currentAlbumData, label_name: event.target.value, labelFugaId }));
  }

  const handlerBasicUpdateAlbum = (value, targetField) => {
    dispatch(updateAddingAlbumRedux({ ...currentAlbumData, [`${targetField}`]: value }));
  }

  const handlerLanguageChoose = event => {
    let language = languagesFuga.find(l => l.name === event.target.value);
    if (language === undefined) language.id = "ES";
    dispatch(updateAddingAlbumRedux({ ...currentAlbumData, languageId: language.id, languageName: event.target.value }));
  }

  const handlerGenreChoose = event => {
    let genreId = allFugaGenres.find(g => g.name === event.target.value).id;
    setTrackData({ ...trackData, genre: genreId, genreName: event.target.value });
    dispatch(updateAddingAlbumRedux({ ...currentAlbumData, genre: genreId, genreName: event.target.value }));
  }

  const handlerSubgenreChoose = event => {
    if (event.target.value === "Crea tu propio subgénero") return;
    let subgenreId = userData.subgenerosPropios.find(g => g.name === event.target.value).id;
    setTrackData({ ...trackData, subgenre: subgenreId, subgenreName: event.target.value });
    dispatch(updateAddingAlbumRedux({ ...currentAlbumData, subgenre: subgenreId, subgenreName: event.target.value }));
  }

  const handleCreateSubgenre = async subgenreName => {
    setOpenLoaderSubgenreCreate(true);
    const createSubgenreResponse = await toWithOutError(dispatch(createSubgenreRedux(subgenreName, currentUserId)))

    if (createSubgenreResponse === "ERROR") {
      setButtonState("error");
      setOpenLoaderSubgenreCreate(false);
      return "ERROR";
    }

    dispatch(updateAddingAlbumRedux({ ...currentAlbumData, subgenre: createSubgenreResponse.id, subgenreName }));
    setTrackData({ ...trackData, subgenreName, subgenre: createSubgenreResponse.id });
    setOpenLoaderSubgenreCreate(false);
    handleCloseEditDialog();
  }

  const handleCreateLabel = async labelName => {
    setOpenLoaderLabelCreate(true);
    const createLabelResponse = await toWithOutError(dispatch(createLabelRedux({ name: labelName, details: "" }, currentUserId)))

    if (createLabelResponse === "SELLO_IN_STORE") setSelloInStore(true);
    if (createLabelResponse === "ERROR") {
      setButtonState("error");
      setOpenLoaderLabelCreate(false);
      return "ERROR";
    }

    dispatch(updateAddingAlbumRedux({ ...currentAlbumData, label_name: labelName, labelFugaId: createLabelResponse.fugaId }));
    setOpenLoaderLabelCreate(false);
    handleCloseEditDialog();
  }

  const handlerUPC = event => {
    if (event.target.value.length <= 13) dispatch(updateAddingAlbumRedux({ ...currentAlbumData, upc: event.target.value }));
    validator.current.showMessageFor('upc');
  }
  const yearsArray = getArrayYears(1950, new Date().getFullYear());

  const needArtistLabelCover = currentAlbumData.nombreArtist && currentAlbumData.label_name && currentAlbumData.cover;
  const needArtistLabelCoverAndContinue = currentAlbumData.nombreArtist && currentAlbumData.label_name && currentAlbumData.cover && currentAlbumData.basicFieldsComplete;
  const showingNotBasicAlbumFields = testingAll ? true : needArtistLabelCoverAndContinue || openLoader;

  const handleCloseSuccessUpload = () => {
    let albumId = currentAlbumData.id;
    dispatch(albumCleanUpdatingAlbum());
    dispatch(tracksCleanUploadingTracks());
    navigate(`/admin/albums/${albumId}`);
  }

  const handleOpenAddSubgenre = () => setOpenEditDialog({
    open: true, title: "Crea un subgénero", subtitle: ["Puedes agregar un nuevo subgénero."],
    handleConfirm: (newValue) => handleCreateSubgenre(newValue.normalize("NFD").replace(/\p{Diacritic}/gu, "")),
    initialValues: "", values: "", handleCloseDialog: handleCloseEditDialog, type: ''
  });

  const handleOpenCreateLabel = () => setOpenEditDialog({
    open: true, title: "Crea un Sello", subtitle: ["Puedes agregar un nuevo sello."],
    handleConfirm: (newValue) => handleCreateLabel(newValue),
    initialValues: "", values: "", handleCloseDialog: handleCloseEditDialog, type: ''
  });

  const handleFinishUploads = () => {
    if (creatingAlbumState === 'collaborators-created') { setCreatingAlbumState("ready-to-delivery"); setActiveLoadingSteps(5); }
    else setCreatingAlbumState("not-ready-to-delivery");
  }

  return canMakeNewRelease
    ? (
      <Grid container textAlign="center">
        <Card style={{ alignItems: "center", borderRadius: "30px" }} >

          <NewAlbumLoading loading={openLoader} activeStep={activeLoadingSteps} artists={artistsToCreate}
            collaborators={collaboratorsToCreate} tracks={myTracks} albumData={currentAlbumData}
            handleFinishUploads={handleFinishUploads} />

          <SuccessDialog isOpen={deliveryState !== 'none' && deliveryState !== 'processing'} title={successDialogTitle} contentTexts={successDialogText}
            handleClose={handleCloseSuccessUpload} successImageSource="/images/success.jpg" size="sm" />

          <DspsDialog isOpen={openSelectDSP} setIsOpen={setOpenSelectDSP} currentAlbumData={currentAlbumData}
            albumWasReleased={false} albumWasEdited={false} />

          <SuccessDialog isOpen={selloInStore} title={`El sello que intentas crear, ya existe asociado a tu cuenta.`} contentTexts={[[`Seleccionalo, en vez de crear uno nuevo.`]]}
            handleClose={() => setSelloInStore(false)} successImageSource="/images/successArtists.jpg" />

          <SuccessDialog isOpen={creatingAlbumState === "success"} title="¡Felicitaciones!" contentTexts={[["Tu lanzamiento ya se encuentra en etapa de de revisión"]]}
            handleClose={handleCloseSuccessUpload} successImageSource="/images/success.jpg" />

          <InfoActionDialog id='missing-files' isOpen={openMissingFilesDialog.open} handleClose={handleCloseSuccessUpload}
            title={openMissingFilesDialog.title} contentTexts={openMissingFilesDialog.text} />

          <InfoActionDialog id='invalid-dialog' isOpen={openInvalidValueDialog.open} handleClose={handleCloseInfoActionDialog}
            title={openInvalidValueDialog.title} contentTexts={openInvalidValueDialog.text} />

          <EditOrAddFieldsDialog setEditOptions={setOpenEditDialog}
            loading={openLoaderSubgenreCreate || openLoaderLabelCreate} buttonState={buttonState} editOptions={openEditDialog} />

          <Grid item xs={12} sx={{ width: "60%" }}>
            <CardHeader color="primary">
              <Typography sx={cardTitleWhiteStyles}>Crear Lanzamiento</Typography>
            </CardHeader>
          </Grid>

          <Grid container item xs={12} paddingTop={4}>

            <ImageInput key="new-album" imagenUrl={currentAlbumData.imagenUrl}
              onClickAddImage={onClickAddImage}
              textButton={currentAlbumData.imagenUrl === "" ? "Arte de Tapa" : "Cambiar"}
              progress={progress} message={messageForCover}
              helperText={albumCoverHelperText}
              fileTypesAccept={"image/jpeg"} fileType="image/jpeg"
            />

            <NewArtist editing={false} view="dialog" isOpen={openAddArtistDialog} handleClose={() => setOpenAddArtistDialog(false)} />

            <Grid container item xs={12} >

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() => setOpenAddArtistDialog(true)}
                  sx={buttonAddArtist}
                  endIcon={<AddCircleOutline />}>
                  Crear Artista Principal
                </Button>
              </Grid>

              <Grid item xs={12} ref={topElementRef}>
                <TextFieldWithInfo
                  name="nombreArtist"
                  sx={textFieldLaFlotaArtistStyle}
                  autoFocus
                  required
                  select
                  label="Artista Principal"
                  value={currentAlbumData.nombreArtist}
                  onChange={handlerArtistChoose}
                  helperText="Selecciona al Artista Principal, si es que ya lo tienes en el sistema. Si no, primero debés crear un Artista."
                  selectItems={myActiveArtists}
                  selectKeyField="id"
                  selectValueField="name"
                  validatorProps={{ restrictions: 'required', message: "Debes seleccionar al Artista del Nuevo Lanzamiento.", validator: validator }}
                />
              </Grid>

            </Grid>

            <AddOtherArtistsAlbumForm
              checkBoxLabel="¿Lanzamiento Colaborativo?"
              checkBoxHelper={lanzamientoColaborativoTooltip}
              checkBoxColor="#9c27b0"
              buttonColor="#9c27b0"
              validator={validator}
            />

            {(currentAlbumData.dsps.filter(dsp => dsp.checked).length > 0 || showingNotBasicAlbumFields) &&
              <Grid item xs={12}>
                <Button variant="contained" onClick={() => handleSelectDSPs()} sx={buttonAddArtist} endIcon={<Edit />}>
                  Editar DSP's
                </Button>
              </Grid>}

            <Grid container item xs={12}>
              <Grid item xs={showingNotBasicAlbumFields ? 6 : 12}>
                <TextFieldWithAddElement
                  name="label_name"
                  sx={showingNotBasicAlbumFields ? textFieldStyle : textFieldLaFlotaArtistStyle}
                  required
                  select
                  label="Sello Discográfico"
                  value={currentAlbumData.label_name}
                  onChange={handlerLabelChoose}
                  helperText="Selecciona el sello discográfico."
                  selectItems={myLabels}
                  selectKeyField="name"
                  selectValueField="name"
                  validatorProps={{ restrictions: 'required|max:100', message: "Debes seleccionar un sello para el Lanzamiento.", validator: validator }}
                  onClickAddElement={handleOpenCreateLabel}
                  addPlaceholder="Crea un nuevo sello"
                />
              </Grid>

              {showingNotBasicAlbumFields && <Grid item xs={6}>
                <TextFieldWithInfo
                  name="title"
                  sx={textFieldStyle}
                  required
                  label="Título del Lanzamiento"
                  value={currentAlbumData.title}
                  onChange={(event) => handlerBasicUpdateAlbum(event.target.value, "title")}
                  validatorProps={{ restrictions: 'required|max:100', message: "Debes ingresar el Título del Lanzamiento.", validator: validator }}
                />
              </Grid>}
            </Grid>

            {showingNotBasicAlbumFields && <Grid container item xs={12}>
              <Grid item xs={6}>
                <TextFieldWithInfoImage
                  name="version"
                  sx={textFieldStyle}
                  label="Versión (opcional, aparecerá en el titulo del lanzamiento)"
                  value={currentAlbumData.version}
                  helperText={versionsHelper}
                  onChange={event => handlerBasicUpdateAlbum(event.target.value, "version")}
                  imageSource="/images/versionReleaseHelp.png"
                  contentTexts={[["No debés escribir los paréntesis, se incluirán solos."]]}
                />
              </Grid>

              <Grid item xs={6}>
                <TextFieldWithInfo
                  name="format"
                  sx={textFieldStyle}
                  select
                  label="Formato del Lanzamiento"
                  value={currentAlbumData.format}
                  onChange={event => handlerBasicUpdateAlbum(event.target.value, "format")}
                  helperText="Elige el formato del lanzamiento, segun la cantidad de canciones o la duración del Lanzamiento."
                  selectItems={["Single", "EP", "Álbum"]}
                />
              </Grid>
            </Grid>}

          </Grid>

          {showingNotBasicAlbumFields && <Grid container item xs={12}>
            <Grid container item xs={6}>
              <Grid item xs={12}>
                <TextFieldWithInfo
                  name="c_year"
                  sx={textFieldStyle}
                  required
                  select
                  label="(C) Año de Copyright"
                  value={currentAlbumData.c_year}
                  onChange={(event) => handlerBasicUpdateAlbum(event.target.value, "c_year")}
                  helperText="Año en que esta grabación fue creada."
                  selectItems={yearsArray}
                  validatorProps={{
                    restrictions: 'required|numeric', message: "Debes seleccionar un año de Copyright del Lanzamiento.", validator,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldWithInfo
                  name="c_line"
                  sx={textFieldStyle}
                  required
                  label="Copyright"
                  value={currentAlbumData.c_line}
                  onChange={(event) => handlerBasicUpdateAlbum(event.target.value, "c_line")}
                  helperText="El dueño de los Derechos de Autor.
              → Si tu lanzamiento contiene Covers debes agregar el nombre de los autores originales acá (Por ej.: Luis Alberto Spinetta)."
                  validatorProps={{
                    restrictions: 'required|max:200', message: "Por favor indicá el dueño de los derechos de autor del lanzamiento.",
                    validator
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={6}>
              <Grid item xs={12}>
                <TextFieldWithInfo
                  sx={textFieldStyle}
                  name="p_year"
                  required
                  select
                  label="(P) Año de Publishing"
                  value={currentAlbumData.p_year}
                  onChange={(event) => handlerBasicUpdateAlbum(event.target.value, "p_year")}
                  helperText="Año en que esta grabación fue publicada."
                  selectItems={yearsArray}
                  validatorProps={{
                    restrictions: 'required|numeric', message: "Debes seleccionar un año de Publishing del Lanzamiento.",
                    validator
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldWithInfo
                  name="p_line"
                  sx={textFieldStyle}
                  required
                  label="Publisher"
                  value={currentAlbumData.p_line}
                  onChange={(event) => handlerBasicUpdateAlbum(event.target.value, "p_line")}
                  helperText="El dueño de los Derechos de Publicación de esta grabación.
                    → Ej. 1: Fito Paez | Ej. 2: Sony Music"
                  validatorProps={{
                    restrictions: 'required|max:200', message: "Por favor indicá el publicador del lanzamiento.",
                    validator
                  }}
                />
              </Grid>
            </Grid>

          </Grid>}

          {showingNotBasicAlbumFields && <Grid container item xs={12}>

            <Grid item xs={6}>
              <TextFieldWithInfo
                name="upc"
                sx={textFieldStyle}
                label="UPC (opcional, único)"
                value={currentAlbumData.upc}
                helperText="Completa sólo si ya tienes un código UPC que quieras usar con este lanzamiento. Si no tienes le asignaremos uno."
                onChange={handlerUPC}
                validatorProps={{
                  restrictions: 'min:12|max:13|numeric', message: "Formato inválido: El UPC es un código de 12 ó 13 números",
                  validator: validator
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldWithInfo
                name="language"
                sx={textFieldStyle}
                required
                select
                label="Idioma Principal del Lanzamiento"
                value={currentAlbumData.languageName}
                onChange={handlerLanguageChoose}
                selectItems={languagesFuga}
                selectKeyField="id"
                selectValueField="name"
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldWithInfo
                name="generosMusicales"
                sx={textFieldStyle}
                required
                select
                label="Género Musical Principal"
                value={currentAlbumData.genreName}
                onChange={handlerGenreChoose}
                selectItems={allFugaGenres}
                selectKeyField="id"
                selectValueField="name"
                validatorProps={{ restrictions: 'required', message: "Debés seleccionar el género principal del Lanzamiento.", validator }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldWithAddElement
                name="subgenerosMusicales"
                sx={textFieldStyle}
                select
                label="Género Musical Secundario"
                value={currentAlbumData.subgenreName}
                onChange={handlerSubgenreChoose}
                selectItems={userData.subgenerosPropios || []}
                selectKeyField="id"
                selectValueField="name"
                onClickAddElement={handleOpenAddSubgenre}
                addPlaceholder="Crea tu propio subgénero"
              />
            </Grid>
          </Grid>}

          {showingNotBasicAlbumFields &&
            <Grid container paddingTop={3} justifyContent="center">

              <Grid item xs={12}>
                <TypographyWithInfo infoTooltip={releaseDateInfoTooltip} title="Fecha del Lanzamiento" />
              </Grid>

              <Grid item xs={12} paddingTop={1}>
                <CalendarInput selectedDate={currentAlbumData.releaseDayjsFormat} label='' maxDate={dayjs().add(1, 'year')}
                  onChangeDate={(dateValue) => handleSetDate('release-date', dateValue, currentAlbumData, dispatch)}
                  minDate={dayjs().add(1, 'day').add(2, 'hour')} withTimeSelection={true} />
              </Grid>

              <Grid item xs={12}>
                <CheckboxWithInfo
                  label="¿El lanzamiento fue publicado en el pasado?"
                  checked={currentAlbumData.oldRelease}
                  onChecked={(event) => handleOriginalDateCheckbox(event.target.checked, currentAlbumData, dispatch)}
                  checkBoxHelper={oldReleaseCheckBoxHelper}
                  color="#9c27b0"
                />

                {currentAlbumData.oldRelease &&
                  <Grid item xs={12}>
                    <CalendarInput selectedDate={currentAlbumData.originalDayjsFormat} label='' maxDate={dayjs()}
                      onChangeDate={(dateValue) => handleSetDate('original-date', dateValue, currentAlbumData, dispatch)}
                      minDate={dayjs().subtract(70, 'year')} withTimeSelection={false} />
                  </Grid>
                }
              </Grid>


              <Grid item xs={12}>
                <CheckboxWithInfo
                  label="¿Permitir pre-comprar antes del lanzamiento?"
                  checked={currentAlbumData.preOrder}
                  onChecked={(event) => handlerBasicUpdateAlbum(event.target.checked, "preOrder")}
                  checkBoxHelper={preSaleCheckBoxHelper}
                  color="#9c27b0"
                />

                {currentAlbumData.preOrder &&
                  <Grid item xs={12}>
                    <CalendarInput selectedDate={currentAlbumData.preOrderDayjsFormat} label=''
                      maxDate={dayjs(currentAlbumData.releaseDayjsFormat).subtract(1, 'day')} minDate={dayjs()} withTimeSelection={true}
                      onChangeDate={(dateValue) => handleSetDate('preorder-date', dateValue, currentAlbumData, dispatch)} />
                  </Grid>
                }

                {currentAlbumData.preOrder &&
                  <CheckboxWithInfo
                    label="Habilitar pre-escucha para la pre-compra"
                    checked={currentAlbumData.preview}
                    onChecked={event => handlerBasicUpdateAlbum(event.target.checked, "preview")}
                    color="#9c27b0"
                    checkBoxHelper="Permitir que los usuarios escuchen un fragmento (30 segundos) de las canciones durante la etapa de Pre-Compra."
                  />
                }
              </Grid>


            </Grid>}

          {showingNotBasicAlbumFields &&
            <Grid container item xs={12} paddingTop={4} justifyContent="center">
              <Grid item xs={8} >
                <TracksTable tracksTableData={tracksDataTable} handleClickAddTrack={handleClickAddTrack} />
              </Grid>
            </Grid>}

          <Grid item xs={12}>
            <NewTrackDialog openDialog={openNewTrackDialog} setOpenNewTrackDialog={setOpenNewTrackDialog} setTracksDataTable={setTracksDataTable}
              tracksDataTable={tracksDataTable} trackData={trackData} setTrackData={setTrackData} circularProgress={(progress) => trackUploadProgress(progress)} />
          </Grid>

          <Grid item xs={12} paddingTop={4}>
            <CardFooter style={{ display: 'inline-flex' }}>
              {(needArtistLabelCoverAndContinue && currentAlbumData.basicFieldsComplete) || openLoader
                ? <ProgressButton
                  textButton={buttonText}
                  loading={openLoader}
                  buttonState={buttonState}
                  onClickHandler={allFieldsValidCreateAlbum}
                  noneIcon={<Save sx={{ color: "rgba(255,255,255, 1)" }} />}
                  noFab={false} />
                : <Button onClick={coverLabelArtistAllValids}>
                  Continuar
                </Button>
              }
            </CardFooter>
          </Grid>

        </Card >

      </Grid >
    ) : <p>TU CUENTA NO ESTA ACTIVA O TODOS TUS ARTISTAS ESTÁN SUSPENDIDOS/INACTIVOS, CONTACTAR CON LA FLOTA.</p>
}

export default NewAlbum;

const textFieldStyle = { width: "60%" };
const textFieldLaFlotaArtistStyle = { width: "40%" };

const cardTitleWhiteStyles = {
  color: "rgba(255,255,255,255)",
  marginTop: "0px",
  minHeight: "auto",
  fontWeight: "300px",
  fontSize: "40px",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  marginBottom: "3px",
  textDecoration: "none"
}

const buttonAddArtist = { marginTop: "2%", backgroundColor: "#9c27b0", '&:hover': { backgroundColor: "#9c27b0" } }
