import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Grid
} from '@mui/material';
import { toWithOutError } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import SuccessDialog from '../../components/Dialogs/SuccessDialog';
import { addPaymentToUserSubtitle, deleteUserDialogText, showConfirmationUpdatePasswordText, showConfirmationUpdatePasswordTitle } from '../../utils/textToShow.utils';
import { Delete } from '@mui/icons-material/';
import EditOrAddFieldsDialog from '../../components/Dialogs/EditOrAddFieldDialog';
import { generateStrongPassword, getPlanNameFromId, getUserById, userCouldHadPayProof, userIsAdmin, userIsRRSS } from 'utils/users.utils';
import { userStateColors, userStatesListShowAdmin, userStatesShowAdmin, USER_SUSPEND_INFRACTION } from "variables/user.variables";
import UserInfractionDialog from "./UserInfractionDialog";
import { editUserDataAndCredentialsFS, getUserAvailableMoney } from "services/BackendCommunication";
import { editUserRedux } from "redux/actions/UsersActions";
import DeleteUserDialog from "./DeleteUserDialog";
import { planesLaFlota } from "variables/financial";
import PayProofDialog from "views/Subscription/PayProofDialog";
import CopyableEditText from "components/TextField/CopyableEditText";
import CalendarInput from "components/Input/CalendarInput";
import ProgressButton from "components/CustomButtons/ProgressButton";
import { getNewUserInfoFromPayment } from "factory/users.factory";
import { getPaymentsByFieldRedux } from "redux/actions/PaymentsActions";
import OkDialog from "components/Dialogs/OkDialog";


const UserActionsDialog = (props) => {

  const { isOpen, handleClose, userId } = props;

  const dispatch = useDispatch();
  const usersFromStore = useSelector(store => store.users);

  const loadingFinancialDataState = { payed: "...", royalties: "...", available: "...", lastRequest: "...", taxesUsd: "..." };

  const user = getUserById(usersFromStore, userId);
  const userIsCancelled = user.userStatus === "USER_CANCELLED";
  const userName = (user.nombre + " " + user.apellido).trim() || "";

  const userStatusToShow = userStatesShowAdmin[user.userStatus] || "SIN ESTADO";
  const userStatusColor = userStateColors[user.userStatus];
  const userPlanToShow = getPlanNameFromId(user.plan);

  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [loadingSavingNewDates, setLoadingSavingNewDates] = useState(false);
  const [buttonState, setButtonState] = useState('none');
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState({ open: false, title: "", subtitle: [""], values: "", type: "" })
  const [openInfractionDialog, setOpenInfractionDialog] = useState(false);
  const [financialInfo, setFinancialInfo] = useState(loadingFinancialDataState);
  const [openPayProofInAction, setOpenPayProofInAction] = useState({ open: false, userId: "" });
  const [newPasswordGenerated, setNewPasswordGenerated] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  let lastPaymentDate = user?.subscription?.lastPaymentDate || "";
  let nextPaymentDate = user?.subscription?.nextPaymentDate || "";
  let lastPayId = user?.subscription?.payId || "";

  const [newNextPaymentDate, setNewNextPaymentDate] = useState(nextPaymentDate);
  const [newLastPaymentDate, setNewLastPaymentDate] = useState(lastPaymentDate);

  let successDialogTitle = "Contraseña generada y cambiada";

  useEffect(() => {
    const getUserFinancialInfo = async () => {
      let { payed, royalties, available, lastRequest, taxesUsd } = await getUserAvailableMoney(user, dispatch);
      setFinancialInfo({ payed, royalties, available, lastRequest, taxesUsd });
    }

    if (isOpen && user.id && financialInfo.payed === "...") {
      getUserFinancialInfo();
    }
  }, [user])

  const handleCloseDelete = () => setOpenDeleteUserDialog(false);

  const handleEditUserState = () => setOpenEditDialog({
    open: true, title: "Cambiar el estado del Usuario", subtitle: ["Solo se cambiará en la APP"],
    handleConfirm: (newValue) => handleConfirmEditUser(newValue, 'userStatus'),
    initialValues: userStatesShowAdmin[user.userStatus] || "", optionsValues: userStatesListShowAdmin,
    values: "", handleCloseDialog: () => handleCloseEditDialog(), type: "only-one-select"
  });

  const handleEditUserPlan = () => setOpenEditDialog({
    open: true, title: "Cambiar el plan del Usuario", subtitle: ["Solo se cambiará en la APP"],
    handleConfirm: (newValue) => handleConfirmEditUser(newValue, 'plan'),
    initialValues: userPlanToShow, optionsValues: planesLaFlota,
    values: "", handleCloseDialog: () => handleCloseEditDialog(), type: "only-one-select"
  });

  const handleEditLastPayId = () => {
    setOpenEditDialog({
      open: true, title: "Asociar último pago con ID", subtitle: addPaymentToUserSubtitle,
      handleConfirm: (newValue) => handleAddPaymentInfo(newValue),
      initialValues: lastPayId, values: "", type: "",
      handleCloseDialog: () => handleCloseEditDialog()
    })
  };

  const handleAddPaymentInfo = async paymentId => {
    setButtonState("loading");
    const paymentFromId = await toWithOutError(dispatch(getPaymentsByFieldRedux('id', paymentId, 1)));
    if (["ERROR", "EMPTY"].includes(paymentFromId)) { setButtonState("error"); return };
    const newUserInfo = getNewUserInfoFromPayment(user, paymentFromId[0]);
    let editResult = await toWithOutError(dispatch(editUserRedux(user, newUserInfo, true, false, false, false)));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
  }

  const handleConfirmEditUser = async (newValue, fieldName) => {
    setButtonState("loading");
    let finalValue = newValue.id ? newValue.id : newValue;
    let fromPaymentAction = fieldName === 'userStatus';
    let editResult = await toWithOutError(dispatch(editUserRedux(user, { [fieldName]: finalValue },
      fromPaymentAction, false, false, false)));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
    if (fieldName === 'userStatus' && (newValue.id === USER_SUSPEND_INFRACTION)) {
      setOpenInfractionDialog(true);
    }
  }

  const handleCloseEditDialog = () => setOpenEditDialog({ open: false, title: "", subtitle: [""], values: "" });

  const handleCloseUserActionsDialog = () => {
    setFinancialInfo(loadingFinancialDataState);
    handleClose();
  }

  const handleEditLastPaymentDate = newDateValue => {
    setNewLastPaymentDate(newDateValue.format());
  }

  const handleEditNextPaymentDate = newDateValue => {
    setNewNextPaymentDate(newDateValue.format());
  }

  const checkIfAnyDateHasChange = () => {
    return (nextPaymentDate !== newNextPaymentDate) || (lastPaymentDate !== newLastPaymentDate);
  }

  const handleAcceptDate = async () => {
    setLoadingSavingNewDates(true);
    const subNewValues = {
      ...user.subscription,
      nextPaymentDate: new Date(newNextPaymentDate).getTime(), lastPaymentDate: new Date(newLastPaymentDate).getTime()
    };
    let editResult = await toWithOutError(dispatch(editUserRedux(user, { subscription: subNewValues },
      true, false, false, false)));
    if (editResult === "ERROR") setButtonState("error");
    setLoadingSavingNewDates(false)
  }

  const generateAndUpdatePassword = async () => {
    setOpenConfirmation(false);
    setLoadingChangePassword(true);
    const generatedPassword = generateStrongPassword(12);
    const changePassResult = await editUserDataAndCredentialsFS({ email: user.email, password: generatedPassword }, dispatch);
    if (changePassResult === "ERROR") { setLoadingChangePassword(false); return "ERROR" };
    setLoadingChangePassword(false);
    const jsxNewPassword = <DialogContentText key={'new pass'}>
      Nueva Contraseña: <b>{generatedPassword}</b>
    </DialogContentText>
    const passwordViewer = <CopyableEditText textToCopy={generatedPassword} color='grey' jsxElementText={jsxNewPassword} />
    setNewPasswordGenerated([passwordViewer]);
  }

  const jsxUserName = <DialogContentText key={'user name'}>
    Nombre Usuario: <b>{userName}</b>
  </DialogContentText>

  const jsxUserId = <DialogContentText key={'user ID'}>
    ID Usuario: <b>{`${user.id}`}</b>
  </DialogContentText>

  const jsxUserStatus = <DialogContentText key={'user status'}>
    Estado: <b style={{ color: userStatusColor }}>{userStatusToShow}</b>
  </DialogContentText>

  const jsxUserPlan = <DialogContentText key={'user plan'}>
    Plan: <b>{userPlanToShow}</b>
  </DialogContentText>

  const jsxLastPayId = <DialogContentText key={'last pay id'}>
    ID Último Pago: <b>{lastPayId}</b>
  </DialogContentText>

  return (
    <>

      <PayProofDialog open={openPayProofInAction.open} setOpen={setOpenPayProofInAction} showOrLoad={'show'} userId={openPayProofInAction.userId} />

      <OkDialog open={openConfirmation} text={showConfirmationUpdatePasswordText} onOk={generateAndUpdatePassword}
        onClose={() => setOpenConfirmation(false)} title={showConfirmationUpdatePasswordTitle} />

      <SuccessDialog isOpen={newPasswordGenerated.length > 0} title={successDialogTitle} contentTexts={newPasswordGenerated}
        handleClose={() => setNewPasswordGenerated([])} successImageSource="/images/success.jpg" size="sm" />

      {openDeleteUserDialog && <DeleteUserDialog isOpen={openDeleteUserDialog} setIsOpen={setOpenDeleteUserDialog} user={user} handleClose={handleCloseDelete}
        title={"Eliminar Usuario"} textName={user.email} textContent={deleteUserDialogText}
        deleteButtonText={"Eliminar"} buttonState={'delete'}
      />}

      <EditOrAddFieldsDialog loading={buttonState === "loading"}
        buttonState={buttonState} editOptions={openEditDialog} setEditOptions={setOpenEditDialog} />

      {openInfractionDialog && <UserInfractionDialog userData={user} openInfractionDialog={openInfractionDialog}
        setOpenInfractionDialog={setOpenInfractionDialog} />}

      <Dialog
        maxWidth="sm"
        id="info-dialog"
        open={isOpen}
        onClose={handleCloseUserActionsDialog}>

        <DialogTitle id="title-info-dialog">
          {`Acciones para el Usuario: ${user.email}`}
        </DialogTitle>

        <DialogContent>

          <Grid item xs={12} paddingBottom={1}>

            {user.email && <CopyableEditText textToCopy={userName} color='grey' jsxElementText={jsxUserName} />}
            {user.id && <CopyableEditText textToCopy={user.id} color='grey' jsxElementText={jsxUserId} />}
            {user.userStatus !== undefined && <CopyableEditText color='grey' jsxElementText={jsxUserStatus} handleEdit={handleEditUserState} />}
            <CopyableEditText textToCopy={user.id} color='grey' jsxElementText={jsxUserPlan} handleEdit={handleEditUserPlan} />
            <CopyableEditText textToCopy={lastPayId} color='grey' jsxElementText={jsxLastPayId} handleEdit={handleEditLastPayId} />

            {userCouldHadPayProof(user) && <DialogContentText key={'last pay proof'}>
              Último Comprobante: <b onClick={() => setOpenPayProofInAction({ open: true, userId })}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                Ver el ultimo comprobante de pago.
              </b>
            </DialogContentText>}

            <DialogContentText sx={{ paddingTop: 1 }} key={'royalties'}>
              Regalías generadas por el Usuario: <b>{`${financialInfo?.royalties} USD`}</b>
            </DialogContentText>

            <DialogContentText key={'taxes'}>
              Impuestos del Usuario: <b>{`${financialInfo?.taxesUsd} USD`}</b>
            </DialogContentText>

            <DialogContentText key={'withdawals'}>
              Dinero retirado por el Usuario: <b>{`${financialInfo?.payed} USD`}</b>
            </DialogContentText>

            <DialogContentText key={'last payed'}>
              Último retiro del Usuario: <b>{`${financialInfo?.lastRequest}`}</b>
            </DialogContentText>

            <DialogContentText sx={{ paddingTop: 1, textDecoration: 'underline' }} key={'available money'}>
              Dinero disponible del Usuario: <b>{`${financialInfo?.available} USD`}</b>
            </DialogContentText>
          </Grid>

          <Grid container paddingTop={3} textAlign="center">

            {lastPaymentDate && <Grid item xs={6}>
              <CalendarInput selectedDate={newLastPaymentDate} width={"10em"}
                label="Fecha Último Pago" onChangeDate={handleEditLastPaymentDate}
              />
            </Grid>}

            {nextPaymentDate && <Grid item xs={6}>
              <CalendarInput selectedDate={newNextPaymentDate} width={"10em"}
                label="Fecha Renovación" onChangeDate={handleEditNextPaymentDate}
              />
            </Grid>}

            {checkIfAnyDateHasChange() && <Grid item xs={12} padding={2}>
              <ProgressButton
                textButton={"Guadar Fechas"}
                loading={loadingSavingNewDates}
                buttonState={buttonState}
                onClickHandler={handleAcceptDate}
                noFab={true}
                buttonFullWidth={false} />
            </Grid>}

            {!userIsCancelled && <Grid item xs={12} paddingTop={3}>
              <Button
                onClick={() => setOpenDeleteUserDialog(true)}
                sx={{ backgroundColor: '#c20202', color: 'white', '&:hover': { backgroundColor: '#c20202' }, width: "50%" }}
                endIcon={<Delete />}>
                Cancelar Suscripción
              </Button>
            </Grid>}

            <Grid item xs={12} paddingTop={2}>
              <ProgressButton
                textButton={"Cambiar Contraseña"}
                loading={loadingChangePassword}
                buttonState={buttonState}
                onClickHandler={() => setOpenConfirmation(true)}
                noFab={true}
                buttonFullWidth={false}
                buttonSx={{ width: "50%" }} />
            </Grid>

          </Grid>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseUserActionsDialog} color="primary">
            Atrás
          </Button>
        </DialogActions>
      </Dialog >
    </>
  )
}

export default UserActionsDialog;

UserActionsDialog.defaultProps = {
  isOpen: false,
}

UserActionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}