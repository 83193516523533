import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import {
  Button, Grid, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Alert,
  Backdrop, CircularProgress, TextField
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { Person } from '@mui/icons-material';
import { authUpdateEmail, authUpdatePassword, reauthenticateWithCredentialOk } from "../../services/AuthServices";
import { useDispatch, useSelector } from 'react-redux';
import { userDataChangeEmailRedux } from "../../redux/actions/UserDataActions";
import TextFieldPassword from "components/TextField/TextFieldPassword";
import { toWithOutError } from "utils";
import { errorPasswordsNotEqualTitle, errorUpdatingEmailSubtitle, errorUpdatingEmailTitle, errorUpdatingPasswordSubtitle, errorUpdatingPasswordTitle } from "utils/textToShow.utils";
// import { isValidEmail } from "utils/users.utils";
import SuccessDialog from "./SuccessDialog";

const ReauthenticateDialog = ({ isOpen, setIsOpen }) => {

  const dispatch = useDispatch();
  const userData = useSelector(store => store.userData);

  const [wantToChangeEmail, setWantToChangeEmail] = useState(false);
  const [wantToChangePassword, setWantToChangePassword] = useState(false);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [reauthSuccess, setReauthSuccess] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [openLoader, setOpenLoader] = useState(false);
  const [openErrorInfo, setOpenErrorInfo] = useState({ open: false, title: "", text: [""] });

  const handleCloseErrorInfo = () => setOpenErrorInfo({ open: false, title: "", text: [""] });

  const handleReauthenticate = async () => {
    setOpenLoader(true);
    const resultReauthenticate = await reauthenticateWithCredentialOk(currentPassword);
    if (resultReauthenticate === "SUCCESS") setReauthSuccess(true);
    else setReauthSuccess("ERROR");
    setOpenLoader(false);
  }

  const updateEmail = async (newEmail, password) => {
    let resultUpdateEmail = await authUpdateEmail(newEmail, dispatch);
    if (resultUpdateEmail !== "ERROR") {
      resultUpdateEmail = await toWithOutError(dispatch(userDataChangeEmailRedux(userData.email, newEmail, userData.id, password)));
      if (resultUpdateEmail === "ERROR") {
        setOpenErrorInfo({ open: true, title: errorUpdatingEmailTitle, text: errorUpdatingEmailSubtitle });
        return "ERROR";
      }
    }
    else { setOpenErrorInfo({ open: true, title: errorUpdatingEmailTitle, text: errorUpdatingEmailSubtitle }); return "ERROR"; }
  }

  const updatePassword = async newPassword => {
    const resultUpdatePassword = await authUpdatePassword(newPassword, userData.id, userData.email, dispatch);
    if (resultUpdatePassword === "ERROR") {
      setOpenErrorInfo({ open: true, title: errorUpdatingPasswordTitle, text: errorUpdatingPasswordSubtitle });
      return "ERROR";
    }
  }

  const handleUpdateCredentials = async () => {
    let changeMaked = false; let results = ""; let emailToChange = false; let passToChange = false;
    setOpenLoader(true);
    // if (currentEmail !== "") {
    //   if (currentEmail === userData.email) { setOpenErrorInfo({ title: "El Email ingresado es el mismo que el actual.", open: true }); setOpenLoader(false); return; }
    //   if (!isValidEmail(currentEmail)) { setOpenErrorInfo({ title: "Email con formato inválido.", open: true }); setOpenLoader(false); return; }
    //   emailToChange = true;
    // }

    if (currentPassword !== "" && currentPassword.toString() === repeatedPassword.toString()) passToChange = true;
    else if (currentPassword !== "") {
      setOpenErrorInfo({ title: errorPasswordsNotEqualTitle, open: true });
      setOpenLoader(false);
      return;
    }

    // Aplico los cambios
    // if (emailToChange) {
    //   results = await updateEmail(currentEmail);
    //   if (results !== "ERROR") changeMaked = true;
    //   // Aca hacer algo si da error
    // }
    if (passToChange) {
      results = await updatePassword(currentPassword);
      if (results !== "ERROR") changeMaked = true;
      // Aca hacer algo si da error
    }
    setOpenLoader(false);
    changeMaked && setSuccessUpdate(true);
  }

  const handleCloseDialog = () => {
    setIsOpen(false);
    setReauthSuccess(false);
    setCurrentEmail("");
    setCurrentPassword("");
    setWantToChangeEmail(false);
    setWantToChangePassword(false);
    setSuccessUpdate(false);
    handleCloseErrorInfo();
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') handleReauthOrUpdate();
  }

  const handleReauthOrUpdate = () => (reauthSuccess === true) ? handleUpdateCredentials() : handleReauthenticate();

  // const handleWantToChangeEmail = () => setWantToChangeEmail(true);

  // const handleNotToChangeEmail = () => {
  //   setWantToChangeEmail(false);
  //   setCurrentEmail("");
  // }

  const handleWantToChangePassword = () => { setCurrentPassword(""); setWantToChangePassword(true); }

  const handleNotToChangePassword = () => {
    setWantToChangePassword(false);
    setCurrentPassword("");
    setRepeatedPassword("");
  }

  useEffect(() => {
    if (reauthSuccess || !isOpen) {
      setCurrentEmail("");
      setCurrentPassword("");
    }
  }, [reauthSuccess, isOpen])

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      id="reauthenticate-dialog"
      open={isOpen}
      onClose={handleCloseDialog}>

      <SuccessDialog isOpen={successUpdate} title={`Credenciales actualizadas`}
        contentTexts={[[`Los datos se han actualizado correctamente.`]]}
        handleClose={handleCloseDialog} successImageSource="/images/successArtists.jpg" />

      {/* <InfoActionDialog id='auth-errors' isOpen={openErrorInfo.open} handleClose={handleCloseErrorInfo}
        title={openErrorInfo.title} contentTexts={openErrorInfo.text} /> */}

      <Backdrop open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DialogTitle id="title-reauthenticate-dialog">
        <Typography component="span" sx={{ fontSize: "1.4rem", fontWeight: 500 }}>{
          // `${reauthSuccess === true ? "Puedes editar tu Email, tu Contraseña o ambas" : "Ingresa tu email y contraseña actuales"}`
          `${reauthSuccess === true ? "Puedes editar tu Contraseña" : "Ingresa tu contraseña actual"}`
        }</Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="title-delete-dialog-elementTarget">
          {`${reauthSuccess === true
            // ? "No es necesario cambiar tanto el email como la contraseña, puedes cambiar solo una de ellas o ambas."
            ? "Procede a cambiar la contraseña por una nueva."
            : "Debes reingresar tus credenciales para confirmar que eres el dueño de la cuenta."}`}
        </DialogContentText>

        {reauthSuccess === "ERROR" &&
          <Alert severity="error">
            Combinación Usuario/Constraseña incorrecta.
          </Alert>}

        {openErrorInfo.open &&
          <Alert severity="error">
            {openErrorInfo.title}
          </Alert>}


        {/* {reauthSuccess === true ? wantToChangeEmail
          ? <Grid sx={{ textAlign: 'center' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label={"Nuevo Correo Electrónico"}
              autoFocus
              value={currentEmail}
              onChange={(evento) => setCurrentEmail(evento.target.value.toLowerCase().trim())}
            />
            <Button sx={{ marginTop: "8px", width: "16em" }} variant="contained" onClick={handleNotToChangeEmail} color="secondary">
              No cambiar Email
            </Button>
          </Grid>

          : <Grid sx={{ textAlign: 'center' }} padding={2}>
            <Button sx={{ width: "16em" }} variant="contained" onClick={handleWantToChangeEmail} color="secondary">
              Cambiar Email
            </Button>
          </Grid>
          : null
        }
 */}
        {!(reauthSuccess === true) && <TextFieldPassword
          margin="normal"
          fullWidth
          label={"Contraseña"}
          password={currentPassword}
          onChange={event => setCurrentPassword(event.target.value)}
          onKeyPress={handleKeyPress}
        />}

        {(reauthSuccess === true) && wantToChangePassword && <TextFieldPassword
          margin="normal"
          fullWidth
          label="Nueva Contraseña"
          password={currentPassword}
          onChange={event => setCurrentPassword(event.target.value)}
          onKeyPress={handleKeyPress}
        />}

        {(reauthSuccess === true) && wantToChangePassword &&
          <TextFieldPassword
            margin="normal"
            id="repeatedPassword"
            label="Repite la Contraseña"
            fullWidth
            password={repeatedPassword}
            onChange={event => setRepeatedPassword(event.target.value)}
            onKeyPress={handleKeyPress}
          />}

        {(reauthSuccess === true) && <Grid sx={{ textAlign: 'center' }} padding={1}>
          <Button sx={{ width: "16em" }} variant="contained"
            onClick={wantToChangePassword ? handleNotToChangePassword : handleWantToChangePassword} color="secondary">
            {wantToChangePassword ? "No Cambiar Contraseña" : "Cambiar Contraseña"}
          </Button>
        </Grid>}

      </DialogContent>

      <DialogActions id="actions-reauthenticate-dialog">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleCloseDialog}
              color="primary">
              Atrás
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              endIcon={<Person />}
              onClick={handleReauthOrUpdate}
              fullWidth>
              {`${(reauthSuccess === true) ? "Confirmar" : "Reingresar"}`}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ReauthenticateDialog;
