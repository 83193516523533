import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker, MobileDatePicker, esES } from '@mui/x-date-pickers/';
import dayjs from 'dayjs';

const CalendarInput = ({ selectedDate, onChangeDate, label, maxDate, minDate, withTimeSelection, width }) => {

  // Tengo que mandarle en Toolbar uno particular para poder cambiar el texto: 'Select date and time' 
  // https://github.com/mui/material-ui-pickers/blob/next/lib/src/DateTimePicker/DateTimePickerToolbar.tsx
  return (withTimeSelection
    ? <LocalizationProvider
      localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
      dateAdapter={AdapterDayjs}
    >
      <MobileDateTimePicker
        label={label}
        value={dayjs(selectedDate)}
        ampm={false}
        minDate={minDate}
        maxDate={maxDate}
        minutesStep={10}
        inputFormat="DD/MM/YYYY HH:mm"
        componentsProps={{ width: '10em' }}
        onChange={onChangeDate}
        renderInput={(params) => <TextField {...params} sx={{ width: width ? width : '22em' }} />}
      />
    </LocalizationProvider>
    : <LocalizationProvider
      localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
      dateAdapter={AdapterDayjs}
    >
      <MobileDatePicker
        label={label}
        value={dayjs(selectedDate)}
        minDate={minDate}
        maxDate={maxDate}
        inputFormat="DD/MM/YYYY"
        onChange={onChangeDate}
        renderInput={(params) => <TextField {...params} sx={{ width: width ? width : '22em' }} />}
      />
    </LocalizationProvider>
  )

}

export default CalendarInput;