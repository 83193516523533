import { generateDiscountNameFromUserAndType } from 'factory/discounts.factory';
import * as ReducerTypes from 'redux/actions/Types';
import { createUserPayoutInDbAndFS, deletePayoutInDbAndFS, updateUserPayoutInDbAndFS } from 'services/BackendCommunication';
import { getPayIdField, getPaymentMethodDBFromPayout } from 'utils/payouts.utils';
import { DISCOUNT_BY_ROYALTIES } from 'variables/discounts.variables';

export const payoutsAddStore = payouts => {
  return { type: ReducerTypes.PAYOUTS_ADD, payload: payouts }
}

export const payoutsAddAndDeleteOthersStore = payouts => {
  return { type: ReducerTypes.PAYOUTS_ADD_AND_DELETE_OTHERS, payload: payouts }
}

export const payoutDeleteByIdStore = payoutId => {
  return { type: ReducerTypes.PAYOUTS_DELETE_BY_ID, payload: payoutId }
}

export const payoutEditByIdStore = newPayout => {
  return { type: ReducerTypes.PAYOUTS_EDIT_BY_ID, payload: newPayout }
}

export const payoutCreateRequestRedux = (newPayout, isAdmin) => async dispatch => {

  delete newPayout.stats;
  newPayout.status = "REQUESTED";
  newPayout.transferTotalUsd = parseFloat(newPayout.transferTotalUsd);
  newPayout.alreadyPaidUsd = parseFloat(newPayout.alreadyPaidUsd);
  newPayout.historicTotalUsd = parseFloat(newPayout.historicTotalUsd);
  newPayout.requestDate = new Date().toISOString().split('T')[0];
  newPayout.paymentMethod = getPaymentMethodDBFromPayout(newPayout);
  
  // Si genero un CUPON, el pago lo pongo automaticamente como COMPLETO.
  // Y el alreadyPaidUsd tambien se lo sumo ya.
  if (newPayout.cupon) {
    newPayout.status = "COMPLETED";
    newPayout.transferDate = newPayout.requestDate;
    newPayout.transferMonth = newPayout.transferDate.slice(0, 7) + '-01';
    let cuponName = generateDiscountNameFromUserAndType(newPayout.ownerEmail, newPayout.ownerId, DISCOUNT_BY_ROYALTIES, isAdmin);
    newPayout.cupon = cuponName;
    newPayout.alreadyPaidUsd = newPayout.alreadyPaidUsd + newPayout.transferTotalUsd;
  }
  
  let resultCreatePayout = await createUserPayoutInDbAndFS(newPayout, isAdmin, dispatch);
  if (resultCreatePayout === "ERROR") return "ERROR";

  dispatch(payoutsAddStore([newPayout]));
  return { result: "SUCCESS", payout: newPayout };
}

export const payoutCompleteRequestRedux = (updatedPayout, payId) => async dispatch => {
  let sendNotification = true;
  updatedPayout.status = "COMPLETED";
  updatedPayout.alreadyPaidUsd = parseFloat(updatedPayout.alreadyPaidUsd) + parseFloat(updatedPayout.transferTotalUsd);
  updatedPayout.transferDate = new Date().toISOString().split('T')[0];
  updatedPayout.transferMonth = updatedPayout.transferDate.slice(0, 7) + '-01';

  let payIdField = getPayIdField(updatedPayout);
  updatedPayout[payIdField] = payId;

  let resultCreatePayout = await updateUserPayoutInDbAndFS(updatedPayout, sendNotification, dispatch);
  if (resultCreatePayout === "ERROR") return "ERROR";

  dispatch(payoutEditByIdStore(updatedPayout));
  return "success";
}

export const payoutEditRedux = updatedPayout => async dispatch => {
  let sendNotification = false;
  let resultCreatePayout = await updateUserPayoutInDbAndFS(updatedPayout, sendNotification, dispatch);
  if (resultCreatePayout === "ERROR") return "ERROR";

  dispatch(payoutEditByIdStore(updatedPayout));
  return "success";
}

export const payoutDeleteRedux = payoutId => async dispatch => {
  let resultDeletePayout = await deletePayoutInDbAndFS(payoutId);
  if (resultDeletePayout === "ERROR") return "ERROR";

  dispatch(payoutDeleteByIdStore(payoutId));
  return "success";
}

export const payoutsSignOut = () => {
  return { type: ReducerTypes.PAYOUTS_SIGN_OUT }
}