import React, { useState } from "react";
import { Grid, CircularProgress, Backdrop } from '@mui/material';
import InfoActionDialog from '../../components/Dialogs/InfoActionDialog';
import AlbumsTable from "views/Albums/AlbumsTable";
import ArtistsTable from "views/Artists/ArtistsTable";
import AlbumsCountStats from "views/Albums/AlbumsCountStats";
import ArtistsCountStats from "views/Artists/ArtistsCountStats";
import { useSelector } from "react-redux";
import { userIsAdmin } from "utils/users.utils";

const DashboardAdmin = () => {

  const userData = useSelector(store => store.userData);
  const isAdmin = userIsAdmin(userData.rol);
  
  // COSAS DEL BUSCADOR
  const [openLoaderDashboard, setOpenLoaderDashboard] = useState(false);
  const [openEmptySearch, setOpenEmptySearch] = useState(false);
  const [openErrorSearch, setOpenErrorSearch] = useState(false);

  return (
    <Grid container spacing={2}>

      <Backdrop open={openLoaderDashboard}>
        <CircularProgress />
      </Backdrop>

      <Grid container item spacing={4} sx={{ justifyContent: "center" }}>

        <InfoActionDialog id='empty-results' isOpen={openEmptySearch} handleClose={() => setOpenEmptySearch(false)}
          title={"La búsqueda no arrojó resultados"} contentTexts={[]} />

        <InfoActionDialog id='error-searching' isOpen={openErrorSearch} handleClose={() => setOpenErrorSearch(false)}
          title={"Hubo un error al realizar la búsqueda."} contentTexts={["Por favor, intente nuevamente."]} />

        {!openLoaderDashboard &&
          <>
            {isAdmin && <Grid item xs={12} padding={1} sx={{ textAlign: "left" }}>
              <AlbumsCountStats />
            </Grid>}

            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <AlbumsTable setOpenErrorSearch={setOpenErrorSearch} setOpenEmptySearch={setOpenEmptySearch} />
            </Grid>

            {isAdmin && <Grid item xs={12} padding={1} sx={{ textAlign: "left" }}>
              <ArtistsCountStats />
            </Grid>}

            <Grid item xs={12} sx={{ textAlign: "center" }} paddingTop={3}>
              <ArtistsTable setOpenErrorSearch={setOpenErrorSearch} setOpenEmptySearch={setOpenEmptySearch} />
            </Grid>
          </>
        }

      </Grid>

    </Grid >
  );
}

export default DashboardAdmin;