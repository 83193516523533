// Types
export const DISCOUNT_BY_ROYALTIES = "DISCOUNT_BY_ROYALTIES";
export const DISCOUNT_BY_UPGRADE = "DISCOUNT_BY_UPGRADE";
export const DISCOUNT_BY_NO_PAY_CART = "DISCOUNT_BY_NO_PAY_CART";
export const DISCOUNT_BY_FRIENDS = "DISCOUNT_BY_FRIENDS";
export const DISCOUNT_BY_CREDIT_FRIENDS = "DISCOUNT_BY_CREDIT_FRIENDS";
export const DISCOUNT_BY_OTHER = "DISCOUNT_BY_OTHER";
export const DISCOUNT_BY_DEAL = "DISCOUNT_BY_DEAL";
export const DISCOUNT_BY_VIP = "DISCOUNT_BY_VIP";

// Status
export const DISCOUNT_STATUS_ACTIVE = "DISCOUNT_STATUS_ACTIVE";
export const DISCOUNT_STATUS_INACTIVE = "DISCOUNT_STATUS_INACTIVE";
export const DISCOUNT_STATUS_CLAIMED = "DISCOUNT_STATUS_CLAIMED";

export const discountTypesList = [
  DISCOUNT_BY_ROYALTIES, DISCOUNT_BY_UPGRADE, DISCOUNT_BY_NO_PAY_CART,
  DISCOUNT_BY_FRIENDS, DISCOUNT_BY_OTHER, DISCOUNT_BY_DEAL, DISCOUNT_BY_VIP,
  DISCOUNT_BY_CREDIT_FRIENDS
]

export const discountTypesListToShow = [
  "Canje Regalías", "Canje por Upgrade", "Carrito",
  "Invitación Amigos", "Otros", "Contratos", "VIP",
  "Credito por Amigos"
]

export const discountTypesAndNamesList = [
  { id: DISCOUNT_BY_ROYALTIES, name: "Canje Regalías" }, { id: DISCOUNT_BY_UPGRADE, name: "Canje por Upgrade" },
  { id: DISCOUNT_BY_NO_PAY_CART, name: "Carrito" }, { id: DISCOUNT_BY_FRIENDS, name: "Invitación Amigos" },
  { id: DISCOUNT_BY_OTHER, name: "Otros" }, { id: DISCOUNT_BY_DEAL, name: "Contratos" },
  { id: DISCOUNT_BY_VIP, name: "VIP" }, { id: DISCOUNT_BY_CREDIT_FRIENDS, name: "Crédito por Amigos" }
]

export const discountsStringsShowToId = {
  "Canje Regalías": DISCOUNT_BY_ROYALTIES,
  "Canje por Upgrade": DISCOUNT_BY_UPGRADE,
  "Carrito": DISCOUNT_BY_NO_PAY_CART,
  "Invitación Amigos": DISCOUNT_BY_FRIENDS,
  "Otros": DISCOUNT_BY_OTHER,
  "Contratos": DISCOUNT_BY_DEAL,
  "VIP": DISCOUNT_BY_VIP,
  "Crédito por Amigos": DISCOUNT_BY_CREDIT_FRIENDS
}

export const discountsTypesToShow = {
  DISCOUNT_BY_ROYALTIES: "Canje Regalías",
  DISCOUNT_BY_UPGRADE: "Canje por Upgrade",
  DISCOUNT_BY_NO_PAY_CART: "Carrito",
  DISCOUNT_BY_FRIENDS: "Invitación Amigos",
  DISCOUNT_BY_OTHER: "Otros",
  DISCOUNT_BY_DEAL: "Contratos",
  DISCOUNT_BY_VIP: "VIP",
  DISCOUNT_BY_CREDIT_FRIENDS: "Crédito por Amigos"
}

export const discountsStatusToShow = {
  DISCOUNT_STATUS_ACTIVE: "Activo",
  DISCOUNT_STATUS_INACTIVE: "Inactivo",
  DISCOUNT_STATUS_CLAIMED: "Reclamado"
}

export const discountStatesListToShow = ["Activo", "Inactivo", "Reclamado"];

export const discountsStatusNameToStatusId = {
  "Activo": DISCOUNT_STATUS_ACTIVE,
  "Inactivo": DISCOUNT_STATUS_INACTIVE,
  "Reclamado": DISCOUNT_STATUS_CLAIMED,
  "Todos los estados": "all"
}

export const discountStateAndNamesList = [
  { id: DISCOUNT_STATUS_ACTIVE, name: "Activo" },
  { id: DISCOUNT_STATUS_INACTIVE, name: "Inactivo" },
  { id: DISCOUNT_STATUS_CLAIMED, name: "Reclamado" },
]

export const discountStateColors = {
  DISCOUNT_STATUS_ACTIVE: "rgb(93, 109, 15)",
  DISCOUNT_STATUS_INACTIVE: "red",
  DISCOUNT_STATUS_CLAIMED: "rgb(231, 190, 66)"
}

export const percentageOrAmountList = [{ id: 'percentage', name: 'Porcentaje' }, { id: 'amount', name: 'Monto Fijo' }];
export const percentageOrAmountToShow = { 'percentage': "Porcentaje", 'amount': "Monto Fijo" };