import React, { useState } from "react";
import {
  Button, Dialog, DialogTitle, DialogContentText, DialogContent,
  CardMedia, Card, Typography, Grid, CardContent, CardActions, Backdrop, CircularProgress
} from '@mui/material';
import { materialColor } from "variables/colors";
import { paymentsPayDelete, paymentsPayUpdated } from "redux/actions/PaymentsActions";
import { toWithOutError } from "utils";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { oneDayInMS } from "utils/timeRelated.utils";
import { SUB_ORDER_CANCELLED_BY_USER, SUB_ORDER_PLAN_SELECTED, SUB_PREF_USER_CANCELLED } from '../../variables/financial';
import { paymentStatusIsPendingPay } from "utils/payments.utils";

const ActivePaymentDialog = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isOpen, handleClose, payment, plan } = props;

  let { title, subtitle, imgSource, maxArtists, priceArs, priceUsd, discountText } = plan;
  let textCantArtists = maxArtists === 1 ? "1 Artista" : maxArtists + " Artistas";

  let paymentIsPendingPay = paymentStatusIsPendingPay(payment.status);

  const [activePaymentLoader, setActivePaymentLoader] = useState(false);

  const handleNewOrder = async () => {
    // aca elimino el pedido viejos inactivos/no completados.
    let deletePaymentDoc = "";
    setActivePaymentLoader(true);
    if (paymentIsPendingPay)
      deletePaymentDoc = await toWithOutError(dispatch(paymentsPayUpdated({ ...payment, status: SUB_ORDER_CANCELLED_BY_USER })))
    else deletePaymentDoc = await toWithOutError(dispatch(paymentsPayDelete(payment.id)));
    if (deletePaymentDoc === "ERROR") return;
    setActivePaymentLoader(false);
    handleClose();
  }

  const handleContinueOrder = async () => {
    if (paymentIsPendingPay) {
      navigate("/admin/checkout");
      return;
    }
    setActivePaymentLoader(true);
    // Sumo un día más de prórroga.
    payment.whenCreatedTS = payment.whenCreatedTS + oneDayInMS;
    payment.status = SUB_ORDER_PLAN_SELECTED;
    let createPaymentDoc = await toWithOutError(dispatch(paymentsPayUpdated(payment)));
    if (createPaymentDoc === "ERROR") return;
    setActivePaymentLoader(false);
    navigate("/admin/checkout");
  }

  const cardStyle = { backgroundColor: materialColor, width: "100%" };
  const dialogSx = { color: materialColor, borderRadius: "4em", width: "95%" };

  return (
    <Dialog
      maxWidth="md"
      id={"active-payment-dialog"}
      open={isOpen}
      onClose={handleClose}
      sx={dialogSx}>


      <Card sx={cardStyle} raised={false}>

        <DialogTitle id={"title-active-payment-dialog"} color="white" sx={{ textAlign: 'center' }}>
          Detectamos que tienes un pedido activo sin finalizar
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          <DialogContentText key={"active-payment-0"} color="white">
            Puedes continuar con este pedido,
          </DialogContentText>
          <DialogContentText key={"active-payment-1"} color="white">
            o puedes realizar uno nuevo (se eliminará el pedido anterior)
          </DialogContentText>
          {paymentIsPendingPay && <DialogContentText key={"active-payment-pending"} color="white">
            {"Si ya realizaste el pago, solo debés cargar el comprobante en la siguiente pantalla."}
          </DialogContentText>}
        </DialogContent>
        <CardMedia
          component="img"
          height={300}
          image={imgSource}
          alt="image plan"
        />

        <Backdrop open={activePaymentLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>


        <CardContent>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontSize: 22, fontWeight: 400 }} color="white">
              {title}
            </Typography>
            <Typography sx={{ fontSize: 18, fontWeight: 350 }} color="white">
              {subtitle}
            </Typography>
            <Typography sx={{ fontSize: 25, fontWeight: 500 }} color="white">
              {`AR$ ${priceArs}/año`}
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 300, marginTop: 1 }} color="white">
              {discountText}
            </Typography>
            <Typography sx={{ fontSize: 22, fontWeight: 300, marginTop: 1 }} color="white">
              {textCantArtists}
            </Typography>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center', }}>
          <Button onClick={handleNewOrder} size="small" variant='contained'
            sx={{ color: "white", fontSize: "1em", width: paymentIsPendingPay ? '14em' : '12em' }}>
            {paymentIsPendingPay ? "Cancelar Pedido" : "Nuevo Pedido"}
          </Button>
          <Button onClick={handleContinueOrder} size="small" variant='contained'
            sx={{ color: "white", fontSize: "1em", width: paymentIsPendingPay ? '14em' : '12em' }}>
            {paymentIsPendingPay ? "Cargar Comprobante" : "Continuar Pedido"}
          </Button>
        </CardActions>
      </Card>

    </Dialog>
  )
}

export default ActivePaymentDialog;

ActivePaymentDialog.defaultProps = {
  isOpen: false,
  title: "",
  contentText: ""
}
