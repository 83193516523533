import { useState, useEffect } from 'react';
import { ResponsiveContainer, Bar, ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem, Backdrop, CircularProgress } from '@mui/material';

import AnalyticEcommerce from 'components/Card/AnalyticEcommerce';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomQueryUsers } from 'services/UserStats';
import { Paper } from '@mui/material';
import { cohesiveGreen, cohesiveOrange, cohesiveViola } from 'variables/colors';
import { getEarnsByDates, getStatsByDates, updateMonthStats } from 'services/PaymentStats';
import { formatAllNumber } from 'utils';
import { userIsAdmin, userIsRRSS } from 'utils/users.utils';
import CalendarInput from 'components/Input/CalendarInput';
import { monthsSpanish, monthsWithIdAndName } from 'utils/timeRelated.utils';
import { Button } from '../../../node_modules/@mui/material/index';

const PaymentsStats = () => {

  const dispatch = useDispatch();
  const userData = useSelector(store => store.userData);
  const userRol = userData.rol;
  const isAdmin = userIsAdmin(userRol);

  const defaultPaymentStats = {
    paymentsArs: 0, paymentsAmountArs: 0, cuponsAmountArs: 0,
    paymentsUsd: 0, paymentsAmountUsd: 0, cuponsAmountUsd: 0,
  };

  const today = new Date();
  const defaultDateRangeValues = {
    init: new Date(today.getFullYear(), today.getMonth(), 1),
    end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    rule: 'month'
  }

  const [refreshStats, setRefreshStats] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(defaultDateRangeValues);
  const [paymentsStats, setPaymentsStats] = useState(defaultPaymentStats);
  const [amountByDayDataArs, setAmountByDayDataArs] = useState("");
  const [amountByDayDataUsd, setAmountByDayDataUsd] = useState("");

  useEffect(() => {
    const getPaymentsByPeriod = async () => {
      let data = await getEarnsByDates(dateRange);
      setAmountByDayDataArs(data.resultArs);
      setAmountByDayDataUsd(data.resultUsd);
    }
    getPaymentsByPeriod();
  }, [dateRange, refreshStats]);

  useEffect(() => {
    const getStatsPayments = async () => {
      let stats = await getStatsByDates(dateRange);
      setPaymentsStats(stats)
    }
    getStatsPayments();
  }, [dateRange, refreshStats]);

  // const handleEditDateRange = newDateValue => {
  //   setDateRange(newDateValue.format());
  // }

  const handleEditYear = event => setDateRange({ ...dateRange, year: event.target.value, rule: 'month' });
  const handleEditMonth = event => setDateRange({ ...dateRange, month: event.target.value, rule: 'month' });

  const udpateActualMonthStats = async () => {
    setLoading(true);
    const resultUpdating = await updateMonthStats(dateRange.month, dateRange.year, dispatch);
    if (resultUpdating === "SUCCESS") {
      setPaymentsStats(defaultPaymentStats);
      setAmountByDayDataArs("");
      setAmountByDayDataUsd("");
      setRefreshStats(!refreshStats);
    }
    setLoading(false);
  }

  return (isAdmin && !userIsRRSS(userRol)) ? (
    <Grid container spacing={2}>

      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid item xs={12} textAlign='center'>
        <Button onClick={udpateActualMonthStats} variant='contained'>Actualizar</Button>
      </Grid>

      <Grid container item xs={12} spacing={2}>
        <Grid item>
          <FormControl>
            <InputLabel id="year-label">Año</InputLabel>
            <Select
              labelId="year-label-id"
              id="year"
              value={dateRange.year}
              label="Año"
              onChange={handleEditYear}
            >
              {[2019, 2020, 2021, 2022, 2023, 2024].map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl>
            <InputLabel id="month-label">Mes</InputLabel>
            <Select
              labelId="month-label-id"
              id="month"
              value={dateRange.month}
              label="Mes"
              onChange={handleEditMonth}
            >
              {monthsWithIdAndName.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

      </Grid>

      <Grid item xs={12} lg={6}>

        <Grid item xs={12} textAlign="center" padding={2}>
          <Typography sx={{ fontSize: "1.5em", fontWeight: 500 }}>Pagos en ARS</Typography>
        </Grid>

        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Paper elevation={0}>
              <AnalyticEcommerce title="Pedidos en ARS" count={paymentsStats.paymentsArs} />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Paper elevation={0}>
              <AnalyticEcommerce title="Suma en ARS" count={"ARS " + formatAllNumber(paymentsStats.paymentsAmountArs, 0)} />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Paper elevation={0}>
              <AnalyticEcommerce title="Cupones en ARS" count={"ARS " + formatAllNumber(paymentsStats.cuponsAmountArs, 0)} />
            </Paper>
          </Grid>
        </Grid>

        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart width={500} height={400} data={amountByDayDataArs} margin={{ top: 20, right: 20, bottom: 20, left: 20, }}>
            <CartesianGrid stroke="#f5f5f5" />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            {/* <YAxis ticks={[5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000]} /> */}
            <YAxis yAxisId="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Line yAxisId="right" name="Monto Bruto ARS" type="monotone" dataKey="transactionAmountArs" strokeWidth={3} stroke={cohesiveOrange} activeDot={{ r: 8 }} />
            <Line yAxisId="right" name="Monto Cupones ARS" type="monotone" dataKey="cuponsArs" strokeWidth={3} stroke={cohesiveGreen} />
            <Bar yAxisId="left" name="Cantidad de Pagos ARS" dataKey="totalPaymentsArs" barSize={20} fill={cohesiveViola} fillOpacity="30%" />
          </ComposedChart>
        </ResponsiveContainer>
      </Grid>

      <Grid item xs={12} lg={6}>

        <Grid item xs={12} textAlign="center" padding={2}>
          <Typography sx={{ fontSize: "1.5em", fontWeight: 500 }}>Pagos en USD</Typography>
        </Grid>

        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Paper elevation={0}>
              <AnalyticEcommerce title="Pedidos en USD" count={paymentsStats.paymentsUsd} />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Paper elevation={0}>
              <AnalyticEcommerce title="Suma en USD" count={"USD " + formatAllNumber(paymentsStats.paymentsAmountUsd, 0)} />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Paper elevation={0}>
              <AnalyticEcommerce title="Cupones en USD" count={"USD " + formatAllNumber(paymentsStats.cuponsAmountUsd, 0)} />
            </Paper>
          </Grid>
        </Grid>

        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart width={500} height={400} data={amountByDayDataUsd} margin={{ top: 20, right: 20, bottom: 20, left: 20, }}>
            <CartesianGrid stroke="#f5f5f5" />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            {/* <YAxis ticks={[5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000]} /> */}
            <YAxis yAxisId="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Line yAxisId="right" name="Monto Bruto USD" type="monotone" dataKey="transactionAmountUsd" strokeWidth={3} stroke={cohesiveOrange} activeDot={{ r: 8 }} />
            <Line yAxisId="right" name="Monto Cupones USD" type="monotone" dataKey="cuponsUsd" strokeWidth={3} stroke={cohesiveGreen} />
            <Bar yAxisId="left" name="Cantidad de Pagos USD" dataKey="totalPaymentsUsd" barSize={20} fill={cohesiveViola} fillOpacity="30%" />
          </ComposedChart>
        </ResponsiveContainer>
      </Grid>

    </Grid >
  ) : null;
}

export default PaymentsStats;