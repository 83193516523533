import { dspsFuga, fugaDspIsDeliveredState } from "variables/fuga"
import { warningAppleDelivery, warningFBIGDelivery, warningYTDelivery } from 'utils/textToShow.utils';

import {
  ACTION_TAKEDOWN, ACTION_DELIVER, INVESTIGATE_INFRACTION, DELIVERED_APPLE_REJECTED,
  DELIVERED_NEED_APPLE_REVISION, DELIVERED_APPLE_USER_CORRECTION, PENDING, DSP_REJECTED,
  appleDeliveredStates, colorFromDeliveryDspLabel, COPYRIGHT_INFRACTION, DELIVERED, ACTION_REDELIVER,
  DSP_STALLED, DSP_NOT_ADDED, DSP_DELIVER, OUR_DSP_REDELIVER, OUR_DSP_NONE_ACTION, OUR_DSP_TAKEDOWN,
  OUR_DSP_DELIVER, OUR_DSP_FIRST_DELIVER, DSP_CANCELLED, DSP_TAKEN_DOWN, UPC_MISSING,
} from "variables/varias";
import { colorFromDeliveryDspActionLabel, colorFromDeliveryDspStateLabel, newDictLabelsActionToNewDspState } from "./dspStateAndActions.utils";

export const getWarningDspHelper = dspName => {
  if (dspName === "Apple Music") return warningAppleDelivery;
  if (dspName === "Facebook Music / Instagram") return warningFBIGDelivery;
  if (dspName === "YouTube Music & Content ID") return warningYTDelivery;
  return ""
}

export const checkboxGroupInfo = dspsFuga.map(dspInfo => {
  return {
    ...dspInfo, checked: false, label: dspInfo.dspName,
    checkBoxHelper: getWarningDspHelper(dspInfo.dspName),
  }
});

const dspShouldBeChecked = (dspInfo, albumState) => {
  let pendingAppleDelivery = dspInfo.dspName === "Apple Music" && appleDeliveredStates.includes(albumState);
  let dspDelivered = checkDspIsDelivered(dspInfo, albumState);
  return dspDelivered || pendingAppleDelivery || dspInfo.lastAction === ACTION_DELIVER || dspInfo.lastAction === ACTION_REDELIVER;
}

const checkDspIsDelivered = dspInfo => {
  return fugaDspIsDeliveredState.includes(dspInfo.state);
}

const getActionDeliverFromLabel = dspLabel => {
  return newDictLabelsActionToNewDspState[dspLabel] || OUR_DSP_NONE_ACTION;
}

const dspIsTakedownOrCancelled = dspInfo => {
  return dspInfo.state === DSP_CANCELLED
    || dspInfo.state === DSP_TAKEN_DOWN
    || dspInfo.lastAction === ACTION_TAKEDOWN;
}

const stateAndActionWhenDspIsRejected = (dspChecked, albumWasEdited) => {
  if (dspChecked && albumWasEdited) return "Rechazada: aplicar cambios";
  if (!dspChecked && albumWasEdited) return "Rechazada: no aplicar cambios";
  if (dspChecked && !albumWasEdited) return "Rechazada: reintentar delivery";
  if (!dspChecked && !albumWasEdited) return "Rechazada";
}

const stateAndActionWhenAppleIsDelivered = (dspChecked, albumWasEdited) => {
  return dspChecked
    ? albumWasEdited ? "Online: aplicar cambios" : "Online"
    : albumWasEdited ? "Online: no aplicar cambios" : "Online: dar de baja";
}

const stateAndActionsWhenAppleIsNotDelivered = (dspChecked, albumWasEdited, albumState) => {
  let appleStringState = "";
  if (albumState === DELIVERED_NEED_APPLE_REVISION) appleStringState = "Esperando revisión";
  if (albumState === DELIVERED_APPLE_USER_CORRECTION) appleStringState = "Esperando nueva revisión";
  if (albumState === DELIVERED_APPLE_REJECTED) appleStringState = "Rechazada, necesita corrección";
  return dspChecked
    ? albumWasEdited
      ? `${appleStringState}: aplicar cambios`
      : `${appleStringState}`
    : albumWasEdited
      ? `${appleStringState}: no aplicar cambios`
      : `${appleStringState}: dar de baja`;
}

const stateAndActionWhenDspIsCancelledOrTD = (dspInfo, dspChecked, albumWasEdited) => {
  if (dspInfo.lastAction === ACTION_DELIVER || dspInfo.lastAction === ACTION_REDELIVER)
    return "Procensando redelivery: esperar";

  if (dspInfo.lastAction === ACTION_TAKEDOWN && dspInfo.state !== DSP_DELIVER
    && dspInfo.state !== DSP_CANCELLED && dspInfo.state !== DSP_TAKEN_DOWN)
    return "Online: en proceso de dar de baja, esperar";

  return dspChecked
    ? albumWasEdited
      ? 'Dado de baja: sin acción'
      : 'Dado de baja: volver a poner Online'
    : albumWasEdited
      ? 'Dado de baja: sin acción'
      : 'Dado de baja'
}

const stateAndActionsWhenDspIsDelivered = (dspChecked, albumWasEdited) => {
  if (dspChecked && albumWasEdited) return "Online: aplicar cambios";
  if (dspChecked && !albumWasEdited) return "Online";
  if (!dspChecked && albumWasEdited) return "Online: no aplicar cambios";
  if (!dspChecked && !albumWasEdited) return "Online: dar de baja";
}

export const dictDspsStatesToActionLabels = (dspInfo, dspChecked, albumState, dspIsDelivered, albumWasEdited) => {
  let dspDeliveredOrGoingTo = dspInfo.state === "DELIVERED" || dspInfo.lastAction === "DELIVER" || dspInfo.lastAction === "REDELIVER";
  let isApple = dspInfo.dspName === "Apple Music";

  if (isApple && albumState === "DELIVERED" && dspDeliveredOrGoingTo && !dspIsTakedownOrCancelled(dspInfo))
    return stateAndActionWhenAppleIsDelivered(dspChecked, albumWasEdited)

  if (dspInfo.state === DSP_REJECTED) return stateAndActionWhenDspIsRejected(dspChecked, albumWasEdited)
  if (dspInfo.state === DSP_STALLED) return "Procesando: no se pueden realizar acciones";

  if (isApple && appleDeliveredStates.includes(albumState))
    return stateAndActionsWhenAppleIsNotDelivered(dspChecked, albumWasEdited, albumState);

  if (dspIsTakedownOrCancelled(dspInfo))
    return stateAndActionWhenDspIsCancelledOrTD(dspInfo, dspChecked, albumWasEdited)


  if (dspIsDelivered && dspInfo.lastAction !== ACTION_TAKEDOWN)
    return stateAndActionsWhenDspIsDelivered(dspChecked, albumWasEdited);

  if (dspInfo.state === DSP_NOT_ADDED && dspChecked) return "Realizar delivery";
  if (dspInfo.state === DSP_NOT_ADDED && !dspChecked) return "";
  if (!dspIsDelivered && !dspChecked) return "";
  return "";
}

const getAppleAction = actionString => {
  if (actionString === OUR_DSP_FIRST_DELIVER) return 'deliver';
  if (actionString === OUR_DSP_DELIVER) return 'force-deliver';
  if (actionString === OUR_DSP_REDELIVER) return 'redeliver';
  if (actionString === OUR_DSP_TAKEDOWN) return 'takedown';
  return 'none';
}

export const getDspsToRedeliverDeliverAndTakenDown = dsps => {
  let redeliverDsps = []; let deliverDsps = []; let takenDownDsps = []; let appleActionString = 'none';
  let dspsNoApple = dsps.filter(dsp => dsp.dspName !== "Apple Music");

  let appleActionData = dsps.find(dspInfo => dspInfo.dspName === "Apple Music");
  if (appleActionData) {
    let actionString = getActionDeliverFromLabel(appleActionData.labelString);
    appleActionString = getAppleAction(actionString);
  }

  dspsNoApple.forEach(dsp => {
    let actionString = getActionDeliverFromLabel(dsp.labelString);
    if (actionString === OUR_DSP_DELIVER || actionString === OUR_DSP_FIRST_DELIVER) deliverDsps.push(dsp);
    if (actionString === OUR_DSP_REDELIVER) redeliverDsps.push(dsp);
    if (actionString === OUR_DSP_TAKEDOWN) takenDownDsps.push(dsp);
  })

  return { redeliverDsps, deliverDsps, takenDownDsps, appleAction: appleActionString }
}

export const getDspStateLabel = (dspInfo, albumState, albumWasEdited) => {
  let dspDelivered = checkDspIsDelivered(dspInfo, albumState);
  let deliveryStateLabel = dictDspsStatesToActionLabels(dspInfo, dspInfo.checked, albumState, dspDelivered, albumWasEdited);

  let deliveryStateSplitted = deliveryStateLabel ? deliveryStateLabel.split(':') : "";
  let deliveryStateString = deliveryStateSplitted[0];
  let deliveryStateFormatted = deliveryStateSplitted.length === 2 ? `${deliveryStateString}: `
    : deliveryStateString ? `${deliveryStateString}` : "";
  let deliveryActionString = deliveryStateSplitted.length === 2 ? deliveryStateSplitted[1].trim() : "";

  const labelStateStyle = { color: colorFromDeliveryDspStateLabel[deliveryStateString], margin: "0", fontWeight: 600, fontSize: "14px", marginTop: "1em", marginBottom: "0" };
  const labelActionStyle = { color: colorFromDeliveryDspActionLabel[deliveryActionString], margin: "0", fontWeight: 600, fontSize: "14px", marginTop: "1em", marginBottom: "0" };
  return {
    jsx: <>
      <b style={cardDSPNameStyles}>{dspInfo.dspName + " | "}</b>
      <b style={labelStateStyle}>{deliveryStateFormatted}</b>
      <b style={labelActionStyle}>{deliveryActionString}</b>
    </>,
    string: deliveryStateLabel
  }
}

const cardDSPNameStyles = { color: "rgba(0,0,0,0.9)", margin: "0", fontWeight: 500, fontSize: "14px", marginTop: "1em", marginBottom: "0" };

export const adaptDspFugaStateToDialog = (albumDspsStateFromFuga, albumState, albumWasEdited) => {
  let dspsAsCheckboxes = albumDspsStateFromFuga.map(dspInfo => {
    let dspChecked = dspShouldBeChecked(dspInfo, albumState);
    let labelJsxAndString = getDspStateLabel({ ...dspInfo, checked: dspChecked }, albumState, albumWasEdited);
    return {
      ...dspInfo, checked: dspChecked, state: dspInfo.state, lastAction: dspInfo.lastAction,
      label: labelJsxAndString.jsx,
      labelString: labelJsxAndString.string,
      checkBoxHelper: getWarningDspHelper(dspInfo.dspName)
    }
  })
  let orderedDsps = [];
  dspsFuga.forEach(dspOrdered => {
    let dspFinded = dspsAsCheckboxes.find(dspU => dspOrdered.dspName === dspU.dspName);
    if (dspFinded) orderedDsps.push(dspFinded);
  })
  return orderedDsps;
}

const exclusiveStates = [COPYRIGHT_INFRACTION, INVESTIGATE_INFRACTION, DELIVERED_APPLE_REJECTED,
  DELIVERED_NEED_APPLE_REVISION, DELIVERED_APPLE_USER_CORRECTION, UPC_MISSING]

export const getAlbumDeliveryState = (audioTracksMissing, ourAlbumState, fugaDspsState) => {
  if (exclusiveStates.includes(ourAlbumState)) return ourAlbumState;
  if (audioTracksMissing) return "TRACKS_MISSING";
  if (!fugaDspsState || fugaDspsState.length === 0) return "";

  let isPending = fugaDspsState.every(dspInfo => dspInfo.lastAction === null);
  if (isPending) return PENDING;

  let isDeliveredAtLeastInOneDsp = fugaDspsState.some(
    dspInfo => (dspInfo.lastAction === "DELIVER" || dspInfo.lastAction === "REDELIVERY") && dspInfo.state !== "REJECTED");

  return isDeliveredAtLeastInOneDsp ? "DELIVERED" : "TAKEN_DOWN";
}

export const getNewAlbumStateWhenAppleActionNeeded = (appleAction, oldState, albumWasEdited) => {
  if (appleAction === 'deliver' || appleAction === 'redeliver') {
    if (oldState === DELIVERED_APPLE_REJECTED) return DELIVERED_APPLE_USER_CORRECTION;
    if (oldState === DELIVERED) return DELIVERED_NEED_APPLE_REVISION;
  }
  if (appleAction === 'force-deliver' || appleAction === 'force-redeliver') return DELIVERED;
  if (appleAction === 'takedown') return albumWasEdited ? oldState : DELIVERED;
  return oldState;
}
