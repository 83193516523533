import firebaseApp from 'firebaseConfig/firebase.js';
import { getFirestore, query, collection, where, getCountFromServer, getDocs, limit, orderBy } from "firebase/firestore";
import { to } from 'utils';
import { createFireStoreError } from 'redux/actions/NotificationsHandlerActions';
import { getCantDaysInMS } from 'utils/timeRelated.utils';
import { usersAddStore } from 'redux/actions/UsersActions';
import {
  USER_ACTIVE, USER_CANCELLED, USER_INACTIVE, USER_NEED_RENOVATION,
  USER_PENDING_PAY, USER_PENDING_PROOF, USER_PENDING_PROOF_CHECK, USER_SUSPEND_NO_PAY
} from 'variables/user.variables';
import { mapCustomQueryNameToStatuses } from 'utils/users.queries.utils';

const db = getFirestore(firebaseApp);

export const defaultCountUsers = {
  totalUsers: "...", activeUsers: "...", needCheckProof: "...", nextToExpire: "...",
  cancelledUsers: "...", needRenovationUsers: "...", pendingFinishPayProccess: "..."
};

const getNextToExpireWhereQuery = limitUsers => {
  let nextXDays = new Date().getTime() + getCantDaysInMS(15);
  return query(collection(db, 'users'), where('userStatus', '==', USER_ACTIVE),
    where('subscription.nextPaymentDate', '<=', nextXDays), orderBy("subscription.nextPaymentDate", "asc"), limit(limitUsers));
}

export const getUsersCountByQuery = async (searchField, fieldValues, dispatch) => {
  let elementsDbFromUserRef = {}; let finalValue = "";
  if (fieldValues.length === 0) elementsDbFromUserRef = collection(db, 'users');
  else if (fieldValues === 'next-to-expire') elementsDbFromUserRef = getNextToExpireWhereQuery(2000);
  else {
    finalValue = Array.isArray(fieldValues) && fieldValues.length > 1 ? fieldValues : fieldValues[0];
    let whereOperator = Array.isArray(finalValue) ? "in" : "==";
    let whereClause = where(searchField, whereOperator, finalValue);
    elementsDbFromUserRef = query(collection(db, 'users'), whereClause);
  }
  let [errorGettingElementsFromUser, countsFromUserSnapshot] = await to(getCountFromServer(elementsDbFromUserRef));
  if (errorGettingElementsFromUser) {
    dispatch(createFireStoreError(`Error obteniendo los counts de la colección USERS.`, errorGettingElementsFromUser));
    return "ERROR";
  }
  return Number(countsFromUserSnapshot.data().count);
}

export const getUsersBasicCounts = async (dispatch) => {
  const [activeUsers, cancelledUsers, needRenovationUsers, pendingFinishPayProccess, needCheckProof, nextToExpire] = await Promise.all([
    await getUsersCountByQuery('userStatus', [USER_ACTIVE], dispatch),
    await getUsersCountByQuery('userStatus', [USER_CANCELLED, USER_SUSPEND_NO_PAY, USER_INACTIVE], dispatch),
    await getUsersCountByQuery('userStatus', [USER_NEED_RENOVATION], dispatch),
    await getUsersCountByQuery('userStatus', [USER_PENDING_PAY, USER_PENDING_PROOF, USER_PENDING_PROOF_CHECK], dispatch),
    await getUsersCountByQuery('userStatus', [USER_PENDING_PROOF_CHECK], dispatch),
    await getUsersCountByQuery('next-to-expire', 'next-to-expire', dispatch)
  ]);
  let totalUsers = activeUsers + cancelledUsers + needRenovationUsers + pendingFinishPayProccess;
  return { totalUsers, activeUsers, cancelledUsers, needRenovationUsers, pendingFinishPayProccess, needCheckProof, nextToExpire };
}

const getCustomQueryRefByName = (customQueryName, limitUsers) => {
  let elementsDbFromUserRef = {};
  if (customQueryName === 'all') elementsDbFromUserRef = query(collection(db, 'users'), limit(limitUsers));
  else if (customQueryName === 'next-to-expire') elementsDbFromUserRef = getNextToExpireWhereQuery(limitUsers);
  else {
    let finalValue = mapCustomQueryNameToStatuses(customQueryName);
    elementsDbFromUserRef = query(collection(db, 'users'), where('userStatus', "in", finalValue), limit(limitUsers));
  }
  return elementsDbFromUserRef;
}

export const getCustomQueryUsers = async (customQueryName, limitUsers, dispatch) => {
  let elementsDbFromUserRef = getCustomQueryRefByName(customQueryName, limitUsers);
  let [errorGettingElementsFromUser, usersSnap] = await to(getDocs(elementsDbFromUserRef));
  if (errorGettingElementsFromUser) {
    dispatch(createFireStoreError(`Error obteniendo una custon query de USER.`, errorGettingElementsFromUser));
    return "ERROR";
  }
  if (usersSnap.empty) return "EMPTY";
  let results = [];
  usersSnap.forEach(userDoc => results.push(userDoc.data()));
  dispatch(usersAddStore(results));
  return results;
}
