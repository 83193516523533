import React, { useState, useEffect } from "react";
// core components
import { Grid, Button, Typography } from '@mui/material';
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import AlbumCard from "views/Albums/AlbumCard";
import useQuery from '../../customHooks/useQuery';
import { filterAlbumsByAdminOrNot, getFilteredAlbumsByUrl, getTitleLanzamientos } from '../../utils/albums.utils';
import { albumCleanUpdatingAlbum, getAlbumsByFieldRedux } from "redux/actions/AlbumsActions";
import { AddCircleOutline } from '@mui/icons-material/';
import NavigationBar from '../../components/Navbars/NavigationBar';
import SnackbarMui from 'components/Snackbar/SnackbarMui';
import useWindowDimensions from '../../customHooks/useWindowDimensions';
import { toWithOutError } from "utils";
import { userIsAdmin } from 'utils/users.utils';

const MyAlbums = () => {

  const navigate = useNavigate();
  const params = useQuery();
  const dispatch = useDispatch()
  const { width } = useWindowDimensions();
  let gridWidth = width >= 1400 ? 3 : width < 1200 ? 3 : 4;

  const albumsFromStore = useSelector(store => store.albums.albums);
  const artists = useSelector(store => store.artists.artists);
  const labels = useSelector(store => store.labels.labels);
  const cantPages = Math.floor(albumsFromStore.length / 8);

  const userRol = useSelector(store => store.userData.rol);
  const isAdmin = userIsAdmin(userRol);

  const [currentPage, setCurrentPage] = useState(0);
  const [openNoMoreWeeks, setOpenNoMoreWeeks] = useState(false);
  const [searchingAlbums, setSearchingAlbums] = useState(false);

  let filteredAlbumsIfNeeded = filterAlbumsByAdminOrNot(getFilteredAlbumsByUrl(params, albumsFromStore), isAdmin);
  const titleLanzamientos = getTitleLanzamientos(params, labels, artists);
  const noTienesLanzamientos = `No tienes ${titleLanzamientos}`;
  const searchingLanzamientos = `Buscando todos los ${titleLanzamientos}`

  useEffect(() => {

    const getAlbumsByArtistId = async artistId => {
      setSearchingAlbums(true);
      await toWithOutError(dispatch(getAlbumsByFieldRedux('artistId', artistId, 100)));
      setSearchingAlbums(false);
    }

    if (params.view === "allOfArtist") getAlbumsByArtistId(params.id);
    else getFilteredAlbumsByUrl(params, albumsFromStore);
  }, [params])

  const myAlbumsCards = () => {
    return filteredAlbumsIfNeeded.length > 0
      ? filteredAlbumsIfNeeded.slice(currentPage * 8, (currentPage + 1) * 8).map((album, index) =>
        <Grid item xs={12} sm={6} lg={gridWidth} key={index} paddingBottom={2} sx={{ height: "600px" }}>
          <AlbumCard key={index} dataAlbum={album} index={index} showActions={true} />
        </Grid>
      )
      : []
  }

  let myAlbums = myAlbumsCards();

  const navigateToNewAlbum = () => {
    dispatch(albumCleanUpdatingAlbum());
    navigate("/admin/new-album");
  }

  const handleClickAfter = () => {
    if (currentPage < cantPages) { setCurrentPage(currentPage + 1); return; }
    setOpenNoMoreWeeks(true);
  }

  const handleClickBefore = () => {
    if (currentPage > 0) { setCurrentPage(currentPage - 1); return; }
    setOpenNoMoreWeeks(true);
  }

  const notiText = currentPage > 0 ? "No hay más lanzamientos." : "Es la primer página."

  return (
    <Grid container spacing={2} textAlign="center">
      <Grid item xs={12}>
        <Typography sx={albumsTitleStyles}>{titleLanzamientos}</Typography>
        <Button variant="contained" color="secondary" onClick={navigateToNewAlbum} endIcon={<AddCircleOutline />}>
          Nuevo Lanzamiento
        </Button>
      </Grid>

      {cantPages > 0 && <Grid container item spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} md={4} >
          <NavigationBar
            title="Navega entre los lanzamientos"
            handleClickAfter={handleClickAfter}
            handleClickBefore={handleClickBefore} />
        </Grid>
      </Grid>}

      <Grid container item>
        {
          myAlbums
        }
      </Grid>

      <Grid item xs={12}>
        {(myAlbums.length === 0 || searchingAlbums) &&
          <h4 style={noAlbumsTitleBlackStyles}>{searchingAlbums ? searchingLanzamientos : noTienesLanzamientos}</h4>}
      </Grid>

      <SnackbarMui
        open={openNoMoreWeeks}
        setOpen={setOpenNoMoreWeeks}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        type={"info"}
        handleClose={() => setOpenNoMoreWeeks(false)}
        autoHide={3000}
        text={notiText} />
      );

    </Grid>
  );
}

export default MyAlbums;

const noAlbumsTitleBlackStyles = { color: "#000000", fontWeight: "300px", marginBottom: "3px" }
const albumsTitleStyles = { color: "#000000", fontWeight: "400px", fontSize: "50px", marginBottom: "2%" }
