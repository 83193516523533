import React, { useState, useEffect } from "react";

import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
  Backdrop, CircularProgress
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';

import { postChangeStateToInfractions } from "utils/textToShow.utils";
import { toWithOutError } from 'utils';
import { usersGetOneByIdRedux } from "redux/actions/UsersActions";
import { queryRoyaltiesWithOp } from 'services/BackendCommunication';
import { userIsAdmin } from 'utils/users.utils';
import { artistStatesShowAdmin } from '../../variables/artist.variables';

const ArtistInfractionDialog = ({ artistData, openInfractionDialog, setOpenInfractionDialog }) => {

  const dispatch = useDispatch();
  const currentUserData = useSelector(store => store.userData);
  const isAdmin = userIsAdmin(currentUserData.rol);

  const [openLoaderSendingInfraction, setOpenLoaderSendingInfracion] = useState(false);
  const [buttonState, setButtonState] = useState("none");
  const [royaltiesGenerated, setRoyaltiesGenerated] = useState("...")

  useEffect(() => {
    const getOwnerData = async userId => {
      const userData = await toWithOutError(dispatch(usersGetOneByIdRedux(userId, false)));
      return userData;
    }

    const getRoyaltiesGenerated = async (artistName, artistOwnerId) => {
      let targetUserData = await getOwnerData(artistOwnerId);
      let royalties = await queryRoyaltiesWithOp(targetUserData.id, 'userId', targetUserData.id, 'sum', 'ourNetRevenue', [], isAdmin, dispatch)
      setRoyaltiesGenerated((royalties.length > 0 && royalties[0].revenuesUSD > 0) ? royalties[0].revenuesUSD : 0);
    }
    if (artistData.ownerId && artistData.name) {
      getRoyaltiesGenerated(artistData.name, artistData.ownerId);
    }
  }, [artistData])


  const handleCancelDialog = () => {
    setOpenInfractionDialog(false);
  }

  const handleSendInfraction = async () => {
    setOpenLoaderSendingInfracion(true);
    // let allResponses = await toWithOutError(dispatch(artistSuspendByInfractionRedux(artistData, actions, emailObservations)));
    // if (allResponses === "ERROR") {setButtonState('error'); setOpenLoaderSendingInfracion(false)}
    setButtonState('success');
    setOpenLoaderSendingInfracion(false);
    closeInfractionDialog();
  }

  const closeInfractionDialog = () => {
    setButtonState('none');
    setOpenInfractionDialog(false);
  }


  return (
    <Dialog open={openInfractionDialog} onClose={handleCancelDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>

      {/* <InfoActionDialog id='user-notified' isOpen={buttonState === 'success'} handleClose={closeInfractionDialog}
        title={'Se ha notificado al usuario'} contentTexts={[""]} /> */}

      <Backdrop open={openLoaderSendingInfraction}>
        <CircularProgress />
      </Backdrop>

      <DialogContent>

        <DialogTitle id="form-artist-title" textAlign='center' sx={{ fontSize: "2em", padding: '0' }}>Acciones a realizar</DialogTitle>
        <DialogTitle id="name-artist" textAlign='center' sx={{ fontSize: "1.5em", padding: '0' }}>{`${artistData.name}`}</DialogTitle>

        <DialogContentText textAlign='left' paddingBottom={1} sx={{ marginTop: '20px' }}>
          {postChangeStateToInfractions(artistStatesShowAdmin[artistData.state])}
        </DialogContentText>

        {artistData.ownerEmail && <DialogContentText key={'owner email'} sx={{ marginTop: '20px' }}>
          Email Usuario: <b>{`${artistData.ownerEmail}`}</b>
        </DialogContentText>}

        {artistData.ownerId && <DialogContentText key={'owner id'}>
          ID Usuario: <b>{`${artistData.ownerId}`}</b>
        </DialogContentText>}

        {artistData.spotify_uri && <DialogContentText key={'artist-uri'}>
          Spotify URI: <b>{`${artistData.spotify_uri}`}</b>
        </DialogContentText>}

        <DialogContentText key={'royalties'}>
          Regalías del Artista: <b>{Number.isFinite(royaltiesGenerated) ? `${royaltiesGenerated} USD` : "... USD"}</b>
        </DialogContentText>

        <DialogContentText key="info" sx={{ marginTop: '20px' }}>
          Todavía no implementé las posibles acciones a realizar al suspender un Artista por Copyright, pero no
          me parecen prioritarias. <br />
          <b>Por lo que para investigar sus regalías hay cambiar el estado de los Lanzamientos a Infracción por Copyright y luego seleccionar
            dar de baja e investigar sus regalías.
          </b>
        </DialogContentText>

      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancelDialog} sx={buttonColorStyle}>
          Atras
        </Button>
        <Button onClick={handleSendInfraction} sx={buttonColorStyle}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ArtistInfractionDialog;

const buttonColorStyle = { color: "#508062" };