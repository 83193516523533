import React, { useState, useEffect } from "react";
// core components
// import Button from "components/CustomButtons/Button.js";
import ArtistCard from 'views/Artists/ArtistCard';
import { Grid, Button, Typography } from '@mui/material';
import { useNavigate } from "react-router";
import { useSelector } from 'react-redux';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { getFilteredArtistByUrl, userCanAddMoreArtists } from '../../utils/artists.utils';
import useQuery from '../../customHooks/useQuery';
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import { maxArtistsText } from "utils/textToShow.utils";
import useWindowDimensions from '../../customHooks/useWindowDimensions';
import { useDispatch } from 'react-redux';
import { toWithOutError } from 'utils';
import { getArtistByFieldRedux } from 'redux/actions/ArtistsActions';
import { userIsAdmin } from 'utils/users.utils';

const MyArtists = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useQuery();
  const { width } = useWindowDimensions();
  let imageWidth = width >= 1400 ? 3 : width < 1200 ? 3 : 4;

  const artistsFromStore = useSelector(store => store.artists.artists);
  const currentUser = useSelector(store => store.userData);
  const isAdmin = userIsAdmin(currentUser.rol);
  const plan = currentUser.plan;

  const [openMaxArtistsDialog, setOpenMaxArtistsDialog] = useState(false);
  const [searchingArtist, setSearchingArtist] = useState(false);

  const filteredArtistsIfNeeded = getFilteredArtistByUrl(params, artistsFromStore);

  useEffect(() => {

    setSearchingArtist(true);
    const getArtistById = async artistId => {
      setSearchingArtist(true);
      await toWithOutError(dispatch(getArtistByFieldRedux('id', artistId, 1)));
      setSearchingArtist(false);
    }

    if (params.view === "artist") getArtistById(params.id);
    else getFilteredArtistByUrl(params, artistsFromStore);
  }, [params])

  const misArtistasProfiles = () => {
    return filteredArtistsIfNeeded.length > 0
      ? filteredArtistsIfNeeded.map((artista, index) =>
        <Grid item xs={12} sm={6} lg={imageWidth} key={index}>
          <ArtistCard key={index} dataArtist={artista} index={index} />
        </Grid>
      )
      : []
  }

  let misArtistas = misArtistasProfiles();

  const agregarArtista = () => {
    if (!userCanAddMoreArtists(isAdmin, false, plan, artistsFromStore)) setOpenMaxArtistsDialog(true);
    else navigate("/admin/new-artist");
  }

  return (
    <Grid container spacing={2} sx={{ textAlign: "center" }}>

      <InfoActionDialog id='cant-add-artists' isOpen={openMaxArtistsDialog} handleClose={() => setOpenMaxArtistsDialog(false)}
        title={"No puedes agregar más Artistas"} contentTexts={maxArtistsText} />

      <Grid item xs={12}>
        <Typography sx={artistsTitleStyles}>Artistas</Typography>
        <Button variant="contained" color="secondary" onClick={agregarArtista} endIcon={<PersonAddIcon />}>
          Agregar Artista
        </Button>
      </Grid>
      <Grid container item >
        {
          misArtistas
        }
      </Grid>
      <Grid item xs={12}>
        {misArtistas.length === 0 &&
          <h4 style={cardTitleBlack}>{searchingArtist ? "Buscando artista" : "No tienes artistas"}</h4>}
      </Grid>
    </Grid>
  );
}

export default MyArtists;

const cardTitleBlack = {
  color: "#000000",
  marginTop: "0px",
  minHeight: "auto",
  fontWeight: "300",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  marginBottom: "3px",
  textDecoration: "none"
};

const artistsTitleStyles = { color: "#000000", fontWeight: "400px", fontSize: "50px", marginBottom: "2%" }