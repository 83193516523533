import { defaultDiscountFormData } from 'factory/discounts.factory';
import * as ReducerTypes from 'redux/actions/Types';

const filterDiscountsWithSameIdThanNewOne = (oldDiscounts, addedDiscounts) => {
  if (!addedDiscounts) return oldDiscounts;
  return oldDiscounts.filter(discount => !addedDiscounts.map(addedDiscount => addedDiscount.id).includes(discount.id));
}

const editAndAddDiscounts = (oldDiscounts, editedFieldsFromDiscount) => {
  return oldDiscounts.map(oldDiscount => {
    if (oldDiscount.id === editedFieldsFromDiscount.id) return { ...oldDiscount, ...editedFieldsFromDiscount };
    else return oldDiscount;
  })
}

const defaultDiscount = defaultDiscountFormData
const initialState = { discounts: [], actualDiscount: defaultDiscount }

const DiscountsReducer = (state = initialState, action) => {
  const data = action.payload;
  switch (action.type) {

    case ReducerTypes.DISCOUNTS_UPDATE_WITH_ID:
      return { actualDiscount: { ...state.actualDiscount, ...data }, discounts: editAndAddDiscounts(state.discounts, data) };

    case ReducerTypes.DISCOUNTS_CREATE:
      const oldUniqueDiscountsInvited = filterDiscountsWithSameIdThanNewOne(state.discounts, data);
      return { ...state, discounts: [...data, ...oldUniqueDiscountsInvited] };

    case ReducerTypes.DISCOUNTS_DELETE_BY_ID:
      return { ...state, discounts: state.discounts.filter(discount => discount.id !== data) }

    case ReducerTypes.DISCOUNTS_SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};

export default DiscountsReducer;