import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button, Grid, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { Delete } from '@mui/icons-material';
import ProgressButton from 'components/CustomButtons/ProgressButton';
import { deleteUserDialogContentText } from "utils/textToShow.utils";
import { toWithOutError } from 'utils';
import { useDispatch } from 'react-redux';
import { usersUnsubscribeRedux } from 'redux/actions/UsersActions';
import { getArtistByFieldRedux } from 'redux/actions/ArtistsActions';
import { basicSuccess } from "redux/actions/NotificationsHandlerActions";

const DeleteUserDialog = (props) => {
  const { isOpen, setIsOpen, user, buttonState } = props;

  const dispatch = useDispatch();
  // const userName = (user.nombre + " " + user.apellido).trim() || "";

  const [openEmptySearch, setOpenEmptySearch] = useState(false);
  const [openErrorSearch, setOpenErrorSearch] = useState(false);
  const [openLoaderMyUsers, setOpenLoaderMyUsers] = useState(false);
  const [artistsFromUser, setArtistFromUser] = useState([]);
  const [openLoaderDelete, setOpenLoaderDelete] = useState(false);
  
  useEffect(() => {
    const getUserArtists = async () => {
      const userArtists = await toWithOutError(dispatch(getArtistByFieldRedux("ownerId", user.id, 100)));
      if (userArtists === "ERROR") { setOpenLoaderMyUsers(false); setOpenErrorSearch(true); return "ERROR"; }
      if (userArtists === "EMPTY") { setOpenLoaderMyUsers(false); setOpenEmptySearch(true); return "EMPTY"; }
      setArtistFromUser(userArtists);
    }

    user.id && getUserArtists();
  }, [user])

  // Cambiar Artista y User de estado a CANCELADO, y dar de baja/eliminar lanzamientos.
  const handleDeleteUser = async () => {
    setOpenLoaderDelete(true);
    await toWithOutError(dispatch(usersUnsubscribeRedux(user)))
    setOpenLoaderDelete(false);
    setIsOpen(false);
    dispatch(basicSuccess("Usuario actualizado", 8000));
  }

  if (!isOpen) return null; // Do not render anything if isOpen is false

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      id="delete-user-dialog"
      open={isOpen}
      onClose={() => setIsOpen(false)}>

      <DialogTitle id="title-user-delete-dialog">
        <Typography sx={dialogTitleStyle}>{`Eliminar Usuario: ${user.email}`}</Typography>
      </DialogTitle>

      <DialogContent>
        <Typography sx={{ fontWeight: 500 }}>Artistas del Usuario:</Typography>
        {Array.isArray(artistsFromUser) &&
          <DialogContentText sx={{ maxHeight: "150px", overflow: "auto" }} id="list-artists">
            <ul style={{ marginTop: '0px', paddingLeft: '15px' }}>
              {artistsFromUser.map((artist, index) =>
                <li key={index}>{artist.name}</li>
              )}
            </ul>
          </DialogContentText>}
        <DialogContentText id="content-user-delete-dialog">
          {deleteUserDialogContentText}
        </DialogContentText>
      </DialogContent>

      <DialogActions id="actions-user-delete-dialog">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} >
            <Button
              variant="contained"
              fullWidth
              onClick={() => setIsOpen(false)}
              color="primary">
              Atrás
            </Button>
          </Grid>
          <Grid item xs={6}>
            <ProgressButton
              textButton={"Eliminar"}
              loading={openLoaderDelete}
              buttonState={buttonState}
              onClickHandler={handleDeleteUser}
              noneIcon={<Delete />}
              noFab={true} />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteUserDialog;

const dialogTitleStyle = {
  fontWeight: "500",
  fontSize: "20px",
  marginBottom: "3px",
}

DeleteUserDialog.defaultProps = {
  isOpen: false,
  textContent: "Confirma que quieres eliminar el usuario",
  title: "Eliminar Usuario"
}

DeleteUserDialog.propTypes = {
  textContent: PropTypes.array,
  textName: PropTypes.string,
  title: PropTypes.string.isRequired,
  deleteButtonText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}