import * as ReducerTypes from 'redux/actions/Types';

const initialState = { name: 'all', limit: 20, collection: '' };

const QueryReducer = (state = initialState, action) => {
  const data = action.payload;
  switch (action.type) {
    case ReducerTypes.QUERY_SET:
      return data;

    case ReducerTypes.QUERY_SIGN_OUT:
      return initialState;  

    default:
      return state;
  }
};

export default QueryReducer;