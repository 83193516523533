import * as ReducerTypes from 'redux/actions/Types';
import * as FirestoreServices from 'services/FirestoreServices.js';
import { addArtistsInvited, invitedArtistSignOut } from './ArtistsInvitedActions';
import { addCollaborators, collaboratorsSignOut } from './CollaboratorsActions';
import { albumsAddStore } from './AlbumsActions';
import { labelsAddStore } from './LabelsActions';
import { artistsAddStore } from './ArtistsActions';
import * as BackendCommunication from 'services/BackendCommunication.js';
import { activitiesAddStore, activitiesSignOut } from './ActivitiesActions';
import { usersAddStore, usersSignOut } from './UsersActions';
import { payoutsAddStore, payoutsSignOut } from './PayoutsActions';
import { uploadsSignOut } from './UploadsActions';
import { discountsAddLocal, discountsSignOut } from './DiscountsActions';
import { paymentsSignIn, paymentsSignOut } from './PaymentsActions';
import { querySignOut } from './QueryActions';
import { logEmailChangedAnalyticEvent } from 'services/GoogleAnalytics';

export const userDataAddInfoStore = userInfo => {
  return {
    type: ReducerTypes.USER_DATA_SIGN_IN,
    payload: userInfo
  }
}

// ACTION CREATORS
export const userDataSignIn = (userInfo, albums, users, artists, labels, collaborators, activities, payouts, discounts, payments, invitedArtists) => async dispatch => {
  dispatch(userDataAddInfoStore(userInfo));
  dispatch(artistsAddStore(Array.isArray(artists) ? artists : []));
  dispatch(usersAddStore(Array.isArray(users) ? users : []));
  dispatch(albumsAddStore(Array.isArray(albums) ? albums : []));
  dispatch(labelsAddStore(Array.isArray(labels) ? labels : []));
  dispatch(addArtistsInvited(Array.isArray(invitedArtists) ? invitedArtists : []));
  dispatch(addCollaborators(Array.isArray(collaborators) ? collaborators : []));
  dispatch(activitiesAddStore(Array.isArray(activities) ? activities : []));
  dispatch(payoutsAddStore(Array.isArray(payouts) ? payouts : []));
  dispatch(discountsAddLocal(Array.isArray(discounts) ? discounts : []))
  dispatch(paymentsSignIn(Array.isArray(payments) ? payments : []))
  return;
};

export const userDataSignOut = () => async dispatch => {
  dispatch({ type: ReducerTypes.USER_DATA_SIGN_OUT });
  dispatch({ type: ReducerTypes.ARTISTS_SIGN_OUT });
  dispatch({ type: ReducerTypes.ALBUMS_SIGN_OUT });
  dispatch({ type: ReducerTypes.LABELS_SIGN_OUT });
  dispatch({ type: ReducerTypes.TRACKS_SIGN_OUT });

  dispatch(invitedArtistSignOut());
  dispatch(collaboratorsSignOut());
  dispatch(usersSignOut());
  dispatch(activitiesSignOut());
  dispatch(payoutsSignOut());
  dispatch(uploadsSignOut());
  dispatch(discountsSignOut());
  dispatch(paymentsSignOut());
  dispatch(querySignOut());
};

export const userDataAddImage = (urlImage) => {
  return {
    type: ReducerTypes.USER_DATA_ADD_IMAGE,
    payload: urlImage
  };
};

// Para cada posible error crear un doc en userModifications para adjuntar el problema.
// REHACER cuando quiera reestablecer el cambio de email.
export const userDataChangeEmailRedux = (oldEmail, newEmail, userId, password) => async dispatch => {
  let userModificationInfo = {
    action: "CHANGE_EMAIL", updateFinancialInfo: "OK", updateUserAssets: "OK",
    userId, updateUserDoc: "OK", updateUsersByEmail: "OK", oldEmail, newEmail,
    whenCreatedTS: new Date().getTime()
  };

  let resultChangingEmail = await BackendCommunication.editUserCredentialsAndElementsFS(oldEmail, newEmail, password, dispatch);
  logEmailChangedAnalyticEvent({ oldEmail, newEmail, userId });
  await FirestoreServices.createUserModDoc(userModificationInfo);

  return "SUCCESS";
}

export const userDataUpdateRedux = (newProfile, fromPaymentAction) => async dispatch => {
  // Si da error simplemente genero la notificacion.
  let resultEdit = await FirestoreServices.editUserDataWithOutCredentials(newProfile, fromPaymentAction, dispatch);
  if (resultEdit === "EDITED") {
    dispatch({
      type: ReducerTypes.USER_DATA_EDIT_PERFIL,
      payload: newProfile
    })
    return "SUCCESS";
  }
  else return "ERROR";
};

export const userDataUpdateLocal = newData => {
  return {
    type: ReducerTypes.USER_DATA_EDIT_PERFIL,
    payload: newData
  }
}

export const addSubgenreToUserStore = newSubgenreNameAndId => {
  return {
    type: ReducerTypes.USER_DATA_ADD_SUBGENERO,
    payload: newSubgenreNameAndId
  }
}

export const createSubgenreRedux = (newSubgenreName, userId) => async dispatch => {
  let subgenreClean = newSubgenreName.normalize("NFD").replace(/\p{Diacritic}/gu, "");
  let subgenreFromFuga = await BackendCommunication.createSubgenreFuga(subgenreClean, dispatch);
  if (subgenreFromFuga === "ERROR") return "ERROR";

  await FirestoreServices.createSubgenreInUserDocFS(subgenreFromFuga, userId);

  dispatch(addSubgenreToUserStore(subgenreFromFuga));
  return subgenreFromFuga;
}
