export const peopleRoles = [
  { id: 'ACTOR', name: 'Actor' }, { id: 'ARRANGER', name: 'Arreglista' }, { id: 'CHOIR', name: 'Coro' },
  { id: 'COMPOSER', name: 'Compositor' }, { id: 'CONDUCTOR', name: 'Conducción' }, { id: 'ENGINEER', name: 'Ingeniero' },
  { id: 'ENSEMBLE', name: 'Conjunto' }, { id: 'VIDEO_PRODUCER', name: 'Productor de video' },
  { id: 'LYRICIST', name: 'Liricista' }, { id: 'MIXER', name: 'Mezclador' }, { id: 'ORCHESTRA', name: 'Orquestra' },
  { id: 'PRODUCER', name: 'Productor' }, { id: 'REMIXER', name: 'Remixer' },
  { id: 'SOLOIST', name: 'Solista' }, { id: 'WRITER', name: 'Escritor' }, { id: 'VIDEO_DIRECTOR', name: 'Director de video' },
]

// ROLES ELIMINADOS
// Por problemas con APPLE y redundantes
// { id: 'CONTRIBUTING_ARTIST', name: 'Artista contribuidor' }
// { id: 'FEATURING', name: 'Featuring' }
// { id: 'PERFORMER', name: 'Performer' }