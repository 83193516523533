import firebaseApp from 'firebaseConfig/firebase.js';
import { getFirestore, query, collection, where, getCountFromServer, getDocs, limit, orderBy } from "firebase/firestore";
import { to } from 'utils';
import { createFireStoreError } from 'redux/actions/NotificationsHandlerActions';
import { mapArtistsCustomQueryNameToStatus } from 'utils/artists.queries.utils';
import { artistsAddStore } from 'redux/actions/ArtistsActions';
import { ARTIST_ACTIVE, ARTIST_SUSPEND_NO_PAY, artistNoActiveStates, artistOacStates, astistSuspectStates } from 'variables/artist.variables';

const db = getFirestore(firebaseApp);

export const defaultCountArtists = {
  totalArtists: "...", activeArtists: "...", moneySuspended: "...", withInfractions: "...",
  withOacRequests: "...", inactiveArtists: "..."
};

export const getArtistsCountByQuery = async (searchField, fieldValues, dispatch) => {
  if (!Array.isArray(fieldValues) || fieldValues.length === 0) return;
  let elementsDbFromArtistsRef = query(collection(db, 'artists'), where(searchField, "in", fieldValues), orderBy("whenCreatedTS", "desc"));
  let [errorGettingElementsFromArtist, countsFromUserSnapshot] = await to(getCountFromServer(elementsDbFromArtistsRef));
  if (errorGettingElementsFromArtist) {
    dispatch(createFireStoreError(`Error obteniendo los counts de la colección ARTISTS.`, errorGettingElementsFromArtist));
    return "ERROR";
  }
  return Number(countsFromUserSnapshot.data().count);
}

export const getArtistsBasicCounts = async dispatch => {
  const [activeArtists, moneySuspended, withInfractions, withOacRequests, inactiveArtists] = await Promise.all([
    await getArtistsCountByQuery('state', [ARTIST_ACTIVE], dispatch),
    await getArtistsCountByQuery('state', [ARTIST_SUSPEND_NO_PAY], dispatch),
    await getArtistsCountByQuery('state', astistSuspectStates, dispatch),
    await getArtistsCountByQuery('state', artistOacStates, dispatch),
    await getArtistsCountByQuery('state', artistNoActiveStates, dispatch)
  ]);
  let totalArtists = activeArtists + moneySuspended + withInfractions + inactiveArtists;
  return {
    totalArtists, activeArtists, withInfractions, moneySuspended,
    withOacRequests, inactiveArtists
  };
}

const getCustomQueryRefByName = (customQueryName, limitArtists) => {
  let elementsDbFromArtistsRef = {};
  if (customQueryName === 'all') elementsDbFromArtistsRef = query(collection(db, 'artists'), limit(limitArtists));
  else {
    let finalValue = mapArtistsCustomQueryNameToStatus(customQueryName);
    elementsDbFromArtistsRef = query(collection(db, 'artists'), where('state', "in", finalValue), limit(limitArtists));
  }
  return elementsDbFromArtistsRef;
}

export const getCustomQueryArtists = async (customQueryName, limitArtists, dispatch) => {
  let elementsDbFromArtistsRef = getCustomQueryRefByName(customQueryName, limitArtists);
  let [errorGettingElementsFromArtist, artistsSnap] = await to(getDocs(elementsDbFromArtistsRef));
  if (errorGettingElementsFromArtist) {
    dispatch(createFireStoreError(`Error obteniendo una custon query de ARTISTS.`, errorGettingElementsFromArtist));
    return "ERROR";
  }
  if (artistsSnap.empty) return "EMPTY";

  let results = [];
  artistsSnap.forEach(artistDoc => results.push(artistDoc.data()));
  dispatch(artistsAddStore(results));
  return results;
}
