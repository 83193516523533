import React from "react";
import PropTypes from "prop-types";
import {
  Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions,
  Backdrop, CircularProgress
} from '@mui/material';

const InfoActionDialog = (props) => {

  const { id, isOpen, handleClose, handleOk, title, contentTexts, loading, handleOkButtonText } = props;

  return (
    <Dialog
      maxWidth="md"
      id={id + "info-dialog"}
      open={isOpen}
      onClose={handleClose}>
      <Backdrop open={Boolean(loading)}>
        <CircularProgress />
      </Backdrop>

      <DialogTitle id={id + "title-info-dialog"}>
        {title}
      </DialogTitle>
      <DialogContent>
        {contentTexts.map((contentText, index) =>
          <DialogContentText key={id + index}>
            {contentText}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {handleOk ? "Cerrar" : "Ok"}
        </Button>
        {handleOk && <Button onClick={handleOk} color="primary">
          {handleOkButtonText ? handleOkButtonText : "OK"}
        </Button>}
      </DialogActions>
    </Dialog>
  )
}

export default InfoActionDialog;

InfoActionDialog.defaultProps = {
  isOpen: false,
  title: "",
  contentText: ""
}

InfoActionDialog.propTypes = {
  contentTexts: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}