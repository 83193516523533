import { Dashboard, Person, PeopleAlt, Album, CollectionsBookmark, Paid, Support, Campaign, Storefront } from "@mui/icons-material";
// core components/views for Admin layout
import MyAlbums from "views/Albums/MyAlbums";
import MyArtists from "views/Artists/MyArtists";
import MyLabels from 'views/Labels/MyLabels';
import UserProfile from "views/UserProfile/UserProfile";
import DashboardAdmin from './views/Dashboard/Dashboard';
import MyUsers from './views/Users/MyUsers';
import Test from './views/ViewTest/Test';
import Subscription from './views/Subscription/Subscription';
import Royalties from 'views/Royalties/Royalties.js';
import Payouts from "views/Royalties/Payouts";
import Discounts from "views/Discounts/Discounts";
import PaymentsStats from "views/Payments/PaymentsStats";

const dashboardRoutes = [
  {
    type: "navlink",
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardAdmin,
    layout: "/admin"
  },
  {
    type: "navlink",
    path: "/dashboard-admin",
    name: "Dashboard Admin",
    icon: Dashboard,
    component: DashboardAdmin,
    layout: "/admin"
  },
  {
    type: "navlink",
    path: "/users",
    name: "Usuarios",
    icon: PeopleAlt,
    component: MyUsers,
    layout: "/admin"
  },
  {
    type: "navlink",
    path: "/stats",
    name: "Estadísticas",
    icon: Paid,
    component: PaymentsStats,
    layout: "/admin"
  },
  {
    type: "navlink",
    path: "/artists",
    name: "Artistas",
    icon: Person,
    component: MyArtists,
    layout: "/admin",
  },
  {
    type: "navlink",
    path: "/labels",
    name: "Sellos",
    icon: CollectionsBookmark,
    component: MyLabels,
    layout: "/admin"
  },
  {
    type: "navlink",
    path: "/albums",
    name: "Lanzamientos",
    icon: Album,
    component: MyAlbums,
    layout: "/admin"
  },
  {
    type: "navlink",
    path: "/regalias",
    name: "Regalías",
    icon: Paid,
    component: Royalties,
    layout: "/admin"
  },
  {
    type: "navlink",
    path: "/retiros",
    name: "Retiros",
    icon: Paid,
    component: Payouts,
    layout: "/admin"
  },
  {
    type: "navlink",
    path: "/cupones",
    name: "Cupones",
    icon: Paid,
    component: Discounts,
    layout: "/admin"
  },
  {
    type: "navlink",
    path: "/user",
    name: "Perfil",
    icon: Person,
    component: UserProfile,
    layout: "/admin"
  },
  {
    type: "navlink",
    path: "/subscription",
    name: "Suscripción",
    icon: Person,
    component: Subscription,
    layout: "/admin"
  },
  {
    type: "navlink",
    path: "/test",
    name: "Test",
    icon: Person,
    component: Test,
    layout: "/admin"
  },
  {
    type: "submenu",
    name: "Soporte",
    path: "/support",
    icon: Support,
    linkNames: ["Preguntas Frecuentes y Contacto", "Invitá Amig@s"],
    links: ["https://app.laflota.com.ar/support", "https://app.laflota.com.ar/admin/cupones"],
    layout: "/admin"
  },
  {
    type: "submenu",
    path: "/dsps",
    name: "DSP's",
    icon: Storefront,
    linkNames: ["DSP's 2024", "DSP's para Artistas", "Letras", "Reclamos"],
    links: ["https://www.laflota.com.ar/dsps-2024", "https://www.laflota.com.ar/dsps-for-artists", "https://www.laflota.com.ar/letras", "https://www.laflota.com.ar/dsps-reclamos"],
    layout: "/admin"
  },
  // {
  //   type: "link",
  //   path: "/marketing",
  //   name: "Difusión y Marketing",
  //   icon: Campaign,
  //   link: "https://drive.google.com/file/d/1_rTzk_irKAgdqHMFsDU5IMaGzEQqVwrY/view",
  //   layout: "/admin"
  // },
];

export default dashboardRoutes;
