import firebaseApp from 'firebaseConfig/firebase.js';
import {
  getFirestore, getDoc, updateDoc, doc, setDoc, arrayUnion, query, collection,
  getDocs, where, deleteDoc, limit, writeBatch, orderBy
} from "firebase/firestore";
import { to, toWithOutError } from 'utils';
import { createFireStoreError } from 'redux/actions/NotificationsHandlerActions';
import { createUserDocItem } from 'factory/users.factory';
import { v4 as uuidv4 } from 'uuid';
import { getCantDaysInMS } from '../utils/timeRelated.utils';
import { writeCloudLog } from './LoggingService';
import { SUB_ORDER_NEED_CHECK, SUB_ORDER_NEED_REFUND, SUB_ORDER_PAYED } from 'variables/financial';
import { allAlbumStates } from 'variables/varias';
import { store } from 'redux/store/Store';
import { userIsAdmin } from 'utils/users.utils';

const db = getFirestore(firebaseApp);

const collectionsWithActivities = ["artists", "albums", "labels", "users", "discounts", "payments"];

// Obtén el usuario actual desde el estado de Redux
const getCurrentUserCredsAndIsAdmin = () => {
  const state = store.getState();
  const user = state.userData;
  return { userId: user.id, isAdmin: userIsAdmin(user.rol), userEmail: user.email }; // Ajusta esto según la estructura de tu estado
}
//==============================================SYSTEM======================================================\\

export const getSystemPropOfUpc = async (dispatch) => {
  const upcSystemRef = doc(db, "system", "manageUpcs");
  let [errorGettingSystemUpc, systemUpcDoc] = await to(getDoc(upcSystemRef));
  if (errorGettingSystemUpc) {
    dispatch(createFireStoreError("Error buscando un Tipo de UPCS en System: ", errorGettingSystemUpc));
    writeCloudLog("FS Error getting UPC System Props", {}, errorGettingSystemUpc, "error");
  }
  return systemUpcDoc.data().currentSystem;
}

export const getSystemUsdToArsRatio = async (dispatch) => {
  const usdSystemRef = doc(db, "system", "usdToArsRatio");
  let [errorGettingSystemUsd, systemUsdDoc] = await to(getDoc(usdSystemRef));
  if (errorGettingSystemUsd) {
    dispatch(createFireStoreError("Error buscando el precio del USD en System: ", errorGettingSystemUsd));
    writeCloudLog("FS Error getting USD System Props", {}, errorGettingSystemUsd, "error");
  }
  return systemUsdDoc.data().usdPrice;
}

export const getPlanesLaFlota = async (dispatch) => {
  const planesRef = doc(db, "system", "planes");
  let [errorGettingPlanes, planesDoc] = await to(getDoc(planesRef));
  if (errorGettingPlanes) {
    dispatch(createFireStoreError("Error buscando los planes de la Flota: ", errorGettingPlanes));
    writeCloudLog("FS Error getting UPC System Props", {}, errorGettingPlanes, "error");
  }
  return Object.values(planesDoc.data());
}

//==============================================USERS MODIFICATIONS=======================================================\\

export const createUserModDoc = async (newUserModData, dispatch) => {
  const id = uuidv4();
  let [errorCreatingDocInUsers] = await to(createElementFS(newUserModData, id, newUserModData.userId, "usersModifications", "", 0, dispatch));
  if (errorCreatingDocInUsers) { console.log(errorCreatingDocInUsers); return { error: errorCreatingDocInUsers }; }
  return newUserModData;
}

//==============================================USERS=======================================================\\

export const editUserDataWithOutCredentials = async (newUserData, fromPaymentAction, dispatch) => {
  if (!newUserData.id) return "ERROR";
  const userDbRef = doc(db, "users", newUserData.id);
  let newInfo = { ...newUserData, lastUpdateTS: new Date().getTime() };
  if (fromPaymentAction) newInfo.lastPayUpdateTS = new Date().getTime();

  let [errorUpdatingUserInDB] = await to(updateDoc(userDbRef, newInfo));
  if (errorUpdatingUserInDB) {
    dispatch(createFireStoreError("Error updating user data info", errorUpdatingUserInDB));
    writeCloudLog("FS Error updating user without credentials", newUserData, errorUpdatingUserInDB, "error");
    return "ERROR";
  }
  return "EDITED";
}

export const getUserDocFS = async (userId, dispatch) => {
  let userInDBRef = doc(db, "users", userId);
  let [errorGettingUserDoc, userDoc] = await to(getDoc(userInDBRef));
  if (errorGettingUserDoc) {
    let result = await toWithOutError(dispatch(createFireStoreError("Error buscando un Usuario: ", errorGettingUserDoc)));
    if (result !== "RETRY") {
      writeCloudLog("FS Error getting user", userId, errorGettingUserDoc, "error");
      return "ERROR";
    }
    else return "RETRY";
  }
  return userDoc;
}

export const updateUserDocPostLoginFS = async (userId, userDoc, dispatch) => {
  let userInDBRef = doc(db, "users", userId);
  let userDocData = userDoc.data();
  let date = new Date();
  let lastTimeSignedInString = date.toLocaleString('es-ES', { timeZone: 'America/Argentina/Buenos_Aires' });
  userDocData.lastTimeSignedInString = lastTimeSignedInString;
  userDocData.lastTimeSignedIn = date.getTime();

  let [errorUpdatingUserSignIn] = await to(updateDoc(userInDBRef, {
    lastUpdateTS: date.getTime(),
    lastTimeSignedIn: date.getTime(), lastTimeSignedInString
  }));
  if (errorUpdatingUserSignIn) {
    dispatch(createFireStoreError("Error al actualizar al usuario. Intente nuevamente.", errorUpdatingUserSignIn));
    writeCloudLog("FS Error getting user doc post login", userId, errorUpdatingUserSignIn, "error");
    return "ERROR";
  };
  return userDocData;
}


export const getUserDataByEmailInFS = async (email, dispatch) => {
  const userByEmailDbRef = query(collection(db, "users"), where("email", "==", email));
  let [errorGettingUserByEmail, userByEmailSnap] = await to(getDocs(userByEmailDbRef));
  if (errorGettingUserByEmail) {
    dispatch(createFireStoreError("Error buscando usuario.", errorGettingUserByEmail));
    writeCloudLog("User by email in USERS doc error getting it, when getting user", email, errorGettingUserByEmail, "error");
    return "ERROR";
  }
  if (userByEmailSnap.empty) return "EMPTY";

  let result = {};
  userByEmailSnap.forEach(userDoc => {
    result = userDoc.data()
  })

  return result;
}

export const createUserDocs = async (newUserData, dispatch) => {
  const { id } = newUserData;
  let userDataComplete = createUserDocItem(newUserData);

  let [errorCreatingDocInUsers] = await to(createElementFS(userDataComplete, id, id, "users", "", 1, dispatch));
  if (errorCreatingDocInUsers) {
    return { error: errorCreatingDocInUsers };
  }
  return userDataComplete;
}

//===================================================ALBUMS===========================================================\\

// No necesariamente lo elimina, puede simplemente cambiarle el estado a uno "NEGATIVO" (INFRACTION, TAKEN_DOWN, etc etc)
export const deleteAlbumFS = async (albumData, albumWithPossibleRoyalties, newState, dispatch) => {
  const elementDbRef = doc(db, 'albums', albumData.id);
  let justDelete = !albumWithPossibleRoyalties && newState === "";
  let isTakenDown = albumWithPossibleRoyalties && newState === "";
  let errorDeletingAlbum = "";
  let action = newState ? newState : albumWithPossibleRoyalties ? 'taken-down-album' : 'delete';

  if (justDelete) errorDeletingAlbum = await toWithOutError(deleteDoc(elementDbRef));
  if (isTakenDown || newState)
    errorDeletingAlbum = await toWithOutError(updateDoc(elementDbRef, { state: newState ? newState : "TAKEN_DOWN", lastUpdateTS: new Date().getTime() }));

  if (errorDeletingAlbum) {
    dispatch(createFireStoreError(`Error eliminando un elemento`, errorDeletingAlbum));
    writeCloudLog("FS Error deleting element", { userId: albumData.ownerId, album: albumData }, errorDeletingAlbum, "error");
  }
  await addActivityFS('albums', albumData.ownerId, albumData, action, dispatch);
}

//===================================================ELEMENTS=========================================================\\

export const getElements = async (userId, typeOfElement, dispatch, limitNumber) => {
  if (!userId) return [];
  let whereClause = [where("ownerId", "==", userId)];
  if (typeOfElement === 'albums') whereClause.push(where('state', 'in', allAlbumStates));

  const elementsDbFromUserRef = query(collection(db, typeOfElement), ...whereClause,
    orderBy("lastUpdateTS", "desc"), limit(limitNumber));
  let [errorGettingElementsFromUser, elementsFromUserSnapshot] = await to(getDocs(elementsDbFromUserRef));
  if (errorGettingElementsFromUser) {
    dispatch(createFireStoreError(`Error obteniendo los elementos de la colección ${typeOfElement}.`, errorGettingElementsFromUser));
    writeCloudLog("FS Error getting elements", { userId, typeOfElement }, errorGettingElementsFromUser, "error");
    return "ERROR";
  }

  let elementsFromUser = [];
  elementsFromUserSnapshot.forEach(elementDoc => {
    elementsFromUser.push(elementDoc.data());
  });

  return elementsFromUser;
}

// Acepta USERS tambien.
export const getElementsByField = async (typeOfElement, searchField, fieldValue, dispatch, limitNumber) => {
  const { userId, isAdmin } = getCurrentUserCredsAndIsAdmin();
  
  let whereOperator = Array.isArray(fieldValue) ? "in" : "==";
  let whereClause = [where(searchField, whereOperator, fieldValue)];
  if (!isAdmin && userId) whereClause.push(where('ownerId', '==', userId));

  const elementsDbFromUserRef = query(collection(db, typeOfElement), ...whereClause,
    orderBy(typeOfElement !== "users" ? "lastUpdateTS" : "lastPayUpdateTS", "desc"), limit(limitNumber));

  let [errorGettingElementsFromUser, elementsFromUserSnapshot] = await to(getDocs(elementsDbFromUserRef));
  if (errorGettingElementsFromUser) {
    dispatch(createFireStoreError(`Error obteniendo los elementos de la colección ${typeOfElement}.`, errorGettingElementsFromUser));
    writeCloudLog(`FS Error getting elements by field: ${searchField} and value: ${fieldValue}`
      , { fieldValue, searchField, typeOfElement }, errorGettingElementsFromUser, "error");
    return "ERROR";
  }
  if (elementsFromUserSnapshot.empty) return "EMPTY";

  let elementsFromUser = [];
  elementsFromUserSnapshot.forEach(elementDoc => {
    elementsFromUser.push(elementDoc.data());
  });

  return elementsFromUser;
}

export const getElementsByFields = async (typeOfElement, searchFields, fieldsValue, dispatch, limitNumber) => {
  let elementsQuery = query(
    collection(db, typeOfElement), orderBy(typeOfElement !== "users" ? "lastUpdateTS" : "lastPayUpdateTS", "desc"), limit(limitNumber));

  searchFields.forEach((searchField, index) => {
    let searchOperator = Array.isArray(fieldsValue[index]) && fieldsValue[index].length > 1 ? "in" : "==";
    let values = Array.isArray(fieldsValue[index]) && fieldsValue[index].length > 1 ? fieldsValue[index]
      : Array.isArray(fieldsValue[index]) ? [fieldsValue[index]] : fieldsValue[index];
    elementsQuery = query(elementsQuery, where(searchField, searchOperator, values))
  });

  let [errorGettingElementsFromUser, elementsFromUserSnapshot] = await to(getDocs(elementsQuery));
  if (errorGettingElementsFromUser) {
    dispatch(createFireStoreError(`Error obteniendo los elementos de la colección ${typeOfElement}.`, errorGettingElementsFromUser));
    writeCloudLog(`FS Error getting elements by field: ${searchFields} and value: ${fieldsValue}`
      , { fieldsValue, searchFields, typeOfElement }, errorGettingElementsFromUser, "error");
    return "ERROR";
  }
  if (elementsFromUserSnapshot.empty) return "EMPTY";

  let elementsFromUser = [];
  elementsFromUserSnapshot.forEach(elementDoc => {
    elementsFromUser.push(elementDoc.data());
  });

  return elementsFromUser;
}

export const getElementsAdminDev = async (userDataFromDB, userId, typeOfElement, dispatch, limitNumber, orderByField) => {
  let elementsDbFromUserRef = {};

  if (userDataFromDB.rol.indexOf("index") >= 0 && userId !== "") {
    elementsDbFromUserRef = query(collection(db, typeOfElement), where("ownerId", "==", userId),
      orderBy(orderByField, "desc"), limit(limitNumber));
  }
  else elementsDbFromUserRef = query(collection(db, typeOfElement), where(orderByField, ">", Date.now() - getCantDaysInMS(30)),
    orderBy(orderByField, "desc"), limit(limitNumber));

  if (!collectionsWithActivities.includes(typeOfElement)) return;
  let [errorGettingElementsFromUser, elementQuerySnap] = await to(getDocs(elementsDbFromUserRef));
  if (errorGettingElementsFromUser) {
    dispatch(createFireStoreError(`Error obteniendo los elementos de la colección ${typeOfElement} siendo admin.`, errorGettingElementsFromUser));
    writeCloudLog("FS Error getting admin elements", { userId, typeOfElement }, errorGettingElementsFromUser, "error");
    return "ERROR";
  }

  let elementsFromUser = [];
  elementQuerySnap.forEach(elementDoc => {
    elementsFromUser.push(elementDoc.data());
  });

  return elementsFromUser;
}

// Elements es: LABEL, ARTIST, TRACK, ALBUMS. ARTISTS_INVITED, COLLABORATORS
export const createElementFS = async (element, elementId, userId, collection, fieldToIncrementInUserStats, amountToIncrement, dispatch) => {
  if (!elementId) return "ERROR";

  const elementDbRef = doc(db, collection, elementId);

  let [errorCreatingElement] = await to(setDoc(elementDbRef, element));
  if (errorCreatingElement) {
    dispatch(createFireStoreError(`Error creando nuevo elemento en la colección ${collection}.`, errorCreatingElement));
    writeCloudLog("FS Error creating elements", { userId, element, collection }, errorCreatingElement, "error");
    return "ERROR";
  }
  await addActivityFS(collection, userId, element, "create", dispatch);
  return "SUCCESS";
}

export const updateElementFS = async (oldData, newElementFields, elementId, collection, dispatch) => {
  const elementDbRef = doc(db, collection, elementId);
  let [errorUpdatingElementInCollection] = await to(updateDoc(elementDbRef, {
    ...newElementFields,
    lastUpdateTS: new Date().getTime()
  }));
  if (errorUpdatingElementInCollection) {
    dispatch(createFireStoreError(`Error actualizando elemento`, errorUpdatingElementInCollection));
    writeCloudLog("FS Error creating elements", { oldData, newElementFields, elementId, collection }, errorUpdatingElementInCollection, "error");
    return "ERROR";
  }

  addActivityFS(collection, oldData.ownerId, { ...oldData, ...newElementFields }, "update", dispatch);
}

// Elements es: LABEL, ARTIST, TRACK, ALBUMS.
export const deleteElementFS = async (element, elementId, userId, collection, fieldToDecrementInUserStats, amountToDecrement, action, dispatch) => {
  const elementDbRef = doc(db, collection, elementId);

  let [errorDeletingElementInCollection] = action !== 'taken-down-album'
    ? await to(deleteDoc(elementDbRef))
    : await to(updateDoc(elementDbRef, { state: "TAKEN_DOWN", lastUpdateTS: new Date().getTime() }));
  if (errorDeletingElementInCollection) {
    dispatch(createFireStoreError(`Error eliminando un elemento`, errorDeletingElementInCollection));
    writeCloudLog("FS Error deleting element", { userId, element, collection }, errorDeletingElementInCollection, "error");
  }
  await addActivityFS(collection, userId, element, action !== 'taken-down-album' ? "delete" : "taken-down", dispatch);
}

const getBasicInfoElement = (element, collection) => {
  if (collection === "albums") return element.title;
  if (collection === "labels" || collection === "artists") return element.name;
  return "";
}

export const getElementsAdminQueryFS = (targetCollection, limitNumber, lastUpdateInTS, adminOrUserId, fieldToCheckUpdate) => {
  let elementsDbRef = query(collection(db, targetCollection), where(fieldToCheckUpdate, ">", lastUpdateInTS),
    orderBy(fieldToCheckUpdate, "desc"), limit(limitNumber));

  if (adminOrUserId !== 'admin') elementsDbRef = query(elementsDbRef, where("ownerId", "==", adminOrUserId));
  return elementsDbRef;
}

export const getDocIfLastUpdateFS = (targetCollection, targetId, lastUpdateInTS) => {
  const elementsDbRef = query(collection(db, targetCollection), where("lastUpdateTS", ">", lastUpdateInTS),
    where("id", "==", targetId), limit(1));
  return elementsDbRef;
}
//====================================================ACTIVITY================================================================\\

const addActivityFS = async (collection, ownerId, element, typeOfAction, dispatch) => {
  if (!collectionsWithActivities.includes(collection) || !Boolean(ownerId)) return;
  let activity = {
    action: typeOfAction, target: collection, targetId: element.id,
    ownerId, targetName: getBasicInfoElement(element, collection), lastUpdateTS: new Date().getTime()
  };

  const newActivityDbRef = doc(db, "usersActivity", uuidv4());
  let [errorCreatingActivity] = await to(setDoc(newActivityDbRef, activity))
  if (errorCreatingActivity) {
    dispatch(createFireStoreError(`Error creando nueva actividad`, errorCreatingActivity));
    writeCloudLog("FS Error creating elements", { ownerId, collection, element }, errorCreatingActivity, "error");
    return "ERROR";
  }

  return "SUCCESS";
}

//============================================================ARTISTS=================================================================\\

// export const suspendAllArtistsFromUserIdFS = async (userId, dispatch) => {
//   const batch = writeBatch(db);
//   const allArtistsFromUserIdRef = query(collection(db, "artists"), where("ownerId", "==", userId));

//   let [errorGettingAllArtists, artistsSnapshot] = await to(getDocs(allArtistsFromUserIdRef));
//   if (errorGettingAllArtists) {
//     dispatch(createFireStoreError("Error obteniendo los tracks del Lanzamiento a eliminar.", errorGettingAllArtists));
//     writeCloudLog("FS Error getting all tracks from album to delete", { userId, collection: "artists" }, errorGettingAllArtists, "error");
//     return "ERROR";
//   }

//   if (artistsSnapshot.empty) return "NO HAY TRACKS A ELIMINAR";

//   let cantArtistsToDelete = 0;
//   artistsSnapshot.forEach(trackDoc => {
//     cantArtistsToDelete++;
//     batch.update(trackDoc.ref, { lastUpdateTS: new Date().getTime(), state: ARTIST_SUSPEND_NO_PAY })
//   });

//   let [errorDeletingAllArtists] = await to(batch.commit());
//   if (errorDeletingAllArtists) {
//     dispatch(createFireStoreError("Error eliminando los artistas del Usuario. ", errorDeletingAllArtists));
//     writeCloudLog("FS Error deleting all artists from User", { userId, collection: "artists" }, errorDeletingAllArtists, "error");
//     return "ERROR";
//   }

//   // Habria que tener totales para cada posible estado...
//   // await updateStatsOfCollection("tracks", (-1) * cantArtistsToDelete, dispatch);

//   return { status: "SUCCESS", cant: cantArtistsToDelete };
// }

//============================================================TRACKS==================================================================\\

export const deleteAllTracksFromAlbumIdFS = async (albumId, userId, dispatch) => {
  const batch = writeBatch(db);
  const allTracksFromAlbumIdRef = query(collection(db, "tracks"), where("albumId", "==", albumId));

  let [errorGettingAllTracks, tracksSnapshot] = await to(getDocs(allTracksFromAlbumIdRef));
  if (errorGettingAllTracks) {
    dispatch(createFireStoreError("Error obteniendo los tracks del Lanzamiento a eliminar.", errorGettingAllTracks));
    writeCloudLog("FS Error getting all tracks from album to delete", { userId, albumId, collection }, errorGettingAllTracks, "error");
    return "ERROR";
  }

  if (tracksSnapshot.empty) return "NO HAY TRACKS A ELIMINAR";

  tracksSnapshot.forEach(trackDoc => {
    batch.delete(trackDoc.ref)
  });

  let [errorDeletingAllTracks] = await to(batch.commit());
  if (errorDeletingAllTracks) {
    dispatch(createFireStoreError("Error eliminando los tracks del Lanzamiento. ", errorDeletingAllTracks));
    writeCloudLog("FS Error deleting all tracks from album", { userId, albumId, collection }, errorDeletingAllTracks, "error");
    return "ERROR";
  }

  return "SUCCESS";
}



//==============================================================ISRCS=================================================================\\

export const getAmountOfIsrcCodesToUseFS = async (amountOfIsrcs, dispatch) => {
  if (amountOfIsrcs === 0) return [];
  const batch = writeBatch(db);

  const isrcsDbRef = query(collection(db, "isrcs"), where("used", "==", false), orderBy("isrc", "asc"), limit(amountOfIsrcs));
  let [errorGettingIsrcs, isrcsSnapshot] = await to(getDocs(isrcsDbRef));
  if (errorGettingIsrcs) {
    dispatch(createFireStoreError("Error obteniendo los ISRC.", errorGettingIsrcs));
    writeCloudLog("FS Error getting ISRCS", amountOfIsrcs, errorGettingIsrcs, "error");
    return "ERROR";
  }

  if (isrcsSnapshot.empty) {
    dispatch(createFireStoreError("No hay ISRCS disponibles. Contactar a soporte por favor.", { code: "EMPTY_ISRCS" }));
    writeCloudLog("FS Error ISRCS empty", amountOfIsrcs, "NO MORE ISRCS", "error");
    return "EMPTY_ISRCS";
  }

  let isrcs = [];
  isrcsSnapshot.forEach(isrcDoc => {
    isrcs.push(isrcDoc.data().isrc);
    batch.update(isrcDoc.ref, { used: true });
  });

  let [errorUpdatingIsrcsStates] = await to(batch.commit());
  if (errorUpdatingIsrcsStates) {
    dispatch(createFireStoreError("Error actualizando los isrcs ya usados. ", errorUpdatingIsrcsStates));
    writeCloudLog("FS Error updating ISRCS state", amountOfIsrcs, errorUpdatingIsrcsStates, "error");
    return "ERROR";
  }

  return isrcs;
}

//==============================================================ISRCS=================================================================\\

export const getUpcFromFS = async dispatch => {

  const upcDbRef = query(collection(db, "upcs"), where("used", "==", false), limit(1));
  let [errorGettingUpcs, upcSnapshot] = await to(getDocs(upcDbRef));
  if (errorGettingUpcs) {
    dispatch(createFireStoreError("Error obteniendo el UPC.", errorGettingUpcs));
    writeCloudLog("FS Error getting UPC: ", errorGettingUpcs, "error");
    return "ERROR";
  }

  if (upcSnapshot.empty) {
    dispatch(createFireStoreError("No hay ISRCS disponibles. Contactar a soporte.", { error: "Empty upcs" }));
    writeCloudLog("FS Error UPCS empty", { error: "Empty upcs" }, "NO MORE UPCS", "error");
    return "ERROR";
  }

  let upc = "";
  upcSnapshot.forEach(async upcDoc => {
    upc = upcDoc.data().upc;
    await updateDoc(upcDoc.ref, { used: true });
  });

  return upc;
}

//========================================================MISCELLANEUS======================================================\\

export const createSubgenreInUserDocFS = async (subgenre, userId, dispatch) => {
  if (!subgenre) return "ERROR";
  let userInDBRef = doc(db, "users", userId);
  let [errorUpdatingUserSignIn] = await to(updateDoc(userInDBRef, { subgenerosPropios: arrayUnion(subgenre) }));
  if (errorUpdatingUserSignIn) {
    dispatch(createFireStoreError("Error al actualizar al usuario. Intente nuevamente.", errorUpdatingUserSignIn));
    writeCloudLog("FS Error getting ISRCS", { subgenre, userId }, errorUpdatingUserSignIn, "error");
    return "ERROR";
  };
}

//=======================================================ACCOUNTING===========================================================\\

export const getAccountingDocFS = async (idDoc, dispatch) => {
  let [errorGettingAccDoc, accountingDocSnap] = await to(getDoc(doc(db, "royaltiesAccounting", idDoc)));
  if (errorGettingAccDoc) {
    dispatch(createFireStoreError("Error al obtener las regalías. Intente nuevamente.", errorGettingAccDoc));
    writeCloudLog("FS Error getting Accounting Doc", idDoc, errorGettingAccDoc, "error");
    return "ERROR";
  }
  if (!accountingDocSnap.exists) return "NOT_FOUND";
  return accountingDocSnap.data().rows;
}

export const getArtistsAccountingValuesFS = async (targetArtistsNames, dispatch) => {
  if (targetArtistsNames.length === 0) return 0;
  let allAccountingArtistsValues = await getAccountingDocFS("accounting-all-releaseArtist", dispatch);
  let resultRows = allAccountingArtistsValues.filter(accRow => targetArtistsNames.includes(accRow.releaseArtist));
  let totalUsd = resultRows.length > 0 ? (resultRows.map(accRow => accRow.revenuesUSD).reduce((acc, value) => acc + value)) : 0;
  return totalUsd;
}

export const getPayoutsToViewFS = async (field, value, limitToGet, offset, dispatch) => {
  let queryRef = {};
  if (value === "") queryRef = query(collection(db, "payouts"), orderBy("requestDate", "desc"), limit(limitToGet))
  else queryRef = query(collection(db, "payouts"), where(`${field}`, "==", value)
    , orderBy("requestDate", "desc"), limit(limitToGet));

  let [errorGettingWds, wdsSnap] = await to(getDocs(queryRef));
  if (errorGettingWds) {
    dispatch(createFireStoreError("Error al obtener los retiros. Intente nuevamente.", errorGettingWds));
    writeCloudLog("FS Error getting withdraws Doc", { field, value, limitToGet, offset }, errorGettingWds, "error");
    return "ERROR";
  }

  if (wdsSnap.empty) return { count: 0, payouts: [] };

  let results = [];
  wdsSnap.forEach(wdsDoc => results.push(wdsDoc.data()))
  return results;
}

export const getPayoutsForTableViewByAllIds = async (id, dispatch) => {
  let queryByAppId = getDocs(query(collection(db, "payouts"), where("id", "==", id), limit(1)));
  let queryByPaypalId = getDocs(query(collection(db, "payouts"), where("paypalId", "==", id), limit(1)));
  let queryByPayoneerId = getDocs(query(collection(db, "payouts"), where("payoneerId", "==", id), limit(1)));
  let queryByMpId = getDocs(query(collection(db, "payouts"), where("mpId", "==", id), limit(1)));
  let queryByOtherId = getDocs(query(collection(db, "payouts"), where("otherPayId", "==", id), limit(1)));

  const [appIdQuerySnapshot, paypalIdQuerySnapshot, payoneerIdQuerySnapshot, mpIdQuerySnapshot, otherIdQuerySnapshot] = await Promise.all([
    queryByAppId, queryByPaypalId, queryByPayoneerId, queryByMpId, queryByOtherId]);

  const appIdDoc = appIdQuerySnapshot.docs;
  const paypalIdDoc = paypalIdQuerySnapshot.docs;
  const payoneerIdDoc = payoneerIdQuerySnapshot.docs;
  const mpIdDoc = mpIdQuerySnapshot.docs;
  const otherIdDoc = otherIdQuerySnapshot.docs;
  let allDocs = [...appIdDoc, ...paypalIdDoc, ...payoneerIdDoc, ...mpIdDoc, ...otherIdDoc];

  if (allDocs.length === 0) return { count: 0, payouts: [] };

  return { count: 1, payouts: [allDocs[0].data()] };
}

//===============================================PAYMENTS===============================================\\

export const createPaymentDocFS = async (paymentDoc, dispatch) => {
  if (!paymentDoc) return "ERROR";
  let paymentsDbRef = doc(db, "payments", paymentDoc.id);
  try { await setDoc(paymentsDbRef, paymentDoc) }
  catch (errorCreatingPayment) {
    dispatch(createFireStoreError(`Error creando iniciador de pago`, errorCreatingPayment));
    writeCloudLog("FS Error creating paymentDoc", { userId: paymentDoc.ownerId, paymentDoc }, errorCreatingPayment, "error");
    return "ERROR";
  };
  return "SUCCESS";
}

export const updatePaymentDocFS = async (newInfo, dispatch) => {
  if (!newInfo?.id) return "ERROR";
  let paymentsDbRef = doc(db, "payments", newInfo.id);
  try { await to(updateDoc(paymentsDbRef, { ...newInfo, lastUpdateTS: new Date().getTime() })) }
  catch (errorCreatingPayment) {
    dispatch(createFireStoreError(`Error actualizando pago`, errorCreatingPayment));
    writeCloudLog("FS Error requesting paymentDoc", { userId: newInfo.ownerId, newInfo }, errorCreatingPayment, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

export const getPendgingCheckPaymentFromUserFS = async (userId, dispatch) => {
  let paymentsRef = query(collection(db, "payments"), where('ownerId', "==", userId),
    where('status', "in", [SUB_ORDER_NEED_CHECK, SUB_ORDER_PAYED, SUB_ORDER_NEED_REFUND]), orderBy("lastUpdateTS", "desc"), limit(1));
  let [errorGettingPayments, paymentsSnap] = await to(getDocs(paymentsRef));
  if (errorGettingPayments) {
    dispatch(createFireStoreError(`Error buscando pago pendiente de verificacion`, { error: errorGettingPayments, userId }));
    return "ERROR";
  }
  if (paymentsSnap.empty) return "EMPTY";

  let result = [];
  paymentsSnap.forEach(paymentDoc => result = paymentDoc.data());
  return result;
}

export const deletePaymentDocFS = async (id, dispatch) => {
  if (!id) return "ERROR";
  let paymentsDbRef = doc(db, "payments", id);
  let [errorDeletingPayment] = await to(deleteDoc(paymentsDbRef));
  if (errorDeletingPayment) {
    dispatch(createFireStoreError(`Error eliminando pago`, errorDeletingPayment));
    writeCloudLog("FS Error deleting paymentDoc", { id }, errorDeletingPayment, "error");
    return "ERROR";
  }
  return "SUCCESS";
}
//==============================================DISCOUNTS===============================================\\

export const createDiscountFS = async (newDiscount, dispatch) => {
  if (!newDiscount) return "NO_DATA";
  let discountsDbRef = doc(db, "discounts", newDiscount.id);
  let [errorCreatingDiscount] = await to(setDoc(discountsDbRef, newDiscount))
  if (errorCreatingDiscount) {
    dispatch(createFireStoreError(`Error creando nuevo cupon`, errorCreatingDiscount));
    writeCloudLog("FS Error creating cupon", { ownerId: newDiscount.ownerId, newDiscount }, errorCreatingDiscount, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

export const deleteDiscountFS = async (discountId, dispatch) => {
  let discountsDbRef = doc(db, "discounts", discountId);
  let [errorDeletingDiscount] = await to(deleteDoc(discountsDbRef))
  if (errorDeletingDiscount) {
    dispatch(createFireStoreError(`Error creando nuevo cupon`, errorDeletingDiscount));
    writeCloudLog("FS Error deleting cupon", { discountId, }, errorDeletingDiscount, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

export const updateDiscountFS = async (discount, dispatch) => {
  let discountsDbRef = doc(db, "discounts", discount.id);
  let [errorUpdatingDiscount] = await to(updateDoc(discountsDbRef, { ...discount, lastUpdateTS: new Date().getTime() }))
  if (errorUpdatingDiscount) {
    dispatch(createFireStoreError(`Error updating nuevo cupon`, errorUpdatingDiscount));
    writeCloudLog("FS Error updating cupon", { discount: discount }, errorUpdatingDiscount, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

export const getDiscountByNameFS = async (discountName, dispatch) => {
  const { userEmail } = getCurrentUserCredsAndIsAdmin();
  const discountsDbQuery = query(collection(db, "discounts"), where("name", "==", discountName));
  let [errorGettingDiscountByName, discountsSnap] = await to(getDocs(discountsDbQuery));
  if (errorGettingDiscountByName) {
    dispatch(createFireStoreError(`Error obteniendo el cupon`, errorGettingDiscountByName));
    writeCloudLog("FS Error getting cupon by name", { discountName, userEmail }, errorGettingDiscountByName, "error");
    return "ERROR";
  };
  if (discountsSnap.empty) return "EMPTY";

  let result = {};
  discountsSnap.forEach(userDoc => {
    result = userDoc.data()
  })

  return result;
}