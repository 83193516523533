import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import AnalyticEcommerce from 'components/Card/AnalyticEcommerce';
import { useDispatch } from 'react-redux';
import { Paper } from '../../../node_modules/@mui/material/index';
import { defaultCountAlbums, getAlbumsBasicCounts, getCustomQueryAlbums } from 'services/AlbumStats';
import { querySetStore } from 'redux/actions/QueryActions';
import { formatThousandsPoint } from 'utils';

const AlbumsCountStats = () => {

  const dispatch = useDispatch();
  
  const [albumsCounts, setAlbumsCounts] = useState(defaultCountAlbums);

  useEffect(() => {
    const getUserCounts = async () => {
      let countsUsers = await getAlbumsBasicCounts(dispatch);
      console.log(countsUsers);
      setAlbumsCounts(countsUsers)
      return countsUsers;
    }
    getUserCounts();
  }, []);

  const handleGetCustomQuerys = async customQueryName => {
    let resultQuery = await getCustomQueryAlbums(customQueryName, 30, dispatch);
    dispatch(querySetStore({ name: customQueryName, limit: 30, collection: 'albums' }));
  }

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('all')}>
          <AnalyticEcommerce title="Lanzamientos Totales" count={formatThousandsPoint(albumsCounts.totalAlbums)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('online')}>
          <AnalyticEcommerce title="Online" count={formatThousandsPoint(albumsCounts.onlineAlbums)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('down')}>
          <AnalyticEcommerce title="Dados de baja (+)" count={formatThousandsPoint(albumsCounts.downAlbums)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('issues')}>
          <AnalyticEcommerce title="Pendientes y similares" count={formatThousandsPoint(albumsCounts.issuesAlbums)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('apple-related')}>
          <AnalyticEcommerce title="Apple pendientes" count={formatThousandsPoint(albumsCounts.appleIssues)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('infractions')}>
          <AnalyticEcommerce title="Con infracción" count={formatThousandsPoint(albumsCounts.infractionAlbums)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('next-to-release')}>
          <AnalyticEcommerce title="Próximos a Lanzar" count={formatThousandsPoint(albumsCounts.nextToRelease)} />
        </Paper>
      </Grid>

      <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

    </Grid>
  );
}

export default AlbumsCountStats;