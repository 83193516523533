import React, { useEffect, useState } from "react";
import { Grid, Button, Card, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SingleUpload from "views/Uploads/SingleUpload";
import { uplaodsAddSkeletonStore, uploadsStartOneStore, uploadsSignOut } from '../../redux/actions/UploadsActions';
import { ALL_UPLOADS_COMPLETE, SUCCESSFULLY_UPLOADED } from '../../variables/uploads.variables';
import { ERROR_UPLOADING } from 'variables/uploads.variables';

const CardUploads = ({ uploadsIds }) => {

  const dispatch = useDispatch();
  const uploads = useSelector(store => store.uploads);
  const uploadingTracks = useSelector(store => store.tracks.uploadingTracks);

  const [cantOfDownloadsComplete, setCantOfDownloadsComplete] = useState(0);

  useEffect(() => {
    let completeDownloads = getCantOfDownloadsComplete();
    if (completeDownloads > cantOfDownloadsComplete) setCantOfDownloadsComplete(completeDownloads);
  }, [uploads])

  useEffect(() => {
    let nextUploadFileId = ALL_UPLOADS_COMPLETE;
    if (cantOfDownloadsComplete < uploadsIds.length) {
      nextUploadFileId = getUploadToContinueDownload();
      if (nextUploadFileId && nextUploadFileId !== ALL_UPLOADS_COMPLETE)
        dispatch(uploadsStartOneStore(nextUploadFileId))
    }
  }, [cantOfDownloadsComplete])

  const handleStartAllUploads = () => {
    dispatch(uploadsStartOneStore(uploadsIds[0]));
  }

  const handleLoadAllUploads = () => {
    for (let testUploadId of uploadsIds) {
      dispatch(uplaodsAddSkeletonStore(testUploadId))
    }
  }

  const handleDeleteAllUploads = () => dispatch(uploadsSignOut());

  const getCantOfDownloadsComplete = () => {
    return uploads.filter(upload => ((upload.status === SUCCESSFULLY_UPLOADED || upload.status === ERROR_UPLOADING)
      && uploadsIds.includes(upload.fileId))).length;
  }

  const getUploadToContinueDownload = () => {
    let notCompletedDownloads = uploads.filter(upload => (upload.status !== SUCCESSFULLY_UPLOADED)
      && (upload.status !== ERROR_UPLOADING) && (uploadsIds.includes(upload.fileId)));
    if (notCompletedDownloads.length > 0) return notCompletedDownloads[0].fileId;
    return ALL_UPLOADS_COMPLETE;
  }

  const getAssetNameFromFileId = fileId => {
    return uploadingTracks.find(track => track.fugaId === fileId)?.title || "";
  }

  const cardElementStyle = { borderRadius: "30px", marginTop: "1%", width: '100%', height: "95%", backgroundColor: '#76767640' };

  return (
    <Grid container item>
      <Card sx={cardElementStyle}>
        <Grid container item padding={1}>

          <Grid item xs={12} textAlign='center' paddingBottom={2}>
            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
              {`Cargando Archivos: ${cantOfDownloadsComplete}/${uploadsIds.length}`}
            </Typography>
          </Grid>

          {uploadsIds.map(fileId =>
            <Grid container item key={'grid' + fileId}>
              <SingleUpload fileId={fileId} assetName={getAssetNameFromFileId(fileId)} caller='new-release' />
            </Grid>)
          }

          {false && <Grid item xs={12} paddingTop={2}>
            <Button onClick={handleLoadAllUploads}>Load All</Button>
            <Button onClick={handleStartAllUploads}>Start All</Button>
            <Button onClick={handleDeleteAllUploads}>Delete All</Button>
          </Grid>}

        </Grid>
      </Card>
    </Grid>
  );
}

export default CardUploads;

