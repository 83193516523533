import { cohesiveViola, ytRedColor } from "./colors";
import { Link as RouterLink } from "react-router-dom";
import { Link } from '@mui/material';

export const ARTIST_SUSPEND_INFRACTION = "ARTIST_SUSPEND_INFRACTION";
export const ARTIST_SUSPEND_NO_PAY = "ARTIST_SUSPEND_NO_PAY";
export const ARTIST_ACTIVE = "ARTIST_ACTIVE";
export const ARTIST_STRIKE_ONE = "ARTIST_STRIKE_ONE";
export const ARTIST_STRIKE_TWO = "ARTIST_STRIKE_TWO";
export const ARTIST_INACTIVE = "ARTIST_INACTIVE";
export const ARTIST_OAC_REQUESTED = "ARTIST_OAC_REQUESTED";
export const ARTIST_OAC_REJECTED = "ARTIST_OAC_REJECTED";

export const artistNoActiveStates = [ARTIST_INACTIVE]
export const artistOacStates = [ARTIST_OAC_REJECTED, ARTIST_OAC_REQUESTED];
export const artistAppStates = [ARTIST_SUSPEND_INFRACTION, ARTIST_SUSPEND_NO_PAY, ARTIST_INACTIVE, ARTIST_ACTIVE, ARTIST_STRIKE_ONE, ARTIST_STRIKE_TWO]
export const astistSuspectStates = [ARTIST_STRIKE_ONE, ARTIST_STRIKE_TWO, ARTIST_SUSPEND_INFRACTION]
export const artistStateCantRelease = [ARTIST_SUSPEND_INFRACTION, ARTIST_SUSPEND_NO_PAY, ARTIST_INACTIVE]
 
export const artistStatesShowAdmin = {
  ARTIST_ACTIVE: "Activo",
  ARTIST_SUSPEND_INFRACTION: "Suspendido Infracción",
  ARTIST_SUSPEND_NO_PAY: "Suspendido ($)",
  ARTIST_INACTIVE: "Inactivo",
  ARTIST_STRIKE_ONE: "Strike 1",
  ARTIST_STRIKE_TWO: "Strike 2",
  ARTIST_OAC_REQUESTED: "OAC Requested",
  ARTIST_OAC_REJECTED: "OAC Rejected",
  undefined: "Activo*",
}

export const artistStatesListShowAdmin = [
  { id: ARTIST_ACTIVE, name: "Activo" },
  { id: ARTIST_SUSPEND_INFRACTION, name: "Suspendido Infracción" },
  { id: ARTIST_SUSPEND_NO_PAY, name: "Suspendido ($)" },
  { id: ARTIST_INACTIVE, name: "Inactivo" },
  { id: ARTIST_STRIKE_ONE, name: "Strike 1" },
  { id: ARTIST_STRIKE_TWO, name: "Strike 2" },
  { id: undefined, name: "Activo*" },
  { id: ARTIST_OAC_REQUESTED, name: "OAC Requested" },
  { id: ARTIST_OAC_REJECTED, name: "OAC Rejected" },
]

export const artistStatesShowUser = {
  ARTIST_ACTIVE: "Activo",
  ARTIST_SUSPEND_INFRACTION: "Suspendido Infracción",
  ARTIST_SUSPEND_NO_PAY: "Suspendido ($)",
  ARTIST_INACTIVE: "Inactivo",
  ARTIST_STRIKE_ONE: "Activo",
  ARTIST_STRIKE_TWO: "Activo",
  ARTIST_OAC_REQUESTED: "OAC Requested",
  ARTIST_OAC_REJECTED: "OAC Rejected",
  undefined: "Activo",
}

export const artistStateColorsAdmin = {
  ARTIST_ACTIVE: "rgb(93, 109, 15)",
  ARTIST_SUSPEND_INFRACTION: "rgb(111, 2, 2)",
  ARTIST_SUSPEND_NO_PAY: "rgb(111, 2, 2)",
  ARTIST_INACTIVE: "red",
  ARTIST_STRIKE_ONE: "rgb(93, 109, 15)",
  ARTIST_STRIKE_TWO: "rgb(93, 109, 15)",
  ARTIST_OAC_REQUESTED: ytRedColor,
  ARTIST_OAC_REJECTED: cohesiveViola,
  undefined: "rgb(93, 109, 15)",
}

export const artistStateColorsUser = {
  ARTIST_ACTIVE: "rgb(93, 109, 15)",
  ARTIST_SUSPEND_INFRACTION: "rgb(111, 2, 2)",
  ARTIST_SUSPEND_NO_PAY: "rgb(111, 2, 2)",
  ARTIST_INACTIVE: "red",
  ARTIST_STRIKE_ONE: "rgb(93, 109, 15)",
  ARTIST_STRIKE_TWO: "rgb(93, 109, 15)",
  ARTIST_OAC_REQUESTED: ytRedColor,
  ARTIST_OAC_REJECTED: cohesiveViola,
  undefined: "rgb(93, 109, 15)",
}

export const linkToOACRequest = (
  <Link component={RouterLink} to="/admin/youtube-for-artists" variant="body2" underline="hover">solicítalo aquí</Link>
);