import { generateOTP } from 'utils';
import { canUseOldPayment, paymentIsFree, paymentMethodNameToId, paymentStatusNameToStatusId } from 'utils/payments.utils';
import { getNextYearDateFromTsToTs } from 'utils/timeRelated.utils';
import { getPlanIdFromName, getPlanObjectFromName } from 'utils/users.utils';
import { SUB_CUPON, SUB_MP, SUB_PAYPAL, paypalComission } from 'variables/financial';

export const generateRandomId = () => {
  let tsString = String(new Date().getTime()).slice(-6, -1);
  let otp = generateOTP();
  return tsString + otp.slice(0, 5) + "LF";
}

export const paymentDefault = userData => {
  const nextPaymentDateSub = Number.isFinite(userData?.subscription?.nextPaymentDate) ? userData?.subscription?.nextPaymentDate : "";
  const firstPaymentDateSub = userData.timestampWhenCreatedUserInFB || "";
  return {
    status: '',
    id: generateRandomId(),
    mpPaymentId: '',
    payment_type_id: '',
    plan: '',
    payerData: "",
    preference_data: '',
    preference_error: '',
    ownerId: '',
    ownerEmail: "",
    payment_method: SUB_MP,
    cupon: { name: "", amountArs: 0, amountUsd: 0, type: "" },
    currency: "ARS",
    whenCreatedTS: new Date().getTime(),
    paymentProof: "",
    product: "",
    lastUpdateTS: new Date().getTime(),
    netReceivedAmount: "",
    otherPaymentId: "",
    transactionAmount: "",
    lastPaymentDate: new Date().getTime(),
    nextPaymentDate: getNextYearDateFromTsToTs(nextPaymentDateSub) || getNextYearDateFromTsToTs(new Date().getTime()),
    firstPaymentDate: firstPaymentDateSub || new Date().getTime(),
  }
}

export const getPaymentModelFromCreatePayment = (paymentFromCreate, userByEmail) => {
  const planObject = getPlanObjectFromName(paymentFromCreate.plan);
  return {
    ...paymentFromCreate,
    ownerId: userByEmail.id,
    netReceivedAmount: Number(paymentFromCreate.netReceivedAmount),
    transactionAmount: Number(paymentFromCreate.transactionAmount),
    status: paymentStatusNameToStatusId(paymentFromCreate.status),
    payment_method: paymentMethodNameToId(paymentFromCreate.payment_method),
    payment_type_id: paymentMethodNameToId(paymentFromCreate.payment_method),
    plan: getPlanIdFromName(planObject.id),
    preference_data: createMpPreferenceData(planObject, paymentFromCreate, false)
  }
}

export const createMpPreferenceData = (plan, actualPayment, isRenew) => {
  let titlePreference = `${plan.title} • ${plan.subtitle} • ${plan.maxArtists}`;
  let descriptionPreference = plan.maxArtists !== 1 ? plan.discountText : "1 Artista";
  let id = canUseOldPayment(actualPayment) ? actualPayment.id : generateRandomId();
  return {
    items: [
      {
        title: titlePreference || "",
        description: descriptionPreference || "",
        id: plan.id,
        picture_url: plan.remoteImgSource || "",
        priceOtherCurrency: plan.priceUsd || 0,
        currency_id: 'ARS',
        unit_price: plan.priceArs || Number(actualPayment.transactionAmount) || 0,
        // unit_price: 10,
        quantity: 1,
      }
    ],
    external_reference: id,
    statement_descriptor: `LAFLOTA-${plan.title}`,
  }
};

const getPreferenceItem = payment => {
  return payment.preference_data?.items.length > 0 ? payment.preference_data?.items[0] : {};
}

export const getUpdatedPaymentByDiscount = (payment, discount) => {
  let isArs = payment.currency === "ARS";
  let itemPayed = { ...getPreferenceItem(payment) };
  if (!Number.isFinite(itemPayed.unit_price)) return "ERROR";

  let finalAmountToPayArs = isArs ? itemPayed.unit_price : itemPayed.priceOtherCurrency;
  let finalAmountToPayUsd = !isArs ? itemPayed.unit_price : itemPayed.priceOtherCurrency;

  if (discount.name) {
    finalAmountToPayArs = discount.percentageOrAmount === "percentage"
      ? finalAmountToPayArs * (1 - (discount.percentage / 100)) : finalAmountToPayArs - discount.amount;
    finalAmountToPayUsd = discount.percentageOrAmount === "percentage"
      ? finalAmountToPayUsd * (1 - (discount.percentage / 100)) : finalAmountToPayUsd - discount.amount;
  }

  finalAmountToPayArs = Number(finalAmountToPayArs.toFixed(2));
  finalAmountToPayUsd = Number(finalAmountToPayUsd.toFixed(2));

  let cuponAmountArs = isArs ? itemPayed.unit_price - finalAmountToPayArs : itemPayed.priceOtherCurrency - finalAmountToPayArs;
  let cuponAmountUsd = !isArs ? itemPayed.unit_price - finalAmountToPayUsd : itemPayed.priceOtherCurrency - finalAmountToPayUsd;
  itemPayed.unit_price = (isArs ? finalAmountToPayArs : finalAmountToPayUsd) + (isArs ? payment?.cupon?.amountArs || 0 : payment?.cupon?.amountUsd || 0);
  itemPayed.priceOtherCurrency = (!isArs ? finalAmountToPayArs : finalAmountToPayUsd) + (!isArs ? payment?.cupon?.amountArs || 0 : payment?.cupon?.amountUsd || 0);
  itemPayed.unit_price = itemPayed.unit_price < 0 ? 0 : itemPayed.unit_price;
  itemPayed.priceOtherCurrency = itemPayed.priceOtherCurrency < 0 ? 0 : itemPayed.priceOtherCurrency;

  let noNeedToPay = paymentIsFree({ preference_data: { ...payment.preference_data, items: [itemPayed] } });

  return {
    ...payment,
    cupon: { name: discount.name, amountArs: cuponAmountArs, type: discount.type, amountUsd: cuponAmountUsd },
    preference_data: { ...payment.preference_data, items: [itemPayed] },
    payment_method: noNeedToPay ? SUB_CUPON : payment.payment_method,
    netReceivedAmount: noNeedToPay ? 0 : payment.netReceivedAmount
  }
}
export const createPayedPaymentInfo = payment => {
  let itemPayed = getPreferenceItem(payment);
  let amountCupon = payment.currency === "USD" ? payment.cupon.amountUsd : payment.cupon.amountArs;
  return {
    ...payment,
    currency: itemPayed.currency_id,
    transactionAmount: itemPayed.unit_price + amountCupon,
    netReceivedAmount: payment.payment_method === SUB_PAYPAL
      ? (itemPayed.unit_price * (1 - paypalComission)) - 0.3 : itemPayed.unit_price,
    product: itemPayed.description,
    payerData: "Sin data del pago"
  }
}

export const getUsdPrefItem = prefDataActualArs => {
  return prefDataActualArs.currency_id === "USD"
    ? prefDataActualArs
    : {
      ...prefDataActualArs,
      currency_id: "USD",
      unit_price: prefDataActualArs.priceOtherCurrency,
      priceOtherCurrency: prefDataActualArs.unit_price
    }
}

export const getArsPrefItem = prefDataActualUsd => {
  return prefDataActualUsd.currency_id === "ARS"
    ? prefDataActualUsd
    : {
      ...prefDataActualUsd,
      currency_id: "ARS",
      unit_price: prefDataActualUsd.priceOtherCurrency,
      priceOtherCurrency: prefDataActualUsd.unit_price
    }
}