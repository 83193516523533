import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Grid
} from '@mui/material';
import { toWithOutError } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import SuccessDialog from '../../components/Dialogs/SuccessDialog';
import { discountChangeclaimersEmail, discountChangeCantSubtitle, discountChangeNameSubtitle, discountChangeOwnerEmail, discountChangeStateSubtitle, discountChangeTypeSubtitle, discountPercentageOrAmountSubtitle, discountNotUsedText, discountChangeCantUsedSubtitle } from '../../utils/textToShow.utils';
import { Delete } from '@mui/icons-material/';
import EditOrAddFieldsDialog from '../../components/Dialogs/EditOrAddFieldDialog';
// import { userIsAdmin } from 'utils/users.utils';

import { getDiscountById } from "utils/discounts.utils";
import { discountsUpdateWithId } from "redux/actions/DiscountsActions";
import { DISCOUNT_BY_CREDIT_FRIENDS, DISCOUNT_STATUS_INACTIVE, discountStateAndNamesList, discountStateColors, discountTypesAndNamesList, discountsStatusToShow, discountsTypesToShow, percentageOrAmountList, percentageOrAmountToShow } from "variables/discounts.variables";
import DeleteDiscountDialog from "./DeleteDiscountDialog";
import CopyableEditText from "components/TextField/CopyableEditText";
import { userIsAdmin } from "utils/users.utils";
import TextWithBullets from "components/Text/TextWithBullets";

const DiscountsActionsDialog = (props) => {

  const { isOpen, handleClose, discountId } = props;

  const dispatch = useDispatch();
  const discountsFromStore = useSelector(store => store.discounts.discounts);
  const userData = useSelector(store => store.userData);
  const isAdmin = userIsAdmin(userData.rol);

  const discount = getDiscountById(discountsFromStore, discountId);
  const isUserCreditDiscount = discount.type === DISCOUNT_BY_CREDIT_FRIENDS && discount.ownerId === userData.id;
  const userIsOwner = isAdmin ? true : discount.ownerId === userData.id;
  const cantUsedDiscount = discount.cantUsed || 0;

  console.log("DISCOUNT: ", discount);

  const [actionState, setActionState] = useState('none')
  const [buttonState, setButtonState] = useState('none');
  const [openDeleteDiscountDialog, setOpenDeleteDiscountDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState({ open: false, title: "", subtitle: [""], values: "", type: "" })

  let successDialogTitle = "";
  let successDialogText = [""];

  const handleEditDiscountName = () => setOpenEditDialog({
    open: true, title: "Cambiar el nombre del Cupón", subtitle: discountChangeNameSubtitle,
    handleConfirm: (newValue) => handleConfirmEditDiscount(newValue, 'name'),
    initialValues: discount.name || "", handleCloseDialog: handleCloseEditDialog, type: ""
  });

  const handleEditDiscountCant = () => setOpenEditDialog({
    open: true, title: "Cambiar la cantidad de Cupones disponibles", subtitle: discountChangeCantSubtitle,
    handleConfirm: (newValue) => handleConfirmEditDiscount(newValue, 'amountOfEqualDiscounts'),
    initialValues: discount.amountOfEqualDiscounts || "", handleCloseDialog: handleCloseEditDialog, type: ""
  });

  const handleEditDiscountCantUsed = () => setOpenEditDialog({
    open: true, title: "Cambiar la cantidad de Cupones usados", subtitle: discountChangeCantUsedSubtitle,
    handleConfirm: (newValue) => handleConfirmEditDiscount(newValue, 'cantUsed'),
    initialValues: discount.cantUsed || "", handleCloseDialog: handleCloseEditDialog, type: ""
  });

  const handleEditDiscountAmount = () => setOpenEditDialog({
    open: true, subtitle: [''], handleCloseDialog: handleCloseEditDialog, type: "",
    title: discount.percentageOrAmount === 'amount'
      ? "Cambiar el monto fijo del Cupón" : "Cambiar el porcentaje del Cupón",
    initialValues: discount.percentageOrAmount === 'amount'
      ? discount.amount : discount.percentage,
    handleConfirm: (newValue) => handleConfirmEditDiscount(Number(newValue), discount.percentageOrAmount === 'amount' ? 'amount' : 'percentage'),
  });

  const handleEditDiscountPercentageOrAmount = () => setOpenEditDialog({
    open: true, title: "Elegir descuento por porcentage o monto fijo", subtitle: discountPercentageOrAmountSubtitle,
    handleConfirm: (newValue) => handleConfirmEditDiscount(newValue, 'percentageOrAmount'),
    initialValues: percentageOrAmountToShow[discount.percentageOrAmount] || "", handleCloseDialog: handleCloseEditDialog,
    type: "only-one-select", optionsValues: percentageOrAmountList
  });

  const handleEditDiscountType = () => setOpenEditDialog({
    open: true, title: "Cambiar tipo de descuento", subtitle: discountChangeTypeSubtitle,
    handleConfirm: (newValue) => handleConfirmEditDiscount(newValue, 'type'),
    initialValues: discountsTypesToShow[discount.type] || "", handleCloseDialog: handleCloseEditDialog,
    type: "only-one-select", optionsValues: discountTypesAndNamesList
  });

  const handleEditDiscountOwnerEmail = () => setOpenEditDialog({
    open: true, title: "Cambiar el Email del dueño del Cupón", subtitle: discountChangeOwnerEmail,
    handleConfirm: (newValue) => handleConfirmEditDiscount(newValue, 'ownerEmail'),
    initialValues: discount.ownerEmail || "", handleCloseDialog: handleCloseEditDialog, type: ""
  });

  const handleEditDiscountClaimersEmail = () => setOpenEditDialog({
    open: true, title: "Cambiar el Email del beneficiario del Cupón", subtitle: discountChangeclaimersEmail,
    handleConfirm: (newValue) => handleConfirmEditDiscount(newValue, 'claimersEmail'),
    initialValues: discount.claimersEmail || "", handleCloseDialog: handleCloseEditDialog, type: ""
  });

  const handleEditDiscountState = () => setOpenEditDialog({
    open: true, title: "Cambiar el estado del Cupón", subtitle: discountChangeStateSubtitle,
    handleConfirm: (newValue) => handleConfirmEditDiscount(newValue, 'status'),
    initialValues: discountsStatusToShow[discount.status] || "", optionsValues: discountStateAndNamesList,
    values: "", handleCloseDialog: handleCloseEditDialog, type: "only-one-select"
  });

  const handleConfirmEditDiscount = async (newValue, fieldName) => {
    setButtonState("loading");
    let finalValue = newValue.id ? newValue.id : newValue;
    let editResult = await toWithOutError(dispatch(discountsUpdateWithId({ ...discount, [fieldName]: finalValue })));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
  }

  const handleCloseEditDialog = () => setOpenEditDialog({ open: false, title: "", subtitle: [""], values: "" });

  const handleCloseDiscountActionsDialog = () => handleClose();

  const amountOrPercentageString = discount.percentageOrAmount === 'amount' ? "Monto Fijo" : "Porcentaje";
  const amountOrPercentageValue = discount.percentageOrAmount === 'amount' ? discount.amount : discount.percentage;
  const percentageSymbolOrCurrency = discount.percentageOrAmount === 'amount' ? discount.currency : "%";

  const jsxDiscountName = <DialogContentText key={'discount name jsx'}>
    Nombre Cupón: <b>{discount.name}</b>
  </DialogContentText>

  const jsxDiscountId = <DialogContentText key={'discount id jsx'}>
    ID Cupón: <b>{discount.id}</b>
  </DialogContentText>

  const jsxDiscountCant = <DialogContentText key={'discount cant'}>
    Cantidad de Cupones: <b>{discount.amountOfEqualDiscounts || "0"}</b>
  </DialogContentText>

  const jsxDiscountCantUsed = <DialogContentText key={'discount cant used'}>
    Cupones Usados: <b>{discount.cantUsed || "0"}</b>
  </DialogContentText>

  const jsxDiscountAmountOrPercentage = <DialogContentText key={'discount amount'}>
    {` ${amountOrPercentageString}`} de descuento del Cupón:
    <b>{amountOrPercentageValue + " " + percentageSymbolOrCurrency}</b>
  </DialogContentText>

  const jsxDiscountSelectAmountOrPercentag = <DialogContentText key={'discount percentage or amount'}>
    Porcentaje o Monto Fijo: <b>{percentageOrAmountToShow[discount.percentageOrAmount] || "Sin definir"}</b>
  </DialogContentText>

  const jsxDiscountType = <DialogContentText key={'discount type'}>
    Tipo de Cupón: <b>{discountsTypesToShow[discount.type] || "Otros"}</b>
  </DialogContentText>

  const jsxDiscountOwnerEmail = <DialogContentText key={'discount owner email'}>
    Email dueño: <b>{discount.ownerEmail || "Sin dueño"}</b>
  </DialogContentText>

  const jsxDiscountState = <DialogContentText key={'discount status'}>
    Estado del Cupón: <b sx={{ color: discountStateColors[discount.status] }}>{discountsStatusToShow[discount.status]}</b>
  </DialogContentText>

  if (!discount) return null;

  return (
    <>
      <SuccessDialog isOpen={actionState === 'proccessing'}
        title={successDialogTitle} contentTexts={successDialogText}
        handleClose={() => setActionState('none')} successImageSource="/images/success.jpg" size="sm" />

      <DeleteDiscountDialog isOpen={openDeleteDiscountDialog} setIsOpen={setOpenDeleteDiscountDialog}
        discount={discount} handleCloseActionDialog={handleClose}
      />

      <EditOrAddFieldsDialog loading={buttonState === "loading"}
        buttonState={buttonState} editOptions={openEditDialog} setEditOptions={setOpenEditDialog} />

      <Dialog
        maxWidth="sm"
        id="info-dialog"
        open={isOpen}
        onClose={handleClose}>

        <DialogTitle id="title-info-dialog">
          {`Cupón: ${discount.name}`}
        </DialogTitle>

        <DialogContent>
          {isAdmin && <Grid item xs={12} paddingBottom={1}>
            <CopyableEditText textToCopy={discount.id} color='grey' jsxElementText={jsxDiscountId} />
            <CopyableEditText textToCopy={discount.name} color='grey' jsxElementText={jsxDiscountName} handleEdit={handleEditDiscountName} />
            <CopyableEditText textToCopy="" color='grey' jsxElementText={jsxDiscountCant} handleEdit={handleEditDiscountCant} />
            <CopyableEditText textToCopy="" color='grey' jsxElementText={jsxDiscountCantUsed} handleEdit={handleEditDiscountCantUsed} />
            <CopyableEditText textToCopy={amountOrPercentageValue} color='grey' jsxElementText={jsxDiscountAmountOrPercentage} handleEdit={handleEditDiscountAmount} />
            <CopyableEditText textToCopy="" color='grey' jsxElementText={jsxDiscountSelectAmountOrPercentag} handleEdit={handleEditDiscountPercentageOrAmount} />
            <CopyableEditText textToCopy="" color='grey' jsxElementText={jsxDiscountType} handleEdit={handleEditDiscountType} />
            <CopyableEditText textToCopy={discount.ownerEmail} color='grey' jsxElementText={jsxDiscountOwnerEmail} handleEdit={handleEditDiscountOwnerEmail} />
            <CopyableEditText textToCopy="" color='grey' jsxElementText={jsxDiscountState} handleEdit={handleEditDiscountState} />
          </Grid>}

          <>
            <DialogContentText sx={{ mb: "8px", fontSize: "17px", fontWeight: 500, color: 'black' }}>
              {userIsOwner ? isUserCreditDiscount
                ? "Este cupón sólo puede ser usado por vos:"
                : "Este cupon ha sido usado o reclamado por los siguientes usuarios:"
                : "No puedes ver quién usó éste cupón ya que no eres el dueño."}
            </DialogContentText>
            <TextWithBullets textLinesList={userIsOwner ? cantUsedDiscount > 0 ? discount.claimersEmail : [discountNotUsedText]
              : [userData.email]} linesPerRow={1} />
          </>

          {isAdmin && <Grid container paddingTop={2}>
            {discount.status !== DISCOUNT_STATUS_INACTIVE &&
              <Grid item xs={12} padding={1}>
                <Button
                  onClick={() => setOpenDeleteDiscountDialog(true)}
                  sx={{ backgroundColor: '#c20202', color: 'white', '&:hover': { backgroundColor: '#c20202' } }}
                  endIcon={<Delete />}
                  fullWidth>
                  Eliminar Cupón
                </Button>
              </Grid>}
          </Grid>}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDiscountActionsDialog} color="primary">
            Atrás
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DiscountsActionsDialog;

DiscountsActionsDialog.defaultProps = {
  isOpen: false,
}

DiscountsActionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}