import React from "react";
import { Box, Stepper, Step, StepLabel } from '@mui/material/';

const BasicStepper = ({ steps, activeStep }) => {

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={index} {...stepProps}>
              <StepLabel key={index} {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

export default BasicStepper;