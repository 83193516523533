import React, { useState } from 'react';
import {
  Button, DialogContent, DialogContentText, DialogTitle, Grid, CircularProgress, Backdrop,
  Collapse
} from '@mui/material';
import { SUB_CRIPTO, SUB_ORDER_NEED_PROOF, SUB_PAYONEER, SUB_PAYPAL, SUB_WESTERN_UNION, SUB_WISE, ourWiseInfo } from 'variables/financial';
import { useDispatch, useSelector } from 'react-redux';
import { formatThousandsPoint, toWithOutError } from 'utils';
import { paymentsPayUpdateLocal, paymentsPayUpdated } from 'redux/actions/PaymentsActions';
import { userDataUpdateRedux } from 'redux/actions/UserDataActions';
import { USER_PENDING_PROOF } from 'variables/user.variables';
// import PayPal from 'views/PaymentCompaniesSolutions/PayPal';
import { createPayedPaymentInfo } from 'factory/payments.factory';
import { getNewUserNeedCheckInfoFromPayment } from 'factory/users.factory';
import { getCriptoJsxData, getPayoneerJsxData, getPaypalJsxData, getWUJsxData, ourWiseInfoJsx } from 'utils/payments.utils';

const TransferenceUsdPayment = ({ onClose, show }) => {

  const dispatch = useDispatch();
  const userData = useSelector(store => store.userData);
  const actualPayment = useSelector(store => store.payments?.actualPayment);

  const preferenceData = actualPayment.preference_data;
  const preferenceItemData = preferenceData?.items?.length > 0 ? preferenceData?.items[0] : {};

  const [openLoader, setOpenLoader] = useState(false);
  const [choosedMethod, setChoosedMethod] = useState('none');

  const handleOk = async () => {
    setOpenLoader(true);
    let id = actualPayment.id;
    let status = SUB_ORDER_NEED_PROOF;
    let userStatus = USER_PENDING_PROOF;
    let updatePaymentNewData = createPayedPaymentInfo(actualPayment);
    let resultUpdatePay = await toWithOutError(dispatch(paymentsPayUpdated({ ...updatePaymentNewData, id, status })));
    if (resultUpdatePay === "ERROR") { setOpenLoader(false); return; }
    let fromPaymentAction = true;
    let newSubInfo = getNewUserNeedCheckInfoFromPayment(userData, updatePaymentNewData);
    let resultUpdateUser = await toWithOutError(
      dispatch(userDataUpdateRedux({ ...newSubInfo, userStatus, id: actualPayment.ownerId }, fromPaymentAction)));
    if (resultUpdateUser === "ERROR") console.log("ERROR UPDATING USER DATA");
    setOpenLoader(false);
    onClose();
  }

  const handleCancel = () => {
    // aca cambio el estado a que solo eligio plan.
    // dispatch(paymentsPayUpdateLocal({ ...actualPayment, status: SUB_ORDER_PLAN_SELECTED }));
    onClose();
  }

  const handleChooseMethod = paymentMethodId => {
    dispatch(paymentsPayUpdateLocal({ ...actualPayment, payment_method: paymentMethodId }));
    setChoosedMethod(paymentMethodId);
  }

  if (!show) return null;

  return (
    <Grid sx={{ maxWidth: "600px" }}>
      <Backdrop open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DialogTitle sx={{ textAlign: "center", padding: "0px 8px 0px 8px" }}>Datos de Pago en USD</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <DialogContentText sx={{ fontSize: "3rem", fontWeight: 500, textAlign: 'center', paddingTop: 0 }} key={'amount-usd-pay'}>
          {preferenceItemData.currency_id + " " + formatThousandsPoint(preferenceItemData.unit_price)}
        </DialogContentText>
        {/* <DialogContentText paddingBottom={1} key={'proofOfPay'}>
          Acá iría texto diciendo que solo con PayPal la verificación del pago es <b>automático</b>
        </DialogContentText> */}
        <Grid container>
          {/* <Grid item xs={12} paddingBottom={1}>
            <b style={{ fontSize: "16px", fontWeight: 450 }}>Suscripción anual con renovación automática:</b>
          </Grid>
          <Grid item xs={12} paddingBottom={1}>
            <PayPal type={"sub"} />
          </Grid> */}
          <Grid item xs={12} paddingBottom={1}>
            <b style={{ fontSize: "16px", fontWeight: 450 }}>Suscripción anual con renovación manual:</b>
            <br />
            <b style={{ fontSize: "14px", fontWeight: 300 }}>Por favor, una vez realizado el pago, cargar el comprobante del mismo para que podamos corroborarlo</b>
          </Grid>
          {/* <Grid item xs={12} padding={1}> */}
            {/* <Button variant="contained" fullWidth onClick={() => handleChooseMethod(SUB_PAYPAL)}> */}
              {/* <PayoneerIcon /> */}
              {/* PayPal */}
            {/* </Button> */}
          {/* </Grid> */}
          <Grid item xs={6} padding={1}>
            <Button variant="contained" fullWidth onClick={() => handleChooseMethod(SUB_PAYPAL)}>
              {/* <PayoneerIcon /> */}
              PayPal
            </Button>
          </Grid>
          <Grid item xs={6} padding={1}>
            <Button variant="contained" fullWidth onClick={() => handleChooseMethod(SUB_CRIPTO)}>
              {/* <CryptocurrencyIcon /> */}
              Criptomonedas
            </Button>
          </Grid>

          <Grid item xs={6} padding={1}>
            <Button variant="contained" fullWidth onClick={() => handleChooseMethod(SUB_WESTERN_UNION)}>
              {/* <WesternUnionIcon /> */}
              Western Union
            </Button>
          </Grid>

          <Grid item xs={6} padding={1}>
            <Button variant="contained" fullWidth onClick={() => handleChooseMethod(SUB_WISE)}>
              {/* <WiseIcon /> */}
              Transferencia Bancaria
            </Button>
          </Grid>

        </Grid>

      </DialogContent>

      <DialogContent sx={{ paddingBottom: 1, paddingTop: 1 }}>
        <Collapse in={choosedMethod === SUB_PAYPAL}><>{getPaypalJsxData}</></Collapse>
        <Collapse in={choosedMethod === SUB_PAYONEER}><>{getPayoneerJsxData}</></Collapse>
        <Collapse in={choosedMethod === SUB_CRIPTO}><>{getCriptoJsxData}</></Collapse>
        <Collapse in={choosedMethod === SUB_WESTERN_UNION}><>{getWUJsxData}</></Collapse>
        <Collapse in={choosedMethod === SUB_WISE}><>{ourWiseInfoJsx}</></Collapse>

      </DialogContent>
      <Grid container>
        <Grid item xs={6}>
          <Button onClick={handleCancel}>Cancelar</Button>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'end' }}>
          <Button onClick={handleOk}>Ok</Button>
        </Grid>
      </Grid>

    </Grid>

  );
}

export default TransferenceUsdPayment;
