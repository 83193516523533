import * as ReducerTypes from 'redux/actions/Types';

const editUploadChunk = (oldUploads, newUploadFields) => {
  let uploadToEdit = oldUploads.find(oldUpload => oldUpload.fileId === newUploadFields.fileId);
  let newUpload = { ...uploadToEdit, ...newUploadFields };
  let editedUploads = oldUploads.filter(upload => upload.fileId !== newUploadFields.fileId);
  return [newUpload, ...editedUploads];
}

const initialState = [];

const UploadsReducer = (state = initialState, action) => {
  const data = action.payload;

  switch (action.type) {

    case ReducerTypes.UPLOADS_ADD:
      return [...state, data];

    case ReducerTypes.UPLOADS_EDIT:
      return editUploadChunk(state, data)

    case ReducerTypes.UPLOADS_DELETE_BY_ID:
      return state.filter(upload => upload.fileId !== data.fileId);

    case ReducerTypes.UPLOADS_SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};

export default UploadsReducer;
