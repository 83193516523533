import firebaseApp from 'firebaseConfig/firebase.js';
import { getAnalytics, logEvent } from "firebase/analytics"

const analytics = getAnalytics(firebaseApp);

// const ourEmails = ["javierpetri2012@gmail.com", "javi.petri.jp@gmail.com", "jog@laflota.com", "pick@laflota.com.ar"];
const ourEmails = ["javierpetri2012@gmail.com", "javi.petri.jp@gmail.com", "jog@laflota.com", "tester@laflota.com.ar", "pick@laflota.com.ar"];

export const logLoginAnalyticEvent = userData => {
  if (ourEmails.includes(userData.email)) return;
  logEvent(analytics, 'login', {
    userId: userData.id,
    userName: userData.name + " " + userData.apellido,
    email: userData.email
  });
}

export const logLoginFBOkAnalyticEvent = (uid, email) => {
  if (ourEmails.includes(email)) return;
  logEvent(analytics, 'login', {
    userId: uid,
    email
  });
}

export const logPasswordResetTryAnalyticEvent = userData => {
  if (ourEmails.includes(userData.email)) return;
  logEvent(analytics, 'password-reset-first-step', {
    email: userData.email
  });
}

export const logPasswordResetSuccessAnalyticEvent = userData => {
  if (ourEmails.includes(userData.email)) return;
  logEvent(analytics, 'password-reset-success', {
    email: userData.email
  });
}

export const logPasswordChangedAnalyticEvent = userData => {
  if (ourEmails.includes(userData.email)) return;
  logEvent(analytics, 'password-changed', {
    email: userData.email
  });
}

export const logEmailChangedAnalyticEvent = userData => {
  if (ourEmails.includes(userData.email)) return;
  logEvent(analytics, 'email-changed', userData);
}

export const logSignUpAnalyticEvent = userData => {
  if (ourEmails.includes(userData.email)) return;
  logEvent(analytics, 'sign-up', {
    userId: userData.id,
    userName: userData.name + " " + userData.apellido,
    email: userData.email
  });
}

export const logReleaseDeliveryAnalyticEvent = albumData => {
  if (ourEmails.includes(albumData.title.indexOf("TEST") >= 0)) return;
  logEvent(analytics, 'release_delivered', {
    userId: albumData.ownerId,
    email: albumData.ownerEmail,
    albumTitle: albumData.title
  })
}

export const logReleaseRedeliveryAnalyticEvent = albumData => {
  if (ourEmails.includes(albumData.title.indexOf("TEST") >= 0)) return;
  logEvent(analytics, 'release_redelivered', {
    userId: albumData.ownerId,
    email: albumData.ownerEmail,
    albumTitle: albumData.title
  })
}

export const logReleaseTakenDownAnalyticEvent = albumData => {
  if (ourEmails.includes(albumData.title.indexOf("TEST") >= 0)) return;
  logEvent(analytics, 'release_takendown', {
    userId: albumData.ownerId,
    email: albumData.ownerEmail,
    albumTitle: albumData.title
  })
}