import React, { useState } from "react";
import { Grid, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import {
  discountsCreateCreditByFriendsText, discountsInfoHowToUseThem,
  discountsInfoInviteFriends, discountsInviteFriendsText, needAdminPermissionsText
} from "utils/textToShow.utils";
import { userIsActive, userIsAdmin } from 'utils/users.utils';
import { cohesiveRed } from "variables/colors";
import DiscountsTable from "./DiscountsTable";
import DiscountsCreateDialog from "./DiscountsCreateDialog";
import { discountModelForInviteFriends } from "factory/discounts.factory";
import { toWithOutError } from "utils";
import { discountsCreateFromCredit, discountsCreateRedux } from "redux/actions/DiscountsActions";
import { getCreditAvailableFromFriendsDiscount, getCreditFriendsDiscountActive, getDiscountCreditText, getInviteFriendsDiscount, hasInviteFriendsDiscount } from "utils/discounts.utils";
import TextWithBullets from "components/Text/TextWithBullets";

const Discounts = () => {

  const dispatch = useDispatch();
  const discounts = useSelector(store => store.discounts.discounts);
  const currentUser = useSelector(store => store.userData);
  const isActiveUser = userIsActive(currentUser.userStatus);
  const isAdmin = userIsAdmin(currentUser.rol);
  const alreadyHasInviteFriends = hasInviteFriendsDiscount(discounts, currentUser.id);
  const inviteFriendsDiscount = getInviteFriendsDiscount(discounts, currentUser.id);
  const creditFriendsDiscountActive = getCreditFriendsDiscountActive(discounts, currentUser.id);
  const percentageCredit = getCreditAvailableFromFriendsDiscount(inviteFriendsDiscount);

  const [openNotAdminWarning, setOpenNotAdminWarning] = useState(false);
  const [openNewDiscountDialog, setOpenNewDiscountDialog] = useState({ open: false, action: "none", userId: 'new' });
  const [openEmptySearch, setOpenEmptySearch] = useState(false);
  const [openErrorSearch, setOpenErrorSearch] = useState(false);
  const [openInviteFriends, setOpenInviteFriends] = useState(false);
  const [openInfoLoader, setOpenInfoLoader] = useState(false);
  const [openCreateCreditDiscount, setOpenCreateCreditDiscount] = useState(false);

  const addDiscount = () => {
    if (!userIsAdmin(currentUser.rol)) setOpenNotAdminWarning(true);
    else setOpenNewDiscountDialog({ open: true, action: 'add', userId: 'new' });
  }

  const handleInviteFriends = () => setOpenInviteFriends(true);

  const handleCreateInviteFriendsCupon = async () => {
    setOpenInviteFriends(false);
    setOpenInfoLoader(true);
    const discount = discountModelForInviteFriends(currentUser);
    await toWithOutError(dispatch(discountsCreateRedux(discount)));
    setOpenInfoLoader(false);
  }

  const handleOpenCreateCreditCupon = () => setOpenCreateCreditDiscount(true);

  const handleCreateCreditCupon = async () => {
    if (percentageCredit < 100) {
      setOpenInfoLoader(true);
      await toWithOutError(dispatch(discountsCreateFromCredit(inviteFriendsDiscount, creditFriendsDiscountActive)));
      setOpenInfoLoader(false);
    }
    setOpenCreateCreditDiscount(false);
  }

  let addUserButtonSx = { backgroundColor: cohesiveRed, "&:hover": { backgroundColor: cohesiveRed } };

  return (isActiveUser || isAdmin) ? (
    <Grid container spacing={1} sx={{ textAlign: "center" }}>

      <InfoActionDialog key={0} id='need-permissions' isOpen={openNotAdminWarning} handleClose={() => setOpenNotAdminWarning(false)}
        title={"Necesitas permisos de Administrador"} contentTexts={needAdminPermissionsText} />

      <InfoActionDialog key={1} id='empty-results' isOpen={openEmptySearch} handleClose={() => setOpenEmptySearch(false)}
        title={"La búsqueda no arrojó resultados"} contentTexts={[]} />

      <InfoActionDialog key={2} id='error-searching' isOpen={openErrorSearch} handleClose={() => setOpenErrorSearch(false)}
        title={"Hubo un error al realizar la búsqueda."} contentTexts={["Por favor, intente nuevamente."]} />

      <InfoActionDialog key={3} id='invite-friends' isOpen={openInviteFriends} handleClose={() => setOpenInviteFriends(false)}
        title={"Invitar Amig@s"} contentTexts={discountsInviteFriendsText} handleOk={handleCreateInviteFriendsCupon}
        loading={openInfoLoader} />

      <InfoActionDialog key={4} id='credit-friends' isOpen={openCreateCreditDiscount} handleClose={() => setOpenCreateCreditDiscount(false)}
        title={"Crear Cupón de Crédito por Amig@s"} contentTexts={getDiscountCreditText(discounts, currentUser.id)}
        handleOk={handleCreateCreditCupon} loading={openInfoLoader} />

      <DiscountsCreateDialog
        isOpen={openNewDiscountDialog}
        handleCloseDialog={() => setOpenNewDiscountDialog({ open: false, action: "none", discountId: 'new' })}
        discountSelected={""} />

      <Grid item xs={12}>
        <Typography sx={artistsTitleStyles}>Cupones</Typography>
      </Grid>

      {!isAdmin &&
        <Grid item xs={12} >
          {discounts.length === 0
            ? <Typography sx={infoTextSx}>{"No tienes Cupones"}</Typography>
            : <TextWithBullets
              textLinesList={!alreadyHasInviteFriends
                ? discountsInfoHowToUseThem : [...discountsInfoHowToUseThem, ...discountsInfoInviteFriends]}
              linesPerRow={1} />
          }
        </Grid>}

      {!isAdmin && !alreadyHasInviteFriends && <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Button variant="contained" sx={addUserButtonSx} onClick={handleInviteFriends} endIcon={<PersonAddIcon />}>
          Invitar Amig@s
        </Button>
      </Grid>}

      {!isAdmin && alreadyHasInviteFriends && <Grid textAlign='center' xs={12}>
        <Typography sx={accumInviteSx}>{`Crédito por invitar amig@s: ${percentageCredit}% ~ $0 `}</Typography>
        {percentageCredit > 0 &&
          <Button variant='contained' onClick={handleOpenCreateCreditCupon}>
            Crear Cupón de Crédito</Button>}
      </Grid>}

      {isAdmin && <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Button variant="contained" sx={addUserButtonSx} onClick={addDiscount} endIcon={<PersonAddIcon />}>
          Crear Cupón
        </Button>
      </Grid>}

      <Grid container item>
        <Grid item xs={12} sx={{ textAlign: "center" }} paddingTop={0}>
          <DiscountsTable setOpenErrorSearch={setOpenErrorSearch} setOpenEmptySearch={setOpenEmptySearch} />
        </Grid>
      </Grid>

    </Grid>
  )
    : null
}

export default Discounts;

const artistsTitleStyles = { color: "#000000", fontWeight: 500, fontSize: "50px" }
const infoTextSx = { color: "grey", fontWeight: 400, fontSize: "18px" }
const accumInviteSx = { color: "balck", fontWeight: 450, fontSize: "20px", ml: 1, mb: 1, mt: 1 }