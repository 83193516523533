
import { allAudioLocalesFuga } from 'variables/varias';
import { textFloatingPointAudio, textLowQualityAudio } from './textToShow.utils';
import { Buffer } from "buffer";

export const recalculatePositions = uploadingTracks => {
  return uploadingTracks.map((track, index) => {
    track.position = index + 1;
    return track;
  });
}

export const reorderTracksByPosition = tracks => {
  return tracks.sort((tA, tB) => {
    if (tA.position < tB.position) return -1;
    else return 1;
  })
}

export const getTracksFieldsFromFugaTrack = track => {
  return {
    explicit: track.parental_advisory, position: track.sequence, title: track.name, track: track.audio,
    genre: track.genre.id, genreName: track.genre.name || "", subgenre: track.subgenre || "",
    isrc: track.isrc, progress: 0, artists: track.artists, allOtherArtists: [], collaborators: [],
  }
}

export const isValidFormatISRC = isrcCode => {
  if (!isrcCode) return true;
  if (!(isrcCode.length === 15 || isrcCode.length === 12)) return false;
  return isrcCode.match(/^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/) || isrcCode.match(/^[A-Z]{2}?\w{3}?\d{2}?\d{5}$/);
}

export const trackNeedISRC = track => {
  if (!track.isrc) return true;
  if (!(track.isrc.length === 15 || track.isrc.length === 12)) return true;
  if (track.isrc.match(/^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/) || track.isrc.match(/^[A-Z]{2}?\w{3}?\d{2}?\d{5}$/)) return false;
  return true;
}

export const checkIfAnyTrackIsExplicit = tracks => {
  return tracks.some(track => track.explicit);
}

export const isValidSpotifyUri = spotifyUri => {
  if (!spotifyUri) return true;
  return spotifyUri.match(/^(spotify:artist:)([a-zA-Z0-9]+)$/);
}


export const readFileDataAsBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = (err) => reject(err);
    reader.readAsArrayBuffer(file);
  });
}

export const readAndCheckAudioFile = async (file, wav, setOpenLowQualityAudioDialog) => {
  let fileReaded = await readFileDataAsBase64(file);
  wav.fromBuffer(Buffer.from(fileReaded));
  // console.log("LOADED WAV FILE: ", wav);

  if (Number(wav.bitDepth) < 16) {
    setOpenLowQualityAudioDialog({ open: true, title: "El archivo de Audio no es soportado (menor a 16 bits) .", text: textLowQualityAudio });
    return "ERROR";
  }
  if (wav.fmt.sampleRate < 44100) {
    setOpenLowQualityAudioDialog({ open: true, title: "El archivo de Audio no es soportado (Sample Rate menor a 44.1 khz).", text: textLowQualityAudio })
    return "ERROR";
  }
  if (wav.bitDepth === "32f" || wav.bitDepth === "64") {
    setOpenLowQualityAudioDialog({ open: true, title: "Archivos Floating point WAV no son soportados.", text: textFloatingPointAudio });
    return "ERROR";
  }
  return "SUCCESS";
}

export const areMissingTracksFuga = assetsDataFuga => {
  if (!assetsDataFuga) return false;
  let findAssetWithNoAudioFile = assetsDataFuga.find(asset => !asset.audio.has_uploaded);
  if (findAssetWithNoAudioFile) return true;
  else return false;
}

export const getMissingTracksFuga = assetsDataFuga => {
  if (!assetsDataFuga) return [];
  return assetsDataFuga.filter(asset => !asset.audio.has_uploaded);
  
}

export const defaultTrackData = (trackData, trackDataTableLength) => {
  return {
    ...trackData, explicit: false, position: trackDataTableLength + 1, title: "", track: { size: 0, name: "" },
    genre: trackData.genre || "", genreName: trackData.genreName || "", subgenre: trackData.subgenre || "",
    price: "", lyrics: "", isrc: "", track_language_id: trackData.track_language_id, audio_locale_id: trackData.audio_locale_id,
    progress: 0, artists: [...trackData.artists, ...trackData.allOtherArtists], collaborators: trackData.collaborators,
    track_language_name: trackData.track_language_name, allOtherArtists: [], id: "", audio_locale_name: trackData.audio_locale_name || "",
    existedInFuga: false, fugaId: ""
  }
}

export const getAudioLocaleIdFromName = (audioLocaleName) => {
  let audio_locale = allAudioLocalesFuga.find(l => l.name === audioLocaleName);
  if (audio_locale === undefined) audio_locale.id = "ES";
  else return audio_locale.id;
}
