import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import React from "react";

import { useSelector } from 'react-redux';

const MpButton = ({ show }) => {
  const storePayment = useSelector(store => store.payments?.actualPayment);

  initMercadoPago('APP_USR-0738d22d-8494-42ff-baa0-6d8f44c266a7', { locale: 'es-AR' });

  return (
    <>
      {storePayment.mpPaymentId && show
        ? <Wallet initialization={{ preferenceId: storePayment.mpPaymentId, redirectMode: 'modal' }} />
        : <></>}
    </>
  )
}

export default MpButton;


