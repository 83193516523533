import React, { useState, useEffect } from "react";

import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
  Backdrop, CircularProgress
} from "@mui/material";
import { useDispatch } from 'react-redux';
import { postChangeStateToInfractions } from "utils/textToShow.utils";
import { getUserAvailableMoney } from 'services/BackendCommunication';
import { artistStatesShowAdmin } from '../../variables/artist.variables';

const UserInfractionDialog = ({ userData, openInfractionDialog, setOpenInfractionDialog }) => {

  const dispatch = useDispatch();

  const [openLoaderSendingInfraction, setOpenLoaderSendingInfracion] = useState(false);
  const [buttonState, setButtonState] = useState("none");
  const [royaltiesGenerated, setRoyaltiesGenerated] = useState("...")

  useEffect(() => {
    const getUserFinancialInfo = async () => {
      let { royalties } = await getUserAvailableMoney(userData, dispatch);
      setRoyaltiesGenerated(royalties);
    }

    if (openInfractionDialog && userData.id) getUserFinancialInfo();

  }, [userData])

  const handleCancelDialog = () => {
    setOpenInfractionDialog(false);
  }

  const handleSendInfraction = async () => {
    setOpenLoaderSendingInfracion(true);
    // let allResponses = await toWithOutError(dispatch(artistSuspendByInfractionRedux(userData, actions, emailObservations)));
    // if (allResponses === "ERROR") {setButtonState('error'); setOpenLoaderSendingInfracion(false)}
    setButtonState('success');
    setOpenLoaderSendingInfracion(false);
    closeInfractionDialog();
  }

  const closeInfractionDialog = () => {
    setButtonState('none');
    setOpenInfractionDialog(false);
  }

  return (
    <Dialog open={openInfractionDialog} onClose={handleCancelDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>

      <Backdrop open={openLoaderSendingInfraction}>
        <CircularProgress />
      </Backdrop>

      <DialogContent>

        <DialogTitle id="form-artist-title" textAlign='center' sx={{ fontSize: "2em", padding: '0' }}>Acciones a realizar</DialogTitle>
        <DialogTitle id="name-artist" textAlign='center' sx={{ fontSize: "1.5em", padding: '0' }}>{`${userData.name}`}</DialogTitle>

        <DialogContentText textAlign='left' paddingBottom={1} sx={{ marginTop: '20px' }}>
          {postChangeStateToInfractions(artistStatesShowAdmin[userData.state])}
        </DialogContentText>

        {userData.ownerEmail && <DialogContentText key={'owner email'} sx={{ marginTop: '20px' }}>
          Email Usuario: <b>{`${userData.ownerEmail}`}</b>
        </DialogContentText>}

        {userData.ownerId && <DialogContentText key={'owner id'}>
          ID Usuario: <b>{`${userData.ownerId}`}</b>
        </DialogContentText>}

        {userData.spotify_uri && <DialogContentText key={'artist-uri'}>
          Spotify URI: <b>{`${userData.spotify_uri}`}</b>
        </DialogContentText>}

        <DialogContentText key={'royalties'}>
          Regalías del Artista: <b>{Number.isFinite(royaltiesGenerated) ? `${royaltiesGenerated} USD` : "... USD"}</b>
        </DialogContentText>

        <DialogContentText key="info" sx={{ marginTop: '20px' }}>
          Todavía no implementé las posibles acciones a realizar al suspender un Artista por Copyright, pero no
          me parecen prioritarias. <br />
          <b>Por lo que para investigar sus regalías hay cambiar el estado de los Lanzamientos a Infracción por Copyright y luego seleccionar
            dar de baja y investigar sus regalías.
          </b>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancelDialog} sx={buttonColorStyle}>
          Atras
        </Button>
        <Button onClick={handleSendInfraction} sx={buttonColorStyle}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserInfractionDialog;

const buttonColorStyle = { color: "#508062" };