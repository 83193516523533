import React, { useState, useEffect } from "react";
import { Grid, Backdrop, CircularProgress, Typography } from '@mui/material';

import { resourceNotYoursText, waitForRoyalties, emptyRoyaltiesResult } from '../../utils/textToShow.utils';
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import SearchNavbar from "components/Navbars/SearchNavbar";
import CustomizedTable from "components/Table/CustomizedTable";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import {
  accountingGroupByValues, getAccountingHeadersForUser, getAccountingRows,
  getRoyaltyHeadersForUser, getTotalesAccountingRow, groupByNameToId
} from "factory/royalties.factory";

import { getRoyaltiesForTableView, getAccountingGroupedByForTableView } from '../../services/BackendCommunication';
import { useDispatch, useSelector } from 'react-redux';
import { createRoyaltyRowForUser } from '../../factory/royalties.factory';
import { userIsAdmin, userIsRRSS, userIsRoot } from 'utils/users.utils';
import { whiteColor } from 'assets/jss/material-dashboard-react.js';
import { fugaGreen } from 'variables/colors';
import { toWithOutError } from 'utils';
import AccountingBar from "components/Navbars/AccountingBar";
import WaitingDialog from "components/Dialogs/WaitingDialog";
import { getAccountingDocFS, getUserDataByEmailInFS } from "services/FirestoreServices";
import { getAccDocId, getRetirosButtons } from "utils/royalties.utils";
import { getSkeletonRows } from "utils/tables.utils";

const Royalties = () => {
  const dispatch = useDispatch();
  const currentUserData = useSelector(store => store.userData);
  const albums = useSelector(store => store.albums.albums);
  const artists = useSelector(store => store.artists.artists);
  const rol = currentUserData.rol;
  const userId = currentUserData.id;

  const isRRSS = userIsRRSS(rol);
  const isAdmin = userIsAdmin(rol);
  const isRoot = userIsRoot(rol);

  const albumsUpc = albums.filter(album => album.upc).map(album => album.upc.toString());
  const artistsNames = artists.map(artist => artist.name);

  const royaltiesRowCantValues = 14;
  const royaltiesAccCantValues = 4;

  // INIT SEARCH STUFF
  const [emailSearchValue, setEmailSearchValue] = useState("");
  const [upcSearchValue, setUpcSearchValue] = useState("");
  const [isrcSearchValue, setIsrcSearchValue] = useState("");
  const [artistSearchValue, setArtistSearchValue] = useState("");

  const [emailAccSearchValue, setEmailAccSearchValue] = useState("");
  const [upcAccSearchValue, setUpcAccSearchValue] = useState("");
  const [isrcAccSearchValue, setIsrcAccSearchValue] = useState("");
  const [artistAccSearchValue, setArtistAccSearchValue] = useState("");
  // END SEARCH STUFF

  const defaultAccParams = {
    field: "userId", values: isAdmin ? [] : userId,
    groupBy: { id: 'dsp', name: "DSP's" }, orderBy: { field: 'revenues', order: 'desc' }
  };
  const defaultRoyaltiesParams = { field: "userId", values: isAdmin ? [] : userId };

  const [emptyResults, setEmptyResults] = useState(false);
  const [loadingRoyalties, setLoadingRoyalties] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [royaltiesRows, setRoyaltiesRows] = useState([]);
  const [openNotAdminWarning, setOpenNotAdminWarning] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchParams, setSearchParams] = useState(defaultRoyaltiesParams);
  const [royaltiesTableIsOpen, setRoyaltiesTableIsOpen] = useState(false);

  const [accountingRows, setAccountingRows] = useState(getSkeletonRows(royaltiesAccCantValues, 10));
  const [filterAccountingParams, setFilterAccountingParams] = useState(defaultAccParams);
  const [accountingTableIsOpen, setAccountingTableIsOpen] = useState(true);

  // Royalties
  useEffect(() => {
    const getRoyaltiesCountAndRows = async () => {
      let { count, rows } = (searchParams.values.length === 0 && !isRoot)
        ? { count: 0, rows: [] }
        : await getRoyaltiesForTableView(userId, searchParams.field, searchParams.values, rowsPerPage, rowsPerPage * page, isAdmin, dispatch);
      setTotalCount(count);
      setRoyaltiesRows(rows?.map(royaltyRow => createRoyaltyRowForUser(royaltyRow)) || []);
    }

    getRoyaltiesCountAndRows();
  }, [page, rowsPerPage, searchParams])

  // Accounting 
  useEffect(() => {
    const getAccountingInfo = async () => {
      setLoadingRoyalties(true);
      let accountingValues = [];
      let { groupBy, field, values, orderBy } = filterAccountingParams;
      if (isRoot && values.length === 0 && groupBy.id !== "assetTitle") {
        accountingValues = await getAccountingDocFS(getAccDocId(isAdmin, groupBy.id, field, values), dispatch);
      }
      else {
        (values.length === 0 && !isRoot)
          ? accountingValues = []
          : accountingValues = await getAccountingGroupedByForTableView(userId, groupBy.id, field, values, isAdmin, dispatch);
      }
      let accountingRowsToShow = getAccountingRows(accountingValues, groupBy, 50, orderBy);
      let totals = getTotalesAccountingRow(accountingValues);
      setAccountingRows([totals, ...accountingRowsToShow]);
      setLoadingRoyalties(false);
    }

    getAccountingInfo();
  }, [filterAccountingParams])

  const handleCollapseAccounting = () => setAccountingTableIsOpen(!accountingTableIsOpen);
  const handleCollapseRoyalties = () => setRoyaltiesTableIsOpen(!royaltiesTableIsOpen);

  const headersRoyaltiesName = getRoyaltyHeadersForUser.map(headerWithWidth => headerWithWidth.name);
  const headersRoytaltiesWidth = getRoyaltyHeadersForUser.map(headerWithWidth => headerWithWidth.width);
  const headersAccountingName = getAccountingHeadersForUser(filterAccountingParams.groupBy).map(headerWithWidth => headerWithWidth.name);
  const headersAccountingWidth = getAccountingHeadersForUser(filterAccountingParams.groupBy).map(headerWithWidth => headerWithWidth.width);

  const handleChangePage = (event, newPage) => {
    setRoyaltiesRows(getSkeletonRows(royaltiesRowCantValues, rowsPerPage));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRoyaltiesRows(getSkeletonRows(royaltiesRowCantValues, rowsPerPage));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let appBarSx = { borderRadius: '0em', backgroundColor: whiteColor };
  let royaltiesTableParams = {
    rows: royaltiesRows, headers: headersRoyaltiesName, columnsWidth: headersRoytaltiesWidth,
    totalCount, handleChangePage, page, handleChangeRowsPerPage, rowsPerPage,
    headersHeight: 65, maxLengthChars: 17, maxWidthText: 150, rowsAlign: 'center',
    rowsHeight: 30, headerColor: fugaGreen, needPaginationInTable: false
  };

  let accountingTableParams = {
    rows: accountingRows, headers: headersAccountingName, columnsWidth: headersAccountingWidth,
    totalCount: 0, headersHeight: 45, rowsHeight: 30, maxLengthChars: 60, maxWidthText: 300,
    rowsAlign: 'left', headerColor: fugaGreen, needPaginationInTable: false
  };

  // INIT SEARCH STUFF

  const setSkeletonRows = caller => {
    if (caller === 'Regalías') setRoyaltiesRows(getSkeletonRows(royaltiesRowCantValues, rowsPerPage));
    if (caller === 'Ingresos') setAccountingRows(getSkeletonRows(royaltiesAccCantValues, accountingRows.length));
  }

  const onSearchEmailHandler = async (email, caller) => {
    if (!email) return;
    let userData = await toWithOutError(getUserDataByEmailInFS(email, dispatch));
    if (userData === "EMPTY") { setEmptyResults(true); return }
    setSkeletonRows(caller);
    if (caller === 'Regalías') setSearchParams({ field: "userId", values: userData.id });
    if (caller === 'Ingresos') setFilterAccountingParams({ ...filterAccountingParams, field: "userId", values: userData.id })
  }

  const onSearchArtistHandler = async (artistName, caller) => {
    if (!artistName) return;
    if (!isAdmin && !artists.map(artist => artist.name).includes(artistName.trim())) {
      setOpenNotAdminWarning(true);
      return;
    }

    setSkeletonRows(caller);
    if (caller === 'Regalías') setSearchParams({ field: "releaseArtist", values: artistName.trim() });
    if (caller === 'Ingresos') setFilterAccountingParams({ ...filterAccountingParams, field: "releaseArtist", values: artistName.trim() });
  }

  const onSearchUPCHandler = async (upcsSeparatedByComa, caller) => {
    let upcsAsArray = upcsSeparatedByComa.toString().split(",");
    if (upcsAsArray.length === 0) return;
    if (!isAdmin && !upcsAsArray.every(upc => albumsUpc.indexOf(upc) > -1)) {
      setOpenNotAdminWarning(true);
      return;
    }

    setSkeletonRows(caller);
    if (caller === 'Regalías') setSearchParams({ field: "upc", values: upcsAsArray });
    if (caller === 'Ingresos') setFilterAccountingParams({ ...filterAccountingParams, field: "upc", values: upcsAsArray });
  }

  const onSearchISRCHandler = async (isrcsSeparatedByComa, caller) => {
    let isrcsAsArray = isrcsSeparatedByComa.toString().split(",");
    if (isrcsAsArray.length === 0) return;
    if (!isAdmin && !isrcsAsArray.every(isrc => albumsUpc.indexOf(isrc) > -1)) {
      // TENGO QUE PEDIR TODOS LOS ISRCS del user para verificar que no busque cosas que no son suyas...
      setOpenNotAdminWarning(true);
      return;
    }

    setSkeletonRows(caller);
    if (caller === 'Regalías') setSearchParams({ field: "isrc", values: isrcsAsArray });
    if (caller === 'Ingresos') setFilterAccountingParams({ ...filterAccountingParams, field: "isrc", values: isrcsAsArray });
  }

  const handleEnterKeyPress = (event, searchProps, caller) => {
    if (event.key === 'Enter') {
      if (searchProps.name === "Email") onSearchEmailHandler(searchProps.value, caller);
      if (searchProps.name === "UPC's separados por coma") onSearchUPCHandler(searchProps.value, caller);
      if (searchProps.name === "ISRC's separados por coma") onSearchISRCHandler(searchProps.value, caller);
      if (searchProps.name === "Artista") onSearchArtistHandler(searchProps.value, caller);
    }
  }

  const emailSearchProps = { shortName: "Email", name: "Email", handleEnterKeyPress, onSearchHandler: onSearchEmailHandler, value: emailSearchValue.trim().toLowerCase(), setValue: setEmailSearchValue };
  const upcSearchProps = { shortName: "UPC's", name: "UPC's separados por coma", handleEnterKeyPress, onSearchHandler: onSearchUPCHandler, value: upcSearchValue.trim(), setValue: setUpcSearchValue };
  const isrcSearchProps = { shortName: "ISRC's", name: "ISRC's separados por coma", handleEnterKeyPress, onSearchHandler: onSearchISRCHandler, value: isrcSearchValue.trim(), setValue: setIsrcSearchValue };
  const artistSearchProps = { shortName: "Artista", name: "Artista", handleEnterKeyPress, onSearchHandler: onSearchArtistHandler, value: artistSearchValue, setValue: setArtistSearchValue };

  const emailAccSearchProps = { shortName: "Email", name: "Email", handleEnterKeyPress, onSearchHandler: onSearchEmailHandler, value: emailAccSearchValue.trim().toLowerCase(), setValue: setEmailAccSearchValue };
  const upcAccSearchProps = { shortName: "UPC's", name: "UPC's separados por coma", handleEnterKeyPress, onSearchHandler: onSearchUPCHandler, value: upcAccSearchValue.trim(), setValue: setUpcAccSearchValue };
  const isrcAccSearchProps = { shortName: "ISRC's", name: "ISRC's separados por coma", handleEnterKeyPress, onSearchHandler: onSearchISRCHandler, value: isrcAccSearchValue.trim(), setValue: setIsrcAccSearchValue };
  const artistAccSearchProps = { shortName: "Artista", name: "Artista", handleEnterKeyPress, onSearchHandler: onSearchArtistHandler, value: artistAccSearchValue, setValue: setArtistAccSearchValue };

  const handleChangeGroupBy = groupByName => {
    setAccountingRows(getSkeletonRows(royaltiesAccCantValues, accountingRows.length > 10 ? accountingRows.length : 10));
    setFilterAccountingParams({
      ...filterAccountingParams,
      groupBy: { id: groupByNameToId(groupByName), name: groupByName || "DSP's" },
      orderBy: { field: groupByNameToId(groupByName), order: "desc" }
    })
  }

  const groupByProps = [{ helper: "Agrupar según", values: accountingGroupByValues, handleChangeGroupBy, value: filterAccountingParams.groupBy }];

  const cleanRoyaltiesParams = () => {
    setRoyaltiesRows(getSkeletonRows(royaltiesRowCantValues, rowsPerPage > 7 ? rowsPerPage : 7));
    setSearchParams(defaultRoyaltiesParams);
    setEmailSearchValue(""); setUpcSearchValue(""); setIsrcSearchValue("");
    setArtistSearchValue("");
  }

  const cleanAccountingParams = () => {
    setAccountingRows(getSkeletonRows(royaltiesAccCantValues, accountingRows.length > 10 ? accountingRows.length : 10));
    setFilterAccountingParams(defaultAccParams);
    setEmailAccSearchValue(""); setUpcAccSearchValue(""); setIsrcAccSearchValue("");
    setArtistAccSearchValue("");
  }

  const cleanSearchResults = caller => {
    if (caller === 'Regalías') cleanRoyaltiesParams();
    if (caller === 'Ingresos') cleanAccountingParams();
  }
  // END SEARCH STUFF

  const buscadoresAccounting = isAdmin
    ? [emailAccSearchProps, upcAccSearchProps, isrcAccSearchProps, artistAccSearchProps]
    : [artistAccSearchProps]

  const buscadoresRoyalties = isAdmin
    ? [emailSearchProps, upcSearchProps, isrcSearchProps, artistSearchProps]
    : [artistSearchProps]

  const handleCloserWaitingRoyalties = () => {
    setLoadingRoyalties(false);
  }

  return (
    <>
      <Backdrop open={false}>
        <CircularProgress />
      </Backdrop>

      <WaitingDialog isOpen={loadingRoyalties} title="Cargando Regalías" contentTexts={waitForRoyalties}
        handleClose={handleCloserWaitingRoyalties} successImageSource="/images/success.jpg" size="sm" />

      <InfoActionDialog id='unabled' isOpen={openNotAdminWarning} handleClose={() => setOpenNotAdminWarning(false)}
        title={"Necesitas permisos de Administrador"} contentTexts={resourceNotYoursText} />

      <InfoActionDialog id='empty-results' isOpen={emptyResults} handleClose={() => setEmptyResults(false)}
        title={"Sin resultados"} contentTexts={emptyRoyaltiesResult} />

      <Grid item xs={12} sx={{ textAlign: "center" }} paddingTop={2}>

          <CardHeader color="primary" style={{ background: fugaGreen, textAlign: "center" }}>
            <Typography sx={cardTitleWhiteStyles}>Regalías</Typography>
            <p style={cardCategoryWhiteStyles}>Los reportes se cargan el 15 de cada mes.</p>
            <p style={cardCategoryWhiteStyles}>A partir de los reportes de Febrero 2022, sólo figuran las transacciones que fueron monetizadas y generaron regalías, no las estadísticas generales. </p>
            <p style={cardCategoryWhiteStyles}>Los reportes del mes de Septiembre 2022 se identifican con el número del mes y el año (2022-09), pero en su mayoría corresponden a la actividad de Junio.</p>
            <p style={cardCategoryWhiteStyles}>El de Octubre a la actividad de Julio, y así sucesivamente, es decir, llegan con 3 meses de diferido. Puede suceder que alguna DSP reporte con más de 3 meses de diferencia.</p>
          </CardHeader>

            {getRetirosButtons(buttonColorStyle, "Ver Retiros")}

            <Grid item xs={12} padding={0} >
              <AccountingBar searchArrayProps={buscadoresAccounting} cleanSearchResults={cleanSearchResults}
                appBarSx={appBarSx} appBarTitle='Ingresos' mainSearchColor={fugaGreen} isOpen={accountingTableIsOpen}
                handleCollapseTable={handleCollapseAccounting} groupByArray={groupByProps} />
            </Grid>

            {accountingTableIsOpen && <Grid item xs={12} paddingBottom={2} sx={{ margin: 'auto' }}>
              <CustomizedTable {...accountingTableParams} />
            </Grid>}

            <Grid item xs={12} paddingTop={2} >
              <SearchNavbar searchArrayProps={buscadoresRoyalties} cleanSearchResults={cleanSearchResults}
                appBarSx={appBarSx} appBarTitle='Regalías' mainSearchColor={fugaGreen}
                isOpen={royaltiesTableIsOpen} handleCollapseTable={handleCollapseRoyalties} />
            </Grid>

            {royaltiesTableIsOpen && <Grid item xs={12} sx={{ margin: 'auto' }}>
              <CustomizedTable {...royaltiesTableParams} />
            </Grid>}

      </Grid>

    </>
  )
}

export default Royalties;

const buttonColorStyle = {
  color: 'white', width: "200px", backgroundColor: fugaGreen, '&:hover': { backgroundColor: fugaGreen, color: 'white' }, raisedPrimary: {
    color: 'white',
  },
};

const cardCategoryWhiteStyles = { color: "rgba(255,255,255,.82)", margin: "0 0 0", fontSize: "14px", fontWeight: "400" }
const cardTitleWhiteStyles = {
  color: "rgba(255,255,255,255)", marginTop: "0px", minHeight: "auto", fontWeight: "300px", fontSize: "40px",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", marginBottom: "3px", textDecoration: "none"
}
