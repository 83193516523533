import React from "react";

import { Grid } from '@mui/material';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ProgressButtonWithInputFile from 'components/CustomButtons/ProgressButtonWithInputFile';
import { Image as ReactImage } from 'mui-image';
import Danger from 'components/Typography/Danger.js';

const ImageInput = ({ sxImage ,imagenUrl, onClickAddImage, helperText, textButton, progress, message, fileTypesAccept, fileType }) => {

  return (
    <Grid item xs={12}>
      <ProgressButtonWithInputFile
        textButton={textButton}
        loading={progress > 0 && !imagenUrl}
        buttonState={progress < 100 ? "none" : "success"}
        onClickHandler={onClickAddImage}
        progress={progress}
        fileType={fileTypesAccept ? fileTypesAccept : "image/jpeg"}
        helperText={helperText}
      />

      <Danger color="error" variant="h6" textAlign='center'>
        {message}
      </Danger>

      {!imagenUrl && (
        <Grid sx={{ textAlign: 'center' }}>
          <SkeletonTheme color="antiquewhite" >
            <Skeleton circle width={250} height={250} />
          </SkeletonTheme>
        </Grid>
      )}

      {(imagenUrl && fileType.includes('image')) && (
        <Grid >
          <ReactImage
            style={sxImage ? sxImage : { width: 250, height: 250, borderRadius: 5 }}
            alt="album-image"
            duration={30}
            src={imagenUrl}
          />
        </Grid>
      )}

      {imagenUrl && fileType.includes('pdf') && (
        <Grid sx={{ padding: "1em", textAlign: 'center' }}>
          <a href={imagenUrl} target="_blank" rel="noreferrer">
            PDF cargado correctamente, hacer click para descargar.
          </a>
        </Grid>
      )}

    </Grid>
  )
}

export default ImageInput;