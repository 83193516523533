import { useState, useEffect } from 'react';

// material-ui
import { Grid } from '@mui/material';

import AnalyticEcommerce from 'components/Card/AnalyticEcommerce';
import { useDispatch } from 'react-redux';
import { defaultCountUsers, getCustomQueryUsers, getUsersBasicCounts } from 'services/UserStats';
import { Paper } from '../../../node_modules/@mui/material/index';
import { querySetStore } from 'redux/actions/QueryActions';
import { formatThousandsPoint } from 'utils';

const UserCountStats = () => {

  const dispatch = useDispatch();
  
  const [usersCounts, setUsersCounts] = useState(defaultCountUsers);

  useEffect(() => {
    const getUserCounts = async () => {
      let countsUsers = await getUsersBasicCounts(dispatch);
      setUsersCounts(countsUsers)
      return countsUsers;
    }
    getUserCounts();
  }, []);

  const handleGetCustomQuerys = async customQueryName => {
    let resultQuery = await getCustomQueryUsers(customQueryName, 30, dispatch);
    dispatch(querySetStore({ name: customQueryName, limit: 30, collection: 'users' }));
  }

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('all')}>
          <AnalyticEcommerce title="Total de Usuarios" count={formatThousandsPoint(usersCounts.totalUsers)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('actives')}>
          <AnalyticEcommerce title="Activos" count={formatThousandsPoint(usersCounts.activeUsers)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('cancelled')}>
          <AnalyticEcommerce title="Cancelados" count={formatThousandsPoint(usersCounts.cancelledUsers)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('pending')}>
          <AnalyticEcommerce title="Pendientes de Pago" count={formatThousandsPoint(usersCounts.pendingFinishPayProccess)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('need-renovation')}>
          <AnalyticEcommerce title="Necesitan Renovación" count={formatThousandsPoint(usersCounts.needRenovationUsers)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('need-verification-proof')}>
          <AnalyticEcommerce title="Chequear Comprobante" count={formatThousandsPoint(usersCounts.needCheckProof)} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.6}>
        <Paper elevation={0} sx={{ cursor: 'pointer' }} onClick={() => handleGetCustomQuerys('next-to-expire')}>
          <AnalyticEcommerce title="Próximos a vencer" count={formatThousandsPoint(usersCounts.nextToExpire)} />
        </Paper>
      </Grid>

      <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

    </Grid>
  );
}

export default UserCountStats;