import React, { useState } from 'react';
import FAQ from './FAQ';
import OutsiderContact from './OutsiderContact';
import { Container, Typography, Paper, Grid, ButtonGroup, Button } from '@mui/material';

import { Image } from 'mui-image'

const OutSupportPage = () => {

  const [showFAQs, setShowFAQs] = useState(true);

  const handleToggle = (section) => {
    setShowFAQs(section === 'faqs');
  };

  return (
    <Grid container component="main" sx={rootStyle}>
      <Grid item xs={false} sm={4} md={5}>
        <Image src="/images/login-full.jpg" alt="image" duration={30} />
      </Grid>

      <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square textAlign='center'>
        <Container maxWidth="md" sx={{ mt: 5 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Preguntas Frecuentes y Soporte
          </Typography>

          <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ mb: 4 }}>
            <Button onClick={() => handleToggle('faqs')} color={showFAQs ? 'primary' : 'inherit'}>
              FAQs
            </Button>
            <Button onClick={() => handleToggle('contact')} color={!showFAQs ? 'primary' : 'inherit'}>
              Contacto
            </Button>
          </ButtonGroup>

          <Grid container spacing={4}>
            {showFAQs ? (
              <Grid item xs={12} md={12}>
                <FAQ />
              </Grid>
            ) : (
              <Grid item xs={12} md={12}>
                <Typography variant="h4" component="h2" gutterBottom>
                  Contáctanos
                </Typography>
                <OutsiderContact />
              </Grid>
            )}
          </Grid>
        </Container>
      </Grid>
    </Grid >
  );
};

export default OutSupportPage;

const rootStyle = { height: '100vh' };
