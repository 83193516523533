import * as ReducerTypes from 'redux/actions/Types';
import { to } from 'utils';
import { createDiscountFS, deleteDiscountFS, updateDiscountFS } from 'services/FirestoreServices';
import { discountCanBeDeleted } from 'utils/discounts.utils';
import { DISCOUNT_STATUS_INACTIVE } from 'variables/discounts.variables';
import * as FirestoreServices from 'services/FirestoreServices.js';
import { setCreditDiscountFromFriendDiscount, setDiscountPostPay } from 'factory/discounts.factory';


export const discountsAddLocal = newDiscountList => {
  if (!Array.isArray(newDiscountList)) newDiscountList = [newDiscountList];
  return {
    type: ReducerTypes.DISCOUNTS_CREATE,
    payload: newDiscountList
  };
};

export const discountsUpdateActualLocal = actualDiscountNewData => {
  return {
    type: ReducerTypes.DISCOUNTS_UPDATE_WITH_ID,
    payload: actualDiscountNewData
  };
};

export const getDiscountstByFieldRedux = (field, fieldValue, limit) => async (dispatch) => {
  let discountsByField = await FirestoreServices.getElementsByField('discounts', field, fieldValue, dispatch, limit);
  if (discountsByField === "EMPTY") return "EMPTY";
  if (Array.isArray(discountsByField) && discountsByField.length > 0) dispatch(discountsAddLocal(discountsByField));
  else return "ERROR";
  return discountsByField || [];
}

export const getDiscountstByNameRedux = discountName => async dispatch => {
  let discountsByName = await FirestoreServices.getDiscountByNameFS(discountName, dispatch);
  if (discountsByName === "EMPTY") return "EMPTY";
  if (discountsByName.id) dispatch(discountsAddLocal(discountsByName));
  else return "ERROR";
  return discountsByName || [];
}


export const discountsCreateRedux = newDiscount => async dispatch => {
  newDiscount.name = newDiscount.name.trim();
  newDiscount.amount = Number(newDiscount.amount);
  newDiscount.percentage = Number(newDiscount.percentage);
  let [errorCreatingPaymentDoc] = await to(createDiscountFS(newDiscount, dispatch));
  if (errorCreatingPaymentDoc) return "ERROR";

  dispatch({
    type: ReducerTypes.DISCOUNTS_CREATE,
    payload: [newDiscount]
  });

  return "SUCCESS";
};

export const discountsCreateFromCredit = (discountByFriends, creditFriendsDiscount) => async dispatch => {
  let discountCredit = setCreditDiscountFromFriendDiscount(discountByFriends, creditFriendsDiscount);
  let updatedDiscountByFriends = { ...discountByFriends, usedAsFriendsCredit: discountCredit.percentage };

  let errorUpdatingDiscountDoc = await dispatch(discountsUpdateWithId(updatedDiscountByFriends));
  if (errorUpdatingDiscountDoc === "ERROR") return "ERROR";

  let errorCreatingCreditDiscount = await dispatch(discountsCreateRedux(discountCredit));
  if (errorCreatingCreditDiscount === "ERROR") return "ERROR";

  return "SUCCESS";
}

export const discountsUpdateWithId = updatedDiscountWithId => async dispatch => {
  let [errorUpdatingDiscountDoc] = await to(updateDiscountFS(updatedDiscountWithId, dispatch));
  if (errorUpdatingDiscountDoc) return "ERROR";

  dispatch({
    type: ReducerTypes.DISCOUNTS_UPDATE_WITH_ID,
    payload: updatedDiscountWithId
  });

  return "SUCCESS";
}

export const discountsUpdatePostPay = (discount, payment) => async dispatch => {
  let discountUsed = discount;
  if (!discount.name) {
    discountUsed = await dispatch(getDiscountstByFieldRedux("name", payment.cupon?.name, 1));
    discountUsed = Array.isArray(discountUsed) && discountUsed.length > 0 ? discountUsed[0] : "ERROR";
    if (discountUsed === "ERROR" || discount === "EMPTY") return "NOT_FOUND";
  }
  let newDiscountInfo = setDiscountPostPay({ ...discountUsed }, payment);
  let [errorUpdatingDiscountDoc] = await dispatch(discountsUpdateWithId(newDiscountInfo));
  if (errorUpdatingDiscountDoc === "ERROR") return "ERROR";
  // Acá podría mandarles un mail, avisando al dueño que usaron su cupon.
  return "SUCCESS";
}

export const discountsDeleteByIdLocal = async discountId => {
  return {
    type: ReducerTypes.DISCOUNTS_DELETE_BY_ID,
    payload: discountId
  };
}

export const discountsDeleteRedux = discount => async (dispatch) => {
  if (!discount.id) return "NO_DATA";
  let deleteResult = "ERROR";
  if (!discountCanBeDeleted(discount)) {
    deleteResult = await dispatch(discountsUpdateWithId({ ...discount, status: DISCOUNT_STATUS_INACTIVE }));
    return deleteResult;
  }
  deleteResult = await to(deleteDiscountFS(discount.id));
  if (deleteResult === "ERROR") return "ERROR";

  dispatch({
    type: ReducerTypes.DISCOUNTS_DELETE_BY_ID,
    payload: discount.id
  });

  return "SUCCESS";
}

export const discountsSignOut = () => {
  return {
    type: ReducerTypes.DISCOUNTS_SIGN_OUT
  }
}
