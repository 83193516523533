import { combineArraysWithNoDuplicates, cloneDeepLimited } from '../utils';
import { planesLaFlota } from '../variables/financial';
import { goToEditArtistIcon, moreInfoActionRender, getLocalLinkTo, getOutLinkTo, iconOpenActions } from './tables.utils';
import { artistStatesShowAdmin, artistStatesShowUser, artistStateColorsAdmin, artistStateColorsUser, artistStateCantRelease, artistNoActiveStates } from '../variables/artist.variables';
import { Typography } from '@mui/material';
import { artistStatesListShowAdmin } from 'variables/artist.variables';
import { userNotActiveStates } from 'variables/user.variables';
import { mapArtistsCustomQueryNameToStatus } from './artists.queries.utils';

export const getArtistCanEdit = (artistState, userState) => {
  return !artistStateCantRelease.includes(artistState)
    && !userNotActiveStates.includes(userState);
}

export const getArtistById = (artists, artistId) => {
  let artistFounded = artists.find(a => a.id === artistId);
  if (!artistFounded) return {};
  else return artistFounded;
}

export const sortArtistsByField = (artists, field) => {
  let validArtists = artists.filter(a => a && a.name);
  return validArtists.sort((aA, aB) => {
    if (aA[field] > aB[field]) return -1;
    else return 1;
  })
}

export const getAllOtherArtistsFromAlbumAndTrack = (artistFromLaFlota, allOtherArtistsAlbum, allArtistsTrack) => {
  let allOtherArtistsFromAlbum = allOtherArtistsAlbum.filter(a => a.name !== "");
  let allArtistsFromTrack = allArtistsTrack.filter(tA => tA.name !== artistFromLaFlota.name);
  let combinedArtists = combineArraysWithNoDuplicates(allOtherArtistsFromAlbum, allArtistsFromTrack);
  return combinedArtists;
}

export const artistsWithUniqueName = allOtherArtists => {
  let finalArtists = []; let finalArtistsNames = [];
  allOtherArtists.forEach(artist => {
    if (Boolean(artist.name) && !finalArtistsNames.includes(artist.name)) {
      finalArtists.push(artist); finalArtistsNames.push(artist.name);
    }
  })
  return finalArtists;
}

export const getFilteredArtistByUrl = (params, artists) => {
  if (params.view === "artist") return artists.filter(artist => artist.id === params.id);
  if (params.view === "allOfUser") return artists.filter(artist => artist.ownerId === params.id);
  return artists;
}

export const artistsStateNameToStateId = stateName => {
  return artistStatesListShowAdmin.find(stateInfo => stateInfo.name === stateName)?.id || "all";
}

const getArtistStateInfo = (sx, artistState, isAdmin) => {
  return <Typography sx={sx} >{isAdmin ? artistStatesShowAdmin[artistState] : artistStatesShowUser[artistState]}</Typography>
}

export const getArtistStateColor = (artistState, isAdmin) => {
  return isAdmin ? artistStateColorsAdmin[artistState] : artistStateColorsUser[artistState];
}

export const getArtistPropsForDataTable = (artists, handleOpenUserDialog, setOpenArtistActionsDialog) => {
  const upcLinkSx = { lineHeight: 1.5, marginLeft: "0.2em", fontWeight: 400, fontSize: "1rem" };
  let artistsPropsToTable = [];
  artists.forEach(artist => {
    const stateInfoStyle = { color: getArtistStateColor(artist.state, true), fontSize: "1em", fontWeight: 600 };
    artistsPropsToTable.push([
      iconOpenActions(artist.id, 'artistId', setOpenArtistActionsDialog),
      goToEditArtistIcon(artist.id),
      getLocalLinkTo(`artists?view=artist&id=${artist.id}`, artist.name),
      artist.spotify_uri,
      artist.apple_id,
      artist.ownerEmail,
      moreInfoActionRender(artist.ownerId, handleOpenUserDialog),
      getOutLinkTo(`https://next.fugamusic.com/artist/${artist.fugaId}`, artist.fugaId, upcLinkSx),
      getArtistStateInfo(stateInfoStyle, artist.state, true),
      artist.id
    ]);
  });
  return artistsPropsToTable;
}

export const getArtistPropsForUserDataTable = (artists, setOpenArtistActionsDialog) => {
  let artistsPropsToTable = [];
  artists.forEach(artist => {
    const stateInfoStyle = { color: getArtistStateColor(artist.state, false), fontSize: "1em", fontWeight: 600 };
    artistsPropsToTable.push([
      iconOpenActions(artist.id, 'artistId', setOpenArtistActionsDialog),
      // artistStateCantRelease.includes(artist.state) ? "" : goToEditArtistIcon(artist.id),
      getLocalLinkTo(`artists?view=artist&id=${artist.id}`, artist.name),
      artist.spotify_uri,
      artist.apple_id,
      getArtistStateInfo(stateInfoStyle, artist.state, false)
    ]);
  });
  return artistsPropsToTable;
}


export const ifNoPrimaryChangeIt = artists => {
  let artistPrimary = {};
  if (artists.length > 0) {
    artistPrimary = artists.find(artist => artist.primary === true);
    if (!artistPrimary) artists[0].primary = true;
  }
  return artists;
}

export const getAllArtistsOfTrack = (artists, artistInvited, artistRecentlyCreated) => {
  let artistsArrayToUpload = [];
  let elementFoundedInInvited = ""; let elementFoundedInRecently = "";
  artists.forEach(artist => {
    if (artist.fugaId) artistsArrayToUpload.push({ primary: artist.primary, id: artist.fugaId });
    else {
      if (artist.name !== "") {
        // Si estan repetidos los artistas y alguno no tiene fugaId puede tomarlo en vez de tomar el que si tiene fugaId.
        elementFoundedInInvited = artistInvited.find(artistInvited => artistInvited.name === artist.name && artistInvited.fugaId);
        elementFoundedInRecently = artistRecentlyCreated.find(artistRecently => artistRecently.name === artist.name && artistRecently.fugaId);
        if (elementFoundedInInvited) {
          artist.fugaId = elementFoundedInInvited.fugaId;
        }
        if (elementFoundedInRecently) {
          artist.fugaId = elementFoundedInRecently.fugaId;
        }

        artistsArrayToUpload.push({ primary: artist.primary, id: artist.fugaId })
      }
    };
  });
  return artistsArrayToUpload;
}

export const userCanAddMoreArtists = (isAdmin, editing, plan, allArtists) => {
  if (isAdmin) return true;
  if (editing) return true;
  const cantActiveArtists = allArtists.filter(artist => !artistNoActiveStates.includes(artist.state)).length;
  let planConRestricciones = planesLaFlota.find(p => plan === p.id);
  if (!planConRestricciones) return true;
  if (planConRestricciones.maxArtists <= cantActiveArtists) return false;
  return true;
}

export const spotifyUriIsValid = spotifyUri => {
  if (spotifyUri.length === 0) return true;
  return Boolean(spotifyUri.match(/^(spotify:artist:)([a-zA-Z0-9]+)$/)) && spotifyUri.length === 37;
}

export const getQueryArtists = (artists, searchParams, filterParams) => {
  let artistsResult = artists;
  artistsResult = artistsResult.filter(artist => artist.name !== "");

  if (searchParams.field !== 'none')
    artistsResult = artists.filter(artist => artist[`${searchParams.field}`] === searchParams.value);

  if (filterParams.value !== 'all')
    artistsResult = artistsResult.filter(artist => artist[`${filterParams.field}`] === filterParams.value);

  return artistsResult;
}

export const artistCanMakeRelease = artistState => {
  return !artistStateCantRelease.includes(artistState);
}

export const artistsWhoCanAddYtChannel = (userId, artists, albumsFromUser) => {
  return artists.map(artist => !artistStateCantRelease.includes(artist.state));
}

export const validateOACId = oacId => {
  const oacRegex = /^UC[a-zA-Z0-9_-]{22}$/;
  return oacRegex.test(oacId);
};

export const getArtistsByCustomQuery = (artists, query) => {
  if (query.name === 'all') return artists;
  let artistsResult = artists;
  artistsResult = artistsResult.filter(artist => artist.name !== "");
  let statusToGet = mapArtistsCustomQueryNameToStatus(query.name);
  artistsResult = artistsResult.filter(artist => statusToGet.includes(artist.state));
  return artistsResult;
}