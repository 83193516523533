import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { artistsStateNameToStateId, getArtistPropsForDataTable, getArtistPropsForUserDataTable, getArtistsByCustomQuery, getQueryArtists } from "utils/artists.utils";
import { toWithOutError } from 'utils';
import { getSearchedUserRedux, usersGetOneByIdRedux } from "../../redux/actions/UsersActions";
import UserDialog from '../Users/UserDialog';
import { getEmailIfNotHaveUser } from '../../utils/users.utils';
import useFirestoreQuery from '../../customHooks/useFirestoreQuery';
import { getElementsAdminQueryFS } from "services/FirestoreServices";
import { sortArtistsByField } from '../../utils/artists.utils';
import { artistsAddStore, getArtistByFieldRedux, getArtistByFieldsRedux } from '../../redux/actions/ArtistsActions';

import CustomizedTable from 'components/Table/CustomizedTable';
import { cohesivePurple, whiteColor } from "variables/colors";
import AccountingBar from "components/Navbars/AccountingBar";
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import { resourceNotYoursText } from "utils/textToShow.utils";
import { userIsAdmin } from 'utils/users.utils';
import ArtistActionsDialog from './ArtistActionsDialog';
import { artistFilterStates } from "utils/artists.table.utils";

const ArtistsTable = (props) => {

  const { setOpenErrorSearch, setOpenEmptySearch } = props;

  const dispatch = useDispatch();

  const queryStore = useSelector(store => store.query);
  const artists = useSelector(store => store.artists.artists);
  const currentUserData = useSelector(store => store.userData);
  const users = useSelector(store => store.users);
  const rol = currentUserData.rol;
  const isAdmin = userIsAdmin(rol);

  const [openArtistActionsDialog, setOpenArtistActionsDialog] = useState({ open: false, artistId: "" });
  // COSAS DEL BUSCADOR
  const [openNotAdminWarningArtists, setOpenNotAdminWarningArtists] = useState(false);
  const [artistsLoading, setArtistsLoading] = useState(false);

  const [emailSearchValue, setEmailSearchValue] = useState("");
  const [artistSearchValue, setArtistSearchValue] = useState("");
  const [fugaIdSearchValue, setFugaIdSearchValue] = useState("");

  const [artistsFiltered, setArtistsFiltered] = useState(artists.filter(a => a && a.name));

  useEffect(() => {
    let groupBy = filterArtistsParams.groupBy;
    let artistsQueried = getQueryArtists(artists, searchArtistsParams,
       { field: filterArtistsParams.field, value: groupBy.id });
    setArtistsFiltered(artistsQueried);
  }, [artists]);

  useEffect(() => {
    if (queryStore.name === 'all' || queryStore.collection !== 'artists') return;
    let artistsCustomQueried = getArtistsByCustomQuery(artists, queryStore);
    setArtistsFiltered(artistsCustomQueried);
  }, [queryStore]);

  let defaultFilterParams = {
    field: "state", values: [],
    groupBy: { id: 'all', name: "Todos los estados" }, orderBy: { field: 'lastUpdateTS', order: 'desc' }
  }

  const [artistsTableIsOpen, setArtistsTableIsOpen] = useState(true);
  const [pageArtists, setPageArtists] = useState(0);
  const [rowsPerPageArtists, setRowsPerPageArtists] = useState(10);
  const [searchArtistsParams, setSearchArtistsParams] = useState({ field: 'none', value: "" });
  const [filterArtistsParams, setFilterArtistsParams] = useState(defaultFilterParams);

  let sortedArtists = sortArtistsByField(artistsFiltered, "lastUpdateTS");
  const [userSelected, setUserSelected] = useState(false);

  const stateArtistsSnap = useFirestoreQuery(getElementsAdminQueryFS("artists", 20, sortedArtists[0]?.lastUpdateTS || new Date().getTime(),
    isAdmin ? "admin" : currentUserData.id, "lastUpdateTS"));

  //onSnapshots
  useEffect(() => {
    if (stateArtistsSnap.status === "loading") return "Loading...";
    if (stateArtistsSnap.status === "error") return `Error al cargar los ultimos Artistas: ${stateArtistsSnap.error.message}`;
    if (stateArtistsSnap.status === "success" && stateArtistsSnap.data.length > 0) {
      dispatch(artistsAddStore(stateArtistsSnap.data));
    }
  }, [stateArtistsSnap])


  const handleCloseUserDialog = () => setUserSelected(false);

  const handleOpenUsuarioDialog = async userId => {
    const userData = await toWithOutError(dispatch(usersGetOneByIdRedux(userId, false)));
    setUserSelected(userData);
  }

  // TABLE ELEMENTS
  const artistsTableElements = isAdmin
    ? getArtistPropsForDataTable(sortedArtists, handleOpenUsuarioDialog, setOpenArtistActionsDialog) || []
    : getArtistPropsForUserDataTable(sortedArtists, setOpenArtistActionsDialog) || [];

  const artistsTableHeaders = isAdmin
    ? ["Opciones", "Editar", "Nombre", "Spotify Uri", "Apple ID", "Email", "Usuario", "Fuga ID", "Estado", "APP ID"]
    : ["Opciones", "Nombre", "Spotify Uri", "Apple ID", "Estado"];
  const artistsTableWidth = isAdmin ? ["4%", "4%", "10%", "20%", "8%", "14%", "5%", "10%", "10%", "15%"] : ["10%", "30%", "27%", "23%", "10%"];

  const cleanSearchResults = () => {
    setSearchArtistsParams({ field: 'none', value: '' });
    setFilterArtistsParams(defaultFilterParams);
    setArtistsFiltered(artists);
    setEmailSearchValue("");
    setArtistSearchValue("");
    setFugaIdSearchValue("");
  }

  const onSearchEmailHandler = async email => {
    setArtistsLoading(true);
    let userFinded = users.find(userFromStore => userFromStore.email === email);
    if (!userFinded) {
      userFinded = await toWithOutError(dispatch(getSearchedUserRedux(email)));
      if (userFinded === "ERROR") { setArtistsLoading(false); setOpenErrorSearch(true); setArtistsFiltered([]); return "ERROR"; }
    }

    setSearchArtistsParams({ field: 'ownerId', value: userFinded.id });
    let artistsFinded = await toWithOutError(dispatch(getArtistByFieldRedux('ownerId', userFinded.id, 50)));
    if (artistsFinded === "ERROR") { setArtistsLoading(false); setOpenErrorSearch(true); setArtistsFiltered([]); return "ERROR"; }
    if (artistsFinded === "EMPTY") { setArtistsLoading(false); setOpenEmptySearch(true); setArtistsFiltered([]); return "EMPTY"; }
    setPageArtists(0);
    setArtistsLoading(false);
  }

  const onSearchArtistNameHandler = async artistName => {
    if (!artistName) return;
    if (!isAdmin && !artists.map(artist => artist.name).includes(artistName.trim())) {
      setOpenNotAdminWarningArtists(true);
      return;
    }

    setArtistsLoading(true);
    setSearchArtistsParams({ field: 'name', value: artistName });
    let artistsFinded = await toWithOutError(dispatch(getArtistByFieldRedux('name', artistName.trim(), 100)));
    if (artistsFinded === "ERROR") { setArtistsLoading(false); setOpenErrorSearch(true); setArtistsFiltered([]); return "ERROR"; }
    if (artistsFinded === "EMPTY") { setArtistsLoading(false); setOpenEmptySearch(true); setArtistsFiltered([]); return "EMPTY"; }
    setPageArtists(0);
    setArtistsLoading(false);
  }

  const onSearchFugaIdHandler = async artistFugaId => {
    setArtistsLoading(true);
    setSearchArtistsParams({ field: 'fugaId', value: parseFloat(artistFugaId) });
    let artistsFinded = await toWithOutError(dispatch(getArtistByFieldRedux('fugaId', parseFloat(artistFugaId), 5)));
    if (artistsFinded === "ERROR") { setArtistsLoading(false); setOpenErrorSearch(true); setArtistsFiltered([]); return "ERROR"; }
    if (artistsFinded === "EMPTY") { setArtistsLoading(false); setOpenEmptySearch(true); setArtistsFiltered([]); return "EMPTY"; }
    setPageArtists(0);
    setArtistsLoading(false);
  }


  const handleEnterKeyPress = (event, searchProps) => {
    if (event.key === 'Enter') {
      if (searchProps.name === "Email") onSearchEmailHandler(searchProps.value);
      if (searchProps.name === "Artista") onSearchArtistNameHandler(searchProps.value);
      if (searchProps.name === "Fuga ID") onSearchFugaIdHandler(searchProps.value);
    }
  }

  const emailSearchProps = { name: "Email", handleEnterKeyPress, onSearchHandler: onSearchEmailHandler, value: emailSearchValue.trim().toLowerCase(), setValue: setEmailSearchValue };
  const artistSearchProps = { name: "Artista", handleEnterKeyPress, onSearchHandler: onSearchArtistNameHandler, value: artistSearchValue, setValue: setArtistSearchValue };
  const fugaIdSearchProps = { name: "Fuga ID", handleEnterKeyPress, onSearchHandler: onSearchFugaIdHandler, value: fugaIdSearchValue.trim(), setValue: setFugaIdSearchValue };

  const allSearchers = isAdmin ? [emailSearchProps, artistSearchProps, fugaIdSearchProps] : [artistSearchProps];
  let appBarSx = { borderRadius: '0em', backgroundColor: whiteColor };

  //TODO lo mismo que para los albums, usar Redux Query etc etc
  const handleChangePage = (event, newPage) => {
    setPageArtists(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageArtists(parseInt(event.target.value, 10));
  };

  let artistsTableParams = {
    rows: artistsTableElements, headers: artistsTableHeaders, columnsWidth: artistsTableWidth,
    totalCount: artists.length > 5 ? artists.length : 0, handleChangePage, page: pageArtists,
    headerColor: cohesivePurple, handleChangeRowsPerPage, rowsPerPage: rowsPerPageArtists,
    headersHeight: 65, maxLengthChars: 50, maxWidthText: 300, rowsAlignHeaders: 'left',
    rowsAlignCells: 'left', rowsHeight: 30, needPaginationInTable: true, loading: artistsLoading
  };

  const handleChangeGroupBy = async groupByName => {
    setArtistsLoading(true);
    let artistsStateId = artistsStateNameToStateId(groupByName);
    setFilterArtistsParams({
      ...filterArtistsParams,
      groupBy: { id: artistsStateId, name: groupByName || "Todos los estados" }
    });
    let fields = artistsStateId !== 'all' ? ['state'] : [];
    let values = artistsStateId !== 'all' ? [artistsStateId] : [];
    if (searchArtistsParams.field !== 'none') { fields.push(searchArtistsParams.field); values.push(searchArtistsParams.value) };
    let artistsFinded = await toWithOutError(dispatch(getArtistByFieldsRedux(fields, values, rowsPerPageArtists * 2)));
    if (artistsFinded === "ERROR") { setArtistsLoading(false); setOpenErrorSearch(true); setArtistsFiltered([]); return "ERROR"; }
    if (artistsFinded === "EMPTY") { setArtistsLoading(false); setOpenEmptySearch(true); setArtistsFiltered([]); return "EMPTY"; }
    setArtistsLoading(false);
  }

  const groupByProps = isAdmin
    ? [{ helper: "Agrupar según", values: artistFilterStates, handleChangeGroupBy, value: filterArtistsParams.groupBy }]
    : [{ values: [] }];

  return (
    <Grid item xs={12} sx={{ textAlign: "center" }}>

      <InfoActionDialog id='need-permisos' isOpen={openNotAdminWarningArtists} handleClose={() => setOpenNotAdminWarningArtists(false)}
        title={"Necesitas permisos de Administrador"} contentTexts={resourceNotYoursText} />

      {userSelected && <UserDialog userData={userSelected} isOpen={Boolean(userSelected.id)} title={`${getEmailIfNotHaveUser(userSelected)}`}
        handleClose={handleCloseUserDialog} contentTexts={["Proximamente datos del usuario"]} rolAdmin={rol} />}

      <ArtistActionsDialog isOpen={openArtistActionsDialog.open} handleClose={() => setOpenArtistActionsDialog({ open: false, artistId: "" })}
        artistId={openArtistActionsDialog.artistId} caller={'artist-table'} />

      <Grid item xs={12} padding={0} >
        <AccountingBar searchArrayProps={allSearchers} cleanSearchResults={cleanSearchResults}
          appBarSx={appBarSx} appBarTitle='Artistas' mainSearchColor={cohesivePurple} isOpen={artistsTableIsOpen}
          handleCollapseTable={() => setArtistsTableIsOpen(!artistsTableIsOpen)} groupByArray={groupByProps} />
      </Grid>

      {artistsTableIsOpen && <Grid item xs={12} sx={{ margin: 'auto' }}>
        <CustomizedTable {...artistsTableParams} />
      </Grid>}
    </Grid>
  )
}

export default ArtistsTable;