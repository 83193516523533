import firebaseApp from 'firebaseConfig/firebase.js';
import { getFirestore, query, collection, where, getCountFromServer, getDocs, limit, orderBy } from "firebase/firestore";
import { to } from 'utils';
import { createFireStoreError } from 'redux/actions/NotificationsHandlerActions';
import { mapAlbumsCustomQueryNameToStatus } from 'utils/albums.queries.utils';
import {
  appleDeliveredStates, albumDeliveredStates, albumInfractionsStates,
  albumsWithStateIssues, albumsDownsStates
} from 'variables/varias';
import { albumsAddStore } from 'redux/actions/AlbumsActions';
import { formatDateWithOffsetAsDayJS } from 'utils/timeRelated.utils';

const db = getFirestore(firebaseApp);

export const defaultCountAlbums = {
  totalAlbums: "...", onlineAlbums: "...", appleIssues: "...", issuesAlbums: "...",
  infractionAlbums: "...", downAlbums: "...", nextToRelease: "..."
};

const getNextToReleaseWhereQuery = limitAlbums => {
  let date = new Date();
  date.setDate(date.getDate() + 5);
  const nowInDayJsFormat = formatDateWithOffsetAsDayJS(new Date());
  const nextXDaysInDayJsFormat = formatDateWithOffsetAsDayJS(date);
  return query(collection(db, 'albums'),
    where('releaseDayjsFormat', '<=', nextXDaysInDayJsFormat),
    where('releaseDayjsFormat', '>=', nowInDayJsFormat),
    orderBy("releaseDayjsFormat", "asc"), limit(limitAlbums));
}

export const getAlbumsCountByQuery = async (searchField, fieldValues, dispatch) => {
  if (searchField !== 'next-to-release' && (!Array.isArray(fieldValues) || fieldValues.length === 0)) return;
  let elementsDbFromAlbumsRef = {};
  if (fieldValues === 'next-to-release') elementsDbFromAlbumsRef = getNextToReleaseWhereQuery(200);
  else elementsDbFromAlbumsRef = query(collection(db, 'albums'), where(searchField, "in", fieldValues), orderBy("whenCreatedTS", "desc"));
  let [errorGettingElementsFromAlbum, countsFromUserSnapshot] = await to(getCountFromServer(elementsDbFromAlbumsRef));
  if (errorGettingElementsFromAlbum) {
    dispatch(createFireStoreError(`Error obteniendo los counts de la colección ALBUMS.`, errorGettingElementsFromAlbum));
    return "ERROR";
  }
  return Number(countsFromUserSnapshot.data().count);
}

export const getAlbumsBasicCounts = async (dispatch) => {
  const [onlineAlbums, appleIssues, issuesAlbums, infractionAlbums, downAlbums, nextToRelease] = await Promise.all([
    await getAlbumsCountByQuery('state', albumDeliveredStates, dispatch),
    await getAlbumsCountByQuery('state', appleDeliveredStates, dispatch),
    await getAlbumsCountByQuery('state', albumsWithStateIssues, dispatch),
    await getAlbumsCountByQuery('state', albumInfractionsStates, dispatch),
    await getAlbumsCountByQuery('state', albumsDownsStates, dispatch),
    await getAlbumsCountByQuery('next-to-release', 'next-to-release', dispatch)
  ]);
  let totalAlbums = onlineAlbums + issuesAlbums + infractionAlbums + downAlbums;
  return {
    totalAlbums, onlineAlbums, issuesAlbums, infractionAlbums,
    appleIssues, downAlbums, nextToRelease
  };
}

const getCustomQueryRefByName = (customQueryName, limitAlbums) => {
  let elementsDbFromAlbumsRef = {};
  if (customQueryName === 'all') elementsDbFromAlbumsRef = query(collection(db, 'albums'), limit(limitAlbums));
  else if (customQueryName === 'next-to-release') elementsDbFromAlbumsRef = getNextToReleaseWhereQuery(limitAlbums);
  else {
    let finalValue = mapAlbumsCustomQueryNameToStatus(customQueryName);
    elementsDbFromAlbumsRef = query(collection(db, 'albums'), where('state', "in", finalValue), limit(limitAlbums));
  }
  return elementsDbFromAlbumsRef;
}

export const getCustomQueryAlbums = async (customQueryName, limitAlbums, dispatch) => {
  let elementsDbFromAlbumsRef = getCustomQueryRefByName(customQueryName, limitAlbums);
  let [errorGettingElementsFromAlbum, albumsSnap] = await to(getDocs(elementsDbFromAlbumsRef));
  if (errorGettingElementsFromAlbum) {
    dispatch(createFireStoreError(`Error obteniendo una custom query de ALBUMS.`, errorGettingElementsFromAlbum));
    return "ERROR";
  }
  if (albumsSnap.empty) return "EMPTY";
  let results = [];
  albumsSnap.forEach(albumDoc => results.push(albumDoc.data()));
  dispatch(albumsAddStore(results));
  return results;
}
