import React, { useState } from 'react';
import {
  Button, DialogContent, DialogContentText, DialogTitle, Grid, CircularProgress, Backdrop
} from '@mui/material';
import { SUB_ORDER_PAYED, SUB_ORDER_PLAN_SELECTED } from 'variables/financial';
import { useDispatch, useSelector } from 'react-redux';
import { formatThousandsPoint, toWithOutError } from 'utils';
import { paymentsPayUpdateLocal, paymentsPayUpdated } from 'redux/actions/PaymentsActions';
import { userDataUpdateRedux } from 'redux/actions/UserDataActions';
import { createPayedPaymentInfo } from 'factory/payments.factory';
import { usingDiscountText } from 'utils/textToShow.utils';
import { getNewUserActiveInfoFromPayment } from 'factory/users.factory';
import { discountsUpdatePostPay } from 'redux/actions/DiscountsActions';

const CuponPayment = ({ onClose, show }) => {

  const dispatch = useDispatch();
  const currentUser = useSelector(store => store.userData);
  const actualPayment = useSelector(store => store.payments?.actualPayment);
  const actualDiscount = useSelector(store => store.discounts?.actualDiscount);
  const preferenceData = actualPayment.preference_data;
  const preferenceItemData = preferenceData?.items.length > 0 ? preferenceData?.items[0] : {};

  const [openLoader, setOpenLoader] = useState(false);

  const handleOk = async () => {
    setOpenLoader(true);
    let id = actualPayment.id;
    let status = SUB_ORDER_PAYED;
    let newUserActive = getNewUserActiveInfoFromPayment(currentUser, actualPayment);
    let updatePaymentNewData = createPayedPaymentInfo(actualPayment);

    let resultUpdatePay = await toWithOutError(dispatch(paymentsPayUpdated({ ...updatePaymentNewData, id, status })));
    if (resultUpdatePay === "ERROR") { setOpenLoader(false); return; }

    let resultUpdateDiscount = await toWithOutError(dispatch(discountsUpdatePostPay(actualDiscount, actualPayment)));
    if (resultUpdateDiscount === "ERROR") { setOpenLoader(false); return; }

    let fromPaymentAction = true;
    let resultUpdateUser = await toWithOutError(dispatch(userDataUpdateRedux({ ...currentUser, ...newUserActive }, fromPaymentAction)));
    if (resultUpdateUser === "ERROR") { setOpenLoader(false); return; }
    setOpenLoader(false);
    onClose();
  }

  const handleCancel = () => onClose();

  if (!show) return null;

  return (
    <Grid sx={{ maxWidth: "600px" }}>
      <Backdrop open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DialogTitle sx={{ textAlign: "center", padding: "0px 8px 0px 8px" }}>Datos de Pago</DialogTitle>

      <DialogContent sx={{ paddingBottom: 0 }}>

        <DialogContentText sx={{ fontSize: "3rem", fontWeight: 500, textAlign: 'center', paddingTop: 0 }} key={'amount-cupon-pay'}>
          {preferenceItemData.currency_id + " " + formatThousandsPoint(preferenceItemData.unit_price)}
        </DialogContentText>

        <DialogContentText key={"cupon-info"} sx={{ textAlign: 'center' }}>
          {actualDiscount.id && usingDiscountText(actualDiscount)}
        </DialogContentText>

      </DialogContent>

      <Grid container>
        <Grid item xs={6}>
          <Button onClick={handleCancel}>Cancelar</Button>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'end' }}>
          <Button onClick={handleOk}>Ok</Button>
        </Grid>
      </Grid>

    </Grid>

  );
}

export default CuponPayment;
