import firebaseApp from 'firebaseConfig/firebase.js';
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { updateMonthStatsServer } from './BackendCommunication';

const db = getFirestore(firebaseApp);

const getPaymentsStatsByDates = async dateRange => {
  let paymentStatsDoc = {}
  if (dateRange.rule === 'month') {
    paymentStatsDoc = await getDoc(doc(db, "paymentsStats", `${dateRange.year}.${dateRange.month.toString().padStart(2, '0')}`));
  }
  return paymentStatsDoc.data();
}

const orderByDayNumber = array => {
  return array.sort((a, b) => (a.name < b.name) ? -1 : 1)
}

export const getEarnsByDates = async dateRange => {
  const paymentsMonthStats = await getPaymentsStatsByDates(dateRange);
  let resultArs = []; let resultUsd = [];

  Object.keys(paymentsMonthStats).forEach((key) => {
    const value = paymentsMonthStats[key];
    if (isFinite(key)) {
      let transactionAmountArs = 0; let cuponsArs = 0; let totalPaymentsArs = 0;
      let transactionAmountUsd = 0; let cuponsUsd = 0; let totalPaymentsUsd = 0;
      for (let i = 0; i < value.currencies.length; i++) {
        let currency = value.currencies[i];
        if (currency === "ARS") {
          transactionAmountArs += Number(value.transactionAmounts[i]);
          cuponsArs += Number(value.cupons[i]);
          totalPaymentsArs += 1;
        }
        else {
          transactionAmountUsd += Number(value.transactionAmounts[i]);
          cuponsUsd += Number(value.cupons[i]);
          totalPaymentsUsd += 1;
        }
      }
      resultArs.push({ name: Number(key), transactionAmountArs, cuponsArs, totalPaymentsArs });
      resultUsd.push({ name: Number(key), transactionAmountUsd, cuponsUsd, totalPaymentsUsd });
    }
  });
  resultArs = orderByDayNumber(resultArs);
  resultUsd = orderByDayNumber(resultUsd);
  return { resultArs, resultUsd };
}


export const getStatsByDates = async dateRange => {
  let paymentsStats = {
    paymentsArs: 0, paymentsAmountArs: 0, cuponsAmountArs: 0,
    paymentsUsd: 0, paymentsAmountUsd: 0, cuponsAmountUsd: 0,
  };

  const paymentsMonthStats = await getPaymentsStatsByDates(dateRange);

  Object.keys(paymentsMonthStats).forEach((key) => {
    const value = paymentsMonthStats[key];
    if (isFinite(key)) {
      for (let i = 0; i < value.currencies.length; i++) {
        let currency = value.currencies[i];
        if (currency === "ARS") {
          paymentsStats.paymentsAmountArs += Number(value.transactionAmounts[i]);
          paymentsStats.cuponsAmountArs += Number(value.cupons[i]);
          paymentsStats.paymentsArs += 1;
        }
        else {
          paymentsStats.paymentsAmountUsd += Number(value.transactionAmounts[i]);
          paymentsStats.cuponsAmountUsd += Number(value.cupons[i]);
          paymentsStats.paymentsUsd += 1;
        }
      }
    }
  });
  return paymentsStats;
}

export const updateMonthStats = async (month, year, dispatch) => {
  const monthFormatted = month >= 10 ? month : `0${month}`;
  const resultUpdate = await updateMonthStatsServer(monthFormatted, year, dispatch);
  return resultUpdate;
}