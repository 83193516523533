// src/views/albums/AlbumTotalInfoSubComponents/AlbumHeader.jsx
import React from 'react';
import { Grid, Typography, IconButton, Card, CardContent } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { getOutLinkTo } from 'utils/tables.utils'; // Asegúrate de que este import esté bien
import { getShowArtistsNames } from 'utils/albums.utils';
import useWindowDimensions from 'customHooks/useWindowDimensions';

const AlbumHeader = ({
  isEditing,
  title,
  version,
  artists,
  handleEditTitle,
  goToPrincipalArtist,
  releaseDate,
  creationDate,
  consumerReleaseTime,
  upc,
  label,
  isAdmin,
  fugaId,
  handleEditReleaseDate,
  albumState,
  albumFugaStatus,
}) => {

  const { width } = useWindowDimensions();

  const upcLinkSx = { lineHeight: 1.5, marginLeft: "0.2em", fontWeight: 300, fontSize: "1.5em" };
  const moreInfoTextStyle = { color: "rgba(0,0,0,0.4)", whiteSpace: "nowrap", margin: "0", fontWeight: 300, fontSize: "1.5em", marginBottom: "0" };
  const stateStyle = { height: "40px", marginLeft: isEditing ? "5%" : "2%", width: '100%', marginTop: "0px" };
  const cardElementStyle = { borderRadius: "30px", width: "70em", height: "20em", margin: '5px' };
  const titleAlbumStlye = { color: "rgba(0,0,0,0.9)", whiteSpace: "nowrap", margin: "0", fontWeight: 500 };

  return (
    <Card sx={cardElementStyle}>
      <Grid item xs container direction="column">
        <Grid item xs>
          {/* Título del Álbum */}
          <Grid container item xs sx={{ height: width < 1427 ? "48px" : "60px", marginLeft: isEditing ? "0" : "2%" }}>
            {isEditing && (
              <IconButton size='small' onClick={handleEditTitle}>
                <Edit />
              </IconButton>
            )}
            <Grid item sx={{ width: "95%" }}>
              <Typography noWrap sx={{ ...titleAlbumStlye, width: "700px", fontSize: "2.5em" }}
                gutterBottom>
                {title + (version ? ` (${version})` : '')}
              </Typography>
            </Grid>
          </Grid>

          {/* Artista Principal */}
          <Grid container item xs sx={{ height: "40px", marginLeft: isEditing ? "0" : "2%" }}>
            {isEditing && (
              <IconButton size='small' aria-label='editar campo: Artista' onClick={goToPrincipalArtist}>
                <Edit />
              </IconButton>
            )}
            <Grid item sx={{ width: "95%", marginTop: isEditing ? "5px" : 0 }}>
              <Typography sx={moreInfoTextStyle}>
                {`${artists.length > 1 ? "Artistas" : "Artista"}: ${albumFugaStatus === 'fetched'
                  ? getShowArtistsNames(artists, width) : ""}`}
              </Typography>
            </Grid>
          </Grid>

          {/* Sello */}
          <Grid item xs={12} sx={{ marginLeft: isEditing ? "5%" : "2%", height: "40px" }}>
            <Typography sx={moreInfoTextStyle}>
              Sello: {label || ""}
            </Typography>
          </Grid>

          {/* UPC */}
          <Grid container item xs={12} sx={{ marginLeft: isEditing ? "5%" : "2%", height: "40px" }}>
            <Typography sx={moreInfoTextStyle}>UPC:&nbsp;</Typography>
            {isAdmin
              ? getOutLinkTo(`https://next.fugamusic.com/product/${fugaId}`, upc, upcLinkSx) || ""
              : <Typography sx={moreInfoTextStyle}>{upc || "No disponible"}</Typography>}
          </Grid>

          {/* Fecha de Creación */}
          <Grid item xs={12} sx={{ marginLeft: isEditing ? "5%" : "2%", height: "40px" }} >
            <Typography sx={moreInfoTextStyle}>
              {`Fecha de creación: ${creationDate}`}
            </Typography>
          </Grid>

          {/* Fecha de Lanzamiento */}
          <Grid container item xs sx={{ height: "40px", marginLeft: isEditing ? "0" : "2%" }}>
            {isEditing && (
              <IconButton
                size="small"
                aria-label="editar campo: Fecha Lanzamiento"
                onClick={handleEditReleaseDate}
              >
                <Edit />
              </IconButton>
            )}
            <Grid item sx={{ width: "95%", marginTop: isEditing ? "5px" : 0 }}>
              <Typography sx={moreInfoTextStyle}>
                {consumerReleaseTime
                  ? `Fecha de lanzamiento: ${releaseDate} | ${consumerReleaseTime}hs`
                  : `Fecha de lanzamiento: ${releaseDate}`}
              </Typography>
            </Grid>
          </Grid>

          {/* Estado del Álbum */}
          <Grid item sx={stateStyle}>
            <Typography sx={{ ...albumState.stateInfoStyle, fontSize: "1.5em" }}>
              {`Estado: ${albumState.stateAsStringToShow}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AlbumHeader;
