import React, { useState } from 'react';
import { mainColor, materialColor } from '../../variables/colors';
import { SUB_ORDER_PLAN_SELECTED, planesLaFlota } from '../../variables/financial';
import { Grid, Card, CardActions, CardContent, CardMedia, Button, Typography, Backdrop, CircularProgress } from '@mui/material';
import { artistsCantSubNameText, artistsCantSubText, artistsCantText, getPlanCardButtonText } from 'utils/plans.utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { paymentsPreferenceCreated } from 'redux/actions/PaymentsActions';
import { createMpPreferenceData } from 'factory/payments.factory';
import { formatThousandsPoint, toWithOutError } from 'utils';
import { userIsActive } from 'utils/users.utils';

import { createTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const theme = createTheme();

const useStyles = makeStyles(() => ({
  responsiveText: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '16px', // Adjust the font size for xs screens
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px', // Adjust the font size for sm screens
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px', // Adjust the font size for md screens
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '28px', // Adjust the font size for lg screens
    },
  },
}));

const PlanCard = ({ upgrading, setOpenSellosDialog, planInfo, sellosPlans }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actualPayment = useSelector(store => store.payments.actualPayment);
  const userData = useSelector(store => store.userData);
  const isUserActive = userIsActive(userData.userStatus);

  const [openLoader, setOpenLoader] = useState(false);

  let { title, subtitle, imgSource, maxArtists, priceArs, priceUsd, discountText } = planInfo;

  const showBuyButtons = upgrading || !isUserActive;
  const userActualPlanName = isUserActive ? planesLaFlota.find(plan => plan.id === userData.plan)?.title : "";
  const isCurrentPlan = title === userActualPlanName;
  const currentPlan = sellosPlans.find(plan => plan.id === userData.plan) || planInfo;
  const currentPlanIsSello = maxArtists >= 5 && currentPlan.maxArtists >= 5;
  const borderHeight = 5;
  const totalHeight = 500;

  let maxWidthCard = 400
  let artistText = artistsCantText(currentPlanIsSello ? currentPlan.maxArtists : maxArtists, upgrading, isCurrentPlan);
  let artistSubText = artistsCantSubText(currentPlanIsSello ? currentPlan.maxArtists : maxArtists, upgrading, isCurrentPlan, discountText);
  let artistSubNameText = artistsCantSubNameText(currentPlanIsSello ? currentPlan.maxArtists : maxArtists, upgrading, isCurrentPlan, subtitle);
  let planCardButtonText = getPlanCardButtonText(currentPlanIsSello ? currentPlan.maxArtists : maxArtists, upgrading, isCurrentPlan, isUserActive)

  const cardStyle = {
    maxWidth: isCurrentPlan ? maxWidthCard - borderHeight * 2 : maxWidthCard,
    height: isCurrentPlan ? totalHeight - borderHeight * 2 : totalHeight,
    backgroundColor: materialColor, borderRadius: "4em", marginTop: "7%", width: "95%",
    border: isCurrentPlan ? 5 : 0, borderColor: mainColor,
  };

  const handleOnClickPlan = async () => {
    if (isCurrentPlan && !currentPlanIsSello) return;
    if (isCurrentPlan && currentPlanIsSello && !upgrading) return;
    if (maxArtists >= 5) setOpenSellosDialog(true);
    else {
      setOpenLoader(true);
      // En createMpPreferenceData chequeo si existe un pago sin terminar ni que no haya pedido reembolso.
      let preference_data = createMpPreferenceData(planInfo, actualPayment);
      let createPaymentDoc = await toWithOutError(dispatch(paymentsPreferenceCreated({
        status: SUB_ORDER_PLAN_SELECTED, preference_data, userId: userData.id, id: preference_data.external_reference,
        plan: planInfo.id, userEmail: userData.email
      }, userData)));
      if (createPaymentDoc === "ERROR") { setOpenLoader(false); return };
      setOpenLoader(false);
      let checkoutUrl = upgrading ? `/admin/checkout?upgrading=true` : "/admin/checkout";
      navigate(checkoutUrl);
    }
  }

  return (
    <Card sx={cardStyle} raised={true}>

      <Backdrop open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <CardMedia
        component="img"
        height={isCurrentPlan ? 226 : 230}
        image={imgSource}
        alt="image plan"
      />
      <CardContent>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography sx={{ fontSize: 18, fontWeight: 400 }} color="white">
            {title}
          </Typography>
          <Typography sx={{ fontSize: 18, fontWeight: 350 }} color="white">
            {artistSubNameText}
          </Typography>
          <Typography sx={{ fontSize: 22, fontWeight: 500 }} color="white">
            {(currentPlanIsSello && !upgrading) ? `AR$ ${formatThousandsPoint(currentPlan.priceArs)}/año` : `AR$ ${formatThousandsPoint(priceArs)}/año`}
          </Typography>
          <Typography sx={{ fontSize: 12, fontWeight: 300 }} color="white">
            {(currentPlanIsSello && !upgrading) ? `USD ${currentPlan.priceUsd}/año` : `USD ${priceUsd}/año`}
          </Typography>
          <Grid sx={{ height: 30 }}>
            <Typography sx={{ fontSize: 12, fontWeight: 200 }} color="white">
              {artistSubText}
            </Typography>
          </Grid>
          <Typography color="white" sx={{ marginTop: 2 }} className={classes.responsiveText}>{artistText}</Typography>
        </Grid>
      </CardContent>

      {(showBuyButtons || isCurrentPlan) && <CardActions sx={{ justifyContent: 'center', }}>
        <Button onClick={handleOnClickPlan} size="small" variant='contained' sx={{ color: "white", fontSize: "1em", width: '12em' }}>
          {planCardButtonText}
        </Button>
      </CardActions>}
    </Card>
  );
}

export default PlanCard;

